import React from "react";

export type DropdownItemProps = {
  helperText?: string;
  label: string;
  value: string;
  isSelected: boolean;
  isFocused?: boolean;
  selectItem: (value: string) => void;
};

const DropdownItem = ({
  label,
  value,
  isSelected,
  helperText,
  isFocused,
  selectItem,
}: DropdownItemProps) => {
  const dropdownItemClass = React.useMemo(() => {
    const selectedClass = isSelected ? ["dropdown-item--selected"] : [];
    const focusedClass = isFocused ? ["dropdown-item--focused"] : [];

    return ["dropdown-item", ...selectedClass, ...focusedClass].join(" ");
  }, [isFocused, isSelected]);

  const selectOptionInner = React.useCallback(() => {
    selectItem(value);
  }, [selectItem, value]);

  return (
    <div className={dropdownItemClass} onClick={selectOptionInner}>
      <span>{label}</span>
      {helperText && (
        <span className="dropdown-item__helper-text">{helperText}</span>
      )}
    </div>
  );
};

DropdownItem.defaultProps = {
  isFocused: false,
  helperText: undefined,
};

export default DropdownItem;
