import React from "react";
import Button from "../components/Button/Button";
import Text from "../components/Text/Text";
import InformationDialog from "../components/Dialog/InformationDialog";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Toast from "../components/Toast/Toast";
import useNlc026DbActions from "../hooks/pages/NLC026/useNlc026DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";

type NLC026Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  name: string;
  plan: string;
  parentUserName: string | null;
  span: number | null;
  lastBillingDate: string;
};

const NLC026: React.FC<NLC026Props> = ({
  isOpen,
  setIsOpen,
  name,
  plan,
  parentUserName,
  span,
  lastBillingDate,
}) => {
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const { handleCancellation, actionError } = useNlc026DbActions();
  const navigate = useNavigateCustom();

  // エラー処理
  useCheckErrorThrowError([actionError]);

  // トーストクローズ
  const closeToast = React.useCallback(() => {
    setIsToastOpen(false);
    setIsOpen(false);
    // 会員一覧画面に遷移
    navigate(ADMIN.NLC035);
  }, [setIsOpen, navigate]);

  // 解約ボタン
  const handleCancellationButton = React.useCallback(() => {
    handleCancellation(setIsToastOpen, setIsOpen);
  }, [handleCancellation, setIsToastOpen, setIsOpen]);

  // 閉じる、キャンセルボタン
  const handleCloseButton = React.useCallback(
    () => setIsOpen(false),
    [setIsOpen],
  );

  return (
    <>
      <InformationDialog
        open={isOpen}
        width="464px"
        title="退会させますか？"
        closeDialog={handleCloseButton}
        alertLevel="error"
        footer={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                width="50px"
                color="danger"
                onClick={handleCancellationButton}
              >
                退会
              </Button>
              <Button
                type="sub"
                width="88px"
                color="neutral"
                onClick={handleCloseButton}
              >
                キャンセル
              </Button>
            </LayoutBox>
          </>
        }
      >
        <LayoutBox direction="column" gap="2x" fullHeight fullWidth>
          <LayoutBox gap="none" direction="column" fullWidth fullHeight>
            <Text size="base">
              {name}様の下記サブスクリプションを解約します。
            </Text>
            <br />
            {parentUserName && (
              <Text size="base">招待者(支払い者):{parentUserName}様</Text>
            )}
            <Text size="base">{plan}</Text>
            <Text size="base">最終更新日:{lastBillingDate}</Text>
            {span === 2 && <Text size="base">※残月数に注意してください。</Text>}
          </LayoutBox>
        </LayoutBox>
      </InformationDialog>
      <Toast open={isToastOpen} onClose={closeToast} displayTime={2000}>
        ユーザーの解約が完了しました
      </Toast>
    </>
  );
};

export default NLC026;
