import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeNlc017CancelDialog,
  closeNlc017SendDialog,
} from "./useNlc017Dialog";
import useNlc017Conditions from "./useNlc017Conditions";

const useNlc017DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [actionError, setActionError] = useState<Error | null>(null);
  const [{ selectPetId }] = useNlc017Conditions();

  const sendResult = () => {
    void (async () => {
      try {
        closeNlc017SendDialog();
        openUpdateActionLoading();

        // ユーザ報告API
        await currentUser?.functions["booking/userReport"](selectPetId);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約取消
  const cancelReservation = () => {
    void (async () => {
      try {
        closeNlc017CancelDialog();
        openUpdateActionLoading();

        // 予約取消API
        await currentUser?.functions["booking/cancelBooking"](selectPetId);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, sendResult, cancelReservation };
};

export default useNlc017DBActions;
