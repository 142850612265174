import React from "react";
import Icon from "../Icon/Icon";

export type LinkProps = {
  icon?: string;
  size?: string;
  url?: string;
  disabled?: boolean;
  external?: boolean;
  children: React.ReactNode;
  linkClassName?: string;
};

const Link = ({
  size = "medium",
  icon = "",
  url = "",
  disabled = false,
  external = true,
  children,
  linkClassName = "",
}: LinkProps) => {
  const linkClass = React.useMemo(() => {
    const sizeClass = [`link--${size}`];
    const disabledClass = disabled ? ["link--disabled"] : [];

    return ["link", ...sizeClass, ...disabledClass].join(" ");
  }, [disabled, size]);

  const target = React.useMemo(
    () => (external ? "_blank" : "_self"),
    [external],
  );

  return (
    <span className={linkClass}>
      <a href={url} target={target} className={linkClassName}>
        {icon && <Icon icon={icon} size={size === "medium" ? "xs" : "2xs"} />}

        <span className="link__content">{children}</span>
        {external && (
          <Icon
            className="external-link-icon"
            icon="open_in_new"
            size={size === "medium" ? "xs" : "2xs"}
          />
        )}
      </a>
    </span>
  );
};

Link.defaultProps = {
  icon: "",
  size: "medium",
  url: "",
  disabled: false,
  external: true,
  linkClassName: "",
};

export default Link;
