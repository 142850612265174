import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNlc028CancelDialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc028CancelDialog = () => {
  stateReactiveVar(true);
};

export const closeNlc028CancelDialog = () => {
  stateReactiveVar(false);
};

export default useNlc028CancelDialog;
