import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  organizationName: ValidationResultType<string>;
  tel: ValidationResultType<string>;
  address: ValidationResultType<string>;
};

// バリデーションの不要な型定義
type DefaultType = {
  campaignCouponId: string;
  campaignError: string;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  organizationName: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  tel: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  address: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  campaignCouponId: "",
  campaignError: "",
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useOrganizationInput = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setOrganizationName = (
  value: DefaultValidationType["organizationName"]["value"],
) => {
  _setValidation(
    "organizationName",
    value,
    VALIDATION_TYPE.ORGANIZATION_NAME_REQUIRED,
  );
};
export const setTel = (value: DefaultValidationType["tel"]["value"]) => {
  _setValidation("tel", value, VALIDATION_TYPE.TEL_REQUIRED);
};
export const setAddress = (
  value: DefaultValidationType["address"]["value"],
) => {
  _setValidation("address", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};

export const setCampaignCouponId = (value: DefaultType["campaignCouponId"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    campaignCouponId: value,
  });
};

export const setCampaignError = (value: DefaultType["campaignError"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    campaignError: value,
  });
};

// 値をすべて更新
export const setOrganizationInput = ({
  organizationName,
  tel,
  address,
  campaignCouponId,
}: {
  organizationName: DefaultValidationType["organizationName"]["value"];
  tel: DefaultValidationType["tel"]["value"];
  address: DefaultValidationType["address"]["value"];
  campaignCouponId: DefaultType["campaignCouponId"];
}) => {
  setOrganizationName(organizationName);
  setTel(tel);
  setAddress(address);
  setCampaignCouponId(campaignCouponId);
};

// 初期化
export const setInitOrganizationInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkOrganizationInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    campaignCouponId: _campaignCouponId,
    campaignError: _campaignError,
    ...validationInfos
  } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useOrganizationInput;
