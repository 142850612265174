import React from "react";
import RadioButtonGroupRow from "./RadioButtonGroupRow";
import RadioButton from "./RadioButton";
import RadioButtonIcon from "./RadioButtonIcon";

export type RadioButtonGroupProps = {
  name: string;
  vertical?: boolean;
  column?: 2 | 3 | 4 | 5 | 6;
  rowItems?: {
    column: number;
    items: {
      label: string;
      value: string;
    }[];
  }[];
  items?: {
    label: string;
    value: string;
  }[];
  iconItems?: {
    title: string;
    value: string;
    disabled: boolean;
  }[];
  onChange: (
    value: string,
  ) => void | ((value: number) => void) | ((value: boolean) => void);
  selectedValue: string;
  withBorder?: boolean;
  width?: string;
  radioGap?: string;
};

const RadioButtonGroup = ({
  name,
  vertical = false,
  column,
  rowItems = [],
  items = [],
  iconItems = [],
  onChange,
  selectedValue,
  withBorder = false,
  width,
  radioGap = "",
}: RadioButtonGroupProps) => {
  const radioClass = React.useMemo(() => {
    const classes = ["radio-group"];
    if (vertical) {
      classes.push("radio-group--vertical");
    }
    if (column) {
      classes.push(`radio-group__column--${column}`);
    }
    if (rowItems?.length > 0) {
      classes.push(`radio-group--multi-column`);
    }

    return classes.join(" ");
  }, [column, rowItems, vertical]);

  const onSelect = React.useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <div className={radioClass} style={{ gap: radioGap }}>
      {rowItems !== undefined &&
        rowItems.map((row, index) => (
          <RadioButtonGroupRow
            column={row.column}
            items={row.items}
            name={name}
            onSelect={onSelect}
            key={`radio-button-row-${index}`}
            selectedValue={selectedValue}
            radioGap={radioGap}
          />
        ))}
      {items !== undefined &&
        items.map(({ label, ...item }) => (
          <RadioButton
            {...item}
            name={name}
            onSelect={onSelect}
            checked={selectedValue === item.value}
            key={`${name}-${item.value}`}
            withBorder={withBorder}
            width={width}
          >
            {label}
          </RadioButton>
        ))}
      {iconItems !== undefined &&
        iconItems.map(({ ...iconItem }) => (
          <RadioButtonIcon
            {...iconItem}
            onSelect={onSelect}
            checked={selectedValue === iconItem.value}
            key={`${name}-${iconItem.value}`}
            name={name}
            withBorder={withBorder}
            title={iconItem.title}
            width={width}
          />
        ))}
    </div>
  );
};

RadioButtonGroup.defaultProps = {
  vertical: false,
  column: undefined,
  rowItems: [],
  items: [],
  withBorder: false,
  iconItems: [],
  width: "",
  radioGap: "",
};

export default RadioButtonGroup;
