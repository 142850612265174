import React, { useEffect } from "react";

import Header from "./Header";
import Link from "../Link/Link";
import Button from "../Button/Button";
import AccountMenu from "../Menu/AccountMenu";
import InfoMenu from "../Menu/InfoMenu";
import NLI005 from "../../pages/NLI005";
import MIE007 from "../../pages/MIE007";
import { useRealmApp } from "../../contexts/RealmApp";
import NLI027 from "../../pages/NLI027";
import { convertAdminRole } from "../../utils/convertDisplay";
import { ADMIN, HEADER_LINK } from "../../constants/pagePaths";
import { Collection } from "../../constants/common";
import { getMongoDb } from "../../utils/query";
import useForceUpdate from "../../hooks/common/useForceUpdate";
import { useCheckErrorThrowError } from "../../utils/checkError";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useNavigateCustom } from "../../hooks/base/usePageTransitionCustom";
import Logo from "../Logo/Logo";

export type CommonHeaderProps = {
  showInfo?: boolean;
};

type User = {
  full_name: string;
  admin_role: number;
  user_id: string;
};

const CommonHeader = ({ showInfo }: CommonHeaderProps) => {
  const appContext = useRealmApp();
  const [isUserDetailDialog, setIsUserDetailDialog] = React.useState(false);
  const [isPassDialog, setIsPassDialog] = React.useState(false);
  const [isEmailDialog, setIsEmailDialog] = React.useState(false);
  const [user, setUser] = React.useState({
    id: "",
    position: "",
    name: "",
  });
  const [forceUpdate] = useForceUpdate();
  const [error, setError] = React.useState<Error | null>(null);

  const navigate = useNavigateCustom();

  // ユーザー表示
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userCollection = getMongoDb(
          appContext.currentUser,
          Collection.USERS,
        );
        const userDocument = (await userCollection.findOne(
          {
            _id: appContext.currentUser?.customData._id as string,
          },
          { projection: { _id: 0, user_id: 1, full_name: 1, admin_role: 1 } },
        )) as User;
        setUser({
          id: userDocument.user_id,
          position: convertAdminRole(userDocument.admin_role),
          name: userDocument.full_name,
        });
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    void fetchUser();
  }, [appContext.currentUser, forceUpdate]);

  useCheckErrorThrowError([error]);

  // プロフィール
  const handleShowProfile = () => {
    setIsUserDetailDialog(true);
  };

  // ログアウト
  const handleLogout = () => {
    void appContext.logOut();
    navigate(ADMIN.NLA001);
  };

  // ベル
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShowInfo = (index: number) => {
    // 2次以降フェーズ対応: phase2に対応予定
  };

  // パスワード変更
  const handleChangePass = () => {
    setIsPassDialog(true);
  };

  // ヘルプ
  const handleShowHelp = () => {
    // 2次以降フェーズ対応: 外部リンクを想定
  };

  // メールアドレス変更
  const handleChangeEmail = () => {
    setIsEmailDialog(true);
  };

  return (
    <Header
      leftControl={
        <Link
          url={HEADER_LINK}
          external={false}
          linkClassName="header__left-logo"
        >
          <Logo size="small" type="white-horizontal" />
        </Link>
      }
      rightControl={
        <>
          {showInfo && <InfoMenu infos={[]} onClick={handleShowInfo} />}
          <div className="header__right-icon">
            <Button
              type="secondary"
              color="background-dark"
              size="medium"
              icon="help_outline"
              onClick={handleShowHelp}
              borderLess
            />
          </div>
          <AccountMenu
            user={user}
            onClickProfile={handleShowProfile}
            onClickChangePass={handleChangePass}
            onClickLogout={handleLogout}
            onClickChangeEmail={handleChangeEmail}
          />
          {isUserDetailDialog && (
            <NLI005
              isOpen={isUserDetailDialog}
              setIsOpen={setIsUserDetailDialog}
            />
          )}
          {isPassDialog && (
            <MIE007 isOpen={isPassDialog} setIsOpen={setIsPassDialog} />
          )}
          {isEmailDialog && (
            <NLI027 isOpen={isEmailDialog} setIsOpen={setIsEmailDialog} />
          )}
        </>
      }
    />
  );
};

CommonHeader.defaultProps = {
  showInfo: true,
};

export default CommonHeader;
