import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import useOrganizationId, {
  setOrganizationId,
} from "../../common/useOrganizationId";
import { ADMIN } from "../../../constants/pagePaths";
import { useNavigateCustom } from "../../base/usePageTransitionCustom";
import { closeNli021Dialog } from "./useNli021Dialog";

const useNli021DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [organizationId] = useOrganizationId();
  const navigateCustom = useNavigateCustom();

  // 団体削除
  const deleteOrganization = () => {
    void (async () => {
      closeNli021Dialog();
      openUpdateActionLoading();
      try {
        const collection = getMongoDb(currentUser, Collection.ORGANIZATIONS);
        await collection.deleteOne({ _id: organizationId });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setOrganizationId("");
        navigateCustom(ADMIN.NLI019);
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    deleteOrganization,
    actionError,
  };
};

export default useNli021DbActions;
