import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNli031Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNli031Dialog = () => {
  stateReactiveVar(true);
};

export const closeNli031Dialog = () => {
  stateReactiveVar(false);
};

export default useNli031Dialog;
