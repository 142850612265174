import { useState, useEffect } from "react";

import { getMongoDb, getAggregateNli016 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  ROLE_KEY,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import {
  closeActionLoading,
  openDeleteActionLoading,
} from "../../base/useLoadingAction";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import {
  checkMasterRole,
  useCheckHasRole,
} from "../../common/useMasterRoleDbActions";

type Nli016FetchParams = {
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLI016;
};

const useNli016DbActions = ({ submittedSearchCriteria }: Nli016FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchResult, setFetchResult] = useStateCustomObj<
    hospitalNli016StateType[]
  >([]);

  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const { search } = submittedSearchCriteria;

  const colName = "_id";
  const sortDirection = "asc";

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.MASTER_HOSPITAL_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDbNotifications = getMongoDb(
          currentUser,
          Collection.HOSPITALS,
        );
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNli016(
          search,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDbNotifications.aggregate(
          aggregate,
        )) as AggregateResultType<hospitalNli016StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    if (hasBrowsingRole) void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    search,
    setFetchResult,
    forceUpdate.forceUpdateCount,
    hasBrowsingRole,
  ]);

  // 医療機関一括削除
  const handleHospitalDelete = (hospitalIdArray: string[]) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_EDIT, currentUser);

        const notificationsCollection = getMongoDb(
          currentUser,
          Collection.HOSPITALS,
        );
        await notificationsCollection.deleteMany({
          _id: { $in: hospitalIdArray },
        });
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    fetchError,
    handleHospitalDelete,
    actionError,
  };
};

export default useNli016DbActions;
