import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

const initialState = localStorage.getItem("orderId") || "";
const stateReactiveVar = createReactiveVar<string>(initialState);

const useOrderId = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setOrderId = (id: string) => {
  stateReactiveVar(id);
  localStorage.setItem("orderId", id);
};

export default useOrderId;
