import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import ProgressBar from "../components/Loading/ProgressBar";
import Badge from "../components/Badge/Badge";
import useNlc012Dialog, {
  closeNlc012Dialog,
} from "../hooks/pages/NLC012/useNlc012Dialog";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { convertPatientInfo } from "../utils/convertDisplay";
import {
  NOUKNOW_SCORE_TEXT,
  PatientNouKnowStatusID,
  PatientNouKnowStatusLabels,
  ROLE_KEY,
} from "../constants/common";
import { getNouKnowTagState, getNouknowColor } from "../utils/utils";
import useNlc012Conditions from "../hooks/pages/NLC012/useNlc012Conditions";
import useNlc012DbActions from "../hooks/pages/NLC012/useNlc012DbActions";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLC012 = () => {
  const [isOpen] = useNlc012Dialog();
  const [nouknowTestResult] = useNlc012Conditions();
  const { fetchError, actionError, confirmSelection } = useNlc012DbActions();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();

  const { name, birth, genderStr, tel, email } =
    convertPatientInfo(patientInfo);

  // 確認済みボタン権限
  const hasNoConfirmStatus = !(
    nouknowTestResult.status === PatientNouKnowStatusID.UPLOADED
  );
  const hasConfirmRole = useCheckHasRole(ROLE_KEY.USER_SCREENING_CONFIRM);
  const disabledConfirm = !hasConfirmRole || hasNoConfirmStatus;

  useCheckErrorThrowError([fetchError, actionError, patientFetchError]);

  const concentrationColor = getNouknowColor(
    nouknowTestResult.concentrationScore,
  );
  const memoryColor = getNouknowColor(nouknowTestResult.memoryScore);

  return (
    <>
      <DialogCustom
        title="レポート"
        size="large"
        height="840px"
        open={isOpen}
        closeDialog={closeNlc012Dialog}
        footerRight={
          <LayoutBox gap="1x">
            <Button
              onClick={closeNlc012Dialog}
              color="neutral"
              type="sub"
              size="large"
              width="108px"
            >
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              width="96px"
              onClick={confirmSelection}
              disabled={disabledConfirm}
            >
              確認済み
            </Button>
          </LayoutBox>
        }
      >
        <LayoutBox direction="column" gap="4x" fullWidth>
          <Sheet type="border-blue" padding="16px 24px">
            <LayoutBox direction="column" gap="2x" fullWidth>
              <LayoutBox direction="column" gap="2x" fullWidth>
                <Tag
                  showIcon={false}
                  label={
                    PatientNouKnowStatusLabels[
                      nouknowTestResult.status as keyof typeof PatientNouKnowStatusLabels
                    ]
                  }
                  state={getNouKnowTagState(
                    PatientNouKnowStatusLabels[
                      nouknowTestResult.status as keyof typeof PatientNouKnowStatusLabels
                    ],
                  )}
                />
                <Text size="2xl" bold>
                  {name}
                </Text>
              </LayoutBox>
              <LayoutBox direction="column" gap="1x" fullWidth>
                <FormSet
                  labelWidth="120px"
                  labelSize="medium"
                  label="受検日"
                  base
                  formVertical={false}
                >
                  <LayoutBox align="center" fullWidth>
                    <Text size="small">{nouknowTestResult.date}</Text>
                  </LayoutBox>
                </FormSet>
                <LayoutBox gap="3x" justify="between" fullWidth>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="生年月日"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{birth}</Text>
                    </LayoutBox>
                  </FormSet>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="性別"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{genderStr}</Text>
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
                <LayoutBox gap="3x" justify="between" fullWidth>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="電話番号"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{tel}</Text>
                    </LayoutBox>
                  </FormSet>
                  <FormSet
                    labelWidth="120px"
                    labelSize="medium"
                    label="メールアドレス"
                    base
                    formVertical={false}
                  >
                    <LayoutBox align="center" fullWidth>
                      <Text size="small">{email}</Text>
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          <LayoutBox direction="column" gap="2x" fullWidth>
            <Sheet type="header" padding="4px 8px">
              <Text size="xl" bold>
                テスト結果
              </Text>
            </Sheet>
            <LayoutBox gap="2x" direction="column" maxWidth="305px" fullWidth>
              <LayoutBox direction="column" fullWidth>
                <LayoutBox justify="between" fullWidth>
                  <Text size="large">集中力スコア</Text>
                  <LayoutBox gap="1x" align="center">
                    <Text size="2xl" bold>
                      {nouknowTestResult.concentrationScoreValue.toFixed(1)}
                    </Text>
                    <Badge
                      label={nouknowTestResult.concentrationScore}
                      size="large"
                      color={concentrationColor.badge}
                    />
                  </LayoutBox>
                </LayoutBox>
                <ProgressBar
                  width="305px"
                  biggerBar
                  percentage={
                    (100 * nouknowTestResult.concentrationScoreValue) / 50
                  }
                  barColor={concentrationColor.other}
                />
                <Text size="large" bold color={concentrationColor.other}>
                  {NOUKNOW_SCORE_TEXT[
                    nouknowTestResult.concentrationScore as keyof typeof NOUKNOW_SCORE_TEXT
                  ] ?? ""}
                </Text>
              </LayoutBox>
              <LayoutBox direction="column" fullWidth>
                <LayoutBox justify="between" fullWidth>
                  <Text size="large">記憶力スコア</Text>
                  <LayoutBox gap="1x" align="center">
                    <Text size="2xl" bold>
                      {nouknowTestResult.memoryScoreValue.toFixed(1)}
                    </Text>
                    <Badge
                      color={memoryColor.badge}
                      label={nouknowTestResult.memoryScore}
                      size="large"
                    />
                  </LayoutBox>
                </LayoutBox>
                <ProgressBar
                  width="305px"
                  biggerBar
                  percentage={(100 * nouknowTestResult.memoryScoreValue) / 50}
                  barColor={memoryColor.other}
                />
                <Text size="large" bold color={memoryColor.other}>
                  {NOUKNOW_SCORE_TEXT[
                    nouknowTestResult.memoryScore as keyof typeof NOUKNOW_SCORE_TEXT
                  ] ?? ""}
                </Text>
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      </DialogCustom>
    </>
  );
};

export default NLC012;
