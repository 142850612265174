import { PatientNouKnowStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  status: PatientNouKnowStatusID;
  selectNouknowId: string;
};

// 結果、ステータスのセレクトボックス
const stateReactiveVar = createReactiveVar<ConditionsType>({
  status: PatientNouKnowStatusID.ALL,
  selectNouknowId: "",
});
const useNlc014Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setStatus = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    status: Number(selectStr),
  });
};

export const setSelectNouknowId = (selectNouknowId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectNouknowId,
  });
};

export default useNlc014Conditions;
