import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  PatientBrainCheckStatusID,
} from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import { FileType } from "../../../components/FileUpload/FileUpload";
import useNlc031Conditions from "./useNlc031Conditions";
import { closeNlc031Dialog } from "./useNlc031Dialog";
import { closeNlc031CancelDialog } from "./useNlc031CancelDialog";

type GcsUrl = {
  file_name: string;
  signedURL: string;
};

type UploadUpdateData = {
  report_path?: string;
  updated_at: Date;
  note?: string;
  status: number;
  commented_user_id?: string;
};

const useNlc031DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [{ brainCheckId, note }] = useNlc031Conditions();

  // 診断画像アップロード
  const uploadFile = async (
    files: FileType[],
    setUploadFiles: React.Dispatch<React.SetStateAction<FileType[]>>,
  ) => {
    openUpdateActionLoading();
    try {
      // Blob URLからBlobオブジェクトを取得
      const blobResponse = await fetch(files[0].url);
      if (!blobResponse.ok) {
        const error = new Error("Failed to read Blob");
        setActionError(checkActionErr(error));

        return;
      }
      const blob = await blobResponse.blob();

      // GCS URL取得
      const gcsUrl = (await currentUser?.functions["braincheck/uploadSignUrl"](
        brainCheckId,
        files[0].name,
      )) as GcsUrl;

      // アップロード
      const uploadResponse = await fetch(gcsUrl.signedURL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: blob,
      });

      if (uploadResponse.ok) {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const updateData: UploadUpdateData = {
          report_path: gcsUrl.file_name,
          status: PatientBrainCheckStatusID.COMPLETED,
          commented_user_id: currentUser?.id ?? "",
          updated_at: new Date(),
        };

        if (note) {
          updateData.note = note;
        }

        await collection.updateOne({ _id: brainCheckId }, { $set: updateData });
        setUploadFiles([]);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNlc031Dialog();
      } else {
        const error = new Error("Failed to upload File");
        setActionError(checkActionErr(error));
      }
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      closeActionLoading();
    }
  };

  // 結果送信取消
  const sendCancel = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const updateData: UploadUpdateData = {
          status: PatientBrainCheckStatusID.COMPLETED,
          updated_at: new Date(),
        };

        await collection.updateOne({ _id: brainCheckId }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc031Dialog();
        closeNlc031CancelDialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    uploadFile,
    sendCancel,
    actionError,
  };
};

export default useNlc031DbActions;
