import React, { useEffect, useMemo, useRef, useState } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Text from "../components/Text/Text";
import Input from "../components/Input/Input";
import {
  ADMIN_USER_DETAIL_CONTACT_ITEMS,
  FORMAT_STYLE,
} from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import Avatar from "../components/Avatar/Avatar";
import Icon from "../components/Icon/Icon";
import Sheet from "../components/Sheet/Sheet";
import useUserDetailTabConditions, {
  setUserDetailTab,
} from "../hooks/commonCondition/useUserDetailTabConditions";
import useChatsDbActions from "../hooks/common/useChatsDbActions";
import { formatDate } from "../utils/utils";
import FormSet from "../components/FormSet/FormSet";

type UploadFileType = Array<FileType & { intervalId?: NodeJS.Timer }>;

/**
 *  ドクターまたはユーザーのチャットコンポーネントを取得します。
 */
const getCommentComponent = (
  chat: Partial<Chats>,
  index: number,
  getSignedUrl: (props: { fileGcsPath: string }) => Promise<string>,
) => {
  const formatPostingTime = (date: Date | undefined) =>
    formatDate(date, FORMAT_STYLE["YYYY/MM/DD HH:MM"]);

  const {
    created_at: createdAt,
    comment,
    commented_user: commentedUser,
    file_name: fileName,
    file_gcs_path: fileGcsPath,
    admin_user_name: adminUserName,
    monthly_report: monthlyReport,
  } = chat;

  if (commentedUser === "admin") {
    return (
      <div className="util-pl-120" key={index} style={{ width: "100%" }}>
        <LayoutBox gap="1x" justify="end">
          <Sheet padding="16px" type="primary-lightest" borderRadius="8px">
            <LayoutBox direction="column">
              <Text color="neutralLight" size="small">
                {formatPostingTime(createdAt)}
              </Text>
              <LayoutBox direction="column" gap="none">
                <Text whiteSpace="pre-line">
                  {monthlyReport && "【月次レポート】"}
                  {monthlyReport && <br />}
                  {comment}
                </Text>
              </LayoutBox>
              {fileName && fileGcsPath && (
                <LayoutBox gap="1/2x" align="center">
                  <Icon icon="attach_file" size="xs" />
                  <a
                    href={fileGcsPath}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      void (async () => {
                        const signedUrl = await getSignedUrl({
                          fileGcsPath,
                        });
                        window.open(signedUrl, "_blank");
                      })();
                    }}
                  >
                    {fileName}
                  </a>
                </LayoutBox>
              )}

              <Text color="neutralLight" size="small">
                応対者：{adminUserName}
              </Text>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </div>
    );
  }

  return (
    <div className="util-pr-40" key={index} style={{ width: "100%" }}>
      <LayoutBox gap="1x">
        <Avatar />
        <Sheet padding="16px" type="gray" borderRadius="0px 8px 8px 8px">
          <LayoutBox direction="column">
            <Text color="neutralLight" size="small">
              {formatPostingTime(createdAt)}
            </Text>
            <LayoutBox direction="column" gap="none">
              <Text whiteSpace="pre-line">{comment}</Text>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
      </LayoutBox>
    </div>
  );
};

const Chat = () => {
  const [formValue, setFormValue] = useState("");
  const [shouldScroll, setShouldScroll] = useState(true);
  const scrollableArea = useRef<HTMLDivElement>(null);
  const [searchCriteria, setSearchCriteria] = React.useState("");
  const [isMonthlyReport, setIsMonthlyReport] = React.useState(false);

  const { allChatData, addComment, getSignedUrl, fetchError, actionError } =
    useChatsDbActions();
  const [{ userDetailTab }] = useUserDetailTabConditions();
  const [uploadFiles, setUploadFiles] = React.useState<UploadFileType>([]);

  // 検索、絞り込み後に表示するデータ
  const displayChatData = useMemo(
    () =>
      allChatData.filter((x) => {
        const comment = x.comment ?? "";

        return (
          searchCriteria.trim() === "" ||
          comment.toLowerCase().includes(searchCriteria.toLowerCase())
        );
      }),
    [allChatData, searchCriteria],
  );

  useCheckErrorThrowError([fetchError, actionError]);

  useEffect(() => {
    if (scrollableArea.current && shouldScroll && allChatData.length > 1) {
      // 初回チャット読み込み時 or shouldScrollがtrueの場合、最新のチャットにスクロール
      const scrollPosition = scrollableArea.current.scrollHeight;
      scrollableArea.current.scrollTop = scrollPosition;
      setShouldScroll(false);
    }
  }, [allChatData, shouldScroll]);

  // 検索
  const handleChangeSearch = (value: string) => {
    setSearchCriteria(value);
  };

  // 送信ボタン押下
  const onSubmitBtnClicked = () => {
    void (async () => {
      await addComment({
        comment: formValue,
        uploadFiles,
        isMonthlyReport,
      });

      // クリア
      setFormValue("");
      setIsMonthlyReport(false);
      setUploadFiles([]);

      // 最新のチャットにスクロール
      setShouldScroll(true);
    })();
  };

  // キャンセル
  const handleClear = () => {
    setFormValue("");
    setIsMonthlyReport(false);
    setUploadFiles([]);
  };

  return (
    <>
      <div className="admin-user__info-detail">
        <div className="util-flex util-px-16 util-flex--grow-one util-overflow-y">
          <LayoutBox direction="column" gap="2x" fullWidth>
            <LayoutBox fullWidth>
              <Tabs
                selectedTab={userDetailTab}
                size="medium"
                tabs={ADMIN_USER_DETAIL_CONTACT_ITEMS}
                bottomBorder
                onClick={setUserDetailTab}
              />
            </LayoutBox>
            <>
              <div className="util-full-width">
                <LayoutBox gap="2x">
                  <Input
                    leftIcon="search"
                    placeholder="検索"
                    width="100%"
                    onChange={handleChangeSearch}
                  />
                </LayoutBox>
              </div>

              <div
                className="admin-user__info-detail-list"
                ref={scrollableArea}
              >
                <LayoutBox direction="column" gap="2x" fullWidth>
                  {displayChatData.map((x, index) =>
                    getCommentComponent(x, index, getSignedUrl),
                  )}
                </LayoutBox>
              </div>
            </>
          </LayoutBox>
        </div>
        <div className="admin-user__info-detail--footer">
          <div className="admin-user__info-detail--footer-inner">
            <LayoutBox direction="column" gap="1x" fullWidth>
              <LayoutBox direction="column" gap="1/2x" fullWidth>
                <FormSet label="内容" vertical required="icon">
                  <Input
                    multiLine
                    width="100%"
                    value={formValue}
                    onChange={(text) => setFormValue(text)}
                  />
                </FormSet>
              </LayoutBox>
              <div className="util-py-8 util-full-width">
                <FileUpload
                  width="100%"
                  uploadFiles={uploadFiles}
                  setUploadFiles={setUploadFiles}
                  draggable={false}
                  fileType="application/pdf"
                />
              </div>
            </LayoutBox>
          </div>
          <div className="admin-user__info-detail--footer-button">
            <LayoutBox align="center" justify="center">
              <Button
                size="large"
                color="neutral"
                type="sub"
                onClick={handleClear}
              >
                キャンセル
              </Button>
              <Button
                icon="send"
                size="large"
                onClick={onSubmitBtnClicked}
                disabled={!formValue}
              >
                送信
              </Button>
            </LayoutBox>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
