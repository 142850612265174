import React from "react";
import LayoutBox from "../LayoutBox/LayoutBox";
import Tag from "../Tag/Tag";
import Popover from "../Popover/Popover";
import Button from "../Button/Button";

export type InfoMenuProps = {
  infos: {
    date: string;
    content: string;
    type: string;
  }[];
  onClick: (index: number) => void;
};

const InfoMenu = ({ infos, onClick }: InfoMenuProps) => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <>
      <div ref={activatorRef} onClick={() => toggleOpen(true)}>
        <div className="header__right-icon">
          <Button
            type="secondary"
            color="background-dark"
            size="medium"
            icon="notifications"
            iconType="outlined"
            borderLess
          />
        </div>
      </div>
      <Popover
        open={isOpen}
        activatorRef={activatorRef}
        width={320}
        direction="underRight"
        forceDirection
        withShadow
        toggleOpen={toggleOpen}
      >
        <div className="menulist">
          {infos.map((info, index) => (
            <React.Fragment key={index}>
              <div
                className="util-px-16 menulist-item--info"
                onClick={() => {
                  setIsOpen(false);
                  onClick(index);
                }}
              >
                <LayoutBox gap="1/2x" direction="column">
                  <LayoutBox align="center">
                    <span className="menulist-item--info-date">
                      {info.date}
                    </span>
                    <Tag
                      state={info.type === "重要" ? "error" : "default"}
                      showIcon={false}
                      label={info.type}
                    />
                  </LayoutBox>
                  <span className="text--base">{info.content}</span>
                </LayoutBox>
              </div>
            </React.Fragment>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default InfoMenu;
