import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Dropdown from "../components/Dropdown/Dropdown";
import Text from "../components/Text/Text";
import DetailsMenu from "../components/Popover/DetailsMenu";
import {
  ADMIN_ORDERS_BOTTOM_TAB_ITEMS,
  ADMIN_ORDERS_TOP_TAB_ITEMS,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  MONTH_LIST,
  ROLE_KEY,
} from "../constants/common";
import {
  downloadCsvNlf001,
  handleSelectECMgmtSubTab,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNlf001Conditions from "../hooks/pages/NLF001/useNlf001Conditions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import useNlf001DbActions from "../hooks/pages/NLF001/useNlf001DbActions";
import { convertNlf001Table } from "../utils/convertDisplay";
import InformationDialog from "../components/Dialog/InformationDialog";
import { setPage } from "../hooks/component/pagination/usePagination";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { ADMIN } from "../constants/pagePaths";
import { setOrderId } from "../hooks/common/useOrderId";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

type DetailsItem = {
  id: string;
  icon: string;
  label: string;
  value: string;
  danger?: boolean;
};

const DETAILS_ITEM_DETAIL: DetailsItem = {
  id: "1",
  icon: "download",
  label: "明細出力",
  value: "1",
};
const DETAILS_ITEM_CANCEL: DetailsItem = {
  id: "2",
  icon: "block",
  label: "取消",
  danger: true,
  value: "2",
};
let DETAILS_ITEM: DetailsItem[] = [];

const NLF001 = () => {
  const [conditions, setConditions] = useNlf001Conditions();
  const [orders, setOrders] = useStateCustomObj<Nlf001TableType[]>([]);
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const {
    fetchResult,
    handleOrderDelete,
    actionError,
    fetchError,
    selectOrderYear,
  } = useNlf001DbActions({
    sortExamples,
    submittedSearchCriteria,
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [deleteOrderId, setDeleteOrderId] = React.useState("");

  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([mainTabError, actionError, fetchError]);

  useEffect(() => {
    const convertResult = convertNlf001Table(fetchResult);
    setOrders(convertResult);
  }, [fetchResult, setOrders]);

  // コード
  const handleChangeId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, id: value }));
  };

  // お客様名
  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, patientName: value }));
  };

  // 注文日 年
  const handleChangeYear = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, orderYear: value }));
  };

  // 注文日 月
  const handleChangeMonth = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, orderMonth: value }));
  };

  // ID
  const handleChangeUserId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, userId: value }));
  };

  // ステータスチェックボックス
  const handleChangeStatusCheckItem = (
    checked: boolean,
    checkedItem: string,
  ) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  // 配送チェックボックス
  const handleChangeDeliveryCheckItem = (
    checked: boolean,
    checkedItem: string,
  ) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      deliveryMethod: {
        ...searchCriteria.deliveryMethod,
        [checkedItem]: checked,
      },
    }));
  };

  // 削除ダイアログ閉じる
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  // 3点リーダー押下
  const handleDetailsMenuItemClick = (orderId: string, label: string) => {
    const selectedItem = DETAILS_ITEM.find((item) => item.label === label);
    if (selectedItem) {
      if (selectedItem.value === "1") {
        // 明細出力
        const copiedOrders = orders.map((order) => ({
          ...order,
          checked: order.id === orderId,
        }));
        downloadCsvNlf001(copiedOrders, window.location.pathname);
      } else if (selectedItem.value === "2") {
        setDeleteOrderId(orderId);
        // 削除ポップアップ
        setIsDeleteDialogOpen(true);
      }
    }
  };

  // 削除処理
  const handleDelete = () => {
    handleOrderDelete(deleteOrderId);
    closeDeleteDialog();
  };

  // 検索ボタン押下
  const handleSearchOrders = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリア押下
  const handleResetSearchCriteria = () => {
    const {
      status: _,
      deliveryMethod: __,
      ...restSearchCriteria
    } = INIT_SEARCH_CRITERIA.NLF001;
    setSearchCriteria((prevState) => ({
      ...prevState,
      ...restSearchCriteria,
    }));
  };

  // 詳細ボタン押下
  const navigate = useNavigateWithUrl();
  const navigateToOrderDetail = (id: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
    });
    setOrderId(id);
    navigate(ADMIN.NLF005);
  };

  // 明細出力
  const handleDetailedOutput = () => {
    downloadCsvNlf001(orders, window.location.pathname);
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = orders.map((order) => {
      if (order.id === userId) {
        return { ...order, checked };
      }

      return order;
    });

    setOrders(newUsers);
    setIsCheckAllUserIds(newUsers.every((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = orders.map((order) => ({ ...order, checked }));
    setOrders(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => orders.some((order) => order.checked === true),
    [orders],
  );

  const isCheckAllId = React.useMemo(
    () => orders.every((order) => order.checked === true),
    [orders],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  // FIXME: 要件固まり次第
  // const handleCSVUpload = () => {
  //   // eslint-disable-next-line no-console
  //   console.log("配送番号CSVアップロード");
  // };

  // 権限チェック
  const hasDetailRole = useCheckHasRole(ROLE_KEY.EC_ORDER_DETAIL);
  const hasCancelRole = useCheckHasRole(ROLE_KEY.EC_ORDER_CANCEL);
  // FIXME: 要件固まり次第
  // const hasDeliveryNumRole = useCheckHasRole(ROLE_KEY.EC_ORDER_DELIVERY_NUM);

  // 三点リーダの選択肢
  DETAILS_ITEM = [];
  if (hasDetailRole) DETAILS_ITEM.push(DETAILS_ITEM_DETAIL);
  if (hasCancelRole) DETAILS_ITEM.push(DETAILS_ITEM_CANCEL);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={5}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <ToggleButton
              size="large"
              items={ADMIN_ORDERS_TOP_TAB_ITEMS}
              onClick={handleSelectECMgmtSubTab}
            />
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <div className="util-full-width">
                  <LayoutBox align="center" justify="around" gap="3x">
                    <FormSet label="コード" labelWidth="96px" base flex="1">
                      <Input
                        value={searchCriteria.id}
                        placeholder="コード"
                        width="100%"
                        onChange={handleChangeId}
                      />
                    </FormSet>
                    <FormSet label="ID" labelWidth="96px" base flex="1">
                      <Input
                        value={searchCriteria.userId}
                        placeholder="ID"
                        width="100%"
                        onChange={handleChangeUserId}
                      />
                    </FormSet>
                    <FormSet label="お客様名" labelWidth="96px" base flex="1">
                      <Input
                        value={searchCriteria.patientName}
                        placeholder="お客様名"
                        width="100%"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox align="center" justify="start" gap="3x">
                    <FormSet
                      label="注文日"
                      labelWidth="96px"
                      base
                      formVertical={false}
                    >
                      <Dropdown
                        items={selectOrderYear}
                        value={searchCriteria.orderYear}
                        placeholder="年"
                        width="106px"
                        onChange={handleChangeYear}
                      />
                      <div className="util-px-8">
                        <Text>年</Text>
                      </div>
                      <Dropdown
                        items={MONTH_LIST}
                        value={searchCriteria.orderMonth}
                        placeholder="月"
                        width="114px"
                        onChange={handleChangeMonth}
                      />
                      <div className="util-ml-8">
                        <Text>月</Text>
                      </div>
                    </FormSet>
                    <FormSet
                      label="ステータス"
                      labelWidth="96px"
                      base
                      formVertical={false}
                    >
                      <LayoutBox align="center" justify="start" gap="2x">
                        <Checkbox
                          label="支払完了"
                          checked={searchCriteria.status.isPaymentCompleted}
                          onChecked={(checked) =>
                            handleChangeStatusCheckItem(
                              checked,
                              "isPaymentCompleted",
                            )
                          }
                        />
                        <Checkbox
                          label="出荷済"
                          checked={searchCriteria.status.isShipmentCompleted}
                          onChecked={(checked) =>
                            handleChangeStatusCheckItem(
                              checked,
                              "isShipmentCompleted",
                            )
                          }
                        />
                        <Checkbox
                          label="配送完了"
                          checked={searchCriteria.status.isDeliveryCompleted}
                          onChecked={(checked) =>
                            handleChangeStatusCheckItem(
                              checked,
                              "isDeliveryCompleted",
                            )
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                    <FormSet
                      label="配送"
                      labelWidth="96px"
                      base
                      formVertical={false}
                    >
                      <LayoutBox align="center" justify="start" gap="2x">
                        <Checkbox
                          label="通常配送"
                          checked={
                            searchCriteria.deliveryMethod.isNormalDelivery
                          }
                          onChecked={(checked) =>
                            handleChangeDeliveryCheckItem(
                              checked,
                              "isNormalDelivery",
                            )
                          }
                        />
                        <Checkbox
                          label="お届け日指定"
                          checked={
                            searchCriteria.deliveryMethod
                              .isDateSpecifiedDelivery
                          }
                          onChecked={(checked) =>
                            handleChangeDeliveryCheckItem(
                              checked,
                              "isDateSpecifiedDelivery",
                            )
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </div>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchOrders}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <div className="util-full-width">
                  <div className="util-mt-16">
                    <LayoutBox align="center" justify="between">
                      <Button
                        type="secondary"
                        size="medium"
                        icon="download"
                        width="98px"
                        onClick={handleDetailedOutput}
                        disabled={!hasDetailRole}
                      >
                        明細出力
                      </Button>
                      {/* FIXME: 要件固まり次第 */}
                      {/* <Button
                        type="secondary"
                        size="medium"
                        icon="upload"
                        width="208px"
                        onClick={handleCSVUpload}
                        disabled={!hasDeliveryNumRole}
                      >
                        配送番号CSVアップロード
                      </Button> */}
                    </LayoutBox>
                  </div>
                </div>
                <Table
                  type="condensed"
                  width="100%"
                  roleKey={ROLE_KEY.EC_ORDER_BROWSING}
                  head={
                    <TableRow>
                      <TableColumn width="238px" minWidth="220px" id="col-1">
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="コード"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn width="77px" id="col-2" />
                      <TableColumn
                        width="238px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-3"
                        onClickSort={onClickSort}
                      >
                        ID
                      </TableColumn>
                      <TableColumn
                        width="238px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-4"
                        onClickSort={onClickSort}
                      >
                        お客様名
                      </TableColumn>
                      <TableColumn
                        width="238px"
                        canSort
                        sortedDirection={sortExamples[2].sortDirection}
                        id="col-5"
                        onClickSort={onClickSort}
                      >
                        ステータス
                      </TableColumn>
                      <TableColumn width="238px" id="col-6" textAlign="right">
                        合計
                      </TableColumn>
                      <TableColumn width="238px" id="col-7">
                        更新
                      </TableColumn>
                      <TableColumn
                        width="238px"
                        minWidth="150px"
                        canSort
                        sortedDirection={sortExamples[3].sortDirection}
                        id="col-8"
                        onClickSort={onClickSort}
                      >
                        注文日時
                      </TableColumn>
                      <TableColumn
                        width="238px"
                        canSort
                        sortedDirection={sortExamples[4].sortDirection}
                        id="col-9"
                        onClickSort={onClickSort}
                      >
                        配送
                      </TableColumn>
                      <TableColumn width="77px" id="col-10" />
                    </TableRow>
                  }
                  body={orders.map((order) => (
                    <TableRow key={order.id} isSelected={order.checked}>
                      <TableCell>
                        <Checkbox
                          label={order.id}
                          checked={order.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, order.id)
                          }
                        />
                      </TableCell>
                      <TableCell textAlign="center">
                        <Button
                          onClick={() => navigateToOrderDetail(order.id)}
                          type="sub"
                          size="small"
                          width="38px"
                        >
                          詳細
                        </Button>
                      </TableCell>
                      <TableCell>{order.userId}</TableCell>
                      <TableCell>{order.patientName}</TableCell>
                      <TableCell>
                        <Tag
                          label={order.status}
                          showIcon={false}
                          state={
                            order.status === "配送完了"
                              ? "success"
                              : order.status === "出荷済"
                              ? "warning"
                              : "error"
                          }
                        />
                      </TableCell>
                      <TableCell textAlign="right">
                        {order.totalAmount}
                      </TableCell>
                      <TableCell>{order.renewal}</TableCell>
                      <TableCell>{order.settlementTime}</TableCell>
                      <TableCell>{order.deliveryMethod}</TableCell>
                      <TableCell textAlign="center">
                        {DETAILS_ITEM.length !== 0 && (
                          <DetailsMenu
                            items={DETAILS_ITEM}
                            width={133}
                            onClick={(value: string) => {
                              handleDetailsMenuItemClick(order.id, value);
                              setIsOpen(false);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <div className="util-full-width">
                  <LayoutBox justify="end">
                    <Pagination input={false} />
                  </LayoutBox>
                </div>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      <InformationDialog
        alertLevel="error"
        open={isDeleteDialogOpen}
        closeDialog={closeDeleteDialog}
        title="削除してもよろしいですか？"
        footer={
          <LayoutBox>
            <Button color="danger" size="medium" onClick={handleDelete}>
              削除
            </Button>
            <Button
              color="neutral"
              type="sub"
              size="medium"
              onClick={closeDeleteDialog}
            >
              キャンセル
            </Button>
          </LayoutBox>
        }
      >
        この操作は取り消せません。
      </InformationDialog>
    </>
  );
};

export default NLF001;
