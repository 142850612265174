import React, { useEffect } from "react";

import Dialog from "../components/Dialog/Dialog";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import useNlf007DbActions from "../hooks/pages/NLF007/useNlf007DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { convertNlf007Table } from "../utils/convertDisplay";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { downloadCsvNlf007 } from "../utils/utils";
import NLC024 from "./NLC024";
import { ROLE_KEY } from "../constants/common";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";
import { setOrderId } from "../hooks/common/useOrderId";

type NLF007Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectSalesData: {
    selectSalesId: string;
    selectSalesOrderTime: string;
  };
  salesIdArray: string[];
};

const NLF007: React.FC<NLF007Props> = ({
  isOpen,
  setIsOpen,
  selectSalesData,
  salesIdArray,
}) => {
  const [sales, setSales] = useStateCustomObj<Nlf007TableType[]>([]);
  const [salesYearMonth, setSalesYearMonth] = React.useState(
    selectSalesData.selectSalesOrderTime,
  );
  const [totalAmount, setTotalAmount] = React.useState("");
  const [salesId, setSalesId] = React.useState(selectSalesData.selectSalesId);
  const { fetchResult, fetchError } = useNlf007DbActions(salesId);
  const [isOrderDetailDialogOpen, setIsOrderDetailDialogOpen] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  useCheckErrorThrowError([fetchError]);

  useEffect(() => {
    const { convertResult, formattedTotalAmount } =
      convertNlf007Table(fetchResult);
    setTotalAmount(formattedTotalAmount);
    setSales(convertResult);
    setIsLoading(false);
  }, [fetchResult, setSales]);

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = () => {
    setIsOpen(false);
  };

  // 明細出力ボタン押下
  const handleDetailedOutput = () => {
    const copiedSales = sales.map((sale) => ({
      ...sale,
      checked: true,
    }));

    downloadCsvNlf007(copiedSales, `${salesId}_sales`);
  };

  // 前へボタン押下
  const handleBeforeOrders = () => {
    setIsLoading(true);
    const currentIndex = salesIdArray.indexOf(salesId);
    const searchId = salesIdArray[currentIndex + 1];
    setSalesId(searchId);
    setSalesYearMonth(
      `${searchId.substring(0, 4)}年${searchId.substring(4, 6)}月`,
    );
  };

  // 次へボタン押下
  const handleAfterOrders = () => {
    setIsLoading(true);
    const currentIndex = salesIdArray.indexOf(salesId);
    const searchId = salesIdArray[currentIndex - 1];
    setSalesId(searchId);
    setSalesYearMonth(
      `${searchId.substring(0, 4)}年${searchId.substring(4, 6)}月`,
    );
  };

  // 詳細ボタン押下
  const handleOrderDetail = (orderId: string) => {
    setIsOrderDetailDialogOpen(true);
    setOrderId(orderId);
  };

  // 権限チェック
  const hasDetailRole = useCheckHasRole(ROLE_KEY.EC_SALES_DETAIL);

  return (
    <div className="admin-area">
      <div className="admin-inner">
        <Dialog
          title="売り上げ詳細"
          subTitle={salesYearMonth}
          open={isOpen}
          size="large"
          height="683px"
          closeDialog={handleCloseButton}
          headerRight={
            <div className="util-mr-48">
              <LayoutBox>
                <Button
                  type="secondary"
                  icon="arrow_backward"
                  onClick={handleBeforeOrders}
                  disabled={
                    salesIdArray[salesIdArray.length - 1] === salesId ||
                    isLoading
                  }
                >
                  前へ
                </Button>
                <Button
                  type="secondary"
                  icon="arrow_forward"
                  iconPosition="right"
                  onClick={handleAfterOrders}
                  disabled={salesIdArray[0] === salesId || isLoading}
                >
                  次へ
                </Button>
              </LayoutBox>
            </div>
          }
          footerRight={
            <LayoutBox>
              <Button
                type="sub"
                color="neutral"
                size="large"
                onClick={handleCloseButton}
              >
                キャンセル
              </Button>
              <Button
                size="large"
                icon="download"
                onClick={handleDetailedOutput}
                disabled={!hasDetailRole}
              >
                明細出力
              </Button>
            </LayoutBox>
          }
        >
          <LayoutBox gap="2x" direction="column">
            <LayoutBox align="center">
              <Text width="88px" bold>
                売上
              </Text>
              <Text size="2xl" width="186px" bold lineHeight="150%">
                {totalAmount}
              </Text>
            </LayoutBox>
            <Table
              type="condensed"
              width="100%"
              head={
                <TableRow>
                  <TableColumn width="205px" id="col-1">
                    コード
                  </TableColumn>
                  <TableColumn width="205px" id="col-2">
                    お客様名
                  </TableColumn>
                  <TableColumn width="205px" id="col-3" textAlign="right">
                    合計
                  </TableColumn>
                  <TableColumn width="205px" id="col-4">
                    注文日時
                  </TableColumn>
                  <TableColumn width="100px" id="col-5" />
                </TableRow>
              }
              body={sales.map((sale) => (
                <TableRow key={sale.id}>
                  <TableCell>{sale.orderId}</TableCell>
                  <TableCell>{sale.patientName}</TableCell>
                  <TableCell textAlign="right">{sale.amount}</TableCell>
                  <TableCell>{sale.settlementTime}</TableCell>
                  <TableCell textAlign="center">
                    <Button
                      type="sub"
                      size="small"
                      onClick={() => handleOrderDetail(sale.orderId)}
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            />
          </LayoutBox>
        </Dialog>
        {isOrderDetailDialogOpen && (
          <NLC024
            isOpen={isOrderDetailDialogOpen}
            setIsOpen={setIsOrderDetailDialogOpen}
          />
        )}
      </div>
    </div>
  );
};
export default NLF007;
