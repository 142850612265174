import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getMongoDb } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import { closeNli029Dialog } from "./useNli029Dialog";
import useShippingInput from "../../input/useShippingInput";
import { ValidationResultType } from "../../../utils/commonValidation";

const useNli029DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);
  const [{ shippingId, deliveryFee, deliveryTimes, deliveryCondition }] =
    useShippingInput();

  const extractValuesFromDeliveryTimes = (
    deliveryTimes: {
      start: ValidationResultType<string>;
      end: ValidationResultType<string>;
    }[],
  ): { start: number; end: number }[] =>
    deliveryTimes.map((time) => ({
      start: Number(time.start.value),
      end: Number(time.end.value),
    }));

  const updateShipping = () => {
    void (async () => {
      try {
        openUpdateActionLoading();

        const collection = getMongoDb(currentUser, Collection.SHIPPINGS);
        await collection.updateOne(
          { _id: shippingId },
          {
            $set: {
              delivery_fee: Number(deliveryFee.value),
              delivery_times: extractValuesFromDeliveryTimes(deliveryTimes),
              delivery_condition: {
                minimum_order_amount: Number(
                  deliveryCondition.minimum_order_amount.value,
                ),
                shipping_cost: Number(deliveryCondition.shipping_cost.value),
              },
              updated_at: new Date(),
            },
          },
        );
        closeNli029Dialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, updateShipping };
};

export default useNli029DbActions;
