import { useState } from "react";

import { useRealmApp } from "../../contexts/RealmApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";

type DicomUrl = {
  url: string;
};

const useDicomUrl = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [dicomUrlError, setDicomUrlError] = useState<Error | null>(null);

  const fetchDicomUrl = async (modalityId: string, studyUid: string) => {
    addLoadCount();
    try {
      const response = (await currentUser?.functions["mri_pet/getDicomURL"](
        modalityId,
        studyUid,
      )) as DicomUrl;

      const dicomUrl = response.url;

      if (!dicomUrl) {
        const error = new Error("Dicom URL not received");
        setDicomUrlError(checkActionErr(error));
      }

      return dicomUrl;
    } catch (err) {
      setDicomUrlError(checkActionErr(err));

      return null;
    } finally {
      decrementLoadCount();
    }
  };

  return {
    fetchDicomUrl,
    dicomUrlError,
  };
};

export default useDicomUrl;
