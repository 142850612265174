import { Link } from "react-router-dom";

import Table from "../components/Table/Table";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import { ADMIN, HOSPITAL } from "../constants/pagePaths";

const header = [
  "大カテゴリ",
  "中カテゴリ",
  "小カテゴリ",
  "バリエーション",
  "ID",
  "link",
];
const data = [
  ["病院", "予約受付", "", "", "MI-B001", HOSPITAL.MIB001],
  ["", "", "", "", "MI-B002", "/MIB002"],
  ["", "", "", "", "MI-B003", "/MIB003"],
  ["", "", "", "", "MI-B004", "/MIB004"],
  ["", "スケジュール", "", "", "MI-C001", HOSPITAL.MIC001],
  ["", "患者一覧", "一覧", "", "MI-D001", HOSPITAL.MID001],
  ["", "", "", "詳細", "MI-D002", HOSPITAL.MID002],
  ["", "", "", "", "MI-D003", "/MID003"],
  ["", "", "", "", "MI-D004", "/MID004"],
  ["", "", "", "", "MI-D005", "/MID005"],
  ["", "枠管理", "", "", "MI-E001", HOSPITAL.MIE001],
  ["", "医師", "", "", "MI-E004", HOSPITAL.MIE004],
  ["", "", "", "", "MI-E005", "/MIE005"],
  ["", "", "", "", "MI-E007", "/MIE007"],
  ["ログイン", "", "", "", "NL-A001", ADMIN.NLA001],
  ["ログイン", "ログイン失敗", "", "", "NL-A001Error", "/NLA001Error"],
  ["ダッシュボード", "", "", "", "NL-B001", ADMIN.NLB001],
  ["", "本人確認", "", "", "NL-C002", "/NLC002"],
  ["", "保険会社チェック", "", "", "NL-C003", "/NLC003"],
  ["", "のうKNOW", "", "", "NL-C004", ADMIN.NLC004],
  ["", "MRI検査", "", "", "NL-C005", ADMIN.NLC005],
  ["", "FDG PET検査", "", "", "NL-C006", ADMIN.NLC006],
  ["", "面談", "", "", "NL-C007", ADMIN.NLC007],
  [
    "",
    "ユーザー詳細",
    "登録情報",
    "本人確認：未 保険会社：未",
    "NL-C008",
    ADMIN.NLC008,
  ],
  ["", "", "", "本人確認：済 保険会社：未", "NL-C009", "/NLC009"],
  ["", "", "", "本人確認：済 保険会社：済", "NL-C010", "/NLC010"],
  ["", "", "テスト結果", "のうKNOW一覧", "NL-C011", ADMIN.NLC011],
  ["", "", "", "のうKNOW詳細 CMC", "NL-C012", "/NLC012"],
  ["", "", "", "のうKNOW詳細 NL", "NL-C013", "/NLC013"],
  ["", "", "", "MRI検査一覧", "NL-C014", ADMIN.NLC014],
  ["", "", "", "MRI検査詳細", "NL-C015", "/NLC015"],
  ["", "", "", "MRI検査詳細 画像詳細", "NL-C016", "/NLC016"],
  ["", "", "", "PET検査", "NL-C017", ADMIN.NLC017],
  ["", "", "支払い状況", "", "NL-C019", ADMIN.NLC019],
  ["", "", "予約状況", "一覧", "NL-C020", ADMIN.NLC020],
  ["", "", "", "新規予約 Default", "NL-C021", "/NLC021"],
  ["", "", "", "新規予約 Calendar", "NL-C022", "/NLC022"],
  ["", "", "注文履歴", "一覧", "NL-C023", ADMIN.NLC023],
  ["", "", "", "詳細", "NL-C024", "/NLC024"],
  ["", "", "", "", "NL-C025", "/NLC025"],
  ["", "", "", "", "NL-C026", "/NLC026"],
  ["", "", "", "", "NL-C027", "/NLC027"],
  ["", "", "", "", "NL-C028", "/NLC028"],
  ["", "", "", "", "NL-C029", "/NLC029"],
  ["", "", "", "", "NL-C030", "/NLC030"],
  ["", "", "", "", "NL-C033", "/NLC033"],
  ["", "", "", "", "NL-C035", "/NLC035"],
  // ["支払・請求", "確定診断給付金", "一覧", "", "NL-D001", "/NLD001"],
  ["", "検査費用支払い", "一覧", "", "NL-D002", ADMIN.NLD002],
  ["", "", "病院詳細", "", "NL-D003", ADMIN.NLD003],
  ["", "", "", "", "NL-D004", ADMIN.NLD004],
  ["", "", "", "", "NL-D005", ADMIN.NLD005],
  ["オンライン寄席", "一覧", "", "", "NL-E001", ADMIN.NLE001],
  ["", "詳細", "", "", "NL-E002", ADMIN.NLE002],
  ["", "新規登録", "", "", "NL-E003", "/NLE003"],
  ["EC管理", "注文管理", "一覧", "すべて", "NL-F001", ADMIN.NLF001],
  ["", "", "", "支払い完了", "NL-F002", "/NLF002"],
  ["", "", "", "出荷済", "NL-F003", "/NLF003"],
  ["", "", "", "配送完了", "NL-F004", "/NLF004"],
  ["", "", "詳細", "", "NL-F005", ADMIN.NLF005],
  ["", "売上", "一覧", "", "NL-F006", ADMIN.NLF006],
  ["", "", "詳細", "", "NL-F007", ADMIN.NLF007],
  ["お知らせ", "一覧", "", "", "NL-G001", ADMIN.NLG001],
  ["", "新規登録", "", "", "NL-G002", "/NLG002"],
  ["", "詳細", "", "", "NL-G003", ADMIN.NLG003],
  ["", "編集", "", "", "NL-G004", "/NLG004"],
  ["キャンペーン", "一覧", "", "", "NL-H001", ADMIN.NLH001],
  ["", "新規登録", "", "", "NL-H002", "/NLH002"],
  ["", "詳細", "", "", "NL-H003", ADMIN.NLH003],
  ["マスタ管理", "権限管理", "閲覧", "", "NL-I001", ADMIN.NLI001],
  ["", "ユーザー管理", "一覧", "", "NL-I003", ADMIN.NLI003],
  ["", "", "詳細", "Admin", "NL-I004", "/NLI004"],
  ["", "", "", "一般", "NL-I005", "/NLI005"],
  ["", "", "一括編集", "", "NL-I006", "/NLI006"],
  ["", "商品管理", "一覧", "商品種別 一覧", "NL-I007", ADMIN.NLI007],
  ["", "", "", "商品種別 新規登録", "NL-I008", "/NLI008"],
  ["", "", "", "SKU 一覧", "NL-I009", ADMIN.NLI009],
  ["", "", "", "SKU 新規登録", "NL-I010", "/NLI010"],
  ["", "", "詳細", "", "NL-I011", ADMIN.NLI011],
  ["", "", "", "SKUから新規登録", "NL-I012", "/NLI012"],
  ["", "", "", "商品種別から新規登録", "NL-I013", "/NLI013"],
  ["", "", "商品種別編集", "", "NL-I014", "/NLI014"],
  ["", "", "オプション管理", "", "NL-I015", "/NLI015"],
  ["", "医療機関", "一覧", "", "NL-I016", ADMIN.NLI016],
  ["", "", "詳細", "", "NL-I017", ADMIN.NLI017],
  ["", "", "ユーザー詳細", "", "NL-I018", "/NLI018"],
  ["", "", "団体管理 一覧", "", "NL-I019", "/NLI019"],
  ["", "", "", "", "NL-I020", "/NLI020"],
  ["", "", "", "", "NL-I021", "/NLI021"],
  ["", "", "", "", "NL-I029", "/NLI029"],
  ["", "", "ユーザー詳細", "", "NL-I022", "/NLI022"],
  ["", "", "新規受注入力	顧客を選択", "", "NL-I023", "/NLI023"],
  ["", "", "マスタ管理	医療機関	病院情報編集", "", "NL-I030", "/NLI030"],
  ["", "", "", "", "NL-I031", "/NLI031"],
  ["", "", "", "", "NL-I032", "/NLI032"],
  ["", "", "", "", "NL-I033", "/NLI033"],
  ["", "", "", "", "NL-I034", "/NLI034"],
  ["", "", "", "", "NL-I035", "/NLI035"],
];

const IndexPage = () => (
  <div className="util-px-32 util-py-32">
    <h1>管理者画面</h1>
    <br />
    <Table
      height="90vh"
      head={
        <TableRow>
          {header.map((item, index) => (
            <TableColumn id={`table-head-column-${index}`} key={index}>
              {item}
            </TableColumn>
          ))}
        </TableRow>
      }
      body={
        <>
          {data.map((row, index) => (
            <TableRow key={index}>
              {row.map((item, index) => (
                <TableColumn id={`table-body-column-${index}`} key={index}>
                  {row.length !== index + 1 && item}
                  {row.length === index + 1 && <Link to={item}>{item}</Link>}
                </TableColumn>
              ))}
            </TableRow>
          ))}
        </>
      }
    />
  </div>
);

export default IndexPage;
