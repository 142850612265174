import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

enum LOCATION_STATE_KEY {
  FROM = "from",
}

type PageStateType = {
  [LOCATION_STATE_KEY.FROM]?: string;
};

// 現在のページ遷移時に保持したstateを取得
export const useCurrentLocationState = () => {
  const location = useLocation();

  return (location.state as PageStateType) ?? {};
};

// ページ遷移
export const useNavigateCustom = () => {
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  return navigateTo;
};

// 現在URLをstateに保持しページ遷移
export const useNavigateWithUrl = () => {
  const navigate = useNavigate();
  const { pathname: pathName } = useLocation();

  const navigateTo = (path: string) => {
    navigate(path, { state: { [LOCATION_STATE_KEY.FROM]: pathName } });
  };

  return navigateTo;
};

// 現在stateを引継ぎながらページ遷移
export const useNavigateWithCurrentState = () => {
  const navigate = useNavigate();
  const state = useCurrentLocationState();

  const navigateTo = (path: string) => {
    navigate(path, { state });
  };

  return navigateTo;
};

/**
 * backボタンの挙動、元のページに遷移
 * 元のページはuseNavigateWithUrlでセットしたstateから判別
 * @param {string} defaultPath 元のページ未設定の場合の遷移先パスを設定
 * @return {Function} 戻るボタンのページ遷移処理
 */
export const useNavigateWithBack = (defaultPath: string) => {
  const locationState = useCurrentLocationState();
  const navigate = useNavigateCustom();

  const navigateTo = () => {
    const backPath = locationState[LOCATION_STATE_KEY.FROM] ?? defaultPath;
    navigate(backPath);
  };

  return navigateTo;
};

// hooksなどの値をリフレッシュしてページ遷移
export const navigateRefresh = (path: string | undefined) => {
  if (path) window.location.href = path;
};
