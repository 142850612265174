import React from "react";

import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Dialog from "../components/Dialog/Dialog";
import Heading from "../components/Heading/Heading";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Tag from "../components/Tag/Tag";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Text from "../components/Text/Text";
import Divider from "../components/Divider/Divider";
import StepNavigation from "../components/StepNavigation/StepNavigation";
import Image from "../components/Image/Image";
import useNlf005DbActions from "../hooks/pages/NLF005/useNlf005DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { convertNlf005 } from "../utils/convertDisplay";
import {
  ADMIN_ORDERS_BOTTOM_TAB_ITEMS,
  ORDER_STEPS,
  ROLE_KEY,
} from "../constants/common";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";
import InformationDialog from "../components/Dialog/InformationDialog";
import { addHyphenToZipcode } from "../utils/utils";

type NLC024Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const NLC024: React.FC<NLC024Props> = ({ isOpen, setIsOpen }) => {
  // NLF005と同じため、共通で使用
  const { fetchResult, fetchError, handleOrderDelete, handleItemDelivery } =
    useNlf005DbActions();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const {
    id,
    patientName,
    patientTel,
    patientEmail,
    status,
    deliveryFee,
    totalItemAmount,
    totalAmount,
    transactionsInfo,
    taxSummary,
    settlementTime,
    deliveryMethod,
    consumptionTaxAmount,
    orderZipcode,
    orderAddress,
  } = convertNlf005(fetchResult);

  useCheckErrorThrowError([fetchError]);

  // 権限チェック
  const hasDeliveryRole = useCheckHasRole(ROLE_KEY.EC_ORDER_DELIVERY);
  const hasCancelRole = useCheckHasRole(ROLE_KEY.EC_ORDER_CANCEL);

  // 出荷済、配送完了時配送手配ボタン非活性
  const disableDeliveryStatusArr = [
    ADMIN_ORDERS_BOTTOM_TAB_ITEMS[2].label,
    ADMIN_ORDERS_BOTTOM_TAB_ITEMS[3].label,
  ];
  const disableDelivery = disableDeliveryStatusArr.includes(status);
  // 出荷済時配注文取消ボタン非活性
  const disableCancelStatusArr = [ADMIN_ORDERS_BOTTOM_TAB_ITEMS[2].label];
  const disableCancel = disableCancelStatusArr.includes(status);

  // 削除ダイアログopen
  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  // 削除ダイアログ閉じる
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        closeDialog={() => setIsOpen(false)}
        size="large"
        title="注文詳細"
        subTitle={id}
        height="1334px"
        footerRight={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                type="sub"
                color="neutral"
                size="large"
                width="108px"
                onClick={() => setIsOpen(false)}
              >
                キャンセル
              </Button>
              <Button
                type="secondary"
                color="danger"
                size="large"
                icon="block"
                width="120px"
                disabled={!hasCancelRole || disableCancel}
                onClick={openDeleteDialog}
              >
                注文取消
              </Button>
              <InformationDialog
                alertLevel="error"
                open={isDeleteDialogOpen}
                closeDialog={closeDeleteDialog}
                title="削除してもよろしいですか？"
                footer={
                  <LayoutBox>
                    <Button
                      color="danger"
                      size="medium"
                      onClick={handleOrderDelete}
                    >
                      削除
                    </Button>
                    <Button
                      color="neutral"
                      type="sub"
                      size="medium"
                      onClick={closeDeleteDialog}
                    >
                      キャンセル
                    </Button>
                  </LayoutBox>
                }
              >
                この操作は取り消せません。
              </InformationDialog>
              <Button
                type="primary"
                size="large"
                width="96px"
                disabled={!hasDeliveryRole || disableDelivery}
                onClick={handleItemDelivery}
              >
                配送手配
              </Button>
            </LayoutBox>
          </>
        }
      >
        <Sheet type="header" padding="4px 8px">
          <Heading tag="h2">注文内容</Heading>
        </Sheet>
        <div className="util-mt-16 util-mb-8">
          <LayoutBox fullWidth>
            <FormSet label="ステータス" base formVertical={false}>
              <Tag
                label={status}
                showIcon={false}
                state={
                  status === "配送完了"
                    ? "success"
                    : status === "出荷済"
                    ? "warning"
                    : "error"
                }
              />
            </FormSet>
          </LayoutBox>
        </div>
        <div className="util-mb-24">
          <Table
            type="relaxed"
            width="100%"
            head={
              <TableRow>
                <TableColumn width="145px" id="col-1" />
                <TableColumn width="145px" id="col-2">
                  SKU
                </TableColumn>
                <TableColumn width="145px" id="col-3">
                  商品名
                </TableColumn>
                <TableColumn width="145px" id="col-4" textAlign="right">
                  数量
                </TableColumn>
                <TableColumn width="145px" id="col-5" textAlign="right">
                  金額
                </TableColumn>
                <TableColumn width="145px" id="col-6" textAlign="right">
                  合計
                </TableColumn>
              </TableRow>
            }
            body={transactionsInfo.map((transaction) => (
              <TableRow key={transaction._id}>
                <TableCell>
                  <LayoutBox fullWidth justify="center">
                    <Image
                      width="85px"
                      height="48px"
                      url={
                        transaction.image_urls?.length > 0
                          ? transaction.image_urls[0][0]
                          : ""
                      }
                    />
                  </LayoutBox>
                </TableCell>
                <TableCell>{transaction.sku_name}</TableCell>
                <TableCell
                  tooltipText={transaction.item_name}
                  tooltipInDialog={isOpen}
                >
                  <Text noWrap width="160px">
                    {transaction.item_name}
                  </Text>
                </TableCell>
                <TableCell textAlign="right">{transaction.quantity}</TableCell>
                <TableCell textAlign="right">
                  {transaction.sku_amount}
                </TableCell>
                <TableCell textAlign="right">
                  {transaction.total_amount}
                </TableCell>
              </TableRow>
            ))}
          />
        </div>
        <LayoutBox justify="end" fullWidth>
          <Text size="large" width="140px" bold>
            商品
          </Text>
          <LayoutBox align="end">
            <Text size="large" width="140px" textAlign="right">
              {totalItemAmount}
            </Text>
          </LayoutBox>
        </LayoutBox>
        <Divider margin="16px 0 16px" />
        <LayoutBox justify="end" fullWidth>
          <Text size="large" width="140px" bold>
            配送料
          </Text>
          <Text size="large" width="140px" textAlign="right">
            {deliveryFee}
          </Text>
        </LayoutBox>
        <Divider margin="16px 0 16px" />
        <LayoutBox justify="end" fullWidth>
          <Text size="large" width="140px" bold>
            内税
          </Text>
          <Text size="large" width="140px" textAlign="right">
            {consumptionTaxAmount}
          </Text>
        </LayoutBox>
        <Divider margin="16px 0 16px" />
        <LayoutBox justify="end" fullWidth>
          <Text size="2xl" width="140px" bold>
            注文合計
          </Text>
          <Text size="2xl" width="140px" textAlign="right" bold>
            {totalAmount}
          </Text>
        </LayoutBox>
        <div className="util-mb-32" />
        <Sheet type="header" padding="4px 8px">
          <Heading tag="h2">税金概要</Heading>
        </Sheet>
        <div className="util-mt-16" />
        <LayoutBox fullWidth minWidth="707.73px">
          <Table
            type="condensed"
            width="100%"
            head={
              <TableRow>
                <TableColumn width="228px" id="col-1">
                  内容
                </TableColumn>
                <TableColumn width="228px" id="col-2" textAlign="right">
                  税率
                </TableColumn>
                <TableColumn width="228px" id="col-3" textAlign="right">
                  課税対象
                </TableColumn>
                <TableColumn width="228px" id="col-4" textAlign="right">
                  税金合計
                </TableColumn>
              </TableRow>
            }
            body={taxSummary.map((tax) => (
              <TableRow key={tax.id}>
                <TableCell>{tax.contents}</TableCell>
                <TableCell textAlign="right">{tax.taxRate}</TableCell>
                <TableCell textAlign="right">{tax.taxableSubject}</TableCell>
                <TableCell textAlign="right">{tax.totalTaxes}</TableCell>
              </TableRow>
            ))}
          />
        </LayoutBox>
        <div className="util-mb-24" />
        <Sheet type="header" padding="4px 8px">
          <Heading tag="h2">状況</Heading>
        </Sheet>
        <div className="util-mb-16" />
        <LayoutBox direction="row" fullWidth align="baseline">
          <StepNavigation
            currentStepId="content-1"
            steps={ORDER_STEPS}
            isVertical
          />
          <LayoutBox direction="column" gap="1x">
            <LayoutBox direction="column" gap="1/2x">
              <Text color="neutralLight" size="small" lineHeight="19.5px">
                {settlementTime}
              </Text>
              <Text size="large">支払い完了</Text>
            </LayoutBox>
            <LayoutBox direction="column" gap="1/2x">
              <Text color="neutralLight" size="small" lineHeight="19.5px">
                {settlementTime}
              </Text>
              <Text size="large">注文</Text>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
        <LayoutBox justify="between" gap="4x" fullWidth>
          <LayoutBox direction="column" fullWidth>
            <Sheet type="header" padding="4px 8px">
              <Heading tag="h2">顧客情報</Heading>
            </Sheet>
            <FormSet label="名前" base formVertical={false}>
              <Text>{patientName}</Text>
            </FormSet>
            <FormSet label="住所" base formVertical={false}>
              <Text>
                {addHyphenToZipcode(orderZipcode)}
                <br />
                {orderAddress}
              </Text>
            </FormSet>
            <FormSet label="電話番号" base formVertical={false}>
              <Text>{patientTel}</Text>
            </FormSet>
            <FormSet label="メールアドレス" base formVertical={false}>
              <Text>{patientEmail}</Text>
            </FormSet>
          </LayoutBox>
          <LayoutBox direction="column" fullWidth>
            <Sheet type="header" padding="4px 8px">
              <Heading tag="h2">支払い</Heading>
            </Sheet>
            <FormSet label="配送" base formVertical={false}>
              <Text>{deliveryMethod}</Text>
            </FormSet>
            <FormSet label="金額" base formVertical={false}>
              <Text>{totalAmount}</Text>
            </FormSet>
            <FormSet label="メールアドレス" base formVertical={false}>
              <Text>{patientEmail}</Text>
            </FormSet>
          </LayoutBox>
        </LayoutBox>
      </Dialog>
    </>
  );
};

export default NLC024;
