import { useState, useEffect } from "react";

import { getAggregateAdminDoctorList, getMongoDb } from "../../utils/query";
import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

// 商品タグを取得
const useAdminDoctorListFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [adminDoctorList, setAdminDoctorList] = useState<
    { value: string; label: string }[]
  >([{ value: "0", label: "すべて" }]);
  const [fetchDoctorListError, setFetchDoctorListError] =
    useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(currentUser, Collection.USERS);
        const aggregate = getAggregateAdminDoctorList();
        const results = (await collection.aggregate(aggregate)) as {
          value: string;
          label: string;
        }[];
        setAdminDoctorList([{ value: "すべて", label: "すべて" }, ...results]);
      } catch (err) {
        setFetchDoctorListError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser]);

  return { adminDoctorList, fetchDoctorListError };
};

export default useAdminDoctorListFetch;
