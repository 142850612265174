import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";
import { setNouknowResultId } from "./useNlc012Conditions";

const stateReactiveVar = createReactiveVar<boolean>(false);

// MRI結果ダイアログの開閉状態を管理
const useNlc012Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc012Dialog = (id: string) => {
  setNouknowResultId(id);
  stateReactiveVar(true);
};

export const closeNlc012Dialog = () => {
  stateReactiveVar(false);
};

export default useNlc012Dialog;
