import { useState, useEffect } from "react";
import {
  INIT_SEARCH_CRITERIA,
  Collection,
  SORT_EXAMPLES,
  SEARCH_INFO,
} from "../../../constants/common";
import { getMongoDb, getAggregateNld005 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nld005FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLD005;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLD005;
};

// NLD005画面のデータ取得処理
const useNld005DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Nld005FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    result: PatientNld005StateType[];
    error: Error | null;
  }>({
    result: [],
    error: null,
  });

  // 検索条件をquery用に整形
  const {
    authorizationStartDate,
    authorizationEndDate,
    paymentStartDate,
    paymentEndDate,
    receiptIssueStartDate,
    receiptIssueEndDate,
  } = submittedSearchCriteria;
  const { id, minTotal, maxTotal } = submittedSearchCriteria;
  const name = convertSearchName(submittedSearchCriteria.name);

  // 項目 チェック
  const itemSearchArray = useMemoStatusArr(
    SEARCH_INFO.NLD005_ITEM,
    submittedSearchCriteria.status,
  );

  // sortを取得 未選択の場合はIDの昇順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "payment_date", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.SALES);

        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNld005(
          id,
          name,
          itemSearchArray,
          authorizationStartDate,
          authorizationEndDate,
          paymentStartDate,
          paymentEndDate,
          receiptIssueStartDate,
          receiptIssueEndDate,
          minTotal,
          maxTotal,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        // データ取得
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNld005StateType>;
        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setErrorAndResult({ result, error: null });
      } catch (err) {
        setTotal(0);
        setErrorAndResult({
          result: [],
          error: checkFetchErr(err),
        });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    colName,
    currentUser,
    itemSearchArray,
    name,
    perPage,
    skip,
    sortDirection,
    authorizationStartDate,
    authorizationEndDate,
    receiptIssueStartDate,
    receiptIssueEndDate,
    submittedSearchCriteria.id,
    id,
    minTotal,
    maxTotal,
    paymentStartDate,
    paymentEndDate,
  ]);

  return errorAndResult;
};

export default useNld005DbActions;
