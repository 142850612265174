import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Dialog from "../components/Dialog/Dialog";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";

import RadioButton from "../components/RadioButton/RadioButton";
import Text from "../components/Text/Text";
import DatePicker from "../components/DatePicker/DatePicker";

const NLI035 = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [campaignName, setCampaignName] = React.useState("入会特典30%OFF");
  const [campaignCode, setCampaignCode] = React.useState("X565FG4");
  const [rateAmount, setRateAmount] = React.useState("rate");
  const [discountRate, setDiscountRate] = React.useState("10");
  const [period, setPeriod] = React.useState("");
  const [limit, setLimit] = React.useState("40");
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Open Modal</Button>
      <Dialog
        open={isOpen}
        closeDialog={() => setIsOpen(false)}
        title="新規キャンペーン登録"
        height="712px"
        footerRight={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                type="sub"
                color="neutral"
                size="large"
                width="108px"
                onClick={() => setIsOpen(false)}
              >
                キャンセル
              </Button>
              <Button type="primary" size="large" width="64px">
                登録
              </Button>
            </LayoutBox>
          </>
        }
      >
        <LayoutBox direction="column" fullWidth gap="2x">
          <FormSet label="キャンペーン名" vertical>
            <Input
              width="100%"
              value={campaignName}
              onChange={setCampaignName}
            />
          </FormSet>
          <FormSet label="キャンペーンコード" vertical>
            <Input
              width="190px"
              value={campaignCode}
              onChange={setCampaignCode}
            />
          </FormSet>
          <FormSet label="定率/定額" vertical>
            <LayoutBox gap="2x" fullWidth>
              <RadioButton
                checked={rateAmount === "rate"}
                value="rate"
                onSelect={setRateAmount}
              >
                定率
              </RadioButton>
              <RadioButton
                checked={rateAmount === "amount"}
                value="amount"
                onSelect={setRateAmount}
              >
                定額
              </RadioButton>
            </LayoutBox>
          </FormSet>
          <FormSet label="割引率" vertical>
            <LayoutBox align="center">
              <Input
                width="86px"
                value={discountRate}
                onChange={setDiscountRate}
              />
              <Text>%</Text>
            </LayoutBox>
          </FormSet>
          <FormSet label="割引適用期間" vertical>
            <LayoutBox align="center">
              <Input width="86px" value={period} onChange={setPeriod} />
              <Text>ヶ月</Text>
            </LayoutBox>
          </FormSet>
          <FormSet label="キャンペーン終了日" vertical>
            <LayoutBox align="center">
              <DatePicker
                width="178px"
                selectedDate={selectedDate}
                onChangeDate={setSelectedDate}
              />
            </LayoutBox>
          </FormSet>
          <FormSet label="利用上限" vertical>
            <LayoutBox align="center">
              <Input width="86px" value={limit} onChange={setLimit} />
              <Text>人</Text>
            </LayoutBox>
          </FormSet>
        </LayoutBox>
        <div className="util-mb-8" />
      </Dialog>
    </>
  );
};

export default NLI035;
