import { useEffect, useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  FORMAT_STYLE,
  convertItemCheckedToDefaultSchedule,
  generateHospitalDefaultItemChecked,
} from "../../../constants/common";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import {
  checkActionErr,
  checkFetchErr,
  redirectToNoDataPage,
} from "../../../contexts/CustomErrorBoundary";
import useMie001Conditions, {
  setDailyScheduleItem,
  setIsChangedTemplate,
  setTimeItems,
} from "./useMie001Conditions";
import { getAggregateMie001, getMongoDb } from "../../../utils/query";
import { formatDate, formatHourTime } from "../../../utils/utils";
import useForceUpdate from "../../common/useForceUpdate";

const useMie001DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchResult, setFetchResult] = useState<Mie001StateType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [
    {
      timeFrame,
      amStartTime,
      amEndTime,
      pmStartTime,
      pmEndTime,
      itemChecked,
      dailyScheduleItem,
    },
    setCondition,
  ] = useMie001Conditions();

  // 病院ID
  const hospitalId: string = currentUser
    ? (currentUser.customData.hospital_id as string)
    : "";

  // 病院検索
  useEffect(() => {
    const fetchData = async () => {
      try {
        const mongoDb = getMongoDb(currentUser, Collection.HOSPITALS);
        const aggregate = getAggregateMie001(hospitalId);
        const result = (await mongoDb.aggregate(
          aggregate,
        )) as Mie001StateType[];

        if (result.length < 1) redirectToNoDataPage();

        setFetchResult(result[0]);

        // MRI 枠、時間単位をセット
        if (result[0].mri_interval) {
          const scheduleInfoArray = result[0].schedule_info;
          if (scheduleInfoArray) {
            // MRIのみ
            setTimeItems(
              generateHospitalDefaultItemChecked(
                scheduleInfoArray.filter((item) => item.modality === "mri"),
              ),
            );
          }
          const scheduleDailyInfoArray = result[0].schedule_daily_info;
          if (scheduleDailyInfoArray) {
            // MRIのみ
            setDailyScheduleItem(
              scheduleDailyInfoArray
                .filter((item) => item.modality === "mri")
                .map((item) => ({
                  datetime: item.datetime,
                  is_open: item.is_open,
                })),
            );
          }

          const originDailyScheduleItem = scheduleDailyInfoArray
            .filter((item) => item.modality === "mri")
            .map((item) => ({
              datetime: item.datetime,
              is_open: item.is_open,
            }));

          setCondition((prevCondition) => ({
            ...prevCondition,
            timeInterval: result[0].mri_interval
              ? String(result[0].mri_interval)
              : "15",
            timeFrame: result[0].mri_interval
              ? (result[0].mri_interval as 0 | 15 | 30 | 60 | 90 | 120)
              : 15,
            amStartTime:
              result[0].mri_open_am?.start ?? new Date(0, 0, 0, 9, 0),
            amEndTime: result[0].mri_open_am?.end ?? new Date(0, 0, 0, 12, 0),
            pmStartTime:
              result[0].mri_open_pm?.start ?? new Date(0, 0, 0, 13, 0),
            pmEndTime: result[0].mri_open_pm?.end ?? new Date(0, 0, 0, 18, 0),
            initAmStartTime:
              result[0].mri_open_am?.start ?? new Date(0, 0, 0, 9, 0),
            initAmEndTime:
              result[0].mri_open_am?.end ?? new Date(0, 0, 0, 12, 0),
            initPmStartTime:
              result[0].mri_open_pm?.start ?? new Date(0, 0, 0, 13, 0),
            initPmEndTime:
              result[0].mri_open_pm?.end ?? new Date(0, 0, 0, 18, 0),
            originData: {
              ...prevCondition.originData,
              amStartTimeStr: result[0].mri_open_am?.start
                ? formatHourTime(result[0].mri_open_am?.start)
                : "09:00",
              amEndTimeStr: result[0].mri_open_am?.end
                ? formatHourTime(result[0].mri_open_am?.end)
                : "12:00",
              pmStartTimeStr: result[0].mri_open_pm?.start
                ? formatHourTime(result[0].mri_open_pm?.start)
                : "13:00",
              pmEndTimeStr: result[0].mri_open_pm?.end
                ? formatHourTime(result[0].mri_open_pm?.end)
                : "18:00",
              timeInterval: String(result[0].mri_interval),
              timeFrame: result[0].mri_interval
                ? (result[0].mri_interval as 0 | 15 | 30 | 60 | 90 | 120)
                : 15,
              timeItems: generateHospitalDefaultItemChecked(
                scheduleInfoArray.filter((item) => item.modality === "mri"),
              ),
              dailyScheduleItem: originDailyScheduleItem,
            },
          }));
        }
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, hospitalId, setCondition, forceUpdate]);

  // デフォルト スケジュール更新
  const handleUpdateDefaultSchedule = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新データ
        const updateData = {
          booking_type: "mri",
          interval: timeFrame,
          opening_am: {
            start: formatHourTime(amStartTime),
            end: formatHourTime(amEndTime),
          },
          opening_pm: {
            start: formatHourTime(pmStartTime),
            end: formatHourTime(pmEndTime),
          },
          hospital_id: hospitalId,
          default_schedule: convertItemCheckedToDefaultSchedule(itemChecked),
        };

        // デフォルトスケジュール更新API
        await currentUser?.functions["booking/saveAvailableBooking"](
          updateData.booking_type,
          updateData.interval,
          updateData.opening_am,
          updateData.opening_pm,
          updateData.hospital_id,
          updateData.default_schedule,
        );
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setIsChangedTemplate(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 日別 スケジュール更新
  const handleUpdateDaily = (startOfWeek: Date, endOfWeek: Date) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const updateRange = {
          start: formatDate(startOfWeek, FORMAT_STYLE["YYYY-MM-DD"]),
          end: formatDate(endOfWeek, FORMAT_STYLE["YYYY-MM-DD"]),
        };

        // 範囲内のみ更新
        const dailyScheduleItemCopy = dailyScheduleItem
          .filter((item) => {
            const itemDate = new Date(item.datetime).getTime();
            const startDate = new Date(updateRange.start);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(updateRange.end);
            endDate.setHours(23, 59, 59, 999);

            const startDateTime = startDate.getTime();
            const endDateTime = endDate.getTime();

            return itemDate >= startDateTime && itemDate <= endDateTime;
          })
          .map((item) => ({
            ...item,
          }));

        // 更新データ
        const updateData = {
          booking_type: "mri",
          hospital_id: hospitalId,
          update_range: updateRange,
          schedules: dailyScheduleItemCopy,
        };

        // 日別スケジュール更新API
        await currentUser?.functions["booking/saveAvailableDailyBooking"](
          updateData.booking_type,
          updateData.hospital_id,
          updateData.update_range,
          updateData.schedules,
        );

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setIsChangedTemplate(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    actionError: error || fetchError,
    handleUpdateDefaultSchedule,
    handleUpdateDaily,
  };
};

export default useMie001DbActions;
