import { useState } from "react";
import {
  UserTypeID,
  Collection,
  UserStatusID,
  ROLE_KEY,
} from "../../../constants/common";

import { useRealmApp } from "../../../contexts/RealmApp";

import { getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openDeleteActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import {
  checkActionErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import { setExistingEmailError } from "../../input/useUserDetailInput";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";
import useCheckUsersEmail from "../../common/useCheckUsersEmail";
import useGenerateSequenceId from "../../common/useGenerateSequenceId";

const useMie006DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const mongoDb = getMongoDb(currentUser, Collection.USERS);
  const { checkExistingEmail, error: existingEmailError } =
    useCheckUsersEmail();
  const { generateId } = useGenerateSequenceId();

  // 医療機関ユーザ登録
  const handleRegisterHospitalUser = (
    hospitalId: string | undefined,
    doctorName: string,
    email: string,
    password: string,
    callbackOnSuccess: () => void,
  ) => {
    if (!hospitalId) redirectToInvalidAccessPage();

    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_ADD, currentUser);

        // Eメール重複チェック
        if (await checkExistingEmail(email)) {
          setExistingEmailError();

          return;
        }

        // userIdの最新番号取得
        const generateUserNo = await generateId(
          { field: "hospital_user_id", value: "hospital_user_no" },
          { field: "hospital_user_no", incrementValue: 1 },
        );

        // 登録データ
        const insertData = {
          _id: email,
          user_id: generateUserNo.toString(),
          hospital_id: hospitalId,
          full_name: doctorName,
          email,
          user_type: UserTypeID.HOSPITAL,
          status: UserStatusID.UNREGISTERED,
          created_at: new Date(),
        };
        await mongoDb.insertOne(insertData);

        // ファンクション
        await realmAppContext.app.emailPasswordAuth.registerUser({
          email,
          password,
        });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        await mongoDb.deleteOne({ _id: email });
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 医療機関ユーザ更新
  const handleUpdateHospitalUser = (
    _id: string,
    doctorName: string,
    hospitalId: string | undefined,
    callbackOnSuccess: () => void,
  ) => {
    if (!hospitalId) redirectToInvalidAccessPage();

    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_EDIT, currentUser);

        // 更新データ
        const updateData = {
          full_name: doctorName,
          updated_at: new Date(),
        };
        await mongoDb.updateOne({ _id }, { $set: updateData });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // ユーザ削除
  const handleDeleteUser = (id: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_EDIT, currentUser);

        // ファンクション
        await realmAppContext.app.currentUser?.functions["auth/deleteUser"](id);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterHospitalUser,
    handleUpdateHospitalUser,
    handleDeleteUser,
    existingEmailError,
  };
};

export default useMie006DbActions;
