import { useEffect, useState, useTransition } from "react";
import { useRealmApp } from "../../contexts/RealmApp";
import { getAggregateMedicalRecords, getMongoDb } from "../../utils/query";
import {
  Collection,
  EventHistoryStatusID,
  MedicalRecordsStatusID,
  ROLE_KEY,
} from "../../constants/common";
import {
  checkActionErr,
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../contexts/CustomErrorBoundary";
import useId from "./useId";
import useForceUpdate from "./useForceUpdate";
import generateUniqueId from "../../utils/generateUniqueId";
import {
  openCreateActionLoading,
  closeActionLoading,
  openUpdateActionLoading,
  openDeleteActionLoading,
} from "../base/useLoadingAction";
import { checkMasterRole } from "./useMasterRoleDbActions";

type FetchParams = {
  searchCriteria: string;
};

// カルテ
const useMedicalRecordsDbActions = ({ searchCriteria }: FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [medicalRecordResults, setMedicalRecordResults] = useState<
    MedicalRecordStateType[]
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [, startTransition] = useTransition();

  const [id] = useId();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        if (!id) redirectToInvalidAccessPage();
        const mongoDb = getMongoDb(currentUser, Collection.KARTES);

        const aggregate = getAggregateMedicalRecords(id, searchCriteria);
        const results = (await mongoDb.aggregate(
          aggregate,
        )) as MedicalRecordStateType[];

        if (!isCancelled) {
          setMedicalRecordResults(results);
        }
      } catch (err) {
        setError(checkFetchErr(err));
      }
    };

    startTransition(() => {
      void fetchData();
    });

    return () => {
      isCancelled = true;
    };
  }, [currentUser, id, forceUpdate.forceUpdateCount, searchCriteria]);

  // 応対履歴登録・更新
  const handleUpsert = (
    inputObj: {
      id?: string;
      description: string;
    },
    handleClear: () => void,
  ) => {
    void (async () => {
      if (inputObj.id) {
        // 更新
        openUpdateActionLoading();
      } else {
        // 登録
        openCreateActionLoading();
      }
      try {
        // TODO: 更新可能か権限チェック
        await checkMasterRole(
          ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
          currentUser,
        );

        const mongoDb = getMongoDb(currentUser, Collection.KARTES);

        if (inputObj.id) {
          // 更新データ
          const updateData = {
            description: inputObj.description,
            commented_user_id: currentUser?.customData._id,
            updated_at: new Date(),
          };

          await mongoDb.updateOne({ _id: inputObj.id }, { $set: updateData });
        } else {
          const uniqueId = generateUniqueId();

          // 登録データ
          const insertData = {
            _id: uniqueId,
            patient_id: id,
            registered_date: new Date(),
            description: inputObj.description,
            commented_user_id: currentUser?.customData._id,
            status: EventHistoryStatusID.VALID,
            created_at: new Date(),
            updated_at: new Date(),
          };

          await mongoDb.insertOne(insertData);
        }

        handleClear();

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 応対履歴削除
  const handleDelete = (
    inputObj: {
      id: string;
      description: string;
    },
    handleClear: () => void,
    closeOpenDeleteConfirm: () => void,
  ) => {
    void (async () => {
      openDeleteActionLoading();
      closeOpenDeleteConfirm();
      try {
        // TODO: 更新可能か権限チェック
        await checkMasterRole(
          ROLE_KEY.USER_EVENT_HISTORY_REGISTER,
          currentUser,
        );

        const mongoDb = getMongoDb(currentUser, Collection.KARTES);

        // 論理削除
        await mongoDb.updateOne(
          { _id: inputObj.id },
          {
            $set: {
              status: MedicalRecordsStatusID.DELETED,
              updated_at: new Date(),
            },
          },
        );

        handleClear();

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { error, medicalRecordResults, handleUpsert, handleDelete };
};

export default useMedicalRecordsDbActions;
