import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { closeNlc021NewDialog } from "./useNlc021NewDialog";
import { Collection } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";

const useNlc021UpdateStatus = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // ステータス更新処理
  const handleUpdateStatus = (id: string, status: number) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_INTERVIEWS,
        );
        await collection.updateOne(
          { _id: id },
          {
            $set: {
              status,
              updated_at: new Date(),
            },
          },
        );

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc021NewDialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateStatus,
  };
};

export default useNlc021UpdateStatus;
