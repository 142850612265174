import { useNavigate } from "react-router-dom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Header from "../components/Header/Header";
import Link from "../components/Link/Link";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import useEmailInput, {
  checkEmailInputError,
  setEmail,
} from "../hooks/input/useEmailInput";
import { useRealmApp } from "../contexts/RealmApp";
import { ADMIN, COMMON, HEADER_LINK } from "../constants/pagePaths";
import { checkActionErr } from "../contexts/CustomErrorBoundary";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import Logo from "../components/Logo/Logo";

/* パスワード忘れ画面 */
const NLJ006 = () => {
  const navigate = useNavigate();
  const realmAppContext = useRealmApp();
  const [{ email }] = useEmailInput();

  const handlePasswordRest = async () => {
    try {
      addLoadCount();
      // ファンクションの実行
      await realmAppContext.app.emailPasswordAuth.callResetPasswordFunction({
        email: email.value,
        password: "DummyPassword",
      });
      // メール送信完了画面
      navigate(COMMON.NLJ005);
    } catch (error) {
      const err = error as Error;
      checkActionErr(err);
    } finally {
      decrementLoadCount();
    }
  };

  // 戻る
  const handleBack = () => {
    navigate(ADMIN.NLA001);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <Header
            leftControl={
              <Link
                url={HEADER_LINK}
                external={false}
                linkClassName="header__left-logo"
              >
                <Logo size="small" type="white-horizontal" />
              </Link>
            }
          />
        </div>
        <div className="util-mb-48" />
        <LayoutBox fullHeight fullWidth justify="center">
          <Sheet
            type="border"
            maxWidth="640px"
            minHeight="247px"
            className="util-flex--align-center util-flex--justify-center"
          >
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              gap="3x"
              fullWidth
            >
              <LayoutBox
                direction="column"
                justify="center"
                align="center"
                gap="1x"
                fullWidth
              >
                <Text size="xl" bold>
                  パスワードを再設定します。
                </Text>
                <Text size="base">
                  メールアドレスを入力後、メール送信ボタンを押下してください。
                </Text>

                <FormSet
                  label="メールアドレス"
                  vertical
                  errorTextList={[email.validationMessage]}
                >
                  <Input
                    size="default"
                    placeholder="メールアドレス"
                    width="100%"
                    value={email.value}
                    onChange={(value) => setEmail(value)}
                    error={email.isError}
                  />
                </FormSet>
              </LayoutBox>
              <LayoutBox>
                <Button
                  color="neutral"
                  type="sub"
                  size="large"
                  iconPosition="left"
                  width="230px"
                  onClick={handleBack}
                >
                  戻る
                </Button>
                <Button
                  size="large"
                  iconPosition="left"
                  width="230px"
                  onClick={() => {
                    void handlePasswordRest();
                  }}
                  disabled={checkEmailInputError()}
                >
                  メール送信
                </Button>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </div>
    </>
  );
};

export default NLJ006;
