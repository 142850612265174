import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

type ForceUpdateType = {
  forceUpdateCount: number;
};

const stateReactiveVar = createReactiveVar<ForceUpdateType>({
  forceUpdateCount: 0,
});

const useForceUpdate = (): ReactiveVarHooks<ForceUpdateType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useForceUpdate;
