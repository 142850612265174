import React from "react";
import Heading, { HeadingProps } from "../Heading/Heading";
import Icon from "../Icon/Icon";

export type AccordionProps = {
  tag?: HeadingProps["tag"];
  isOpen?: boolean;
  title: string;
  ghost?: boolean;
  children: React.ReactNode;
};

const Accordion = ({
  tag = "h4",
  isOpen = false,
  title,
  ghost,
  children,
}: AccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = React.useState(isOpen);

  const accordionClass = React.useMemo(() => {
    const tagClass = [`accordion--${tag}`];
    const openStatusClass = isOpen ? [`accordion--is-open`] : [];
    const ghostClass = ghost ? ["accordion--ghost"] : [];

    return ["accordion", ...tagClass, ...openStatusClass, ...ghostClass].join(
      " ",
    );
  }, [isOpen, tag, ghost]);

  React.useEffect(() => {
    setIsAccordionOpen(isOpen);
  }, [isOpen]);

  const onClick = React.useCallback(() => {
    setIsAccordionOpen((prev) => !prev);
  }, []);

  return (
    <div className={accordionClass}>
      <div className="accordion__header" onClick={onClick}>
        <Heading tag={tag}>{title}</Heading>
        <span className="accordion__header-icon-container">
          <Icon
            icon={isAccordionOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            size="medium"
          />
        </span>
      </div>
      {isAccordionOpen && <div className="accordion__content">{children}</div>}
    </div>
  );
};

Accordion.defaultProps = {
  tag: "h4",
  isOpen: false,
  ghost: false,
};

export default Accordion;
