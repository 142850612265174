import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import {
  ADMIN_PAYMENT_SUB_TAB_ITEMS,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  SalesStatusID,
} from "../constants/common";
import {
  downloadCsvNld004,
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectPaymentSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { setPage } from "../hooks/component/pagination/usePagination";
import { convertNld004Table } from "../utils/convertDisplay";
import { ADMIN } from "../constants/pagePaths";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { setId } from "../hooks/common/useId";
import useNld004DbActions from "../hooks/pages/NLD004/useNld004DbActions";
import DatePicker from "../components/DatePicker/DatePicker";
import Tag from "../components/Tag/Tag";
import useNld004Conditions from "../hooks/pages/NLD004/useNld004Conditions";

const NLD004 = () => {
  const [conditions, setConditions] = useNld004Conditions();
  const [users, setUsers] = React.useState<Nld004TableType[]>([]);
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [authorizationStartDate, setAuthorizationStartDate] = React.useState<
    Date | undefined
  >(conditions.authorizationStartDate);
  const [authorizationEndDate, setAuthorizationEndDate] = React.useState<
    Date | undefined
  >(conditions.authorizationEndDate);
  const [paymentStartDate, setPaymentStartDate] = React.useState<
    Date | undefined
  >(conditions.paymentStartDate);
  const [paymentEndDate, setPaymentEndDate] = React.useState<Date | undefined>(
    conditions.paymentEndDate,
  );

  const { result, error: fetchError } = useNld004DbActions({
    sortExamples,
    submittedSearchCriteria,
  });

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([fetchError, mainTabError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNld004Table(result);
    setUsers(convertResult);
  }, [result]);

  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };

  const handleChangeUserId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, userId: value }));
  };

  const handleChangeEmail = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, email: value }));
  };

  const handleChangePhoneNum = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, phoneNum: value }));
  };

  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  const handleChangeStatusPayment = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      statusPayment: {
        ...searchCriteria.statusPayment,
        [checkedItem]: checked,
      },
    }));
  };

  // 検索ボタン押下時のイベント
  const handleSearchUsers = () => {
    // 検索条件を確定
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリア
  const handleResetSearchCriteria = () => {
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLD004);
    setAuthorizationStartDate(undefined);
    setAuthorizationEndDate(undefined);
    setPaymentStartDate(undefined);
    setPaymentEndDate(undefined);
  };

  // CSVダウンロードボタン押下時の処理
  const handleDownloadCSV = () => {
    downloadCsvNld004(users, window.location.pathname);
  };

  // 請求日
  const handleChangeRangeAuthorizationDate = React.useCallback(
    (authorizationStartDate?: Date, authorizationEndDate?: Date) => {
      setAuthorizationStartDate(authorizationStartDate);
      setAuthorizationEndDate(authorizationEndDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        authorizationStartDate,
        authorizationEndDate,
      }));
    },
    [],
  );

  // 支払日
  const handleChangeRangePaymentDate = React.useCallback(
    (paymentStartDate?: Date, paymentEndDate?: Date) => {
      setPaymentStartDate(paymentStartDate);
      setPaymentEndDate(paymentEndDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        paymentStartDate,
        paymentEndDate,
      }));
    },
    [],
  );

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = users.map((user) => ({ ...user, checked }));
    setUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const isCheckAllId = React.useMemo(
    () => users.every((user) => user.checked === true),
    [users],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToUserDetails = (patientId: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
      authorizationStartDate,
      authorizationEndDate,
      paymentStartDate,
      paymentEndDate,
    });
    setId(patientId);
    navigate(ADMIN.NLC019);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={3}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <ToggleButton
              size="large"
              selectedButton="2"
              items={ADMIN_PAYMENT_SUB_TAB_ITEMS}
              onClick={handleSelectPaymentSubTab}
            />
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <div className="util-full-width">
                  <LayoutBox align="center" justify="between" gap="3x">
                    <FormSet label="ID" labelWidth="95px" base flex="1">
                      <Input
                        value={searchCriteria.userId}
                        placeholder="ID"
                        width="100%"
                        onChange={handleChangeUserId}
                      />
                    </FormSet>
                    <FormSet label="お客様名" labelWidth="95px" base flex="1">
                      <Input
                        value={searchCriteria.name}
                        placeholder="お客様名"
                        width="100%"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet
                      label="メールアドレス"
                      labelWidth="95px"
                      base
                      flex="1"
                    >
                      <Input
                        value={searchCriteria.email}
                        placeholder="メールアドレス"
                        width="100%"
                        onChange={handleChangeEmail}
                      />
                    </FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox align="center" justify="between" gap="3x">
                    <FormSet label="電話番号" labelWidth="95px" base flex="1">
                      <Input
                        value={searchCriteria.phoneNum}
                        placeholder="電話番号"
                        width="100%"
                        onChange={handleChangePhoneNum}
                      />
                    </FormSet>
                    <FormSet label="請求日" labelWidth="95px" base flex="1">
                      <DatePicker
                        type="range"
                        placeholder="開始日"
                        placeholderEnd="終了日"
                        width="100%"
                        onChangeRangeDate={handleChangeRangeAuthorizationDate}
                        selectedDateRange={{
                          startDate: authorizationStartDate,
                          endDate: authorizationEndDate,
                        }}
                      />
                    </FormSet>
                    <FormSet label="支払日" labelWidth="95px" base flex="1">
                      <DatePicker
                        type="range"
                        placeholder="開始日"
                        placeholderEnd="終了日"
                        width="100%"
                        onChangeRangeDate={handleChangeRangePaymentDate}
                        selectedDateRange={{
                          startDate: paymentStartDate,
                          endDate: paymentEndDate,
                        }}
                      />
                    </FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox align="center" justify="between" gap="3x">
                    <FormSet
                      label="支払状況"
                      labelWidth="95px"
                      base
                      flex="0.75"
                    >
                      <LayoutBox
                        align="center"
                        justify="start"
                        gap="3x"
                        fullHeight
                      >
                        <Checkbox
                          label="与信取得"
                          checked={
                            searchCriteria.statusPayment.creditAcquisition
                          }
                          onChecked={(checked) =>
                            handleChangeStatusPayment(
                              checked,
                              "creditAcquisition",
                            )
                          }
                        />

                        <Checkbox
                          label="与信取消"
                          checked={
                            searchCriteria.statusPayment.creditCancellation
                          }
                          onChecked={(checked) =>
                            handleChangeStatusPayment(
                              checked,
                              "creditCancellation",
                            )
                          }
                        />
                        <Checkbox
                          label="売上済"
                          checked={searchCriteria.statusPayment.sold}
                          onChecked={(checked) =>
                            handleChangeStatusPayment(checked, "sold")
                          }
                        />
                        <Checkbox
                          label="売上取消"
                          checked={
                            searchCriteria.statusPayment.saleCancellation
                          }
                          onChecked={(checked) =>
                            handleChangeStatusPayment(
                              checked,
                              "saleCancellation",
                            )
                          }
                        />
                        <Checkbox
                          label="売上失敗"
                          checked={searchCriteria.statusPayment.saleFailure}
                          onChecked={(checked) =>
                            handleChangeStatusPayment(checked, "saleFailure")
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                    <FormSet label="項目" labelWidth="95px" base flex="1">
                      <LayoutBox
                        align="center"
                        justify="start"
                        gap="3x"
                        fullHeight
                      >
                        <Checkbox
                          label="会費"
                          checked={searchCriteria.status.fee}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "fee")
                          }
                        />
                        <Checkbox
                          label="FDG-PET検査"
                          checked={searchCriteria.status.isFDGPETScan}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "isFDGPETScan")
                          }
                        />
                        <Checkbox
                          label="面談"
                          checked={searchCriteria.status.interview}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "interview")
                          }
                        />
                        <Checkbox
                          label="EC"
                          checked={searchCriteria.status.ec}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "ec")
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </div>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <div className="util-full-width">
                  <LayoutBox align="center" justify="end">
                    <Button
                      type="secondary"
                      size="medium"
                      icon="file_download"
                      onClick={handleDownloadCSV}
                    >
                      CSVダウンロード
                    </Button>
                  </LayoutBox>
                </div>
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="167px"
                        minWidth="140px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        minWidth="150px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        お客様名
                      </TableColumn>
                      <TableColumn width="77px" id="col-3" />
                      <TableColumn
                        width="77px"
                        minWidth="170px"
                        canSort
                        sortedDirection={sortExamples[2].sortDirection}
                        id="col-4"
                        onClickSort={onClickSort}
                      >
                        項目
                      </TableColumn>
                      <TableColumn width="217px" id="col-5" textAlign="right">
                        金額
                      </TableColumn>
                      <TableColumn width="171px" id="col-6">
                        請求日
                      </TableColumn>
                      <TableColumn width="217px" id="col-7">
                        支払日
                      </TableColumn>
                      <TableColumn width="110px" minWidth="110px" id="col-8">
                        支払状況
                      </TableColumn>
                      <TableColumn width="217px" minWidth="150px" id="col-9">
                        電話番号
                      </TableColumn>
                      <TableColumn width="217px" id="col-10">
                        メールアドレス
                      </TableColumn>
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id} isSelected={user.checked}>
                      <TableCell>
                        <Checkbox
                          label={user.userId}
                          checked={user.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, user.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => navigateToUserDetails(user.patientId)}
                          type="sub"
                          size="small"
                        >
                          詳細
                        </Button>
                      </TableCell>
                      <TableCell>{user.category}</TableCell>
                      <TableCell textAlign="right">{user.amount}</TableCell>
                      <TableCell>
                        {user.authorizationDate ? user.authorizationDate : "-"}
                      </TableCell>
                      <TableCell>
                        {user.paymentDate ? user.paymentDate : "-"}
                      </TableCell>
                      <TableCell>
                        <Tag
                          label={user.statusStr}
                          showIcon={false}
                          state={
                            user.statusNum === SalesStatusID.SALE_FAILURE
                              ? "error"
                              : user.statusNum === SalesStatusID.SOLD
                              ? "success"
                              : user.statusNum ===
                                SalesStatusID.SALE_CANCELLATION
                              ? "warning"
                              : user.statusNum ===
                                SalesStatusID.CREDIT_ACQUISITION
                              ? "information"
                              : "neutral"
                          }
                        />
                      </TableCell>
                      <TableCell>{user.tel}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))}
                />
                <div className="util-full-width">
                  <LayoutBox justify="end">
                    <Pagination input={false} />
                  </LayoutBox>
                </div>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLD004;
