import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  brainCheckId: string;
} & PatientNlc031StateType;

const INIT_TEST_DATA: PatientNlc031StateType = {
  status: 0,
  examStartDate: "",
  reportPath: "",
  note: "",
  commentedUserName: "",
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  brainCheckId: "",
  ...INIT_TEST_DATA,
});
const useNlc031Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setBrainCheckId = (brainCheckId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...INIT_TEST_DATA,
    brainCheckId,
  });
};

export const setNlc031Data = (data: PatientNlc031StateType) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...data,
  });
};

export const setBrainNote = (note: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    note,
  });
};

// 入力エラーチェック エラーの場合true
export const checkItemInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { note } = currentInfos;

  // 必須チェック（note が空ならエラー）
  const isEmpty = note === "";

  return isEmpty;
};

export default useNlc031Conditions;
