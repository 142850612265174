import useCampaignInfoAction from "../../common/useCampaignInfoAction";

// NLH001画面のデータ取得処理
const useNlh002DbActions = () => {
  // データ取得共通処理
  const { fetchResult, fetchError, actionError, updateCampaign } =
    useCampaignInfoAction();

  return {
    actionError: actionError || fetchError,
    fetchResult,
    updateCampaign,
  };
};

export default useNlh002DbActions;
