import { useState } from "react";
import { Collection } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";

// NLC033画面のデータ取得処理
const useNlc033DBActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);

  // ユーザ評価を更新
  const handleUpdateCheckList = (id: string, checkList: boolean) => {
    void (async () => {
      try {
        openUpdateActionLoading();
        const collection = getMongoDb(currentUser, Collection.CHATS);
        await collection.updateOne(
          { _id: id },
          {
            $set: {
              check_list: checkList,
              updated_at: new Date(),
            },
          },
        );
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    handleUpdateCheckList,
    error,
  };
};

export default useNlc033DBActions;
