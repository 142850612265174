import React from "react";
import Icon from "../Icon/Icon";

export type FileUploadButtonProps = {
  disabled?: boolean;
  fileType?: string;
  onUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number,
  ) => void;
  children: React.ReactNode;
};

const FileUploadButton = ({
  disabled = false,
  onUpload,
  children,
  fileType,
}: FileUploadButtonProps) => {
  const _onUpload = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onUpload(event);
      // eslint-disable-next-line no-param-reassign
      event.target.value = "";
    },
    [onUpload],
  );

  const fileUploadButtonClass = React.useMemo(() => {
    const classes = ["file-upload__button"];
    if (disabled) classes.push("file-upload__button--disabled");

    return classes.join(" ");
  }, [disabled]);

  return (
    <label className={fileUploadButtonClass}>
      <input
        type="file"
        className="file-upload__input"
        onChange={_onUpload}
        accept={fileType}
      />
      <span className="file-upload__button-icon">
        <Icon icon="upload" size="2xs" />
      </span>
      {children}
    </label>
  );
};

FileUploadButton.defaultProps = {
  disabled: false,
  fileType: "",
};

export default FileUploadButton;
