import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.NLD002;
  search: typeof INIT_SEARCH_CRITERIA.NLD002;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.NLD002,
  search: INIT_SEARCH_CRITERIA.NLD002,
  startDate: undefined,
  endDate: undefined,
});

const useNld002Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNld002Conditions;
