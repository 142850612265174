import { useEffect, useState } from "react";
import { Collection } from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc011, getMongoDb } from "../../../utils/query";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkActionErr,
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useNlc011Conditions from "./useNlc011Conditions";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import { closeNlc011SendDialog } from "./useNlc011Dialog";

// MRIのテスト結果を取得
const useNlc011DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;
  const [{ status, selectNouknowId }] = useNlc011Conditions();
  // 患者ID
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [nouknowTestResult, setNouknowTestResult] = useState<
    PatientNlc011StateType[]
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(currentUser, Collection.PATIENT_NOUKNOWS);
        const aggregate = getAggregateNlc011(id, status, skip, perPage);
        const aggregateResult = (await collection.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNlc011StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setNouknowTestResult(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, perPage, skip, status, forceUpdate]);

  // 結果送信
  const sendResult = () => {
    void (async () => {
      try {
        closeNlc011SendDialog();
        openUpdateActionLoading();
        // ユーザー報告API
        await currentUser?.functions["nouknow/userReport"](selectNouknowId);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { error, nouknowTestResult, actionError, sendResult };
};

export default useNlc011DbActions;
