import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { closeNli034Dialog } from "./useNli034Dialog";
import useNli034Input, {
  setCampaignCodeError,
} from "../../input/useNli034Input";
import {
  CampaignCouponsStatusID,
  Collection,
  paymentTypeID,
} from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";

const useNli034DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [
    {
      campaignCode,
      campaignName,
      discountPrice,
      term,
      selectedDate,
      limit,
      afterCampaignType,
      paymentSpan,
    },
  ] = useNli034Input();
  const collection = getMongoDb(currentUser, Collection.CAMPAIGN_COUPONS);

  // キャンペーン登録
  const handleRegisterCampaign = () => {
    void (async () => {
      openCreateActionLoading();
      try {
        // キャンペーンコード重複チェック
        const campaignCodeData = (await collection.findOne({
          campaign_code: campaignCode.value,
          status: CampaignCouponsStatusID.APPLY,
        })) as CampaignCouponsType;
        if (campaignCodeData) {
          setCampaignCodeError(
            "すでに登録されているキャンペーンコードです。別のキャンペーンコードを入力してください。",
          );

          return;
        }

        const params = {
          campaign_code: campaignCode.value,
          campaign_name: campaignName.value,
          payment_type: paymentTypeID.AMOUNT,
          payment_span: Number(paymentSpan),
          discount_price: Number(discountPrice.value),
          cancel_period: selectedDate ? new Date(selectedDate) : null,
          term: Number(term.value),
          limit: Number(limit.value),
          canceled_after_campaign: Number(afterCampaignType),
        };

        await currentUser?.functions["stripe/coupon/upsertCoupon"](params);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNli034Dialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterCampaign,
  };
};

export default useNli034DbActions;
