import { useState } from "react";
import { Collection } from "../../constants/common";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../contexts/RealmApp";
import { getMongoDb } from "../../utils/query";

type UserDataType = {
  _id: string;
  email: string;
};

// usersに同一emailが存在するかチェックします
const useCheckUsersEmail = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [error, setError] = useState<Error | null>(null);

  const checkExistingEmail = async (email: string) => {
    try {
      const collection = getMongoDb(currentUser, Collection.USERS);
      const existingEmail = (await collection.findOne({
        email,
      })) as UserDataType | null;

      if (existingEmail) {
        return true;
      }

      return false;
    } catch (err) {
      setError(checkFetchErr(err));

      return false;
    }
  };

  return { error, checkExistingEmail };
};

export default useCheckUsersEmail;
