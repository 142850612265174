import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import ContentFooter from "../components/ContentFooter/ContentFooter";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  formatHourTime,
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
  handleSelectHospitalMasterTab,
} from "../utils/utils";
import {
  DAILY_SCHEDULE_OPTION_DROPDOWN,
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
  INSPECTION_TOGGLE_ITEMS,
  SCHEDULE_FRAME_DROPDOWN_ITEMS,
  SCHEDULE_HOUR_OPTIONS,
  SCHEDULE_MINUTE_OPTIONS,
  createTimeFromDateStr,
} from "../constants/common";
import { convertNli017Hospital } from "../utils/convertDisplay";
import MIE005 from "./MIE005";
import useMie001DbActions from "../hooks/pages/MIE001/useMie001DbActions";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import useMie001Conditions, {
  bulkUpdateSelectDailyItem,
  clearBulkChecked,
  clearChangeDailyItem,
  setAmEndTime,
  setAmStartTime,
  setBulkChecked,
  setDailyScheduleItem,
  setIsChangedTemplate,
  setItemChecked,
  setPmEndTime,
  setPmStartTime,
  setResetOriginData,
  setSelectDailyItem,
  setTimeFrame,
  setTimeInterval,
  setTimeItems,
} from "../hooks/pages/MIE001/useMie001Conditions";
import Checkbox from "../components/Checkbox/Checkbox";
import Dropdown from "../components/Dropdown/Dropdown";
import TimePicker from "../components/TimePicker/TimePicker";
import TableCell from "../components/Table/TableCell";
import TimeTable from "../components/Table/TimeTable";
import Calender from "../components/Calendar/Calendar";
import InformationDialog from "../components/Dialog/InformationDialog";
import useMie001Dialog, {
  closeMie001Dialog,
  openMie001Dialog,
} from "../hooks/pages/MIE001/useMie001Dialog";
import useScheduleDBActions from "../hooks/common/useScheduleDBActions";

const MIE001 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHospitalDialogOpen, setIsHospitalDialogOpen] = React.useState(false);
  const [inputTimeError, setInputTimeError] = React.useState("");
  const [frameToggleType, setFrameToggleType] = React.useState("1");
  const [selectedValue, setSelectedValue] = React.useState("1");
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [isOpenTemplateConfirm] = useMie001Dialog();
  const { deleteSchedules, scheduleActionError } = useScheduleDBActions();
  const {
    fetchResult: fetchHospitalResult,
    handleUpdateDefaultSchedule,
    handleUpdateDaily,
    actionError,
  } = useMie001DbActions();
  const [
    {
      timeInterval,
      timeFrame,
      originData,
      amStartTime,
      amEndTime,
      pmStartTime,
      pmEndTime,
      initAmStartTime,
      initAmEndTime,
      initPmStartTime,
      initPmEndTime,
      timeItems,
      itemChecked,
      selectDailyItem,
      bulkChecked,
      dailyScheduleItem,
      isChangedTemplate,
    },
  ] = useMie001Conditions();

  const { hospitalName, tel, address, email, prefecture } =
    convertNli017Hospital(fetchHospitalResult);

  // メインタブ
  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  const [formatAMTimeStart, setFormatAMTimeStart] = React.useState<string>(
    formatHourTime(amStartTime),
  );
  const [formatAMTimeEnd, setFormatAMTimeEnd] = React.useState<string>(
    formatHourTime(amEndTime),
  );
  const [formatPMTimeStart, setFormatPMTimeStart] = React.useState<string>(
    formatHourTime(pmStartTime),
  );
  const [formatPMTimeEnd, setFormatPMTimeEnd] = React.useState<string>(
    formatHourTime(pmEndTime),
  );
  const [cancelCount, setCancelCount] = React.useState(0);

  // エラー処理
  useCheckErrorThrowError([mainTabError, actionError, scheduleActionError]);

  useEffect(() => {
    setFormatAMTimeStart(formatHourTime(initAmStartTime));
    setFormatAMTimeEnd(formatHourTime(initAmEndTime));
    setFormatPMTimeStart(formatHourTime(initPmStartTime));
    setFormatPMTimeEnd(formatHourTime(initPmEndTime));
  }, [
    initAmStartTime,
    initAmEndTime,
    initPmStartTime,
    initPmEndTime,
    timeItems,
  ]);

  // 時間の有効性チェック
  const checkTimeValidity = (startTime: Date, endTime: Date): boolean => {
    const startHours = startTime.getHours();
    const startMinutes = startTime.getMinutes();
    const endHours = endTime.getHours();
    const endMinutes = endTime.getMinutes();

    if (
      startHours > endHours ||
      (startHours === endHours && startMinutes > endMinutes)
    ) {
      setInputTimeError("日付を正しく入力してください。");

      return true;
    }
    setInputTimeError("");

    return false;
  };

  // 適用ボタン押下
  const handleApplyButtonClick = () => {
    // 時間の有効性チェック
    if (
      checkTimeValidity(amStartTime, amEndTime) ||
      checkTimeValidity(pmStartTime, pmEndTime)
    ) {
      return;
    }

    // 枠テンプレートを変更の場合、確認ポップアップを表示
    if (originData.timeInterval && originData.timeInterval !== timeInterval) {
      openMie001Dialog();

      return;
    }

    setTimeFrame(Number(timeInterval) as 0 | 15 | 30 | 60 | 90 | 120);
    setFormatAMTimeStart(formatHourTime(amStartTime));
    setFormatAMTimeEnd(formatHourTime(amEndTime));
    setFormatPMTimeStart(formatHourTime(pmStartTime));
    setFormatPMTimeEnd(formatHourTime(pmEndTime));
  };

  // デフォルト枠テンプレート適用
  const applyTemplate = () => {
    // APIでデータ削除
    deleteSchedules("mri", Number(timeInterval));

    // 枠テンプレート変更の場合、デフォルト、日別を初期化する
    const initTimeItems = Object.fromEntries(
      Object.entries(timeItems).map(([day, times]) => [
        day,
        Object.fromEntries(Object.keys(times).map((time) => [time, false])),
      ]),
    );
    const initSelectDailyItem = Object.fromEntries(
      Object.entries(selectDailyItem).map(([day, times]) => [
        day,
        Object.fromEntries(Object.keys(times).map((time) => [time, "-"])),
      ]),
    );

    // デフォルト、日別データ初期化
    setTimeItems(initTimeItems);
    setSelectDailyItem(initSelectDailyItem);
    setDailyScheduleItem([]);

    // 元データリセット
    setResetOriginData(timeInterval);

    // 時間反映
    setTimeFrame(Number(timeInterval) as 0 | 15 | 30 | 60 | 90 | 120);
    setFormatAMTimeStart(formatHourTime(amStartTime));
    setFormatAMTimeEnd(formatHourTime(amEndTime));
    setFormatPMTimeStart(formatHourTime(pmStartTime));
    setFormatPMTimeEnd(formatHourTime(pmEndTime));

    setIsChangedTemplate(true);
    closeMie001Dialog();
  };

  // 枠テンプレート変更
  const onChangeDrop = React.useCallback(
    (value: string) => setTimeInterval(value),
    [],
  );

  // AM開始時間変更
  const onChangeAMStartTime = (date: Date) => {
    setAmStartTime(new Date(date));
  };

  // AM終了時間変更
  const onChangeAMEndTime = (date: Date) => {
    const nextDate = new Date(date);
    setAmEndTime(date);
    if (nextDate.getHours() > pmStartTime.getHours()) {
      setPmStartTime(nextDate);
    }
  };

  // 診療時間 時間 選択肢
  const hourOptionsPMStart = SCHEDULE_HOUR_OPTIONS.filter((item) => {
    const hour = amEndTime.getHours();

    return Number(item.value) >= hour;
  });

  // 医療機関情報を編集ボタン押下
  const handleEditHospital = () => {
    setIsHospitalDialogOpen(true);
  };

  // デフォルト 保存ボタン API
  const handleDefaultUpdateButton = () => {
    handleUpdateDefaultSchedule();
  };

  // デフォルト キャンセルボタン
  const handleDefaultCancel = () => {
    setTimeInterval(originData.timeInterval);
    setTimeFrame(originData.timeFrame);
    setTimeItems(originData.timeItems);
    setFormatAMTimeStart(originData.amStartTimeStr);
    setAmStartTime(createTimeFromDateStr(originData.amStartTimeStr));
    setFormatAMTimeEnd(originData.amEndTimeStr);
    setAmEndTime(createTimeFromDateStr(originData.amEndTimeStr));
    setFormatPMTimeStart(originData.pmStartTimeStr);
    setPmStartTime(createTimeFromDateStr(originData.pmStartTimeStr));
    setFormatPMTimeEnd(originData.pmEndTimeStr);
    setPmEndTime(createTimeFromDateStr(originData.pmEndTimeStr));
    setCancelCount((prev) => prev + 1);
  };

  // デフォルト、日別 トグルボタン
  const handleChangeFrame = (value: string) => {
    setFrameToggleType(value);

    if (value === "1" && !isChangedTemplate) {
      // 変更データクリア
      clearChangeDailyItem();
      setDailyScheduleItem(originData.dailyScheduleItem);
      // チェックボックスクリア
      clearBulkChecked(bulkChecked);
      setSelectedValue("1");
    }
    if (value === "2" && !isChangedTemplate) {
      // デフォルト入力内容リセット
      handleDefaultCancel();
    }
  };

  const getWeekStartAndEnd = (inputDate: Date) => {
    let date = new Date(inputDate.getTime());

    const dayOfWeek = date.getDay();
    const differenceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

    const startOfWeek = new Date(
      date.setDate(date.getDate() + differenceToMonday),
    );

    date = new Date(inputDate.getTime());
    const differenceToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
    const endOfWeek = new Date(
      date.setDate(date.getDate() + differenceToSunday),
    );

    return { startOfWeek, endOfWeek };
  };

  const { startOfWeek, endOfWeek } = getWeekStartAndEnd(selectedDate);

  const getListDaysRender = () => {
    const listDays: Record<number, string> = {
      0: "月",
      1: "火",
      2: "水",
      3: "木",
      4: "金",
      5: "土",
      6: "日",
    };
    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(startOfWeek);
      nextDay.setDate(nextDay.getDate() + i);
      const day = nextDay.getDate();
      listDays[i] = `${day} ${listDays[i]}`;
    }

    return listDays;
  };

  // カレンダー選択
  const handleSelectCalender = (date: Date) => {
    setSelectedDate(date);
    // 変更データクリア
    clearChangeDailyItem();
    setDailyScheduleItem(originData.dailyScheduleItem);
    // チェックボックスクリア
    clearBulkChecked(bulkChecked);
    setSelectedValue("1");
  };

  // 日別 保存ボタン API
  const handleUpdateDailyButton = () => {
    handleUpdateDaily(startOfWeek, endOfWeek);
  };

  // 日別 キャンセルボタン押下
  const handleDailyCancel = () => {
    // 変更データクリア
    clearChangeDailyItem();
    setDailyScheduleItem(originData.dailyScheduleItem);
    setSelectedDate(new Date());
    // チェックボックスクリア
    clearBulkChecked(bulkChecked);
    setSelectedValue("1");
  };

  // 日別 一括変更 適用ボタン
  const handleDailyBulkChange = () => {
    bulkUpdateSelectDailyItem(selectedValue, startOfWeek);
    // チェックボックスクリア
    clearBulkChecked(bulkChecked);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner--with-content-footer">
          <LayoutBox direction="column" gap="2x">
            <div className="util-mt-16 util-mb-16">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <Sheet type="border-blue" padding="16px">
                <LayoutBox gap="2x" direction="column" fullWidth>
                  <LayoutBox justify="between" fullWidth>
                    <Text size="2xl" bold>
                      {hospitalName}
                    </Text>
                    <Button
                      type="secondary"
                      icon="edit"
                      size="medium"
                      width="180px"
                      onClick={handleEditHospital}
                    >
                      医療機関情報を編集
                    </Button>
                  </LayoutBox>
                  <LayoutBox direction="column" fullWidth>
                    <LayoutBox
                      align="center"
                      fullWidth
                      justify="between"
                      gap="3x"
                    >
                      <FormSet
                        label="電話番号"
                        base
                        labelWidth="120px"
                        labelSize="medium"
                        formVertical={false}
                      >
                        {tel}
                      </FormSet>
                      <FormSet
                        label="メールアドレス"
                        base
                        labelWidth="120px"
                        labelSize="medium"
                        formVertical={false}
                      >
                        {email}
                      </FormSet>
                    </LayoutBox>
                    <FormSet
                      label="住所"
                      base
                      labelWidth="120px"
                      labelSize="medium"
                      formVertical={false}
                    >
                      {prefecture}
                      {address}
                    </FormSet>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <div className="util-mt-24 util-mb-24">
                <Tabs
                  size="medium"
                  selectedTab={0}
                  onClick={handleSelectHospitalMasterTab}
                  tabs={HOSPITAL_MASTER_MANAGEMENT_OPTIONS.map((option) => ({
                    title: option.label,
                  }))}
                  bottomBorder
                />
              </div>
              <LayoutBox direction="column" gap="2x">
                <ToggleButton
                  size="medium"
                  items={INSPECTION_TOGGLE_ITEMS}
                  selectedButton={frameToggleType}
                  onClick={handleChangeFrame}
                />
                {frameToggleType === "1" && (
                  <>
                    <LayoutBox
                      justify="between"
                      direction="column"
                      fullWidth
                      gap="3x"
                    >
                      <div className="util-mt-8">
                        <Text>
                          受付可能な時間にはチェックをつけてください。
                        </Text>
                      </div>
                      <LayoutBox align="center" gap="3x">
                        <FormSet
                          label="診療時間"
                          base
                          labelWidth="120px"
                          labelSize="medium"
                          formVertical={false}
                          fullWidth={false}
                          errorTextList={[inputTimeError]}
                        >
                          <LayoutBox>
                            <LayoutBox align="center" gap="1x">
                              <TimePicker
                                minuteOptions={SCHEDULE_MINUTE_OPTIONS}
                                value={amStartTime}
                                onChange={onChangeAMStartTime}
                                showButton={false}
                                dropdownWidth="76px"
                              />
                              〜
                              <TimePicker
                                minuteOptions={SCHEDULE_MINUTE_OPTIONS}
                                value={amEndTime}
                                onChange={onChangeAMEndTime}
                                showButton={false}
                                dropdownWidth="76px"
                              />
                            </LayoutBox>
                            <LayoutBox align="center">
                              <div className="util-flex--shrink-0">
                                <Text> / </Text>
                              </div>
                              <LayoutBox align="center" gap="1x">
                                <TimePicker
                                  hourOptions={hourOptionsPMStart}
                                  minuteOptions={SCHEDULE_MINUTE_OPTIONS}
                                  value={pmStartTime}
                                  onChange={setPmStartTime}
                                  showButton={false}
                                  dropdownWidth="76px"
                                  defaultHours={9}
                                />
                                〜
                                <TimePicker
                                  minuteOptions={SCHEDULE_MINUTE_OPTIONS}
                                  value={pmEndTime}
                                  onChange={setPmEndTime}
                                  showButton={false}
                                  dropdownWidth="76px"
                                  defaultHours={18}
                                />
                              </LayoutBox>
                            </LayoutBox>
                          </LayoutBox>
                        </FormSet>
                        <FormSet
                          label="枠テンプレート"
                          base
                          labelWidth="120px"
                          labelSize="medium"
                          formVertical={false}
                          fullWidth={false}
                        >
                          <div className="util-mr-8">
                            <Dropdown
                              onChange={onChangeDrop}
                              value={timeInterval}
                              items={SCHEDULE_FRAME_DROPDOWN_ITEMS}
                              width="80px"
                            />
                          </div>
                          <Text>分枠</Text>
                        </FormSet>
                        <Button
                          type="secondary"
                          color="main"
                          onClick={handleApplyButtonClick}
                        >
                          適用
                        </Button>
                      </LayoutBox>
                    </LayoutBox>
                    <TimeTable
                      checkbox
                      timeFrame={timeFrame}
                      amStart={formatAMTimeStart}
                      amEnd={formatAMTimeEnd}
                      pmStart={formatPMTimeStart}
                      pmEnd={formatPMTimeEnd}
                      direction="column"
                      defaultItemChecked={timeItems}
                      itemChecked={itemChecked}
                      setItemChecked={setItemChecked}
                      selectDailyItem={selectDailyItem}
                      setSelectDailyItem={setSelectDailyItem}
                      frameToggleType={frameToggleType}
                      cancelCount={cancelCount}
                      startOfWeek={startOfWeek}
                      selectedDate={selectedDate}
                      dailyScheduleItem={dailyScheduleItem}
                      setDailyScheduleItem={setDailyScheduleItem}
                      bulkChecked={bulkChecked}
                      setBulkChecked={setBulkChecked}
                      renderTimeSlot={(item) => (
                        <TableCell
                          textAlign="center"
                          padding="0 8px"
                          bottomBorder={item?.bottomBorder}
                          topBorder={item?.topBorder}
                        >
                          <Checkbox
                            checked={item.selected}
                            onChecked={() => item.onClickItem(item)}
                          />
                        </TableCell>
                      )}
                    />
                  </>
                )}
                {frameToggleType === "2" && (
                  <LayoutBox fullWidth gap="2x">
                    <Calender
                      bordered={false}
                      calendarStartDay={1}
                      startDate={startOfWeek}
                      endDate={endOfWeek}
                      onChangeDate={(date) => handleSelectCalender(date)}
                    />

                    <LayoutBox direction="column" fullWidth>
                      <LayoutBox align="center" justify="end" fullWidth>
                        <FormSet
                          label="一括変更"
                          base
                          labelWidth="120px"
                          labelSize="medium"
                          formVertical={false}
                          fullWidth={false}
                          toolTip={{
                            text: (
                              <div className="util-flex util-flex--column">
                                <Text size="xs" color="white">
                                  -：デフォルトの予約枠から変更なし
                                </Text>
                                <Text size="xs" color="white">
                                  ◯：枠ありに変更
                                </Text>
                                <Text size="xs" color="white">
                                  ×：枠なしに変更
                                </Text>
                              </div>
                            ),
                            width: 200,
                          }}
                        >
                          <Dropdown
                            onChange={setSelectedValue}
                            value={selectedValue}
                            items={[
                              {
                                label: "-（変更なし）",
                                value: "1",
                              },
                              {
                                label: "◯",
                                value: "2",
                              },
                              {
                                label: "×",
                                value: "3",
                              },
                            ]}
                            width="128px"
                          />
                        </FormSet>
                        <Button
                          type="secondary"
                          color="main"
                          onClick={handleDailyBulkChange}
                        >
                          適用
                        </Button>
                      </LayoutBox>
                      <LayoutBox gap="2x" fullWidth direction="column">
                        <TimeTable
                          checkbox
                          timeFrame={timeFrame}
                          amStart={formatAMTimeStart}
                          amEnd={formatAMTimeEnd}
                          pmStart={formatPMTimeStart}
                          pmEnd={formatPMTimeEnd}
                          direction="column"
                          defaultItemChecked={timeItems}
                          listDays={getListDaysRender()}
                          itemChecked={itemChecked}
                          setItemChecked={setItemChecked}
                          selectDailyItem={selectDailyItem}
                          setSelectDailyItem={setSelectDailyItem}
                          cancelCount={cancelCount}
                          frameToggleType={frameToggleType}
                          startOfWeek={startOfWeek}
                          selectedDate={selectedDate}
                          dailyScheduleItem={dailyScheduleItem}
                          setDailyScheduleItem={setDailyScheduleItem}
                          bulkChecked={bulkChecked}
                          setBulkChecked={setBulkChecked}
                          renderTimeSlot={(item) => (
                            <TableCell
                              textAlign="center"
                              padding="8px"
                              bottomBorder={item?.bottomBorder}
                              topBorder={item?.topBorder}
                              background={
                                item.bulkChecked[item.dayNumber][
                                  item.timePeriod
                                ]
                                  ? "#CDE3FF"
                                  : undefined
                              }
                            >
                              <LayoutBox align="center" gap="3x">
                                <Text width="20px" textAlign="center">
                                  {item.selected ? "◯" : "×"}
                                </Text>
                                <div className="util-flex--grow-one">
                                  <Dropdown
                                    value={item.selectDailyItem}
                                    items={
                                      item.selected
                                        ? DAILY_SCHEDULE_OPTION_DROPDOWN.filter(
                                            (option) =>
                                              option.label === "-" ||
                                              option.label === "×",
                                          )
                                        : DAILY_SCHEDULE_OPTION_DROPDOWN.filter(
                                            (option) =>
                                              option.label === "-" ||
                                              option.label === "◯",
                                          )
                                    }
                                    width="100%"
                                    onChange={(value) =>
                                      item.onSelectItem(item, value)
                                    }
                                  />
                                </div>
                              </LayoutBox>
                            </TableCell>
                          )}
                        />
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                )}
              </LayoutBox>
            </Sheet>
          </LayoutBox>
          <div className="util-mb-24" />
        </div>
      </div>
      <ContentFooter
        footerRight={
          <>
            <Button
              type="sub"
              color="neutral"
              size="large"
              width="108px"
              onClick={
                frameToggleType === "1"
                  ? handleDefaultCancel
                  : handleDailyCancel
              }
            >
              キャンセル
            </Button>
            <Button
              color="main"
              type="primary"
              size="large"
              width="64px"
              onClick={
                frameToggleType === "1"
                  ? handleDefaultUpdateButton
                  : handleUpdateDailyButton
              }
            >
              保存
            </Button>
          </>
        }
      />
      {isHospitalDialogOpen && (
        <MIE005
          isOpen={isHospitalDialogOpen}
          setIsOpen={setIsHospitalDialogOpen}
          title="医療機関情報を編集"
          hospitalData={fetchHospitalResult}
        />
      )}
      {isOpenTemplateConfirm && (
        <InformationDialog
          alertLevel="error"
          open={isOpenTemplateConfirm}
          closeDialog={() => closeMie001Dialog()}
          title="枠テンプレートを変更してもよろしいですか？"
          footer={
            <LayoutBox>
              <Button
                color="main"
                size="medium"
                onClick={() => {
                  applyTemplate();
                }}
              >
                OK
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={() => closeMie001Dialog()}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          <>
            枠テンプレートを変更する場合、デフォルト・日別の枠テンプレートが外れます。
            <br />
            ※ 確定した予約は変更されません。
            <br />
            よろしいですか？
          </>
        </InformationDialog>
      )}
    </>
  );
};

export default MIE001;
