/* eslint-disable no-console */
import { useState, useRef, useReducer, useEffect, useCallback } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Tag from "../components/Tag/Tag";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import DatePicker from "../components/DatePicker/DatePicker";
import Link from "../components/Link/Link";
import Tooltip from "../components/Tooltip/Tooltip";
import {
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  ROLE_KEY,
} from "../constants/common";
import {
  copyClipboard,
  formatDate,
  getYoseLabelInfo,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";

import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import NLE003 from "./NLE003";
import { useNLE001DbActions } from "../hooks/pages/NLE001/useNLE001DbActions";
import { setId } from "../hooks/common/useId";
import useNlE001Conditions from "../hooks/pages/NLE001/useNLE001Conditions";
import useNLE003DbActions, {
  yoseInsertData,
} from "../hooks/pages/NLE003/useNLE003DbActions";
import usePagination from "../hooks/component/pagination/usePagination";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

type yoseFormType = Omit<yoseDataTermsType, "sortDirection">;

const NLE001 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const activatorRef = useRef<HTMLDivElement>(null);

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.YOSE_LIST_BROWSING);
  const hasAddRole = useCheckHasRole(ROLE_KEY.YOSE_LIST_ADD);

  const navigate = useNavigateWithUrl();
  const { getYoseData, actionError } = useNLE001DbActions();
  const [initialYoseDataTerms, setInitialYoseDataTerms] = useNlE001Conditions();
  const { insertData } = useNLE003DbActions();
  useCheckErrorThrowError([mainTabError, actionError]);
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  // 寄席フォームの状態
  const [yoseForm, setYoseForm] = useReducer(
    (currentState: yoseFormType, update: Partial<yoseFormType>) => ({
      ...currentState,
      ...update,
    }),
    INIT_SEARCH_CRITERIA.NLE001,
  );

  // 寄席テーブルのデータ検索条件(検索した条件を残しておく)
  const [yoseDataTerms, setYoseDataTerms] = useReducer(
    (currentState: yoseDataTermsType, update: Partial<yoseDataTermsType>) => ({
      ...currentState,
      ...update,
    }),
    initialYoseDataTerms,
  );

  // 寄席テーブルのデータ
  const [yoseData, setYoseData] = useState<NLE001YoseList[]>([]);

  //  新規動画登録ダイアログの表示状態
  const [isDialogOpen, toggleIsDialogOpen] = useReducer((v) => !v, false);

  /**
   *  寄席テーブルのデータを更新します。
   * @param {yoseDataTermsType} terms データの検索条件。省略した場合、yoseDataTermsを参照します。
   */
  const updateYoseData = useCallback(
    (terms: yoseDataTermsType = yoseDataTerms) => {
      void (async () => {
        const data = await getYoseData(terms);
        setYoseData(data);
      })();
    },
    [getYoseData, yoseDataTerms],
  );

  /**
   *  検索ボタン押下時のイベント
   */
  const onSearchBtnClicked = () => {
    setYoseDataTerms(yoseForm);
    updateYoseData({ ...yoseDataTerms, ...yoseForm });
  };

  /**
   *  データを並び替えます。
   */
  const onClickSort = (direction: "asc" | "desc" | "", _colName: string) => {
    const colName = _colName as unknown as "yose_title" | "status";
    const sortDirection = {
      sortDirection: {
        colName,
        direction,
      },
    };
    setYoseDataTerms(sortDirection);
    updateYoseData({ ...yoseDataTerms, ...sortDirection });
  };

  /**
   *  寄席テーブルの詳細ボタン押下時に発火します。
   */
  const onDetailBtnClicked = (id: string) => {
    setId(id);
    setInitialYoseDataTerms(yoseDataTerms);
    navigate(ADMIN.NLE002);
  };

  /**
   *  新規動画登録モーダルにおける登録ボタン押下時のイベント
   */
  const onInsertBtnClicked = (data: yoseInsertData) => {
    void (async () => {
      await insertData(data);
      toggleIsDialogOpen();
      updateYoseData();
    })();
  };

  /**
   *  Vimeoの動画URLを作成します。
   */
  const createVimeoUrl = (vimeoId: string) =>
    `https://player.vimeo.com/video/${vimeoId}`;

  useEffect(() => {
    updateYoseData();
  }, [perPage, skip, hasBrowsingRole, updateYoseData]);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={4}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="1x" fullWidth>
                <LayoutBox gap="3x" fullWidth>
                  <FormSet
                    label="動画名"
                    labelWidth="90px"
                    base
                    minWidth="259px"
                  >
                    <Input
                      value={yoseForm.yoseTitle}
                      placeholder="動画名"
                      width="100%"
                      onChange={(yoseTitle) => setYoseForm({ yoseTitle })}
                    />
                  </FormSet>
                  <FormSet
                    label="配信日"
                    labelWidth="90px"
                    base
                    minWidth="293.33"
                  >
                    <DatePicker
                      type="range"
                      placeholder="開始日"
                      placeholderEnd="終了日"
                      width="100%"
                      // onChangeDate={onChange}
                      selectedDate={yoseForm.deliveryStartDate}
                      onChangeRangeDate={(deliveryStartDate, deliveryEndDate) =>
                        setYoseForm({
                          deliveryStartDate,
                          deliveryEndDate,
                        })
                      }
                      selectedDateRange={{
                        startDate: yoseForm.deliveryStartDate,
                        endDate: yoseForm.deliveryEndDate,
                      }}
                    />
                  </FormSet>
                  <FormSet
                    label="配信停止日"
                    labelWidth="90px"
                    base
                    minWidth="259.33px"
                  >
                    <DatePicker
                      type="range"
                      placeholder="開始日"
                      placeholderEnd="終了日"
                      width="100%"
                      onChangeRangeDate={(
                        unDeliveryStartDate,
                        unDeliveryEndDate,
                      ) =>
                        setYoseForm({
                          unDeliveryStartDate,
                          unDeliveryEndDate,
                        })
                      }
                      selectedDateRange={{
                        startDate: yoseForm.unDeliveryStartDate,
                        endDate: yoseForm.unDeliveryEndDate,
                      }}
                    />
                  </FormSet>
                </LayoutBox>
                <LayoutBox fullWidth>
                  <FormSet
                    label="ステータス"
                    labelWidth="90px"
                    base
                    minWidth="350px"
                    formVertical
                  >
                    <LayoutBox align="center" fullHeight gap="2x">
                      <Checkbox
                        label="配信前"
                        checked={yoseForm.status.isBeforeDelivery}
                        onChecked={(checked) =>
                          setYoseForm({
                            status: {
                              ...yoseForm.status,
                              isBeforeDelivery: checked,
                            },
                          })
                        }
                      />
                      <Checkbox
                        label="新着"
                        checked={yoseForm.status.isNew}
                        onChecked={(checked) =>
                          setYoseForm({
                            status: {
                              ...yoseForm.status,
                              isNew: checked,
                            },
                          })
                        }
                      />
                      <Checkbox
                        label="公開中"
                        checked={yoseForm.status.isPublic}
                        onChecked={(checked) =>
                          setYoseForm({
                            status: {
                              ...yoseForm.status,
                              isPublic: checked,
                            },
                          })
                        }
                      />
                      <Checkbox
                        label="公開終了"
                        checked={yoseForm.status.isClosePublic}
                        onChecked={(checked) =>
                          setYoseForm({
                            status: {
                              ...yoseForm.status,
                              isClosePublic: checked,
                            },
                          })
                        }
                      />
                      <Checkbox
                        label="配信停止"
                        checked={yoseForm.status.isStopDelivery}
                        onChecked={(checked) =>
                          setYoseForm({
                            status: {
                              ...yoseForm.status,
                              isStopDelivery: checked,
                            },
                          })
                        }
                      />
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={() => {
                      setYoseForm(INIT_SEARCH_CRITERIA.NLE001);
                    }}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={onSearchBtnClicked}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox
                direction="column"
                gap="2x"
                justify="end"
                align="end"
                fullWidth
              >
                <LayoutBox align="center" justify="end">
                  <Button
                    type="secondary"
                    size="medium"
                    icon="add"
                    onClick={toggleIsDialogOpen}
                    disabled={!hasAddRole}
                  >
                    新規動画登録
                  </Button>
                </LayoutBox>
                <Table
                  roleKey={ROLE_KEY.YOSE_LIST_BROWSING}
                  type="relaxed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="356px"
                        canSort
                        sortedDirection={
                          yoseDataTerms.sortDirection.colName === "yose_title"
                            ? yoseDataTerms.sortDirection.direction
                            : ""
                        }
                        id="yose_title"
                        onClickSort={onClickSort}
                      >
                        動画名
                      </TableColumn>
                      <TableColumn width="68px" id="col-2" />
                      {/* <TableColumn width="93px" id="col-3" /> */}

                      <TableColumn width="355.5px" id="col-4">
                        URL
                      </TableColumn>
                      <TableColumn
                        width="123px"
                        id="status"
                        canSort
                        sortedDirection={
                          yoseDataTerms.sortDirection.colName === "status"
                            ? yoseDataTerms.sortDirection.direction
                            : ""
                        }
                        onClickSort={onClickSort}
                      >
                        ステータス
                      </TableColumn>
                      <TableColumn width="166px" id="col-6">
                        出演者
                      </TableColumn>
                      {/* <TableColumn width="166px" id="col-7">
                        再生回数
                      </TableColumn> */}
                      <TableColumn width="165px" id="col-8">
                        配信日
                      </TableColumn>
                      <TableColumn width="166px" id="col-9">
                        新着期間
                      </TableColumn>
                    </TableRow>
                  }
                  body={yoseData.map((yose) => {
                    const statusLabel = getYoseLabelInfo(yose.status);

                    return (
                      <TableRow key={yose._id}>
                        <TableCell>{yose.yose_title}</TableCell>
                        <TableCell>
                          <Button
                            type="sub"
                            size="small"
                            onClick={() => {
                              void onDetailBtnClicked(yose._id);
                            }}
                          >
                            詳細
                          </Button>
                        </TableCell>
                        {/* <TableCell>
                        <Image />
                      </TableCell> */}
                        <TableCell>
                          <LayoutBox justify="between" align="center" fullWidth>
                            <Link url={createVimeoUrl(yose.vimeo_id)} external>
                              {createVimeoUrl(yose.vimeo_id)}
                            </Link>
                            <Tooltip content="URLをコピー" direction="top">
                              <Button
                                type="sub"
                                shape="circle"
                                icon="content_copy"
                                size="small"
                                onClick={() =>
                                  copyClipboard(createVimeoUrl(yose.vimeo_id))
                                }
                              />
                            </Tooltip>
                          </LayoutBox>
                        </TableCell>
                        <TableCell>
                          <Tag
                            label={statusLabel.text}
                            showIcon={false}
                            state={statusLabel.btnState}
                          />
                        </TableCell>

                        <TableCell>{yose.cast}</TableCell>
                        <TableCell>
                          {`${formatDate(yose.streaming_date)} ～ ${formatDate(
                            yose.streaming_end_date,
                          )}`}
                        </TableCell>
                        <TableCell>{formatDate(yose.new_period)}</TableCell>
                      </TableRow>
                    );
                  })}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isDialogOpen && (
        <NLE003
          onCloseBtnClicked={toggleIsDialogOpen}
          onInsertBtnClicked={onInsertBtnClicked}
        />
      )}
    </>
  );
};

export default NLE001;
