import { useEffect } from "react";
import { ADMIN, COMMON, HOSPITAL } from "../constants/pagePaths";
import { useRealmApp } from "../contexts/RealmApp";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { UserTypeID } from "../constants/common";

// ログイン分岐処理
const LoginProcessing = () => {
  const navigate = useNavigateCustom();
  const realmAppContext = useRealmApp();

  useEffect(() => {
    void (async () => {
      try {
        // ファンクション
        await realmAppContext.app.currentUser?.refreshCustomData();

        // 変換処理
        const userTypeObject = realmAppContext.currentUser?.customData
          .user_type as usersNumberIntType;
        const userTypeString = userTypeObject
          ? userTypeObject.$numberInt
          : null;
        const userType = userTypeString ? Number(userTypeString) : null;

        if (userType === UserTypeID.ADMIN) {
          navigate(ADMIN.NLB001);
        } else if (userType === UserTypeID.HOSPITAL) {
          navigate(HOSPITAL.MIB001);
        } else {
          // 該当なしの場合、ログアウトして、エラー画面
          void realmAppContext.logOut();
          navigate(COMMON.NLJ007);
        }
      } catch (e) {
        // 該当なしの場合、ログアウトして、エラー画面
        void realmAppContext.logOut();
        navigate(COMMON.NLJ007);
      }
    })();
  }, [navigate, realmAppContext]);

  return <></>;
};

export default LoginProcessing;
