import React from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import Header from "../components/Header/Header";
import Link from "../components/Link/Link";
import { HEADER_LINK } from "../constants/pagePaths";
import Logo from "../components/Logo/Logo";

const NLJ001: React.FC<{ resetErrorBoundary: () => void }> = ({
  resetErrorBoundary,
}) => (
  <>
    <div className="admin-area">
      <div className="admin-header">
        {/* <HeaderAdmin /> */}
        <Header
          leftControl={
            <Link
              url={HEADER_LINK}
              external={false}
              linkClassName="header__left-logo"
            >
              <Logo size="small" type="white-horizontal" />
            </Link>
          }
        />
      </div>
      <div className="util-mb-48" />
      <LayoutBox fullHeight fullWidth justify="center">
        <Sheet
          type="border"
          maxWidth="640px"
          minHeight="247px"
          className="util-flex--align-center util-flex--justify-center"
        >
          <LayoutBox
            direction="column"
            justify="center"
            align="center"
            gap="3x"
            fullWidth
          >
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              gap="1x"
              fullWidth
            >
              <Icon icon="error" size="large" color="#A21B11" />
              <Text size="xl" bold>
                データを取得できませんでした。
              </Text>
              <Text size="base">ページを再読み込みしてお試しください。</Text>
            </LayoutBox>
            <Button
              size="large"
              icon="refresh"
              iconPosition="left"
              width="230px"
              onClick={resetErrorBoundary}
            >
              ページを再読み込みする
            </Button>
          </LayoutBox>
        </Sheet>
      </LayoutBox>
    </div>
  </>
);

export default NLJ001;
