import React from "react";
import Button from "../components/Button/Button";
import Checkbox from "../components/Checkbox/Checkbox";
import FormSet from "../components/FormSet/FormSet";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Icon from "../components/Icon/Icon";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import MenuList from "../components/MenuList/MenuList";
import Pagination from "../components/Pagination/Pagination";
import DetailsMenu from "../components/Popover/DetailsMenu";
import Popover from "../components/Popover/Popover";
import Sheet from "../components/Sheet/Sheet";
import Tabs from "../components/Tab/Tabs";
import Table from "../components/Table/Table";
import TableCell from "../components/Table/TableCell";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectMasterMgmtSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  AfterCampaignID,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  paymentSpanID,
} from "../constants/common";
import useNli033Conditions from "../hooks/pages/NLI033/useNli033Conditions";
import { setPage } from "../hooks/component/pagination/usePagination";
import useNli033DbActions from "../hooks/pages/NLI033/useNli033DbActions";
import useNli034Dialog, {
  openNli034Dialog,
} from "../hooks/pages/NLI034/useNli034Dialog";
import NLI034 from "./NLI034";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNli033DBFetch from "../hooks/pages/NLI033/useNli033DBFetch";
import DatePicker from "../components/DatePicker/DatePicker";
import { _convertToCommaSeparatedString } from "../utils/convertDisplay";

const NLI033 = () => {
  const [isNli034Open] = useNli034Dialog();
  const [conditions] = useNli033Conditions();
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);
  const [deleteCampaignId, setDeleteCampaignId] = React.useState("");
  const { handleDeleteCampaign, error } = useNli033DbActions();
  const { fetchResults, fetchError } = useNli033DBFetch({
    submittedSearchCriteria,
  });

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラーチェック
  useCheckErrorThrowError([mainTabError, fetchError, error]);

  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };

  const handleChangeCode = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, code: value }));
  };

  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      paymentSpan: {
        ...searchCriteria.paymentSpan,
        [checkedItem]: checked,
      },
    }));
  };

  const handleSearchCampaign = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLI033);

  // キャンペーンを追加ボタン押下
  const handleAdd = () => {
    openNli034Dialog();
  };

  // 削除ボタン
  const handleDelete = () => {
    handleDeleteCampaign(deleteCampaignId);
    setIsDeleteConfirmOpen(false);
  };

  // 削除ダイアログclose
  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  // 削除ダイアログopen
  const handleDeleteConfirm = (campaignId: string) => {
    setDeleteCampaignId(campaignId);
    setIsDeleteConfirmOpen(true);
  };

  // キャンペーン終了後チェック変更
  const handleChangeCheckItemCampaign = (
    checked: boolean,
    checkedItem: string,
  ) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      campaignEnd: {
        ...searchCriteria.campaignEnd,
        [checkedItem]: checked,
      },
    }));
  };

  // キャンペーン終了日変更
  const handleChangeCampaignDate = React.useCallback(
    (startDate?: Date, endDate?: Date) => {
      setSearchCriteria((prevState) => ({
        ...prevState,
        campaignStartDate: startDate,
        campaignEndDate: endDate,
      }));
    },
    [],
  );

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-mt-8 util-mb-8">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <ToggleButton
              size="large"
              items={MASTER_MANAGEMENT_OPTIONS}
              selectedButton="7"
              onClick={handleSelectMasterMgmtSubTab}
            />
          </LayoutBox>
          <div className="util-mb-24" />
          <LayoutBox direction="column" gap="3x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <div className="util-full-width">
                  <LayoutBox align="center" gap="3x">
                    <FormSet
                      label="キャンペーンコード"
                      labelWidth="128px"
                      base
                      fullWidth={false}
                      flex="1"
                    >
                      <Input
                        value={searchCriteria.code}
                        placeholder="キャンペーンコード"
                        onChange={handleChangeCode}
                        width="100%"
                      />
                    </FormSet>
                    <FormSet
                      label="キャンペーン名"
                      labelWidth="128px"
                      base
                      fullWidth={false}
                      flex="1"
                    >
                      <Input
                        value={searchCriteria.name}
                        placeholder="キャンペーン名"
                        onChange={handleChangeName}
                        width="100%"
                      />
                    </FormSet>
                    <FormSet
                      label="月額/年額"
                      labelWidth="128px"
                      base
                      formVertical={false}
                      fullWidth={false}
                      flex="1"
                    >
                      <LayoutBox align="center" justify="start" gap="2x">
                        <Checkbox
                          label="月額"
                          checked={searchCriteria.paymentSpan.month}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "month")
                          }
                        />
                        <Checkbox
                          label="年額"
                          checked={searchCriteria.paymentSpan.year}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "year")
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox align="center" gap="3x">
                    <FormSet
                      label="キャンペーン終了日"
                      labelWidth="128px"
                      base
                      fullWidth={false}
                      flex="1"
                    >
                      <DatePicker
                        type="range"
                        placeholder="開始日"
                        width="100%"
                        placeholderEnd="終了日"
                        onChangeRangeDate={handleChangeCampaignDate}
                        selectedDateRange={{
                          startDate: searchCriteria.campaignStartDate,
                          endDate: searchCriteria.campaignEndDate,
                        }}
                      />
                    </FormSet>
                    <FormSet
                      label="キャンペーン終了後"
                      labelWidth="128px"
                      base
                      formVertical={false}
                      fullWidth={false}
                      flex="2 1 24px"
                    >
                      <LayoutBox align="center" justify="start" gap="2x">
                        <Checkbox
                          label="退会"
                          checked={searchCriteria.campaignEnd.cancel}
                          onChecked={(checked) =>
                            handleChangeCheckItemCampaign(checked, "cancel")
                          }
                        />
                        <Checkbox
                          label="通常料金で継続"
                          checked={searchCriteria.campaignEnd.regularPrice}
                          onChecked={(checked) =>
                            handleChangeCheckItemCampaign(
                              checked,
                              "regularPrice",
                            )
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </div>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchCampaign}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox justify="end" fullWidth>
                  <Button
                    icon="add"
                    iconPosition="left"
                    width="194px"
                    onClick={handleAdd}
                  >
                    キャンペーンを追加
                  </Button>
                </LayoutBox>
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn width="220px" id="col-1">
                        キャンペーンコード
                      </TableColumn>
                      <TableColumn width="220px" id="col-2">
                        キャンペーン名
                      </TableColumn>
                      <TableColumn width="130px" id="col-3">
                        月額/年額
                      </TableColumn>
                      <TableColumn width="220px" id="col-4">
                        値引き額
                      </TableColumn>
                      <TableColumn width="220px" id="col-5">
                        期間
                      </TableColumn>
                      <TableColumn width="220px" id="col-6">
                        キャンペーン終了日
                      </TableColumn>
                      <TableColumn width="220px" id="col-7">
                        キャンペーン終了後
                      </TableColumn>
                      <TableColumn width="220px" id="col-8">
                        利用上限
                      </TableColumn>
                      <TableColumn width="300px" id="col-9">
                        対象
                      </TableColumn>
                      <TableColumn width="61px" id="col-10" />
                    </TableRow>
                  }
                  body={fetchResults.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Text size="xs">{user.campaign_code}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.campaign_name}</Text>
                      </TableCell>
                      <TableCell>
                        <Tag
                          label={
                            user.payment_span
                              ? user.payment_span === paymentSpanID.MONTH
                                ? "月額"
                                : "年額"
                              : ""
                          }
                          showIcon={false}
                          state={
                            user.payment_span === paymentSpanID.MONTH
                              ? "warning"
                              : user.payment_span === paymentSpanID.YEAR
                              ? "information"
                              : "neutral"
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Text size="xs">
                          {user.discount_price != null
                            ? `${_convertToCommaSeparatedString(
                                user.discount_price,
                              )}円`
                            : "-"}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">
                          {user.payment_span
                            ? user.payment_span === paymentSpanID.MONTH
                              ? `${user.term}ヶ月`
                              : `${user.term}年`
                            : ""}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.cancel_period}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">
                          {user.canceled_after_campaign ===
                          AfterCampaignID.CANCELLATION
                            ? "退会"
                            : "通常料金で継続"}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{`${user.limit}人`}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.organization_name}</Text>
                      </TableCell>

                      <TableCell textAlign="center">
                        <DetailsMenu
                          onClick={() => handleDeleteConfirm(user._id)}
                          items={[
                            {
                              label: "削除",
                              value: "0",
                              icon: "delete",
                              danger: true,
                              disabled: !!user.usageLimit,
                            },
                          ]}
                          width={101}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
                {isDeleteConfirmOpen && (
                  <InformationDialog
                    alertLevel="error"
                    open={isDeleteConfirmOpen}
                    closeDialog={handleCloseDeleteConfirm}
                    title="削除してもよろしいですか？"
                    footer={
                      <LayoutBox>
                        <Button
                          color="danger"
                          size="medium"
                          onClick={handleDelete}
                        >
                          削除
                        </Button>
                        <Button
                          color="neutral"
                          type="sub"
                          size="medium"
                          onClick={handleCloseDeleteConfirm}
                        >
                          キャンセル
                        </Button>
                      </LayoutBox>
                    }
                  >
                    この操作は取り消せません。
                  </InformationDialog>
                )}
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isNli034Open && <NLI034 />}
    </>
  );
};

export default NLI033;
