import { useState } from "react";

import { useRealmApp } from "../../contexts/RealmApp";
import { checkActionErr } from "../../contexts/CustomErrorBoundary";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../base/useLoadingAction";
import { Collection } from "../../constants/common";
import { getMongoDb } from "../../utils/query";

const useEvaluationDbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);

  // ユーザ評価を更新
  const handleUpdateEvaluation = (id: string, evaluation: string) => {
    void (async () => {
      try {
        openUpdateActionLoading();
        const collection = getMongoDb(currentUser, Collection.PATIENTS);
        await collection.updateOne(
          { _id: id },
          {
            $set: {
              evaluation: Number(evaluation),
              updated_at: new Date(),
            },
          },
        );
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    error,
    handleUpdateEvaluation,
  };
};

export default useEvaluationDbActions;
