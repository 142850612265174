import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  closeActionLoading,
  openCreateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import {
  Collection,
  HospitalPaymentsExaminationID,
  ROLE_KEY,
} from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";
import generateUniqueId from "../../../utils/generateUniqueId";

const useNlc021UpdateStatus = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // hospital_payments登録;
  const handleRegisterHospitalPayments = (
    patientId: string | undefined,
    hospitalId: string | undefined,
  ) => {
    void (async () => {
      openCreateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_HOSPITAL_ADD, currentUser);
        const uniqueId = generateUniqueId();
        // 登録データ
        const insertData = {
          _id: uniqueId,
          patient_id: patientId,
          hospital_id: hospitalId,
          examination: HospitalPaymentsExaminationID.INTERVIEW,
          amount: 1000,
          exam_date: new Date(),
          created_at: new Date(),
          updated_at: new Date(),
        };

        await getMongoDb(currentUser, Collection.HOSPITAL_PAYMENTS).insertOne(
          insertData,
        );
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterHospitalPayments,
  };
};

export default useNlc021UpdateStatus;
