import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.NLC005;
  search: typeof INIT_SEARCH_CRITERIA.NLC005;
};

// NLC004コンポーネントで管理する値 表示、検索条件をページ間で保持
const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.NLC005,
  search: INIT_SEARCH_CRITERIA.NLC005,
});
const useNlc005Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNlc005Conditions;
