import { useState, useEffect } from "react";

import {
  getMongoDb,
  getAggregateNotSetCampaignCoupons,
} from "../../utils/query";
import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import useForceUpdate from "./useForceUpdate";

// 未設定のキャンペーンを取得
const useNotSetCampaignCouponsFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchResult, setFetchResult] = useState<NotSetCampaignCouponType[]>(
    [],
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 未設定のキャンペーンクーポンを取得
        const collection = getMongoDb(currentUser, Collection.CAMPAIGN_COUPONS);
        const aggregate = getAggregateNotSetCampaignCoupons();
        const result = (await collection.aggregate(
          aggregate,
        )) as NotSetCampaignCouponType[];

        setFetchResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount]);

  return { fetchError, fetchResult };
};

export default useNotSetCampaignCouponsFetch;
