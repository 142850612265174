import { useEffect, useState } from "react";
import {
  Collection,
  PatientNouKnowStatusID,
  ROLE_KEY,
} from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc012, getMongoDb } from "../../../utils/query";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkActionErr,
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import useNlc012Conditions, { setNlc12Data } from "./useNlc012Conditions";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import useNlc012Dialog, { closeNlc012Dialog } from "./useNlc012Dialog";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

// MRIのテスト結果を取得
const useNlc012DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [isOpen] = useNlc012Dialog();

  // のうのうのID
  const [{ nouKnowId }] = useNlc012Conditions();
  // 患者ID
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(currentUser, Collection.PATIENT_NOUKNOWS);
        const aggregate = getAggregateNlc012(nouKnowId);
        const result = (await collection.aggregate(
          aggregate,
        )) as PatientNlc012StateType[];

        if (result[0]) setNlc12Data(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (isOpen) void fetchData();
  }, [currentUser, id, nouKnowId, isOpen]);

  // 確認済み
  const confirmSelection = () => {
    void (async () => {
      try {
        openUpdateActionLoading();
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.USER_SCREENING_CONFIRM, currentUser);

        const collection = getMongoDb(currentUser, Collection.PATIENT_NOUKNOWS);
        await collection.updateOne(
          { _id: nouKnowId },
          {
            $set: {
              status: PatientNouKnowStatusID.CONFIRMED,
              updated_at: new Date(),
            },
          },
        );
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNlc012Dialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { fetchError, actionError, confirmSelection };
};

export default useNlc012DbActions;
