import { INIT_SEARCH_CRITERIA } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const initialYoseDataTerms: yoseDataTermsType = {
  ...INIT_SEARCH_CRITERIA.NLE001,
  sortDirection: {
    colName: "updated_at",
    direction: "desc",
  },
};

const stateReactiveVar =
  createReactiveVar<yoseDataTermsType>(initialYoseDataTerms);

/**
 *  寄席テーブルデータの検索初期条件を取得します。保存したデータがある場合は参照します。
 */
const useNlE001Conditions = (): ReactiveVarHooks<yoseDataTermsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNlE001Conditions;
