import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNlc015CancelDialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc015CancelDialog = () => {
  stateReactiveVar(true);
};

export const closeNlc015CancelDialog = () => {
  stateReactiveVar(false);
};

export default useNlc015CancelDialog;
