import { convertUrlsToBase64 } from "../../../utils/utils";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type DefaultType = string[];
const INIT_STATE: DefaultType = [];

const stateReactiveVar = createReactiveVar<DefaultType>(INIT_STATE);
const useImageUpload = (): ReactiveVarHooks<DefaultType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setInitImagesUpload = () => {
  stateReactiveVar(INIT_STATE);
};

// 現在のURLの一覧からbase64に変換したデータを取得
export const getBase64Data = async () =>
  convertUrlsToBase64(stateReactiveVar());

export default useImageUpload;
