import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  deliveryFee: ValidationResultType<string>;
};
type DefaultOptionValidationType = {
  deliveryCondition: {
    minimum_order_amount: ValidationResultType<string>;
    shipping_cost: ValidationResultType<string>;
  };
  deliveryTimes: {
    start: ValidationResultType<string>;
    end: ValidationResultType<string>;
  }[];
};

// バリデーションの不要な型定義
type DefaultNoValidationType = {
  companyName: string;
  shippingId: string;
  companyNameItems: {
    label: string;
    value: string;
  }[];
};

type DefaultType = DefaultValidationType &
  DefaultOptionValidationType &
  DefaultNoValidationType;

const INIT_STATE: DefaultType = {
  shippingId: "",
  deliveryFee: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  companyName: "",
  companyNameItems: [],
  deliveryTimes: [],
  deliveryCondition: {
    minimum_order_amount: {
      value: "",
      validationMessage: "",
      isError: false,
    },
    shipping_cost: {
      value: "",
      validationMessage: "",
      isError: false,
    },
  },
};

// SKUの新規登録、編集ダイアログの入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultType>(INIT_STATE);
const useShippingInput = (): ReactiveVarHooks<DefaultType> =>
  useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setShippingValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setDeliveryFee = (
  value: DefaultValidationType["deliveryFee"]["value"],
) => {
  _setShippingValidation("deliveryFee", value, VALIDATION_TYPE.PRICE_REQUIRED);
};

export const setShippingId = (value: string) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    shippingId: value,
  });
};

export const setCompanyName = (value: string) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    companyName: value,
  });
};

export const setCompanyNameItems = (
  items: {
    label: string;
    value: string;
  }[],
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    companyNameItems: items,
  });
};

export const setDeliveryTimes = (
  items: {
    start: ValidationResultType<string>;
    end: ValidationResultType<string>;
  }[],
) => {
  const currentInput = stateReactiveVar();
  const validatedItems = items.map((item) => ({
    start: {
      value: item.start.value,
      ...commonValidation(item.start.value, VALIDATION_TYPE.HOUR_REQUIRED),
    },
    end: {
      value: item.end.value,
      ...commonValidation(item.end.value, VALIDATION_TYPE.HOUR_REQUIRED),
    },
  }));
  stateReactiveVar({
    ...currentInput,
    deliveryTimes: validatedItems,
  });
};

export const setDeliveryCondition = (deliveryCondition: {
  minimum_order_amount: string;
  shipping_cost: string;
}) => {
  const currentInput = stateReactiveVar();
  const validatedCondition = {
    minimum_order_amount: {
      value: deliveryCondition.minimum_order_amount,
      ...commonValidation(
        deliveryCondition.minimum_order_amount,
        VALIDATION_TYPE.PRICE_REQUIRED,
      ),
    },
    shipping_cost: {
      value: deliveryCondition.shipping_cost,
      ...commonValidation(
        deliveryCondition.shipping_cost,
        VALIDATION_TYPE.PRICE_REQUIRED,
      ),
    },
  };

  stateReactiveVar({
    ...currentInput,
    deliveryCondition: validatedCondition,
  });
};

// 初期化
export const setInitShippingInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkShippingInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    shippingId: _shippingId,
    companyName: _companyName,
    companyNameItems: _companyNameItems,
    deliveryTimes,
    deliveryCondition,
    ...validationInfos
  } = currentInfos;
  const requiredValues = Object.values(validationInfos);
  const validationValues = Object.values({
    ...validationInfos,
    ...deliveryCondition,
  });

  // 時間チェック
  const hasDeliveryTimesError = deliveryTimes.some(
    (time) => time.start.isError || time.end.isError,
  );
  if (hasDeliveryTimesError) return true;

  // 時間存在チェック
  if (deliveryTimes.length === 0) return true;

  // 必須チェック
  const isEmpty = requiredValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useShippingInput;
