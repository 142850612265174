import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";
import { setMriResultId } from "./useNlc015Conditions";

const stateReactiveVar = createReactiveVar<boolean>(false);

// MRI結果ダイアログの開閉状態を管理
const useNlc015Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc015Dialog = (id: string) => {
  setMriResultId(id);
  stateReactiveVar(true);
};

export const closeNlc015Dialog = () => {
  stateReactiveVar(false);
};

export default useNlc015Dialog;
