import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// テーブルのロード状態を管理
const stateReactiveVar = createReactiveVar<boolean>(false);
export const useLoadingTable = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openTableLoading = () => {
  stateReactiveVar(true);
};

export const closeTableLoading = () => {
  stateReactiveVar(false);
};
