import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openNlj002Dialog,
  setIsError,
  setMessage,
} from "../NLJ002/useNlj002Dialog";
import useCheckUsersEmail from "../../common/useCheckUsersEmail";
import { setExistingEmailError } from "../../input/useEmailInput";

const useNli027DbActions = () => {
  const realmAppContext = useRealmApp();
  const [error, setError] = useState<Error | null>(null);
  const { checkExistingEmail, error: existingEmailError } =
    useCheckUsersEmail();

  const handleUpdate = (
    _id: string,
    email: string,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      try {
        // メール重複チェック
        if (await checkExistingEmail(email)) {
          setExistingEmailError();

          return;
        }

        // ファンクション
        await realmAppContext.app.currentUser?.functions.changeEmail({
          userId: _id,
          newEmail: email,
          isAdmin: true,
        });
        // 完了ポップアップ
        setMessage(
          "メールアドレス変更はまだ完了していません",
          "受信したメールのURLをクリックすることでメールアドレスの変更が完了します。",
        );
        setIsError(false);
        openNlj002Dialog();

        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      }
    })();
  };

  return {
    actionError: error,
    existingEmailError,
    handleUpdate,
  };
};

export default useNli027DbActions;
