import React from "react";
import Icon from "../Icon/Icon";

export type TagProps = {
  icon?: string;
  label?: string;
  showIcon?: boolean;
  type?: "default" | "clickable" | "input" | "switch";
  state?:
    | "default"
    | "error"
    | "warning"
    | "information"
    | "success"
    | "neutral"
    | "purple"
    | "orange";
  emphasis?: boolean;
  checked?: boolean;
  disabled?: boolean;
  width?: string;
  onClose?: () => void;
  onClick?: () => void;
  onChecked?: (checked: boolean) => void;
};

const Tag = ({
  icon,
  label,
  showIcon = true,
  type = "default",
  state = "default",
  emphasis = false,
  checked = false,
  disabled = false,
  width = "fit-content",
  onClose,
  onClick,
  onChecked = () => {},
}: TagProps) => {
  const [switchChecked, setSwitchChecked] = React.useState(checked);

  const tagClass = React.useMemo(() => {
    const classes = ["tag"];

    if (type !== "default") classes.push(`tag--${type}`);

    if (switchChecked) classes.push("tag--checked");

    if (disabled) {
      if (emphasis) classes.push("tag--disabled-emphasis");
      else classes.push("tag--disabled");

      return classes.join(" ");
    }

    if (type === "default" || type === "clickable")
      if (emphasis) {
        classes.push(`tag--${state}-emphasis`);
      } else {
        classes.push(`tag--${state}`);
      }

    return classes.join(" ");
  }, [disabled, emphasis, state, switchChecked, type]);

  const iconStr = React.useMemo(() => {
    if ((type === "default" || type === "clickable") && showIcon) {
      if (icon) return icon;
      if (state === "default") return "account_circle";
      if (state === "error") return "error";
      if (state === "warning") return "warning";
      if (state === "information") return "info";
      if (state === "success") return "check_circle";
      if (state === "neutral") return "pending";

      return null;
    }

    return null;
  }, [icon, showIcon, state, type]);

  const labelClass = React.useMemo(() => {
    if (type === "switch") return "tag--switch__label";
    if (type === "clickable") return "tag--clickable__label";

    return "tag__label";
  }, [type]);

  const onSwitchChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      onChecked(checked);
      setSwitchChecked(checked);
    },
    [onChecked],
  );

  if (type !== "switch")
    return (
      <span className={tagClass} style={{ width }} onClick={onClick}>
        {iconStr !== null && (
          <span className="tag__icon">
            <Icon icon={iconStr} size="2xs" />
          </span>
        )}
        {label !== undefined && <span className={labelClass}>{label}</span>}

        {type === "input" && (
          <button
            type="button"
            className="tag--input__close-button"
            onClick={onClose}
          >
            <span className="tag--input__close-icon">
              <Icon icon="close" size="xs" />
            </span>
          </button>
        )}
      </span>
    );

  return (
    <label className={tagClass} style={{ width }}>
      <input
        type="checkbox"
        className="tag--switch__input"
        checked={switchChecked}
        onChange={onSwitchChecked}
      />
      {label !== undefined && <span className={labelClass}>{label}</span>}
    </label>
  );
};

Tag.defaultProps = {
  icon: "",
  label: "",
  showIcon: true,
  type: "default",
  state: "default",
  emphasis: false,
  checked: false,
  disabled: false,
  width: "fit-content",
  onClose: () => {},
  onClick: () => {},
  onChecked: () => {},
};

export default Tag;
