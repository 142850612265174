import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc019, getMongoDb } from "../../../utils/query";
import { Collection, SalesCategoryID } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";

// ユーザの支払い状況を取得
const useNlc019DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [payments, setPayments] = useState<paymentsNlc019StateType[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const [id] = useId();

  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(currentUser, Collection.SALES);
        const conditions = {
          patient_id: id,
          category: {
            $in: [
              SalesCategoryID.INTERVIEW,
              SalesCategoryID.SUBSCRIPTION,
              SalesCategoryID.PET,
              SalesCategoryID.PRODUCT_PURCHASE,
            ],
          },
        };
        const aggregate = getAggregateNlc019(conditions, skip, perPage);
        const aggregateResult = (await collection.aggregate(
          aggregate,
        )) as AggregateResultType<paymentsNlc019StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setPayments(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, perPage, skip]);

  return { error, payments };
};

export default useNlc019DbActions;
