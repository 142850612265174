import { useState, useEffect } from "react";

import { getMongoDb, getAggregateItemTags } from "../../utils/query";
import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import useForceUpdate from "./useForceUpdate";

// 商品タグを取得
const useItemTagsFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchItemTagsResult, setFetchItemTagsResult] = useState<
    ItemTagsStateType[]
  >([]);
  const [fetchItemTagsError, setFetchItemTagsError] = useState<Error | null>(
    null,
  );

  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDbItemTags = getMongoDb(currentUser, Collection.ITEM_TAGS);
        const aggregateItemTags = getAggregateItemTags();
        const itemTagsResult = (await mongoDbItemTags.aggregate(
          aggregateItemTags,
        )) as ItemTagsStateType[];
        setFetchItemTagsResult(itemTagsResult);
      } catch (err) {
        setFetchItemTagsError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, forceUpdate]);

  return { fetchItemTagsError, fetchItemTagsResult };
};

export default useItemTagsFetch;
