import { MriStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  status: MriStatusID;
  selectMriId: string;
};

// 結果、ステータスのセレクトボックス
const stateReactiveVar = createReactiveVar<ConditionsType>({
  status: MriStatusID.ALL,
  selectMriId: "",
});
const useNlc014Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setStatus = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    status: Number(selectStr),
  });
};

export const setSelectMriId = (selectMriId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectMriId,
  });
};

export default useNlc014Conditions;
