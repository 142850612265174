import { useState } from "react";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { convertUrlToBase64 } from "../../../utils/utils";
import generateUniqueId from "../../../utils/generateUniqueId";

import { useRealmApp } from "../../../contexts/RealmApp";
import { FileType } from "../../../components/FileUpload/FileUpload";
import { ROLE_KEY } from "../../../constants/common";
import { useCheckHasRole } from "../../common/useMasterRoleDbActions";

export type yoseInsertData = {
  id?: string | undefined;
  formState: NLE003FormType;
  uploadFiles: FileType[] | [];
};

const useNLE003DbActions = () => {
  const realmAppContext = useRealmApp();

  const [actionError, setActionError] = useState<Error | null>(null);
  const { currentUser } = realmAppContext;
  const hasEditRole = useCheckHasRole(ROLE_KEY.YOSE_DETAIL_EDIT);

  /**
   *  寄席データを追加します。
   * @param {yoseInsertData} 寄席の更新データ
   */
  const insertData = async (props: yoseInsertData) => {
    if (!hasEditRole) return;

    const { id, formState, uploadFiles } = props;
    const imageUrl = uploadFiles[0]?.url;
    const base64Url = imageUrl ? await convertUrlToBase64(imageUrl) : "";

    const param = {
      _id: id || generateUniqueId(),
      vimeo_id: formState.vimeoId,
      yose_title: formState.yoseTitle,
      yose_description: formState.yoseDescription,
      cast: formState.cast,
      cast_profile_image: base64Url,
      cast_note: formState.castNote,
      streaming_date: formState.newStreamingDate,
      streaming_end_date: formState.newStreamingEndDate,
      new_period: formState.newPeriod,

      // バリデーションエラーを回避するために記述 ※バックエンドの改修待ち
      status: "",
      video_id: "",
    };

    openUpdateActionLoading();
    try {
      await currentUser?.functions.upsertYoseApi(param);
    } catch (err) {
      setActionError(checkActionErr(err));
    } finally {
      closeActionLoading();
    }
  };

  return {
    insertData,
    actionError,
  };
};

export default useNLE003DbActions;
