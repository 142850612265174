import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableCell from "../components/Table/TableCell";
import TableColumn from "../components/Table/TableColumn";
import DatePicker from "../components/DatePicker/DatePicker";
import {
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  SORT_EXAMPLES,
} from "../constants/common";
import {
  convertToCurrency,
  downloadCsvNld003,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useNld003DbActions from "../hooks/pages/NLD003/useNld003DbActions";
import { convertNld003Table } from "../utils/convertDisplay";
import { setPage } from "../hooks/component/pagination/usePagination";
import Pagination from "../components/Pagination/Pagination";
import useHospitalPaymentTotalAmountFetch from "../hooks/common/useHospitalPaymentTotalAmountFetch";

const NLD003 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const [startDate, setStartDate] = React.useState<Date | undefined>();
  const [endDate, setEndDate] = React.useState<Date | undefined>();

  const [users, setUsers] = React.useState<Nld003TableType[]>([]);
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const [sortExamples, setSortExample] = React.useState(SORT_EXAMPLES.NLD003);
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLD003,
  );
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLD003,
  );
  const { fetchResult, fetchError, fetchHospitalResult, fetchHospitalError } =
    useNld003DbActions({
      sortExamples,
      submittedSearchCriteria,
    });

  // 集計
  const { hospitalPaymentTotal, hospitalPaymentTotalError } =
    useHospitalPaymentTotalAmountFetch();

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([
    mainTabError,
    fetchError,
    fetchHospitalError,
    hospitalPaymentTotalError,
  ]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNld003Table(fetchResult);
    setUsers(convertResult);
  }, [fetchResult]);

  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };

  // 検索ボタン押下時のイベント
  const handleSearchUsers = () => {
    // 検索条件を確定
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () => {
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLD002);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  // 実施日変更
  const handleChangeRangeDate = React.useCallback(
    (startDate?: Date, endDate?: Date) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setSearchCriteria((prevState) => ({
        ...prevState,
        startDate,
        endDate,
      }));
    },
    [],
  );

  // CSVダウンロード
  const handleDownloadCSV = () => {
    downloadCsvNld003(users, window.location.pathname);
  };

  // 項目チェックボックス
  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      status: {
        ...searchCriteria.status,
        [checkedItem]: checked,
      },
    }));
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = users.map((user) => ({ ...user, checked }));
    setUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });
    setSortExample(newSortExamples);
  };

  // 一覧へ戻る
  const handleGoBack = useNavigateWithBack(ADMIN.NLD002);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={3}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__payment-info">
          <LayoutBox direction="column" gap="2x">
            <Button
              type="sub"
              size="large"
              icon="arrow_back"
              onClick={handleGoBack}
            >
              一覧へ戻る
            </Button>
            <LayoutBox gap="2x">
              <Sheet className="util-px-24 util-py-24 admin-user__payment-info-hospital">
                <LayoutBox direction="column" gap="3x">
                  <Text size="2xl" bold>
                    {fetchHospitalResult?.hospital_name}
                  </Text>
                  <div className="util-full-width">
                    <LayoutBox direction="column">
                      <div className="util-full-width">
                        <LayoutBox justify="between">
                          <LayoutBox align="center">
                            <Label base size="medium" width="120px">
                              電話番号
                            </Label>
                            {fetchHospitalResult?.tel}
                          </LayoutBox>
                        </LayoutBox>
                      </div>
                      <div className="util-full-width">
                        <LayoutBox justify="between">
                          <LayoutBox align="center">
                            <Label base size="medium" width="120px">
                              メールアドレス
                            </Label>
                            {fetchHospitalResult?.email}
                          </LayoutBox>
                        </LayoutBox>
                      </div>
                      <div className="util-full-width">
                        <LayoutBox justify="between">
                          <LayoutBox align="center">
                            <Label base size="medium" width="120px">
                              住所
                            </Label>
                            {fetchHospitalResult?.prefecture}
                            {fetchHospitalResult?.address}
                          </LayoutBox>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
              <div className="admin-user__payment-info-detail">
                <LayoutBox direction="column" gap="2x">
                  <Sheet className="util-px-24 util-py-24">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox align="center" gap="3x" rowGap="1x" wrap>
                        <FormSet
                          label="受検患者名"
                          labelWidth="96px"
                          base
                          minWidth="652px"
                        >
                          <Input
                            width="548px"
                            value={searchCriteria.name}
                            onChange={handleChangeName}
                          />
                        </FormSet>
                        <FormSet
                          label="実施日"
                          labelWidth="96px"
                          minWidth="414px"
                          base
                        >
                          <DatePicker
                            type="range"
                            placeholder="開始日"
                            width="310px"
                            placeholderEnd="終了日"
                            onChangeRangeDate={handleChangeRangeDate}
                            selectedDateRange={{ startDate, endDate }}
                          />
                        </FormSet>
                      </LayoutBox>
                      <FormSet
                        label="項目"
                        labelWidth="96px"
                        base
                        minWidth="100%"
                      >
                        <LayoutBox align="center" justify="start" gap="2x">
                          <Checkbox
                            label="のうKNOW"
                            checked={searchCriteria.status.isKnow}
                            onChecked={(checked) =>
                              handleChangeCheckItem(checked, "isKnow")
                            }
                          />
                          <Checkbox
                            label="MRI検査"
                            checked={searchCriteria.status.isMRIExam}
                            onChecked={(checked) =>
                              handleChangeCheckItem(checked, "isMRIExam")
                            }
                          />
                          <Checkbox
                            label="PET検査"
                            checked={searchCriteria.status.isFDGPETScan}
                            onChecked={(checked) =>
                              handleChangeCheckItem(checked, "isFDGPETScan")
                            }
                          />
                          <Checkbox
                            label="あたまの健康チェック"
                            checked={searchCriteria.status.isHealthCheck}
                            onChecked={(checked) =>
                              handleChangeCheckItem(checked, "isHealthCheck")
                            }
                          />
                          <Checkbox
                            label="健康相談"
                            checked={searchCriteria.status.isHealthConsultation}
                            onChecked={(checked) =>
                              handleChangeCheckItem(
                                checked,
                                "isHealthConsultation",
                              )
                            }
                          />
                        </LayoutBox>
                      </FormSet>
                      <div className="util-full-width">
                        <LayoutBox align="center" justify="end">
                          <Button
                            type="sub"
                            color="neutral"
                            size="large"
                            onClick={handleResetSearchCriteria}
                          >
                            クリア
                          </Button>
                          <Button
                            type="primary"
                            size="large"
                            onClick={handleSearchUsers}
                          >
                            絞り込み
                          </Button>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </Sheet>
                  <Sheet className="util-px-24 util-py-24">
                    <LayoutBox direction="column" gap="2x">
                      <div className="util-full-width">
                        <LayoutBox align="center" justify="between">
                          <FormSet label="集計" labelWidth="96px" base>
                            <LayoutBox align="center" fullHeight>
                              <Text bold size="xl">
                                {convertToCurrency(hospitalPaymentTotal)}
                              </Text>
                            </LayoutBox>
                          </FormSet>
                          <Button
                            type="secondary"
                            size="medium"
                            icon="file_download"
                            onClick={handleDownloadCSV}
                          >
                            CSVダウンロード
                          </Button>
                        </LayoutBox>
                      </div>
                      <div className="admin-user__payment-info-detail-table">
                        <Table
                          type="condensed"
                          width="100%"
                          head={
                            <TableRow>
                              <TableColumn id="col-1" width="280px">
                                <LayoutBox align="center" justify="start">
                                  <Checkbox
                                    checked={isCheckAllUserIds}
                                    label="お客様名"
                                    indeterminate={
                                      !isCheckAllUserIds && isCheckEachId
                                    }
                                    onChecked={(checked) =>
                                      handleCheckIsAllUserIds(checked)
                                    }
                                  />
                                </LayoutBox>
                              </TableColumn>
                              <TableColumn
                                id="col-2"
                                width="280px"
                                textAlign="right"
                              >
                                金額
                              </TableColumn>
                              <TableColumn
                                id="col-3"
                                width="280px"
                                canSort
                                sortedDirection={sortExamples[0].sortDirection}
                                onClickSort={onClickSort}
                              >
                                項目
                              </TableColumn>
                              <TableColumn
                                id="col-4"
                                width="280px"
                                canSort
                                sortedDirection={sortExamples[1].sortDirection}
                                onClickSort={onClickSort}
                              >
                                実施日
                              </TableColumn>
                            </TableRow>
                          }
                          body={users.map((user) => (
                            <TableRow key={user.id}>
                              <TableCell>
                                <Checkbox
                                  label={user.name}
                                  checked={user.checked}
                                  onChecked={(checked) =>
                                    handleCheckUserId(checked, user.id)
                                  }
                                />
                              </TableCell>
                              <TableCell textAlign="right">
                                {user.amount}
                              </TableCell>
                              <TableCell>{user.examination}</TableCell>
                              <TableCell>{user.examDateStr}</TableCell>
                            </TableRow>
                          ))}
                        />
                      </div>
                    </LayoutBox>
                  </Sheet>
                </LayoutBox>
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </div>
            </LayoutBox>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLD003;
