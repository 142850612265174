import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

// 面談新規予約ダイアログの開閉状態を管理
const useNlc021NewDialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc021NewDialog = () => {
  stateReactiveVar(true);
};

export const closeNlc021NewDialog = () => {
  stateReactiveVar(false);
};

export default useNlc021NewDialog;
