import React, { useEffect, useState } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import Image from "../components/Image/Image";
import Heading from "../components/Heading/Heading";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import List from "../components/List/List";
import Divider from "../components/Divider/Divider";
import Switch from "../components/Switch/Switch";
import Tag from "../components/Tag/Tag";
import TableCell from "../components/Table/TableCell";
import Dropdown from "../components/Dropdown/Dropdown";
import ImageGallery from "../components/ImageGallery/ImageGallery";
import ImageUpload from "../components/ImageUpload/ImageUpload";
import ListItem from "../components/List/ListItem";
import {
  MASTER_MANAGEMENT_OPTIONS,
  RECOMMEND_SCOPES,
  RECOMMEND_TERMS,
  ROLE_KEY,
  TAX_RATE,
} from "../constants/common";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useNli011DbActions from "../hooks/pages/NLI011/useNli011DbActions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import useSkuInput, {
  checkSkuInputError,
  setInitSkuInput,
  setSkuAmount,
  setSkuCode,
  setSkuInput,
  setSkuIsShow,
  setSkuName,
  setSkuOption,
  setSkuOptions,
  setSkuStock,
  setSkuTax,
} from "../hooks/input/useSkuInput";
import useImageUpload, {
  setInitImagesUpload,
} from "../hooks/component/ImageUpload/useImageUpload";
import { initProductMgmtId, setSkuId } from "../hooks/common/useProductMgmtId";
import InformationDialog from "../components/Dialog/InformationDialog";
import DetailsMenu from "../components/Popover/DetailsMenu";
import { convertNli011Display } from "../utils/convertDisplay";
import NLI008 from "./NLI008";
import NLI015 from "./NLI015";
import useNli008Dialog, {
  openNli008Dialog,
} from "../hooks/pages/NLI008/useNli008Dialog";
import MoneyInput from "../components/MoneyInput/MoneyInput";
import RadioButton from "../components/RadioButton/RadioButton";
import { openNli015Dialog } from "../hooks/pages/NLI015/useNli015Dialog";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const DETAILS_ITEM = [
  {
    id: "1",
    icon: "delete",
    label: "削除",
    danger: true,
    value: "1",
  },
];

const INIT_ITEM_INFO: Nli011ItemInfoType = {
  itemCode: "",
  itemName: "",
  itemTags: [],
  itemDesc: "",
  itemImages: [],
};

const NLI011 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [{ code, name, amount, stock, options, tax, isShow }] = useSkuInput();
  const [isNli008DialogOpen] = useNli008Dialog();
  const [term, setTerm] = useState<string>("");
  const [scope, setScope] = useState<string>("");

  const [itemInfo, setItemInfo] =
    useStateCustomObj<Nli011ItemInfoType>(INIT_ITEM_INFO);
  const [skuInfos, setSkuInfos] = useStateCustomObj<Nli011SkuInfoType[]>([]);
  const [optionConfigInfos, setOptionConfigInfos] = useStateCustomObj<
    Nli011OptionConfigInfoType[]
  >([]);
  const [, setUploadImages] = useImageUpload();

  const {
    fetchResult,
    fetchError,
    actionError,
    deleteItem,
    addSku,
    updateSku,
    deleteSku,
    selectRecommendTerm,
    selectRecommendScope,
  } = useNli011DbActions();
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([fetchError, actionError, mainTabError]);

  // 全体のデータセット
  useEffect(() => {
    if (fetchResult) {
      const { convertItemInfo, convertSkusInfo, convertOptionConfigInfos } =
        convertNli011Display(fetchResult);
      const { conditions, range } = fetchResult;
      setTerm(conditions !== undefined ? conditions.toString() : "");
      setScope(range ?? "");
      setItemInfo(convertItemInfo);
      setSkuInfos(convertSkusInfo);
      setOptionConfigInfos(convertOptionConfigInfos);
      setSkuOptions(
        convertOptionConfigInfos.map(({ optionConfigId }) => ({
          optionConfigId,
          description: "",
          optionContentsId: "",
        })),
      );
    }
  }, [fetchResult, setItemInfo, setSkuInfos, setOptionConfigInfos]);
  // SKUを選択、キャンセルボタン押下時に入力内容を更新
  const [cancelCount, setCancelCount] = useState(0);
  useEffect(() => {
    const selectSku = skuInfos.find(({ skuId }) => skuId === selectedItem);
    if (selectSku) {
      // 選択したSKUの入力内容反映
      const {
        skuCode,
        skuName,
        amount,
        stock,
        options,
        isShow,
        tax,
        skuImages,
      } = selectSku;
      setSkuInput({
        code: skuCode,
        name: skuName,
        amount,
        stock,
        options,
        tax,
        isShow,
      });
      setUploadImages(skuImages);
    } else {
      // 初期表示、新規追加押下時
      setInitSkuInput();
      setInitImagesUpload();
    }
  }, [
    selectedItem,
    setUploadImages,
    skuInfos,
    cancelCount,
    isNli008DialogOpen,
    fetchResult,
  ]);

  // 削除ダイアログ(共通)
  const [dialogOpen, setDialogOpen] = useState(false);
  type DeleteTargetType = "" | "item" | "sku" | "recommend";
  const [deleteTarget, setDeleteDialog] = useState<DeleteTargetType>("");
  const openDialog = (target: DeleteTargetType) => {
    setDeleteDialog(target);
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };
  // 各種削除処理
  const handleDelete = () => {
    switch (deleteTarget) {
      case "item":
        deleteItem(ADMIN.NLI007);
        break;
      case "sku":
        deleteSku();
        setSelectedItem("");
        break;
      default:
        break;
    }
    setDeleteDialog("");
    closeDialog();
  };

  // SKU一覧選択処理
  const handleSkuListClick = (itemId: string) => {
    setSkuId(itemId);
    setSelectedItem(itemId);
  };
  // SKU新規追加ボタン押下
  const clickSkuAddButton = () => {
    setSelectedItem("");
  };
  // SKU3点リーダー押下
  const handleSkuDetailsMenuItemClick = (skuId: string, value: string) => {
    const selectedItem = DETAILS_ITEM.find((item) => item.label === value);
    if (selectedItem) {
      if (selectedItem.value === DETAILS_ITEM[0].value) {
        // 削除ポップアップ
        setSkuId(skuId);
        openDialog("sku");
      }
    }
  };
  // SKUキャンセルボタン押下処理
  const clickSkuCancel = () => {
    // 入力前の状態に戻す
    setCancelCount((prevCount) => prevCount + 1);
  };

  // 一覧へ戻る
  const goBack = useNavigateWithBack(ADMIN.NLI007);
  const handleGoBack = () => {
    initProductMgmtId();
    goBack();
  };

  // 商品種別未設定 かつ SKUが1件でもあった場合削除ボタンを非活性
  const isDeleteDisabled = !itemInfo.itemCode && skuInfos.length > 0;

  // すべて、設定しないを選択した場合範囲を非活性化
  const getIsScopeDisabled = (targetTerm: string) =>
    [RECOMMEND_TERMS[0].value, RECOMMEND_TERMS[6].value].includes(targetTerm);

  const changeRecommendTerms = (selectTerm: string) => {
    setTerm(selectTerm);
    const isScopeDisabled = getIsScopeDisabled(selectTerm);

    let newScope = scope;
    if (isScopeDisabled) {
      // すべて、設定しないを選択した場合、デフォルトの範囲を設定
      newScope = RECOMMEND_SCOPES[2].value;
      setScope(newScope);
    }

    selectRecommendTerm(selectTerm, newScope);
  };

  const changeRecommendScopes = (selectScope: string) => {
    setScope(selectScope);
    selectRecommendScope(term, selectScope);
  };
  const isScopeDisabled = getIsScopeDisabled(term);

  // 権限チェック
  const hasAddRole = useCheckHasRole(ROLE_KEY.MASTER_PRODUCT_ADD);
  const hasEditRole = useCheckHasRole(ROLE_KEY.MASTER_PRODUCT_EDIT);
  // 追加、保存ボタンの表示非表示
  const isShowAddEditButton = (() => {
    if (selectedItem) {
      // SKUを選択中は編集権限を確認
      return hasEditRole;
    }

    // 非選択中は追加権限を確認
    return hasAddRole;
  })();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <LayoutBox gap="2x" justify="start" align="center" fullWidth>
              <Button
                type="sub"
                size="large"
                width="136px"
                icon="arrow_back"
                onClick={handleGoBack}
              >
                一覧へ戻る
              </Button>
              <Text size="2xl" bold>
                商品詳細
              </Text>
            </LayoutBox>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox gap="2x" direction="column" fullWidth>
                <Sheet type="header" padding="4px 8px 4px 8px">
                  <Heading tag="h2">商品種別</Heading>
                </Sheet>
                <LayoutBox justify="between" fullWidth>
                  <LayoutBox justify="start" gap="2x" fullWidth>
                    <LayoutBox direction="column" flexShrink={0}>
                      <ImageGallery urls={itemInfo.itemImages} />
                      {itemInfo.itemImages.length === 0 && (
                        <Image
                          width="286px"
                          height="162px"
                          placholderText="未設定"
                          type="simple"
                        />
                      )}
                    </LayoutBox>
                    <LayoutBox gap="2x" direction="column" fullWidth>
                      <LayoutBox fullWidth>
                        <LayoutBox direction="column" fullWidth>
                          <Text size="small" color="neutralLight">
                            {itemInfo.itemCode || "-"}
                          </Text>
                          <Text size="2xl" bold>
                            {itemInfo.itemName || "未設定"}
                          </Text>
                          <LayoutBox justify="start" fullWidth>
                            {itemInfo.itemTags.map((itemTag) => (
                              <Tag
                                key={itemTag._id}
                                label={itemTag.tag_name}
                                showIcon={false}
                                state="default"
                              />
                            ))}
                          </LayoutBox>
                        </LayoutBox>
                        <LayoutBox justify="end" fullWidth>
                          <Button
                            type="secondary"
                            color="danger"
                            icon="delete"
                            width="88px"
                            size="large"
                            onClick={() => openDialog("item")}
                            disabled={isDeleteDisabled || !hasEditRole}
                          >
                            削除
                          </Button>
                          <Button
                            type="secondary"
                            icon="edit"
                            width="168px"
                            size="large"
                            onClick={openNli008Dialog}
                            disabled={!hasEditRole}
                          >
                            商品種別を編集
                          </Button>
                        </LayoutBox>
                      </LayoutBox>
                      <LayoutBox fullWidth minWidth="1030px" maxWidth="75%">
                        <Text>{itemInfo.itemDesc || "-"}</Text>
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
            <Sheet className="util-px-24 util-py-16">
              <LayoutBox gap="3x" justify="between" fullWidth>
                <LayoutBox
                  direction="column"
                  gap="2x"
                  maxWidth="1300px"
                  fullWidth
                >
                  <Sheet type="header" padding="4px 8px 4px 8px">
                    <LayoutBox justify="between" fullWidth>
                      <Heading tag="h2">SKU</Heading>
                      <Button
                        type="secondary"
                        icon="settings"
                        size="small"
                        width="109px"
                        onClick={openNli015Dialog}
                        disabled={!hasEditRole}
                      >
                        オプション管理
                      </Button>
                    </LayoutBox>
                  </Sheet>
                  <LayoutBox justify="between" gap="2x" fullWidth>
                    <LayoutBox
                      gap="2x"
                      direction="column"
                      width="400px"
                      flexShrink={0}
                    >
                      <LayoutBox justify="end" fullWidth>
                        <Button
                          type="secondary"
                          icon="add"
                          width="98px"
                          onClick={clickSkuAddButton}
                          disabled={!hasAddRole}
                        >
                          新規追加
                        </Button>
                      </LayoutBox>

                      <List height="100%">
                        {skuInfos.map((skuInfo) => (
                          <ListItem
                            key={skuInfo.skuId}
                            id={skuInfo.skuId}
                            left={
                              <Image
                                width="64px"
                                height="64px"
                                url={skuInfo.skuImages[0] ?? ""}
                              />
                            }
                            center={
                              <LayoutBox direction="column" fullWidth>
                                <LayoutBox fullWidth>
                                  <Text size="small" color="neutralLight">
                                    {skuInfo.skuCode}
                                  </Text>
                                </LayoutBox>
                                <Text size="large" bold>
                                  {skuInfo.skuName}
                                </Text>
                                <LayoutBox gap="1/2x" fullWidth>
                                  <LayoutBox gap="1/2x" justify="between">
                                    <Text size="small" bold>
                                      金額：
                                    </Text>
                                    <Text size="small">
                                      {`¥${Number(
                                        skuInfo.amount,
                                      ).toLocaleString()}`}
                                    </Text>
                                  </LayoutBox>
                                  <Divider
                                    vertical
                                    height="20px"
                                    margin="12px"
                                  />
                                  <LayoutBox gap="1/2x" justify="between">
                                    <Text size="small" bold>
                                      在庫：
                                    </Text>
                                    <Text size="small">{skuInfo.stock}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </LayoutBox>
                            }
                            right={
                              <LayoutBox align="end" fullWidth>
                                {hasEditRole && (
                                  <DetailsMenu
                                    items={DETAILS_ITEM}
                                    width={141}
                                    onClick={(value: string) => {
                                      handleSkuDetailsMenuItemClick(
                                        skuInfo.skuId,
                                        value,
                                      );
                                      setIsOpen(false);
                                    }}
                                  />
                                )}
                              </LayoutBox>
                            }
                            onClick={handleSkuListClick}
                            isSelected={selectedItem === skuInfo.skuId}
                          />
                        ))}
                      </List>
                    </LayoutBox>
                    <LayoutBox justify="between" fullWidth flexShrink={2}>
                      <Sheet
                        type="border"
                        padding="0px"
                        footer={
                          <>
                            <LayoutBox justify="end" fullWidth>
                              <Button
                                type="sub"
                                size="large"
                                color="neutral"
                                width="108px"
                                onClick={clickSkuCancel}
                              >
                                キャンセル
                              </Button>
                              {isShowAddEditButton && (
                                <Button
                                  type="primary"
                                  width="64px"
                                  size="large"
                                  onClick={selectedItem ? updateSku : addSku}
                                  disabled={checkSkuInputError()}
                                >
                                  {selectedItem ? "保存" : "追加"}
                                </Button>
                              )}
                            </LayoutBox>
                          </>
                        }
                        maxWidth="884px"
                      >
                        <Sheet padding="16px 32px">
                          <LayoutBox justify="between" gap="3x" fullWidth>
                            <LayoutBox direction="column" fullWidth>
                              <FormSet
                                label="SKUコード"
                                vertical
                                errorTextList={[code.validationMessage]}
                              >
                                <Input
                                  value={code.value}
                                  placeholder="SKUコード"
                                  width="100%"
                                  onChange={setSkuCode}
                                  error={code.isError}
                                />
                              </FormSet>
                              <FormSet
                                label="SKU名"
                                vertical
                                errorTextList={[name.validationMessage]}
                              >
                                <Input
                                  value={name.value}
                                  placeholder="SKU名"
                                  width="100%"
                                  onChange={setSkuName}
                                  error={name.isError}
                                />
                              </FormSet>
                              <FormSet label="商品表示" vertical>
                                <Switch
                                  onChange={setSkuIsShow}
                                  checked={isShow}
                                >
                                  表示
                                </Switch>
                              </FormSet>
                              <LayoutBox
                                justify="between"
                                gap="3x"
                                wrap
                                fullWidth
                                rowGap="1x"
                              >
                                <FormSet
                                  label="金額"
                                  vertical
                                  maxWidth="190px"
                                  errorTextList={[amount.validationMessage]}
                                >
                                  <MoneyInput
                                    width="100%"
                                    value={amount.value}
                                    onChange={setSkuAmount}
                                  />
                                </FormSet>
                                <FormSet
                                  label="消費税率"
                                  vertical
                                  maxWidth="190px"
                                >
                                  <LayoutBox gap="2x" fullWidth>
                                    {TAX_RATE.map(({ rate, label }) => (
                                      <RadioButton
                                        checked={tax === rate}
                                        value={rate}
                                        onSelect={setSkuTax}
                                        key={label}
                                      >
                                        {label}
                                      </RadioButton>
                                    ))}
                                  </LayoutBox>
                                </FormSet>
                                <FormSet
                                  label="在庫"
                                  vertical
                                  maxWidth="190px"
                                  errorTextList={[stock.validationMessage]}
                                >
                                  <Input
                                    width="100%"
                                    value={stock.value}
                                    onChange={setSkuStock}
                                    error={stock.isError}
                                  />
                                </FormSet>
                              </LayoutBox>
                              <Divider width="100%" margin="0" />
                              {optionConfigInfos.map(
                                ({ optionTitle, optionConfigId }) => (
                                  <FormSet
                                    label={optionTitle}
                                    vertical
                                    maxWidth="190px"
                                    errorTextList={[
                                      options?.[optionConfigId]
                                        ?.validationMessage,
                                    ]}
                                    key={optionConfigId}
                                  >
                                    <Input
                                      width="100%"
                                      value={
                                        options?.[optionConfigId]?.value ?? ""
                                      }
                                      onChange={(newValue) =>
                                        setSkuOption(optionConfigId, newValue)
                                      }
                                      error={options?.[optionConfigId]?.isError}
                                    />
                                  </FormSet>
                                ),
                              )}
                            </LayoutBox>
                            <LayoutBox fullWidth flexShrink={4}>
                              <FormSet label="商品画像" vertical>
                                <LayoutBox direction="column" fullWidth>
                                  <LayoutBox wrap minWidth="168px" fullWidth>
                                    <ImageUpload />
                                  </LayoutBox>
                                </LayoutBox>
                              </FormSet>
                            </LayoutBox>
                          </LayoutBox>
                        </Sheet>
                      </Sheet>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
                <LayoutBox direction="column" gap="2x" width="500px">
                  <Sheet type="header" padding="4px 8px 4px 8px">
                    <Heading tag="h2">レコメンド対象</Heading>
                  </Sheet>
                  <Table
                    type="regular"
                    width="100%"
                    head={
                      <TableRow>
                        <TableColumn width="166.67px" id="col-1">
                          内容
                        </TableColumn>
                        <TableColumn width="166.67px" id="col-2">
                          条件
                        </TableColumn>
                        <TableColumn width="166.67px" id="col-3">
                          範囲
                        </TableColumn>
                      </TableRow>
                    }
                    body={
                      <TableRow>
                        <TableCell>
                          <Text size="xs">ユーザー評価</Text>
                        </TableCell>
                        <TableCell>
                          <Dropdown
                            size="small"
                            onChange={changeRecommendTerms}
                            items={RECOMMEND_TERMS}
                            width="138px"
                            value={term}
                            disabled={!hasEditRole}
                          />
                        </TableCell>
                        <TableCell>
                          <Dropdown
                            size="small"
                            onChange={changeRecommendScopes}
                            items={RECOMMEND_SCOPES}
                            width="177px"
                            disabled={isScopeDisabled || !hasEditRole}
                            value={scope}
                          />
                        </TableCell>
                      </TableRow>
                    }
                  />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {/* 削除確認ダイアログ */}
      <InformationDialog
        alertLevel="error"
        open={dialogOpen}
        closeDialog={closeDialog}
        title="削除してもよろしいですか？"
        footer={
          <LayoutBox>
            <Button color="danger" size="medium" onClick={handleDelete}>
              削除
            </Button>
            <Button
              color="neutral"
              type="sub"
              size="medium"
              onClick={closeDialog}
            >
              キャンセル
            </Button>
          </LayoutBox>
        }
      >
        この操作は取り消せません。
      </InformationDialog>
      <NLI008 />
      <NLI015 />
    </>
  );
};

export default NLI011;
