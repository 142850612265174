import React from "react";
import Tooltip from "../Tooltip/Tooltip";

export type TableCellProps = {
  textAlign?: "left" | "right" | "center" | "justify";
  stickyLeft?: string;
  stickyRight?: string;
  tooltipText?: string;
  rowSpan?: number;
  background?: string;
  children: React.ReactNode;
  wrap?: boolean;
  bottomBorder?: boolean;
  topBorder?: boolean;
  bold?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rest?: any[];
  tooltipInDialog?: boolean;
  padding?: string;
  verticalAlign?: string;
  borderLeft?: boolean;
};

const TableCell = React.forwardRef(
  (
    {
      textAlign,
      stickyLeft,
      stickyRight,
      background,
      tooltipText,
      children,
      wrap = false,
      tooltipInDialog = false,
      padding,
      bottomBorder,
      topBorder,
      bold,
      rowSpan,
      verticalAlign,
      borderLeft,
      ...rest
    }: TableCellProps,
    ref: React.Ref<HTMLTableCellElement>,
  ) => {
    const cellClass = React.useMemo(() => {
      const stickyClass = stickyLeft
        ? ["table__cell--sticky"]
        : stickyRight
        ? ["table__cell--sticky table__cell--sticky-right"]
        : [];

      const wrapClass = wrap ? ["table__cell--wrap"] : [];
      const borderClass = bottomBorder
        ? ["table__cell--with-border-bottom"]
        : "";
      const borderTopClass = topBorder ? ["table__cell--with-border-top"] : "";
      const isBold = bold ? ["table__cell--bold"] : "";

      const leftBorder = !borderLeft ? ["table__cell--no-border-left"] : [];

      return [
        "table__cell",
        ...stickyClass,
        ...wrapClass,
        ...borderClass,
        ...isBold,
        ...leftBorder,
        ...borderTopClass,
      ].join(" ");
    }, [
      stickyLeft,
      stickyRight,
      wrap,
      bottomBorder,
      topBorder,
      bold,
      borderLeft,
    ]);

    const cellRef = React.useRef<HTMLDivElement>(null);
    const [isTooltipActive, setIsTooltipActive] = React.useState(false);
    const [recalculate, setRecalculate] = React.useState(tooltipInDialog);

    const hasScroll = (element: HTMLElement): boolean =>
      element.scrollWidth > element.clientWidth;

    const checkDescendants = React.useCallback(
      (element: HTMLElement): boolean => {
        const children = Array.from(element.children) as HTMLElement[];

        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          if (hasScroll(child)) {
            return true;
          }

          if (child.children.length > 0) {
            const hasScrollDescendant = checkDescendants(child);
            if (hasScrollDescendant) {
              return true;
            }
          }
        }

        return false;
      },
      [],
    );

    React.useEffect(() => {
      const checkEllipsis = () => {
        const cellElement = cellRef.current;
        if (cellElement) {
          const mainCellHasScroll = hasScroll(cellElement);
          const hasScrollDescendant = checkDescendants(cellElement);

          setIsTooltipActive(mainCellHasScroll || hasScrollDescendant);
        }
      };
      checkEllipsis();
      window.addEventListener("resize", checkEllipsis);
      if (recalculate !== tooltipInDialog) {
        setRecalculate(tooltipInDialog);
        checkEllipsis();
      }

      return () => {
        window.removeEventListener("resize", checkEllipsis);
      };
    }, [checkDescendants, tooltipInDialog, setRecalculate, recalculate]);

    return (
      <td
        className={cellClass}
        style={{
          textAlign,
          left: stickyLeft,
          right: stickyRight,
          background,
          padding,
          verticalAlign,
        }}
        rowSpan={rowSpan}
        ref={ref}
        {...rest}
      >
        {tooltipText !== "" ? (
          <div className="table-cell__tooltip">
            <Tooltip
              direction="under"
              disabled={!isTooltipActive}
              content={<span>{tooltipText}</span>}
            >
              <div className="table__cell-inner" ref={cellRef}>
                {children}
              </div>
            </Tooltip>
          </div>
        ) : (
          <>{children}</>
        )}
      </td>
    );
  },
);

TableCell.defaultProps = {
  textAlign: "left",
  stickyLeft: "",
  stickyRight: "",
  tooltipText: "",
  rowSpan: 1,
  background: "",
  wrap: false,
  bottomBorder: false,
  topBorder: false,
  bold: false,
  rest: [],
  tooltipInDialog: false,
  padding: "0 16px",
  verticalAlign: "",
  borderLeft: true,
};

export default TableCell;
