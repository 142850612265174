import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  code: ValidationResultType<string>;
  name: ValidationResultType<string>;
  description: ValidationResultType<string>;
};
// バリデーションの不要な型定義
type DefaultType = {
  tags: { id: string; label: string }[];
  isShow: boolean;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  code: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  name: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  description: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  tags: [],
  isShow: true,
};

// Itemの新規登録、編集ダイアログの入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useItemInput = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setItemValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setItemCode = (value: DefaultValidationType["code"]["value"]) => {
  _setItemValidation("code", value, VALIDATION_TYPE.ITEM_CODE_REQUIRED);
};
export const setItemName = (value: DefaultValidationType["name"]["value"]) => {
  _setItemValidation("name", value, VALIDATION_TYPE.ITEM_NAME_REQUIRED);
};
export const setItemDescription = (
  value: DefaultValidationType["description"]["value"],
) => {
  _setItemValidation(
    "description",
    value,
    VALIDATION_TYPE.ITEM_DESCRIPTION_OPTIONAL,
  );
};

export const deleteItemTags = (deleteTagId: DefaultType["tags"][0]["id"]) => {
  const currentInput = stateReactiveVar();
  const tags = currentInput.tags.filter((tag) => tag.id !== deleteTagId);
  stateReactiveVar({
    ...currentInput,
    tags,
  });
};
export const setItemTags = (value: DefaultType["tags"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    tags: value,
  });
};

export const setItemIsShow = (value: DefaultType["isShow"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    isShow: value,
  });
};

// 値をすべて更新
export const setItemInput = ({
  code,
  name,
  description,
  tags,
  isShow,
}: {
  code: DefaultValidationType["code"]["value"];
  name: DefaultValidationType["name"]["value"];
  description: DefaultValidationType["description"]["value"];
  tags: DefaultType["tags"];
  isShow: DefaultType["isShow"];
}) => {
  setItemCode(code);
  setItemName(name);
  setItemDescription(description);
  setItemTags(tags);
  setItemIsShow(isShow);
};

// 初期化
export const setInitItemInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkItemInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    tags: _tags,
    isShow: _isShow,
    ...checkValidationInfos
  } = currentInfos;
  const { description: _, ...checkEmptyInfos } = checkValidationInfos;
  const checkEmptyValues = Object.values(checkEmptyInfos);
  const checkValidationValues = Object.values(checkValidationInfos);

  // 必須チェック
  const isEmpty = checkEmptyValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = checkValidationValues.some(
    ({ isError }) => isError,
  );
  if (isValidationError) return true;

  return false;
};

export default useItemInput;
