import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.NLD004;
  search: typeof INIT_SEARCH_CRITERIA.NLD004;
  authorizationStartDate: Date | undefined;
  authorizationEndDate: Date | undefined;
  paymentStartDate: Date | undefined;
  paymentEndDate: Date | undefined;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.NLD004,
  search: INIT_SEARCH_CRITERIA.NLD004,
  authorizationStartDate: undefined,
  authorizationEndDate: undefined,
  paymentStartDate: undefined,
  paymentEndDate: undefined,
});

const useNld004Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNld004Conditions;
