import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.MIC001;
  search: typeof INIT_SEARCH_CRITERIA.MIC001;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.MIC001,
  search: INIT_SEARCH_CRITERIA.MIC001,
});

const useMic001Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useMic001Conditions;
