import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useOrganizationInput, {
  setCampaignError,
} from "../../input/useOrganizationInput";
import generateUniqueId from "../../../utils/generateUniqueId";
import { closeNli020Dialog } from "./useNli020Dialog";

const useNli020DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const collection = getMongoDb(currentUser, Collection.ORGANIZATIONS);
  const [{ organizationName, tel, address, campaignCouponId }] =
    useOrganizationInput();

  // 団体登録
  const handleRegisterOrganization = () => {
    void (async () => {
      openCreateActionLoading();
      try {
        // キャンペーンクーポン重複チェック
        if (campaignCouponId) {
          const organizationData = (await collection.findOne({
            campaign_coupon_id: campaignCouponId,
          })) as OrganizationType;
          if (organizationData) {
            setCampaignError(
              "選択したキャンペーンは既に使用されています。キャンセルを押下し、再度お試しください。",
            );

            return;
          }
        }

        setCampaignError("");

        // 登録データ
        const insertData = {
          _id: generateUniqueId(),
          organization_name: organizationName.value,
          tel: tel.value,
          address: address.value,
          campaign_coupon_id: campaignCouponId,
          created_at: new Date(),
          updated_at: new Date(),
        };

        await collection.insertOne(insertData);
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNli020Dialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 団体更新
  const handleUpdateOrganization = (_id: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // キャンペーンクーポン重複チェック
        const organizationData = (await collection.findOne({
          campaign_coupon_id: campaignCouponId,
          _id: { $ne: _id },
        })) as OrganizationType;
        if (organizationData) {
          setCampaignError(
            "選択したキャンペーンは既に使用されています。キャンセルを押下し、再度お試しください。",
          );

          return;
        }
        setCampaignError("");

        // 更新データ
        const updateData = {
          organization_name: organizationName.value,
          tel: tel.value,
          address: address.value,
          campaign_coupon_id: campaignCouponId,
          updated_at: new Date(),
        };

        await collection.updateOne({ _id }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNli020Dialog();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleRegisterOrganization,
    handleUpdateOrganization,
  };
};

export default useNli020DbActions;
