import { PetStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  status: PetStatusID;
  selectPetId: string;
};

// ステータスのセレクトボックス
const stateReactiveVar = createReactiveVar<ConditionsType>({
  status: PetStatusID.ALL,
  selectPetId: "",
});
const useNlc017Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setStatus = (selectStr: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    status: Number(selectStr),
  });
};

export const setSelectPetId = (selectPetId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    selectPetId,
  });
};

export default useNlc017Conditions;
