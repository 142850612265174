import React, { useEffect, useState } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import {
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  ROLE_KEY,
  SORT_EXAMPLES,
  UserStatusID,
} from "../constants/common";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useNli017DbActions from "../hooks/pages/NLI017/useNli017DbActions";
import {
  convertNli017Hospital,
  convertNli017Table,
} from "../utils/convertDisplay";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { setPage } from "../hooks/component/pagination/usePagination";
import MIE006 from "./MIE006";
import { setInitUserDetailInput } from "../hooks/input/useUserDetailInput";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";
import NLI030 from "./NLI030";

const NLI017 = () => {
  const [hospitalUsers, setHospitalUsers] = useStateCustomObj<
    Nli017TableType[]
  >([]);
  // ソートの状態
  const [sortExamples, setSortExample] = React.useState(SORT_EXAMPLES.NLI017);
  // 入力中の検索条件
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLI017,
  );
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLI017,
  );
  const {
    fetchHospitalResult,
    fetchHospitalError,
    fetchHospitalUserResult,
    fetchHospitalUserError,
  } = useNli017DbActions({ sortExamples, submittedSearchCriteria });
  const { hospitalName, tel, prefecture, address, email } =
    convertNli017Hospital(fetchHospitalResult);
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHospitalDialogOpen, setIsHospitalDialogOpen] = useState(false);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [userDialogTitle, setUserDialogTitle] = useState("");
  const [selectHospitalUser, setSelectHospitalUser] =
    useState<Nli017TableType>();

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    fetchHospitalError,
    fetchHospitalUserError,
  ]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNli017Table(fetchHospitalUserResult);
    setHospitalUsers(convertResult);
  }, [fetchHospitalUserResult, setHospitalUsers]);

  // 検索条件 医療機関ユーザID
  const handleChangeHospitalUserId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, hospitalUserId: value }));
  };

  // 検索条件 医師名
  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, doctorName: value }));
  };

  // 検索条件 メール
  const handleChangeEmail = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, email: value }));
  };

  // 検索ボタン押下
  const handleSearchUsers = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () => {
    const { role: _, ...restSearchCriteria } = INIT_SEARCH_CRITERIA.NLI017;
    setSearchCriteria((prevState) => ({
      ...prevState,
      ...restSearchCriteria,
    }));
  };

  // 医療機関情報を編集ボタン押下
  const handleEditHospital = () => {
    setIsHospitalDialogOpen(true);
  };

  // 詳細ボタン押下
  const handleUpdateUser = (hospitalUser: Nli017TableType) => {
    setUserDialogTitle("更新");
    setSelectHospitalUser(hospitalUser);
    setIsUserDialogOpen(true);
  };

  // スタッフを追加ボタン押下
  const handleAddUser = () => {
    setUserDialogTitle("登録");
    setSelectHospitalUser(undefined);
    setInitUserDetailInput();
    setIsUserDialogOpen(true);
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = hospitalUsers.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setHospitalUsers(newUsers);

    setIsCheckAllUserIds(newUsers.every((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);

    const newUsers = hospitalUsers.map((user) => ({ ...user, checked }));

    setHospitalUsers(newUsers);
  };

  const isCheckEachId = React.useMemo(
    () => hospitalUsers.some((user) => user.checked === true),
    [hospitalUsers],
  );

  const isCheckAllId = React.useMemo(
    () => hospitalUsers.every((user) => user.checked === true),
    [hospitalUsers],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  // 一覧へ戻る
  const handleGoBack = useNavigateWithBack(ADMIN.NLI016);

  // 権限チェック
  const hasAddRole = useCheckHasRole(ROLE_KEY.MASTER_HOSPITAL_ADD);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-mt-8 util-mb-8">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <Button
                type="sub"
                icon="arrow_back"
                width="112px"
                onClick={handleGoBack}
              >
                一覧へ戻る
              </Button>
              <Sheet
                padding=""
                type="border-blue"
                className="util-mt-24 util-px-20 util-py-16"
              >
                <LayoutBox direction="column" gap="2x">
                  <LayoutBox direction="column" gap="2x" fullWidth>
                    <LayoutBox align="center" justify="between" fullWidth>
                      <Text size="2xl" bold>
                        {hospitalName}
                      </Text>
                      <div className="util-mr-4">
                        <Button
                          type="secondary"
                          icon="edit"
                          width="180px"
                          onClick={handleEditHospital}
                        >
                          医療機関情報を編集
                        </Button>
                      </div>
                    </LayoutBox>
                    <LayoutBox direction="column" fullWidth>
                      <LayoutBox align="center" justify="start" gap="2x">
                        <FormSet
                          label="電話番号"
                          labelWidth="120px"
                          base
                          flex="1"
                          labelSize="medium"
                          formVertical={false}
                          minWidth="413px"
                        >
                          <Text>{tel}</Text>
                        </FormSet>
                        <FormSet
                          label="メールアドレス"
                          labelWidth="120px"
                          base
                          flex="1"
                          labelSize="medium"
                          formVertical={false}
                        >
                          <Text>{email}</Text>
                        </FormSet>
                      </LayoutBox>
                      <LayoutBox align="center" justify="start" gap="2x">
                        <FormSet
                          label="住所"
                          labelWidth="120px"
                          base
                          flex="1"
                          labelSize="medium"
                          formVertical={false}
                        >
                          <Text>
                            {prefecture}
                            {address}
                          </Text>
                        </FormSet>
                      </LayoutBox>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <Sheet type="gray" className="util-mt-24 util-px-24 util-py-24">
                <LayoutBox direction="column">
                  <LayoutBox
                    align="center"
                    justify="between"
                    gap="3x"
                    fullWidth
                  >
                    <FormSet label="ID" labelWidth="120px" base flex="1">
                      <Input
                        value={searchCriteria.hospitalUserId}
                        placeholder="ID"
                        width="100%"
                        onChange={handleChangeHospitalUserId}
                      />
                    </FormSet>
                    <FormSet
                      label="スタッフ名"
                      labelWidth="120px"
                      base
                      flex="1"
                    >
                      <Input
                        value={searchCriteria.doctorName}
                        placeholder="スタッフ名"
                        width="494px"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet
                      label="メールアドレス"
                      labelWidth="120px"
                      base
                      flex="1"
                    >
                      <Input
                        value={searchCriteria.email}
                        placeholder="メールアドレス"
                        width="100%"
                        onChange={handleChangeEmail}
                      />
                    </FormSet>
                  </LayoutBox>
                </LayoutBox>
                <div className="util-mt-16">
                  <LayoutBox align="center" justify="end">
                    <Button
                      type="sub"
                      color="neutral"
                      size="large"
                      onClick={handleResetSearchCriteria}
                    >
                      クリア
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleSearchUsers}
                    >
                      検索
                    </Button>
                  </LayoutBox>
                </div>
              </Sheet>
              <LayoutBox direction="column" gap="2x" justify="end">
                <div className="util-full-width util-mt-24">
                  <LayoutBox align="center" justify="end">
                    <Button
                      type="secondary"
                      size="medium"
                      icon="person_add_alt_1"
                      onClick={handleAddUser}
                      disabled={!hasAddRole}
                    >
                      スタッフを追加
                    </Button>
                  </LayoutBox>
                </div>
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn
                        width="330px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="330px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        スタッフ名
                      </TableColumn>
                      <TableColumn width="330px" id="col-3">
                        メールアドレス
                      </TableColumn>

                      <TableColumn width="77px" id="col-4" />
                    </TableRow>
                  }
                  body={hospitalUsers.map((hospitalUser) => (
                    <TableRow
                      key={hospitalUser.id}
                      isSelected={hospitalUser.checked}
                    >
                      <TableCell>
                        <Checkbox
                          label={hospitalUser.userId}
                          checked={hospitalUser.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, hospitalUser.userId)
                          }
                        />
                      </TableCell>
                      <TableCell>{hospitalUser.fullName}</TableCell>
                      <TableCell>{hospitalUser.email}</TableCell>
                      <TableCell textAlign="center">
                        <Button
                          type="sub"
                          size="small"
                          onClick={() => handleUpdateUser(hospitalUser)}
                          disabled={
                            hospitalUser.status === UserStatusID.UNREGISTERED
                          }
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isHospitalDialogOpen && (
        <NLI030
          isOpen={isHospitalDialogOpen}
          setIsOpen={setIsHospitalDialogOpen}
          title="医療機関情報を編集"
          hospitalData={fetchHospitalResult}
        />
      )}
      {isUserDialogOpen && (
        <MIE006
          isOpen={isUserDialogOpen}
          setIsOpen={setIsUserDialogOpen}
          title={userDialogTitle}
          hospitalId={fetchHospitalResult?._id}
          hospitalUser={selectHospitalUser}
          setHospitalUser={setSelectHospitalUser}
        />
      )}
    </>
  );
};

export default NLI017;
