import React from "react";
import Spinner from "../Loading/Spinner";
import Button from "../Button/Button";

export type ToastProps = {
  type?: "default" | "loading";
  open?: boolean;
  displayTime?: number;
  onClose: () => void;
  children: React.ReactNode;
};

const Toast = ({
  type = "default",
  open = true,
  displayTime = 5000,
  onClose,
  children,
}: ToastProps) => {
  const [internalOpen, setInternalOpen] = React.useState(false);

  const showToast = React.useCallback(() => {
    if (open) {
      setInternalOpen(open);
      if (type === "default") {
        setTimeout(() => {
          setInternalOpen(false);
          onClose();
        }, displayTime);
      }
    }
  }, [displayTime, onClose, open, type]);

  React.useEffect(() => {
    if (open !== internalOpen) {
      showToast();
    }
  }, [internalOpen, open, showToast]);

  const displayStyle = React.useMemo(() => {
    if (internalOpen) {
      return {
        display: "flex",
      };
    }

    return { display: "none" };
  }, [internalOpen]);

  const _onClose = React.useCallback(() => {
    setInternalOpen(false);
    onClose();
  }, [onClose]);

  return (
    <div
      className={`toast ${internalOpen ? "toast--open" : "toast--close"}`}
      style={displayStyle}
    >
      {type === "loading" && <Spinner color="white" />}
      {children}
      <Button
        className="toast__button"
        color="background-dark"
        icon="close"
        type="sub"
        size="small"
        onClick={_onClose}
      />
    </div>
  );
};

Toast.defaultProps = {
  type: "default",
  open: true,
  displayTime: 5000,
};

export default Toast;
