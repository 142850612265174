import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Text from "../components/Text/Text";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import {
  INIT_SEARCH_CRITERIA,
  INIT_PAGE,
  HOSPITAL_MASTER_MANAGEMENT_OPTIONS,
} from "../constants/common";
import useHospitalMainTabFetch from "../hooks/useHospitalMainTabFetch";
import {
  handleSelectHospitalMainTab,
  handleSelectHospitalMasterInfo,
} from "../utils/utils";
import { useCheckErrorThrowError } from "../utils/checkError";
import useMid001DbActions from "../hooks/pages/MID001/useMid001DbActions";
import { convertMid001Table } from "../utils/convertDisplay";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { setPage } from "../hooks/component/pagination/usePagination";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { HOSPITAL } from "../constants/pagePaths";
import MenuList from "../components/MenuList/MenuList";
import Popover from "../components/Popover/Popover";
import { setId } from "../hooks/common/useId";
import useMid001Conditions from "../hooks/pages/MID001/useMid001Conditions";

const MID001 = () => {
  const [conditions, setConditions] = useMid001Conditions();
  const [users, setUsers] = useStateCustomObj<Mid001TableType[]>([]);
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { fetchResult, fetchError } = useMid001DbActions({
    sortExamples,
    submittedSearchCriteria,
  });

  const { error: mainTabError, tabItems: mainTabItems } =
    useHospitalMainTabFetch();

  useCheckErrorThrowError([mainTabError, fetchError]);

  useEffect(() => {
    const convertResult = convertMid001Table(fetchResult);
    setUsers(convertResult);
  }, [fetchResult, setUsers]);

  // ID検索入力
  const handleChangeId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, id: value }));
  };

  // お客様名検索入力
  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, patientName: value }));
  };

  // 電話番号検索入力
  const handleChangeTel = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, tel: value }));
  };

  // 検索ボタン押下
  const handleSearchUsers = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.MID001);

  // 詳細ボタン押下
  const navigate = useNavigateWithUrl();
  const navigateToDetail = (id: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
    });
    setId(id);
    navigate(HOSPITAL.MID002);
  };

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={2}
                tabs={mainTabItems}
                onClick={handleSelectHospitalMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={HOSPITAL_MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectHospitalMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox align="center" justify="around" gap="3x" fullWidth>
                <FormSet label="ID" labelWidth="96px" base>
                  <Input
                    value={searchCriteria.id}
                    placeholder="ID"
                    width="100%"
                    onChange={handleChangeId}
                  />
                </FormSet>
                <FormSet label="お客様名" labelWidth="96px" base>
                  <Input
                    value={searchCriteria.patientName}
                    placeholder="お客様名"
                    width="100%"
                    onChange={handleChangeName}
                  />
                </FormSet>
                <FormSet label="電話番号" labelWidth="96px" base>
                  <Input
                    value={searchCriteria.tel}
                    placeholder="電話番号"
                    width="100%"
                    onChange={handleChangeTel}
                  />
                </FormSet>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <>
                      <TableRow>
                        <TableColumn
                          width="140px"
                          id="col-1"
                          canSort
                          sortedDirection={sortExamples[0].sortDirection}
                          onClickSort={onClickSort}
                        >
                          ID
                        </TableColumn>
                        <TableColumn
                          id="col-2"
                          canSort
                          sortedDirection={sortExamples[1].sortDirection}
                          onClickSort={onClickSort}
                        >
                          お客様名
                        </TableColumn>
                        <TableColumn width="77px" id="col-3" />
                        <TableColumn width="282px" id="col-4">
                          最終予約日
                        </TableColumn>
                        <TableColumn width="282px" id="col-5">
                          電話番号
                        </TableColumn>
                        <TableColumn id="col-6" width="282px">
                          メールアドレス
                        </TableColumn>
                      </TableRow>
                    </>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Text size="xs">{user.userId}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs"> {user.patientName}</Text>
                      </TableCell>
                      <TableCell textAlign="center">
                        <Button
                          type="sub"
                          size="small"
                          onClick={() => navigateToDetail(user.id)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.fixBookDateDisplay}</Text>
                      </TableCell>
                      <TableCell>{user.tel}</TableCell>

                      <TableCell>
                        <Text size="xs">{user.email}</Text>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default MID001;
