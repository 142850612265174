/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartData,
  ChartOptions,
} from "chart.js/auto";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale);

export type BarChartProps = {
  chartData: ChartData;
  chartOptions?: ChartOptions;
  height?: number;
  unit?: string;
  unitDirection?: "left" | "right";
  maxYScale?: number;
  barWidth?: number;
};

const BarChart = ({
  chartData,
  chartOptions,
  height = 300,
  unit = "¥",
  unitDirection = "left",
  maxYScale = 1000,
  barWidth = 0.6,
}: BarChartProps) => {
  const barChartRef: any = React.useRef();

  const [imageElements, setImageElements] = React.useState<HTMLImageElement[]>(
    [],
  );

  React.useEffect(() => {
    const images = [];
    for (let i = 0; i < 4; i++) {
      const img = new Image();
      img.src = `assets/chart-pattern-${i + 1}.png`;
      images.push(img);
      img.onload = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        barChartRef.current.update();
      };
    }
    setImageElements(images);
  }, []);

  const barChartData: any = React.useMemo(
    () => ({
      ...chartData,
      datasets: chartData.datasets.map((dataset, index, array) => ({
        ...dataset,
        backgroundColor:
          index === 0
            ? "#096AE2"
            : (ctx: any) => {
                if (imageElements[array.length - index - 1]) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  const context = ctx.chart.ctx;
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  const pattern = context.createPattern(
                    imageElements[array.length - index - 1],
                    "repeat",
                  );

                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  return pattern;
                }

                return "#fff";
              },
        barPercentage: barWidth,
        borderWidth: 2,
        borderColor: "#fff",
      })),
    }),
    [chartData, imageElements, barWidth],
  );

  const barChartOptions: any = React.useMemo(
    () =>
      chartOptions || {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false,
            grid: {
              lineWidth: 0,
            },
          },
          y: {
            stacked: false,
            max: maxYScale,
            ticks: {
              callback: (value: string) =>
                unitDirection === "right"
                  ? `${value.toLocaleString()}${unit}`
                  : `${unit}${value.toLocaleString()}`,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,
            position: "nearest",

            external: (context: any) => {
              // Tooltip Element
              let tooltipEl = document.getElementById("barchart-tooltip");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "barchart-tooltip";
                tooltipEl.innerHTML =
                  '<table></table><div style="border:4px solid transparent; border-top-color:rgba(0, 0, 0, 0.8);position:absolute;left:47.5%;bottom:-8px;"></div>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              const tooltipModel = context.tooltip;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = "0";

                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (tooltipModel.yAlign) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              // Set Text
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (tooltipModel.body) {
                let innerHtml = "<thead>";

                innerHtml += "</thead><tbody>";

                chartData.datasets.forEach((dataset, index) => {
                  let background = "background:#096AE2";
                  const label = dataset?.label ?? "";
                  if (index === 1) {
                    background =
                      "background: url(assets/chart-pattern-1.png) repeat";
                  } else if (index === 2) {
                    background =
                      "background: url(assets/chart-pattern-2.png) repeat";
                  }
                  innerHtml +=
                    `<tr><td><div style="width:16px;height:16px;${background};margin-right:4px;border-radius:50%;border:2px solid #fff;"></div>` +
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `</td><td>
                      ${
                        (unit &&
                          unitDirection === "right" &&
                          `${label}:${
                            dataset?.data[
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                              tooltipModel.dataPoints[0].dataIndex
                            ]?.toLocaleString() || ""
                          }${unit}`) ||
                        ""
                      }
                      ${
                        (unit &&
                          unitDirection === "left" &&
                          `${unit}${
                            dataset?.data[
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                              tooltipModel.dataPoints[0].dataIndex
                            ]?.toLocaleString() || ""
                          }`) ||
                        ""
                      }
                    </td></tr>`;
                });
                innerHtml += "</tbody>";

                const tableRoot = tooltipEl.querySelector("table");
                if (tableRoot) tableRoot.innerHTML = innerHtml;
              }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              const position = context.chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = "1";
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left = `${
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
                tooltipModel.caretX -
                17 * chartData.datasets.length -
                (chartData.datasets.length === 1 ? 8 : 0)
              }px`;
              tooltipEl.style.top = `${
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access
                position.top +
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-plus-operands
                tooltipModel.caretY -
                (chartData.datasets.length * 24 + 24)
              }px`;
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
              tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
              tooltipEl.style.pointerEvents = "none";
              tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
              tooltipEl.style.borderRadius = "4px";
              tooltipEl.style.color = "#fff";
              tooltipEl.style.padding = "8px";
              tooltipEl.style.fontSize = "13px";
            },
          },
        },
      },
    [chartData.datasets, chartOptions, unit, unitDirection, maxYScale],
  );

  return (
    <Bar
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ref={barChartRef}
      style={{ width: "100%", height }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data={barChartData}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      options={barChartOptions}
      height={height}
    />
  );
};

BarChart.defaultProps = {
  chartOptions: undefined,
  height: undefined,
  unit: "¥",
  unitDirection: "left",
  maxYScale: 10000,
  barWidth: 0.6,
};

export default BarChart;
