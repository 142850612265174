import React from "react";
import RadioButton from "./RadioButton";

export type RadioButtonGroupRowProps = {
  column: number;
  items: {
    label: string;
    value: string;
  }[];
  name: string;
  selectedValue: string;
  onSelect: (value: string) => void;
  radioGap: string;
};

const RadioButtonGroupRow = ({
  column,
  items,
  name,
  onSelect,
  selectedValue,
  radioGap,
}: RadioButtonGroupRowProps) => {
  const radioClass = React.useMemo(() => {
    const classes = ["radio-group-row"];
    if (column) {
      classes.push(`radio-group-row__column--${column}`);
    }

    return classes.join(" ");
  }, [column]);

  return (
    <div className={radioClass} style={{ gap: radioGap }}>
      {items.map(({ label, ...item }) => (
        <RadioButton
          {...item}
          key={`${name}-${item.value}`}
          name={name}
          onSelect={onSelect}
          checked={selectedValue === item.value}
        >
          {label}
        </RadioButton>
      ))}
    </div>
  );
};

export default RadioButtonGroupRow;
