import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.MID001;
  search: typeof INIT_SEARCH_CRITERIA.MID001;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.MID001,
  search: INIT_SEARCH_CRITERIA.MID001,
});

const useMid001Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useMid001Conditions;
