import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Table from "../components/Table/Table";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import TableCell from "../components/Table/TableCell";
import {
  INIT_EVALUATION,
  MASTER_MANAGEMENT_OPTIONS,
  PatientTypeID,
  SalesStatusID,
  USER_EVALUATION,
  UserStatusID,
} from "../constants/common";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import {
  convertNlc019Payments,
  convertPatientInfo,
} from "../utils/convertDisplay";
import Label from "../components/FormSet/Label";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import Divider from "../components/Divider/Divider";
import useNlc019DbActions from "../hooks/pages/NLC019/useNlc019DbActions";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";
import Chat from "../features/Chat";
import MedicalRecord from "../features/MedicalRecord";
import useUserDetailTabConditions from "../hooks/commonCondition/useUserDetailTabConditions";
import Pagination from "../components/Pagination/Pagination";
import Tag from "../components/Tag/Tag";
import RadioButton from "../components/RadioButton/RadioButton";

const NLC019 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(INIT_EVALUATION);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const [{ userDetailTab }] = useUserDetailTabConditions();

  // 患者情報取得
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const {
    id,
    userId,
    name,
    birth,
    genderStr,
    tel,
    email,
    address,
    evaluation,
    patientTypeNum,
    patientTypeStr,
    statusNum,
    statusStr,
    orgName,
  } = convertPatientInfo(patientInfo);

  // 支払い情報取得
  const { payments, error: paymentsError } = useNlc019DbActions();
  const convertPayments = convertNlc019Payments(payments);

  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
    paymentsError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={3}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLD004}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <LayoutBox align="center">
                          <span className="util-font-neutral--light font-component-chip">
                            {userId}
                          </span>
                          <Tag
                            showIcon={false}
                            label={patientTypeStr}
                            state={
                              patientTypeNum === PatientTypeID.MEMBER
                                ? "information"
                                : patientTypeNum === PatientTypeID.ORG
                                ? "success"
                                : patientTypeNum === PatientTypeID.INVITATION
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <Tag
                            showIcon={false}
                            label={statusStr}
                            state={
                              statusNum === UserStatusID.ACTIVE ||
                              statusNum === UserStatusID.INVITATION
                                ? "success"
                                : statusNum === UserStatusID.CANCELLATION
                                ? "error"
                                : statusNum === UserStatusID.SUSPENDED
                                ? "warning"
                                : "neutral"
                            }
                          />
                        </LayoutBox>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          {patientTypeNum !== PatientTypeID.INVITATION && (
                            <div className="util-half-width">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  認知症リスク
                                  <br />
                                  評価
                                </Label>
                                <div className="util-flex util-flex--wrap util-flex--col-gap16">
                                  {USER_EVALUATION.map((item) => (
                                    <RadioButton
                                      key={item.value}
                                      name="userRatingRadioButton"
                                      value={item.value}
                                      checked={selectedValue === item.value}
                                      onSelect={handleChangeEvaluation}
                                    >
                                      {item.label}
                                    </RadioButton>
                                  ))}
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="large" width="120px">
                                    生年月日
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {birth}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="large" width="120px">
                                    性別
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {genderStr}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="large" width="120px">
                                    電話番号
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {tel}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="large" width="120px">
                                    メールアドレス
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    <Text>{email}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="large" width="120px">
                                    住所
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {address}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    団体
                                  </Label>
                                  {orgName}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <Tabs
                    selectedTab={3}
                    tabs={userDetailTabItems}
                    size="medium"
                    bottomBorder
                    onClick={handleSelectAdminUserDetailTab}
                  />
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="3x">
                      <div className="util-full-width">
                        <LayoutBox direction="column" gap="2x" fullWidth>
                          <Table
                            type="regular"
                            width="100%"
                            head={
                              <TableRow>
                                <TableColumn minWidth="151px" id="col-1">
                                  項目
                                </TableColumn>
                                <TableColumn
                                  textAlign="right"
                                  width="161px"
                                  id="col-2"
                                >
                                  金額
                                </TableColumn>
                                <TableColumn width="152px" id="col-3">
                                  請求日
                                </TableColumn>
                                <TableColumn width="157px" id="col-4">
                                  支払日
                                </TableColumn>
                                <TableColumn minWidth="110px" id="col-5">
                                  支払状況
                                </TableColumn>
                                <TableColumn width="461px" id="col-6">
                                  Stripe ID
                                </TableColumn>
                              </TableRow>
                            }
                            body={convertPayments.map((convertPayment) => (
                              <TableRow key={convertPayment.id}>
                                <TableCell>{convertPayment.category}</TableCell>
                                <TableCell textAlign="right">
                                  {convertPayment.amount}
                                </TableCell>
                                <TableCell>
                                  {convertPayment.authorizationDate}
                                </TableCell>
                                <TableCell>
                                  {convertPayment.paymentDate}
                                </TableCell>
                                <TableCell>
                                  <Tag
                                    label={convertPayment.statusStr}
                                    showIcon={false}
                                    state={
                                      convertPayment.statusNum ===
                                      SalesStatusID.SALE_FAILURE
                                        ? "error"
                                        : convertPayment.statusNum ===
                                          SalesStatusID.SOLD
                                        ? "success"
                                        : convertPayment.statusNum ===
                                          SalesStatusID.SALE_CANCELLATION
                                        ? "warning"
                                        : convertPayment.statusNum ===
                                          SalesStatusID.CREDIT_ACQUISITION
                                        ? "information"
                                        : "neutral"
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {convertPayment.stripePaymentIntent}
                                </TableCell>
                              </TableRow>
                            ))}
                          />
                        </LayoutBox>
                        <LayoutBox justify="end" fullWidth>
                          <Pagination input={false} />
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          {userDetailTab === 0 && <EventHistories />}
          {userDetailTab === 1 && <Chat />}
          {userDetailTab === 2 && <MedicalRecord />}
        </div>
      </div>
    </>
  );
};

export default NLC019;
