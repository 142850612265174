import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

// Zoomリンク再発行
const useNlc021ReissueZoomLink = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // Zoomリンク再発行
  const reissueZoomLink = (
    id: string,
    interviewFixDateStartDate: Date,
    interviewFixDateEndDate: Date,
    doctorUserId: string,
    doctorName: string,
    doctorZoomUserId: string,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // ZOOM再発行API
        await currentUser?.functions["interview/fix"](
          id,
          interviewFixDateStartDate,
          interviewFixDateEndDate,
          doctorUserId,
          doctorName,
          doctorZoomUserId,
          true,
        );

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    reissueZoomLink,
  };
};

export default useNlc021ReissueZoomLink;
