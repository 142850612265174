import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Text from "../components/Text/Text";
import Input from "../components/Input/Input";
import List from "../components/List/List";
import ListItem from "../components/List/ListItem";
import DetailsMenu from "../components/Popover/DetailsMenu";
import {
  ADMIN_USER_DETAIL_CONTACT_ITEMS,
  ROLE_KEY,
  USER_DETAIL_TAB_OPTIONS,
} from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import { convertMedicalRecords } from "../utils/convertDisplay";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import InformationDialog from "../components/Dialog/InformationDialog";
import useUserDetailTabConditions, {
  setUserDetailTab,
} from "../hooks/commonCondition/useUserDetailTabConditions";
import useMedicalRecordsDbActions from "../hooks/common/useMedicalRecordsDbActions";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";
import FormSet from "../components/FormSet/FormSet";

const MedicalRecord = () => {
  const [searchCriteria, setSearchCriteria] = React.useState("");
  const [medicalRecords, setMedicalRecords] = useStateCustomObj<
    MedicalRecordTableType[]
  >([]);
  const [inputObj, setInputObj] = useStateCustomObj({
    id: "",
    description: "",
  });
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = React.useState(false);

  const [{ userDetailTab }] = useUserDetailTabConditions();

  const {
    error: eventHistoriesError,
    medicalRecordResults,
    handleUpsert,
    handleDelete,
  } = useMedicalRecordsDbActions({ searchCriteria });

  // TODO: 登録権限
  const hasRegisterRole = useCheckHasRole(ROLE_KEY.USER_EVENT_HISTORY_REGISTER);
  const isDisabled = !hasRegisterRole;

  useCheckErrorThrowError([eventHistoriesError]);

  useEffect(() => {
    const convertResults = convertMedicalRecords(medicalRecordResults);
    setMedicalRecords(convertResults);
  }, [medicalRecordResults, setMedicalRecords]);

  // 検索
  const handleChangeSearch = (value: string) => {
    setSearchCriteria(value);
  };

  // キャンセル
  const handleClear = () => {
    setInputObj({
      id: "",
      description: "",
    });
  };
  // 入力
  const handleSelectInput = (value: string, key: string) => {
    setInputObj((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // 登録ボタン
  const handleUpsertButton = () => {
    handleUpsert(inputObj, handleClear);
  };

  // 3点リーダ押下
  const handleEventDetail = (
    label: string,
    medicalRecord: MedicalRecordTableType,
  ) => {
    const selectedItem = USER_DETAIL_TAB_OPTIONS.find(
      (item) => item.label === label,
    );
    if (selectedItem) {
      if (selectedItem.value === "0") {
        // 編集
        setInputObj({
          id: medicalRecord.id,
          description: medicalRecord.description,
        });
      } else if (selectedItem.value === "1") {
        // 削除
        setInputObj((prevState) => ({
          ...prevState,
          id: medicalRecord.id,
        }));
        setIsOpenDeleteConfirm(true);
      }
    }
  };

  // 削除ボタン
  const handleDeleteButton = () => {
    handleDelete(inputObj, handleClear, () => setIsOpenDeleteConfirm(false));
  };

  return (
    <>
      <div className="admin-user__info-detail">
        <div className="util-flex util-px-16 util-flex--grow-one util-overflow-y">
          <LayoutBox direction="column" gap="2x" fullWidth>
            <LayoutBox fullWidth>
              <Tabs
                selectedTab={userDetailTab}
                size="medium"
                tabs={ADMIN_USER_DETAIL_CONTACT_ITEMS}
                bottomBorder
                onClick={setUserDetailTab}
              />
            </LayoutBox>
            <>
              <div className="util-full-width">
                <LayoutBox gap="2x">
                  <Input
                    leftIcon="search"
                    placeholder="検索"
                    width="100%"
                    onChange={handleChangeSearch}
                  />
                </LayoutBox>
              </div>

              <div
                className="admin-user__info-detail-list"
                style={{ width: "100%" }}
              >
                <List>
                  {medicalRecords.map((medicalRecord, i) => (
                    <ListItem
                      key={i}
                      id={String(i)}
                      center={
                        <>
                          <Text size="small" color="neutralLight">
                            {medicalRecord.createdAt}
                          </Text>
                          <LayoutBox direction="column" gap="none">
                            <Text whiteSpace="pre-line">
                              {medicalRecord.description}
                            </Text>
                          </LayoutBox>
                          <Text size="small" color="neutralLight">
                            応対者：{medicalRecord.adminUserName}
                          </Text>
                        </>
                      }
                      right={
                        isDisabled ? (
                          <></>
                        ) : (
                          <DetailsMenu
                            items={USER_DETAIL_TAB_OPTIONS}
                            buttonSize="medium"
                            width={101}
                            onClick={(label) =>
                              handleEventDetail(label, medicalRecord)
                            }
                          />
                        )
                      }
                    />
                  ))}
                </List>
              </div>
            </>
          </LayoutBox>
        </div>
        <div className="admin-user__info-detail--footer">
          <div className="admin-user__info-detail--footer-inner">
            <div className="admin-user__info-detail--footer-text">
              <FormSet label="内容" vertical required="icon">
                <Input
                  multiLine
                  width="100%"
                  value={inputObj.description}
                  onChange={(value) => handleSelectInput(value, "description")}
                  disabled={isDisabled}
                />
              </FormSet>
            </div>
          </div>
          <div className="admin-user__info-detail--footer-button">
            <LayoutBox align="center" justify="center">
              <Button
                size="large"
                color="neutral"
                type="sub"
                onClick={handleClear}
                disabled={isDisabled}
              >
                キャンセル
              </Button>
              <Button
                size="large"
                onClick={handleUpsertButton}
                disabled={isDisabled || !inputObj.description}
              >
                登録
              </Button>
            </LayoutBox>
          </div>
        </div>
      </div>
      {isOpenDeleteConfirm && (
        <InformationDialog
          alertLevel="error"
          open={isOpenDeleteConfirm}
          closeDialog={() => setIsOpenDeleteConfirm(false)}
          title="削除してもよろしいですか？"
          footer={
            <LayoutBox>
              <Button color="danger" size="medium" onClick={handleDeleteButton}>
                削除
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={() => setIsOpenDeleteConfirm(false)}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          この操作は取り消せません。
        </InformationDialog>
      )}
    </>
  );
};

export default MedicalRecord;
