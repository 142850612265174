import React from "react";
import LegendItem from "./LegendItem";

export type LegendProps = {
  vertical?: boolean;
  height?: string;
  column?: number;
  items?: {
    no: "1" | "2" | "3" | "4" | "5" | "6" | "7";
    label?: string;
  }[];
};

const Legend = ({
  height,
  vertical = false,
  column = 1,
  items = [],
}: LegendProps) => {
  const legendClass = React.useMemo(() => {
    const verticalClass = vertical ? ["legend--vertical"] : [];

    return ["legend", ...verticalClass].join(" ");
  }, [vertical]);

  return (
    <div className={legendClass} style={{ height }}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <LegendItem {...item} column={column} isVertical={vertical} />
        </React.Fragment>
      ))}
    </div>
  );
};

Legend.defaultProps = {
  vertical: false,
  height: undefined,
  column: 1,
  items: [],
};

export default Legend;
