import React from "react";
import { Navigate } from "react-router-dom";

import { ADMIN } from "../constants/pagePaths";
import { useRealmApp } from "../contexts/RealmApp";

// ログイン不要画面
const PublicElement: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const realmAppContext = useRealmApp();
  const isLogin = Boolean(realmAppContext.currentUser?.profile?.emailVerified);

  return isLogin ? <Navigate to={ADMIN.NLB001} /> : element;
};

export default PublicElement;
