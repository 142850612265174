import { useNavigate } from "react-router-dom";

import Button from "../components/Button/Button";
import Header from "../components/Header/Header";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Link from "../components/Link/Link";
import { COMMON, HEADER_LINK } from "../constants/pagePaths";
import Logo from "../components/Logo/Logo";

// 未設定パスの画面コンポーネント
const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const clickTop = () => navigate(COMMON.LOGIN_PROCESSING);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <Header
            leftControl={
              <Link
                url={HEADER_LINK}
                external={false}
                linkClassName="header__left-logo"
              >
                <Logo size="small" type="white-horizontal" />
              </Link>
            }
          />
        </div>
        <div className="util-mb-48" />
        <LayoutBox fullHeight fullWidth justify="center">
          <Sheet
            type="border"
            maxWidth="640px"
            minHeight="247px"
            className="util-flex--align-center util-flex--justify-center"
          >
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              gap="3x"
              fullWidth
            >
              <LayoutBox
                direction="column"
                justify="center"
                align="center"
                gap="1x"
                fullWidth
              >
                <Icon icon="error" size="large" color="#A21B11" />
                <Text size="xl" bold>
                  ページが見つかりません。
                </Text>
              </LayoutBox>
              <Button
                size="large"
                iconPosition="left"
                width="230px"
                onClick={clickTop}
              >
                TOPへ
              </Button>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </div>
    </>
  );
};

export default NotFound;
