import { useState } from "react";

import useOneNotificationFetch from "../../common/useOneNotificationFetch";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  NotificationStatusID,
  ROLE_KEY,
} from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import { ADMIN } from "../../../constants/pagePaths";
import { useNavigateCustom } from "../../base/usePageTransitionCustom";
import {
  closeActionLoading,
  openDeleteActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import useId from "../../common/useId";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

const useNlg003DbActions = () => {
  const navigate = useNavigateCustom();
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [id] = useId();
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const mongoDbNotifications = getMongoDb(
    currentUser,
    Collection.NOTIFICATIONS,
  );

  // お知らせ取得
  const { fetchResult, fetchError } = useOneNotificationFetch();

  // 配信停止トグルボタン動作
  const handleDeliveryToggle = (isDeliveryStopped: boolean) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.NOTICE_DETAIL_EDIT, currentUser);

        const status = isDeliveryStopped
          ? NotificationStatusID.UNPUBLISHED
          : NotificationStatusID.NOW_OPEN;
        const updateData = { status };
        await mongoDbNotifications.updateOne({ _id: id }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // お知らせ削除
  const handleNotificationDelete = () => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.NOTICE_DETAIL_EDIT, currentUser);

        const notificationsCollection = getMongoDb(
          currentUser,
          Collection.NOTIFICATIONS,
        );
        await notificationsCollection.updateOne(
          { _id: id },
          {
            $set: {
              status: NotificationStatusID.DELETED,
              last_updated_at: new Date(),
            },
          },
        );

        // 一覧画面遷移
        navigate(ADMIN.NLG001);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error || fetchError,
    fetchResult,
    handleDeliveryToggle,
    handleNotificationDelete,
  };
};

export default useNlg003DbActions;
