import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNli021Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNli021Dialog = () => {
  stateReactiveVar(true);
};

export const closeNli021Dialog = () => {
  stateReactiveVar(false);
};

export default useNli021Dialog;
