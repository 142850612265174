import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  nouKnowId: string;
} & PatientNlc012StateType;

const INIT_TEST_DATA: PatientNlc012StateType = {
  date: "",
  status: 0,
  concentrationScore: "",
  concentrationScoreValue: 0,
  memoryScore: "",
  memoryScoreValue: 0,
  comment: "",
  pdfUrl: "",
};

// MRI結果画面のidと入力
const stateReactiveVar = createReactiveVar<ConditionsType>({
  nouKnowId: "",
  ...INIT_TEST_DATA,
});
const useNlc012Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setNouknowResultId = (nouKnowId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...INIT_TEST_DATA,
    nouKnowId,
  });
};

export const setNlc12Data = (data: PatientNlc012StateType) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...data,
  });
};

export const setNouknowComment = (comment: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    comment,
  });
};

export default useNlc012Conditions;
