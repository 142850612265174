import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useNlc014Conditions from "./useNlc014Conditions";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeNlc014CancelDialog,
  closeNlc014SendDialog,
} from "./useNlc014Dialog";

// MRIのテスト結果を取得
const useNlc014DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);

  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [{ selectMriId }] = useNlc014Conditions();

  // 結果送信
  const sendResult = () => {
    void (async () => {
      try {
        closeNlc014SendDialog();
        openUpdateActionLoading();

        // ユーザ報告API
        await currentUser?.functions["booking/userReport"](selectMriId);

        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約取消
  const cancelReservation = () => {
    void (async () => {
      try {
        closeNlc014CancelDialog();
        openUpdateActionLoading();

        // 予約取消API
        await currentUser?.functions["booking/cancelBooking"](selectMriId);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, sendResult, cancelReservation };
};

export default useNlc014DbActions;
