import { useEffect, useState } from "react";
import { Collection } from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNli005, getMongoDb } from "../../../utils/query";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import { setTotal } from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";

const useNli005DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [fetchResult, setFetchResult] = useStateCustomObj<Nli005Type>({
    _id: "",
    user_id: "",
    full_name: "",
  });
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const id = currentUser?.customData._id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 検索条件
        const searchConditions = {
          _id: id,
        };

        const mongoDb = getMongoDb(currentUser, Collection.USERS);
        const aggregate = getAggregateNli005(searchConditions);

        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as Nli005Type[];

        setFetchResult(aggregateResult[0]);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, setFetchResult, id]);

  const handleUpdateUser = (_id: string, userName: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.USERS);
        // 更新データ
        const updateData = {
          full_name: userName,
        };

        await mongoDb.updateOne({ _id }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    fetchError,
    actionError: error,
    handleUpdateUser,
  };
};

export default useNli005DbActions;
