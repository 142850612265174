import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";
import { setInitOrganizationInput } from "../../input/useOrganizationInput";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNli020Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNli020Dialog = () => {
  stateReactiveVar(true);
};

export const closeNli020Dialog = () => {
  setInitOrganizationInput();
  stateReactiveVar(false);
};

export default useNli020Dialog;
