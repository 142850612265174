import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";
import { setBrainCheckId } from "./useNlc031Conditions";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNlc031Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc031Dialog = (id: string) => {
  setBrainCheckId(id);
  stateReactiveVar(true);
};

export const closeNlc031Dialog = () => {
  stateReactiveVar(false);
};

export default useNlc031Dialog;
