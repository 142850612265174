import React from "react";
import Button from "../Button/Button";
import MenuList from "../MenuList/MenuList";
import Popover from "../Popover/Popover";

export type TableColumnProps = {
  id?: string;
  textAlign?: "left" | "right" | "center" | "justify";
  textSize?: "medium" | "small";
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  canSort?: boolean;
  sortedDirection?: "asc" | "desc" | string;
  canFilter?: boolean;
  filterItems?: {
    id: string;
    label: string;
    value: string;
    checked?: boolean;
  }[];
  selectedFilterValues?: string[];
  stickyLeft?: string;
  stickyRight?: string;
  onClickSort?: (sortDirection: "asc" | "desc" | "", id: string) => void;
  onCheckFilter?: (value: string) => void;
  children?: React.ReactNode;
  rowSpan?: number;
  colSpan?: number;
  padding?: string;
  className?: string;
};

const TableColumn = ({
  id = "",
  textAlign = "left",
  textSize = "medium",
  width = "auto",
  minWidth,
  maxWidth,
  height,
  canSort = false,
  sortedDirection,
  canFilter = false,
  filterItems = [],
  selectedFilterValues = [],
  stickyLeft,
  stickyRight,
  onClickSort,
  onCheckFilter,
  children,
  rowSpan,
  colSpan,
  padding,
  className,
}: TableColumnProps) => {
  const _onClickSort = React.useCallback(() => {
    const sortDirection =
      sortedDirection === "asc"
        ? "desc"
        : sortedDirection === "desc"
        ? "asc"
        : "desc";

    if (onClickSort) {
      onClickSort(sortDirection, id);
    }
  }, [onClickSort, id, sortedDirection]);

  const _onCheckFilter = React.useCallback(
    (filterValue: string) => {
      if (onCheckFilter) {
        onCheckFilter(filterValue);
      }
    },
    [onCheckFilter],
  );

  const tableCellClass = React.useMemo(() => {
    const sizeClass = [`table__cell--${textSize}`];
    const stickyClass = stickyLeft
      ? ["table__cell--sticky"]
      : stickyRight
      ? ["table__cell--sticky table__cell--sticky-right"]
      : [];

    return ["table__cell", ...sizeClass, ...stickyClass].join(" ");
  }, [stickyLeft, stickyRight, textSize]);

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const filterActivatorRef = React.useRef(null);
  const onPopoverToggleOpen = React.useCallback((open: boolean) => {
    setIsFilterOpen(open);
  }, []);

  const sortButtonActiveClass = React.useMemo(() => {
    const activeClass = sortedDirection ? [] : [`table__sort-btn--inactive`];

    return ["table__sort-btn", ...activeClass].join(" ");
  }, [sortedDirection]);

  const sortButtonIcon = React.useMemo(() => {
    if (sortedDirection === "asc") {
      return "keyboard_arrow_up";
    }
    if (sortedDirection === "desc") {
      return "keyboard_arrow_down";
    }

    return "unfold_more";
  }, [sortedDirection]);

  const onClickFilterButton = React.useCallback(
    () => setIsFilterOpen(true),
    [],
  );

  return (
    <th
      className={`${tableCellClass} ${className ? ` ${className}` : ""}`}
      style={{
        textAlign,
        minWidth,
        width,
        maxWidth,
        height,
        left: stickyLeft,
        right: stickyRight,
        padding,
      }}
      colSpan={colSpan}
      rowSpan={rowSpan}
      id={`table-column-${id}`}
    >
      <div className="table__cell-inner">
        <div
          className="table__cell-inner-text"
          style={{
            textAlign,
            width: canSort ? "auto" : "100%",
          }}
        >
          {children}
        </div>
        {canSort && (
          <Button
            className={sortButtonActiveClass}
            icon={sortButtonIcon}
            type="sub"
            color="neutral"
            size="small"
            onClick={_onClickSort}
          />
        )}
        {canFilter && (
          <span className="table__filter-btn-container">
            <span ref={filterActivatorRef}>
              <Button
                className="table__filter-btn"
                icon="sort"
                type="sub"
                color="neutral"
                size="small"
                onClick={onClickFilterButton}
              />
            </span>

            <Popover
              open={isFilterOpen}
              activatorRef={filterActivatorRef}
              width={120}
              toggleOpen={onPopoverToggleOpen}
            >
              <div className="table__filter-container">
                <MenuList
                  items={filterItems}
                  checkbox
                  onCheck={_onCheckFilter}
                  selectedValues={selectedFilterValues}
                />
              </div>
            </Popover>
          </span>
        )}
      </div>
    </th>
  );
};

TableColumn.defaultProps = {
  id: "",
  textAlign: "left",
  textSize: "medium",
  width: "auto",
  minWidth: "",
  maxWidth: "",
  height: "",
  canSort: false,
  sortedDirection: "",
  canFilter: false,
  filterItems: () => [],
  stickyLeft: "",
  stickyRight: "",
  selectedFilterValues: [],
  onClickSort: undefined,
  onCheckFilter: undefined,
  children: undefined,
  rowSpan: 1,
  colSpan: 1,
  className: "",
  padding: "",
};

export default TableColumn;
