import { OptionConfigsStatusID } from "../../constants/common";
import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import generateUniqueId from "../../utils/generateUniqueId";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = ValidationResultType<string> & {
  key: string;
  status: number;
  isDbData: boolean;
};

const INIT_STATE: DefaultValidationType[] = [];

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType[]>(INIT_STATE);
const useNli015Input = (): ReactiveVarHooks<DefaultValidationType[]> =>
  useReactiveVarHooks(stateReactiveVar);

// 新規オプション追加
export const addNli015Value = () => {
  const currentState = stateReactiveVar();
  const newState = [
    ...currentState,
    {
      value: "",
      key: generateUniqueId(),
      status: OptionConfigsStatusID.VISIBLE,
      isDbData: false,
      ...INIT_VALIDATION_RESULT,
    },
  ];
  stateReactiveVar(newState);
};

// 削除処理
export const deleteNli015Value = (targetKey: DefaultValidationType["key"]) => {
  const currentState = stateReactiveVar();
  const newState = currentState
    .map((item) => {
      if (item.key === targetKey) {
        // 新規追加したオプションを削除するときは、完全に削除
        if (!item.isDbData) {
          return null;
        }

        // DBのオプションを削除するときは、削除のステータスに変更
        return {
          ...item,
          status: OptionConfigsStatusID.HIDDEN,
        };
      }

      return item;
    })
    .filter((item) => item) as DefaultValidationType[];
  stateReactiveVar(newState);
};

// オプション入力値をバリデーションチェックしながらセット
export const setNli015Value = (
  value: DefaultValidationType["value"],
  targetKey: DefaultValidationType["key"],
) => {
  const currentState = stateReactiveVar();
  const newState = currentState.map((item) => {
    if (item.key === targetKey) {
      return {
        ...item,
        value,
        ...commonValidation(value, VALIDATION_TYPE.OPTION_NAME_REQUIRED),
      };
    }

    return item;
  });
  stateReactiveVar(newState);
};

// 値をすべて更新
export const setNli015Values = (options: OptionConfigType[]) => {
  // DBの値を表示用に変換
  const newState: DefaultValidationType[] = options.map((option) => ({
    value: option.option_title,
    key: option._id,
    status: OptionConfigsStatusID.VISIBLE,
    isDbData: true,
    ...INIT_VALIDATION_RESULT,
  }));
  stateReactiveVar(newState);
};

// 初期化
export const setInitUserDetailInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkNli015InputError = (): boolean => {
  const currentState = stateReactiveVar();

  // 削除されていない値のみチェック
  const activeState = currentState.filter(
    ({ status }) => status === OptionConfigsStatusID.VISIBLE,
  );
  // 必須チェック
  const isEmpty = activeState.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = activeState.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useNli015Input;
