import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getMongoDb } from "../../../utils/query";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import {
  checkMasterRole,
  useCheckHasRole,
} from "../../common/useMasterRoleDbActions";

const useNli001DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [masterRoles, setMasterRoles] = useState<MasterRolesStateType[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.MASTER_AUTHORITY_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.MASTER_ROLES);

        const result = await mongoDb.find({}, { sort: { role_sort_no: 1 } });

        setMasterRoles(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    // 権限があるときのみデータ取得
    if (hasBrowsingRole) void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, hasBrowsingRole]);

  // 権限更新
  const handleUpdate = (
    submittedRoles: MasterRolesStateType[],
    closeEdit: () => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_AUTHORITY_EDIT, currentUser);

        const mongoDb = getMongoDb(currentUser, Collection.MASTER_ROLES);

        const updatePromise = submittedRoles.map((role) =>
          mongoDb.updateOne(
            { _id: role._id },
            {
              $set: {
                has_admin: role.has_admin,
                has_doctor: role.has_doctor,
                has_user: role.has_user,
                updated_at: new Date(),
              },
            },
          ),
        );

        await Promise.all(updatePromise).then(() => {
          setForceUpdate({
            forceUpdateCount: forceUpdate.forceUpdateCount + 1,
          });
          closeEdit();
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { error, masterRoles, handleUpdate };
};

export default useNli001DbActions;
