import { useState } from "react";
import {
  UserStatusID,
  Collection,
  UserTypeID,
  ROLE_KEY,
} from "../../../constants/common";

import { useRealmApp } from "../../../contexts/RealmApp";

import { getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openCreateActionLoading,
  openDeleteActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { setExistingEmailError } from "../../input/useUserDetailInput";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";
import useCheckUsersEmail from "../../common/useCheckUsersEmail";
import useGenerateSequenceId from "../../common/useGenerateSequenceId";

const useNli004DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const mongoDb = getMongoDb(currentUser, Collection.USERS);
  const { checkExistingEmail, error: existingEmailError } =
    useCheckUsersEmail();
  const { generateId } = useGenerateSequenceId();

  // ユーザ登録
  const handleRegisterUser = (
    userName: string,
    email: string,
    role: string,
    password: string,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      openCreateActionLoading();
      let isRegistered = false;
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_ADD, currentUser);

        // メール重複チェック
        if (await checkExistingEmail(email)) {
          setExistingEmailError();

          return;
        }

        // userIdの最新番号取得
        const generateUserNo = await generateId(
          { field: "admin_user_id", value: "admin_user_no" },
          { field: "admin_user_no", incrementValue: 1 },
        );

        // 登録データ
        const insertData = {
          _id: email,
          full_name: userName,
          user_id: generateUserNo.toString(),
          email,
          admin_role: Number(role),
          status: UserStatusID.UNREGISTERED,
          user_type: UserTypeID.ADMIN,
          created_at: new Date(),
        };
        await mongoDb.insertOne(insertData);
        isRegistered = true;

        // ファンクション
        await realmAppContext.app.emailPasswordAuth.registerUser({
          email,
          password,
        });

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        // ユーザ登録を行った後にエラーが発生した場合は登録を削除
        if (isRegistered) await mongoDb.deleteOne({ _id: email });
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // ユーザ更新
  const handleUpdateUser = (
    _id: string,
    userName: string,
    role: string,
    status: string,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, currentUser);

        // 更新データ
        const updateData = {
          full_name: userName,
          admin_role: Number(role),
          status: Number(status),
        };

        await mongoDb.updateOne({ _id }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };
  // ユーザ削除
  const handleDeleteUser = (id: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, currentUser);

        // ファンクション
        await realmAppContext.app.currentUser?.functions["auth/deleteUser"](id);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    existingEmailError,
    handleRegisterUser,
    handleUpdateUser,
    handleDeleteUser,
  };
};

export default useNli004DbActions;
