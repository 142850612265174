import { useCallback, useEffect, useState } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Input from "../components/Input/Input";
import Tag from "../components/Tag/Tag";
import Switch from "../components/Switch/Switch";
import FormSet from "../components/FormSet/FormSet";
import ImageUpload from "../components/ImageUpload/ImageUpload";
import useNli008Dialog, {
  closeNli008Dialog,
} from "../hooks/pages/NLI008/useNli008Dialog";
import useProductMgmtId from "../hooks/common/useProductMgmtId";
import useNli008DbActions from "../hooks/pages/NLI008/useNli008DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useItemInput, {
  checkItemInputError,
  // deleteItemTags,
  setItemCode,
  setItemDescription,
  setItemInput,
  setItemIsShow,
  setItemName,
  setItemTags,
} from "../hooks/input/useItemInput";
import { ItemsSkusStatusID } from "../constants/common";
import useImageUpload from "../hooks/component/ImageUpload/useImageUpload";
import Suggestion from "../components/Suggestion/Suggestion";
import DialogCustom from "../components/Dialog/DialogCustom";
import generateUniqueId from "../utils/generateUniqueId";

const NLI008 = () => {
  const [isOpen] = useNli008Dialog();
  const [, setUploadImages] = useImageUpload();
  const [{ code, name, description, tags: selectTags, isShow }] =
    useItemInput();
  const [{ itemId }] = useProductMgmtId();
  const {
    fetchResult,
    fetchError,
    databaseTags,
    fetchItemTagsError,
    actionError,
    insertItem,
    updateItem,
  } = useNli008DbActions();
  const [isOpenTagInput, setIsOpenTagInput] = useState(false);
  const [suggestionValue, setSuggestionValue] = useState("");
  const [isTagExists, setIsTagExists] = useState(false);

  // 選択中のタグを入力候補から削除
  const selectTagIds = selectTags.map(({ id }) => id);
  const tagSuggestionItems = databaseTags
    .filter(({ id }) => !selectTagIds.includes(id))
    .sort();

  // エラー処理
  useCheckErrorThrowError([fetchError, actionError, fetchItemTagsError]);

  useEffect(() => {
    // 編集ボタンを押下した場合の初期表示
    if (fetchResult) {
      setUploadImages(fetchResult.images);
      setItemInput({
        code: fetchResult.item_code,
        name: fetchResult.item_name,
        description: fetchResult.item_description,
        tags: fetchResult.item_tags_info.map((item) => ({
          id: item._id,
          label: item.tag_name,
        })),
        isShow: fetchResult.status === ItemsSkusStatusID.VISIBLE,
      });
    }
  }, [fetchResult, setUploadImages]);

  const closeDialog = () => {
    closeNli008Dialog();
  };

  const handleSuggestionChange = useCallback((value: string) => {
    setSuggestionValue(value);
    setIsTagExists(false);
  }, []);
  const handleTagClose = (tagId: string) =>
    setItemTags(selectTags.filter(({ id }) => id !== tagId));

  const onClickAddTag = useCallback(
    (addTag: string) => {
      if (addTag) {
        const isSelectTag = selectTags.some(({ label }) => label === addTag);
        if (isSelectTag) {
          // すでに追加済みのタグを追加した場合
          setIsTagExists(true);
        } else {
          setSuggestionValue("");
          setIsTagExists(false);
          const databaseTag = databaseTags.find((tag) => tag.label === addTag);
          const newId = databaseTag ? databaseTag.id : generateUniqueId();
          const newTag = { id: newId, label: addTag };
          setItemTags([...selectTags, newTag]);
        }
      }
    },

    [databaseTags, selectTags],
  );

  const onCancelInputTag = () => {
    setIsOpenTagInput(false);
    setSuggestionValue("");
    setIsTagExists(false);
  };

  const clickRegister = itemId ? updateItem : insertItem;

  return (
    <DialogCustom
      title={itemId ? "商品を編集" : "商品新規追加"}
      height="791px"
      size="large"
      open={isOpen}
      closeDialog={closeDialog}
      footerRight={
        <LayoutBox>
          <Button
            width="108px"
            size="large"
            type="sub"
            color="neutral"
            onClick={closeDialog}
          >
            キャンセル
          </Button>
          <Button
            width="64px"
            size="large"
            onClick={clickRegister}
            disabled={checkItemInputError()}
          >
            {itemId ? "保存" : "登録"}
          </Button>
        </LayoutBox>
      }
    >
      <LayoutBox direction="column" fullWidth gap="3x">
        <FormSet
          label="コード"
          vertical
          errorTextList={[code.validationMessage]}
        >
          <Input
            size="default"
            value={code.value}
            placeholder="コード"
            width="100%"
            onChange={setItemCode}
            error={code.isError}
          />
        </FormSet>
        <FormSet
          label="商品名"
          vertical
          errorTextList={[name.validationMessage]}
        >
          <Input
            size="default"
            value={name.value}
            placeholder="商品名"
            width="100%"
            onChange={setItemName}
            error={name.isError}
          />
        </FormSet>
        <FormSet
          label="商品説明"
          vertical
          errorTextList={[description.validationMessage]}
        >
          <Input
            size="default"
            value={description.value}
            placeholder="商品説明"
            width="100%"
            multiLine
            onChange={setItemDescription}
            error={description.isError}
          />
        </FormSet>

        <FormSet label="商品画像" vertical>
          <LayoutBox fullWidth flexShrink={4}>
            <LayoutBox direction="column" fullWidth>
              <LayoutBox wrap minWidth="168px" fullWidth>
                <ImageUpload />
              </LayoutBox>
            </LayoutBox>
          </LayoutBox>
        </FormSet>

        <FormSet label="タグ" vertical>
          <LayoutBox direction="column" fullWidth>
            <LayoutBox fullWidth wrap>
              {selectTags.map((tag) => (
                <Tag
                  key={tag.id}
                  type="input"
                  label={tag.label}
                  onClose={() => handleTagClose(tag.id)}
                />
              ))}
            </LayoutBox>
            {!isOpenTagInput && (
              <Button
                size="small"
                icon="add"
                width="89px"
                iconPosition="left"
                iconType="filled"
                type="secondary"
                onClick={() => {
                  setIsOpenTagInput(true);
                }}
              >
                タグを追加
              </Button>
            )}
            {isOpenTagInput && (
              <LayoutBox gap="1x" align="center">
                <Suggestion
                  placeholder="Input Tag"
                  onChange={handleSuggestionChange}
                  value={suggestionValue}
                  items={tagSuggestionItems}
                  width="228px"
                  size="small"
                  status={isTagExists ? "error" : "default"}
                />
                <Button
                  width="50px"
                  type="secondary"
                  onClick={() => onClickAddTag(suggestionValue)}
                >
                  保存
                </Button>
                <Button
                  width="88px"
                  type="sub"
                  color="neutral"
                  onClick={onCancelInputTag}
                >
                  キャンセル
                </Button>
              </LayoutBox>
            )}
          </LayoutBox>
        </FormSet>

        <FormSet label="商品表示" vertical>
          <Switch checked={isShow} onChange={setItemIsShow}>
            表示
          </Switch>
        </FormSet>
      </LayoutBox>
    </DialogCustom>
  );
};
export default NLI008;
