import { useState, useEffect } from "react";

import {
  Collection,
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
} from "../../../constants/common";
import { getMongoDb, getAggregateNli017 } from "../../../utils/query";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import useStateCustomObj from "../../base/useStateCustomObj";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import { useRealmApp } from "../../../contexts/RealmApp";
import useForceUpdate from "../../common/useForceUpdate";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { setId } from "../../common/useId";
import useOneHospitalFetch from "../../common/useOneHospitalFetch";

type Mie004FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLI017;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLI017;
};

// NLI017と同じ
const useMie004DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Mie004FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // 医療機関取得
  setId(currentUser ? (currentUser.customData.hospital_id as string) : "");
  const { fetchResult: fetchHospitalResult, fetchError: fetchHospitalError } =
    useOneHospitalFetch();

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchHospitalUserResult, setFetchHospitalUserResult] =
    useStateCustomObj<hospitalUserNli017StateType[]>([]);
  const [fetchHospitalUserError, setFetchHospitalUserError] =
    useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const { hospitalUserId, doctorName, email } = submittedSearchCriteria;

  const hospitalId = fetchHospitalResult?.hospital_id || "";

  // 検索のチェック状態をquery用に整形
  // const memoStatusArr = useMemoStatusArr(
  //   SEARCH_INFO.NLI017,
  //   submittedSearchCriteria.role,
  // );

  // sortを取得 未選択の場合は最終更新日の降順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "created_at", sortDirection = "desc" } = currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.USERS);
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNli017(
          hospitalId,
          hospitalUserId,
          doctorName,
          email,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<hospitalUserNli017StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchHospitalUserResult(result);
      } catch (err) {
        setTotal(0);
        setFetchHospitalUserError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };
    if (hospitalId) void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    doctorName,
    email,
    hospitalUserId,
    setFetchHospitalUserResult,
    hospitalId,
    forceUpdate.forceUpdateCount,
  ]);

  return {
    fetchHospitalError,
    fetchHospitalResult,
    fetchHospitalUserResult,
    fetchHospitalUserError,
  };
};

export default useMie004DbActions;
