import { useEffect, useState } from "react";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateOptions, getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import useNli015Dialog, {
  closeNli015Dialog,
  openNli015Dialog,
} from "./useNli015Dialog";
import useProductMgmtId from "../../common/useProductMgmtId";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useNli015Input from "../../input/useNli015Input";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

// NLI015画面のデータ操作処理
const useNli015DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [{ itemId }] = useProductMgmtId();
  const [isOpen] = useNli015Dialog();
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [fetchResult, setFetchResult] = useState<OptionConfigType[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [optionArr] = useNli015Input();

  // 編集時の取得処理;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const optionConfigsCollection = getMongoDb(
          currentUser,
          Collection.OPTION_CONFIGS,
        );
        const aggregate = getAggregateOptions(itemId);
        // mongoDBのデータ
        const result = (await optionConfigsCollection.aggregate(
          aggregate,
        )) as OptionConfigType[];

        setFetchResult(result);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    // itemIdがあり、編集ダイアログを開いている時のみデータ取得
    if (itemId && isOpen) void fetchData();
  }, [currentUser, forceUpdate, setFetchResult, itemId, isOpen]);

  // オプションを保存
  const saveOptions = () => {
    void (async () => {
      closeNli015Dialog();
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_PRODUCT_EDIT, currentUser);

        const saveData = optionArr.map(({ key, value, status }) => ({
          _id: key,
          item_id: itemId,
          option_title: value,
          status,
        }));

        await currentUser?.functions.upsertOptionConfigsApi(saveData);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err, openNli015Dialog));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { fetchResult, fetchError, actionError, saveOptions };
};

export default useNli015DbActions;
