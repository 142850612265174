import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Tag from "../components/Tag/Tag";
import Link from "../components/Link/Link";
import Image from "../components/Image/Image";
import Video from "../components/Video/Video";
import Switch from "../components/Switch/Switch";
import Divider from "../components/Divider/Divider";
import Toast from "../components/Toast/Toast";
import Tooltip from "../components/Tooltip/Tooltip";
import {
  MASTER_MANAGEMENT_OPTIONS,
  ROLE_KEY,
  YOSE_STATUS,
} from "../constants/common";
import {
  formatDate,
  getYoseLabelInfo,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import useNLE002DbActions from "../hooks/pages/NLE002/useNLE002DbActions";
import InformationDialog from "../components/Dialog/InformationDialog";
import useId from "../hooks/common/useId";
import NLE003 from "./NLE003";
import useNLE003DbActions, {
  yoseInsertData,
} from "../hooks/pages/NLE003/useNLE003DbActions";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLE002 = () => {
  // 既存コード
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const toastActivatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // 権限チェック
  const hasEditRole = useCheckHasRole(ROLE_KEY.YOSE_DETAIL_EDIT);

  const navigate = useNavigate();
  const [id] = useId(); // 寄席IDを取得
  const { getYoseDetailData, updateDeliveryStatus, deleteYose } =
    useNLE002DbActions({
      id,
    });
  const { insertData } = useNLE003DbActions();
  useCheckErrorThrowError([mainTabError]);

  const [isOpenDeleteConfirm, toggleIsOpenDeleteConfirm] = useReducer(
    (v) => !v,
    false,
  );
  const [isOpenYoseDialog, toggleIsYoseDialog] = useReducer((v) => !v, false);
  const [yoseDetailData, setYoseDetailData] = useState<
    yoseDetailData | undefined
  >(undefined);

  const yoseDocument = yoseDetailData?.document;
  const yoseUrl = `https://player.vimeo.com/video/${
    yoseDocument?.vimeo_id ?? ""
  }`;
  const statusLabel = getYoseLabelInfo(
    yoseDocument?.status || YOSE_STATUS.preDelivery,
  );

  /**
   *  一覧へ戻る押下時に発火します。
   */
  const handleGoBack = useNavigateWithBack(ADMIN.NLE001);

  /**
   *  寄席のデータを取得してstateを更新します。
   */
  const updateYoseData = useCallback(() => {
    void (async () => {
      const data = await getYoseDetailData();
      setYoseDetailData(data);
    })();
  }, [getYoseDetailData]);

  /**
   *  削除ボタン押下時に発火します。
   */
  const onDeleteBtnClicked = () => {
    void (async () => {
      await deleteYose();
      toggleIsOpenDeleteConfirm();
      navigate(ADMIN.NLE001);
    })();
  };

  /**
   *  動画編集モーダルにおける登録ボタン押下時のイベント
   */
  const onInsertBtnClicked = (data: yoseInsertData) => {
    void (async () => {
      await insertData({ ...data, id });
      toggleIsYoseDialog();
      updateYoseData();
    })();
  };

  /**
   *  配信停止トグルボタン押下時のイベント
   */
  const onStopDeliveryBtnClicked = () => {
    void (async () => {
      await updateDeliveryStatus(!yoseDocument?.is_suspend);
      updateYoseData();
    })();
  };

  useEffect(() => {
    updateYoseData();
  }, [updateYoseData]);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={4}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Button
              type="sub"
              size="large"
              icon="arrow_back"
              onClick={handleGoBack}
            >
              一覧へ戻る
            </Button>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox justify="between" gap="2x" direction="row" fullWidth>
                <LayoutBox direction="column" gap="2x" fullWidth>
                  <FormSet
                    label="動画"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {yoseDocument?.vimeo_id && (
                      <Video
                        url={`https://player.vimeo.com/video/${yoseDocument?.vimeo_id}`}
                      />
                    )}
                  </FormSet>
                  <FormSet
                    label="ステータス"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    <Tag
                      state={statusLabel.btnState}
                      label={statusLabel.text}
                      showIcon={false}
                    />
                  </FormSet>
                  <FormSet
                    label="動画タイトル"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {yoseDocument?.yose_title}
                  </FormSet>
                  <FormSet
                    label="動画URL"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    <Link url={yoseUrl}>{yoseUrl}</Link>
                    <div className="util-ml-8">
                      <Tooltip content="URLをコピー">
                        <div
                          ref={toastActivatorRef}
                          onClick={() => setIsToastOpen(true)}
                        >
                          <Button
                            icon="content_copy"
                            shape="circle"
                            size="small"
                            type="sub"
                            borderLess
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </FormSet>
                  <FormSet
                    label="詳細説明"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {yoseDocument?.yose_description}
                  </FormSet>
                  <Divider margin="0" />
                  <FormSet
                    label="出演者名"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {yoseDocument?.cast}
                  </FormSet>
                  <FormSet
                    label="出演者プロフィール
画像"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    <Image
                      type="avatar"
                      url={yoseDetailData?.sign_url ?? undefined}
                    />
                  </FormSet>
                  <FormSet
                    label="略歴"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {yoseDocument?.cast_note}
                  </FormSet>
                  <Divider margin="0" />
                  <FormSet
                    label="配信日"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {`${formatDate(
                      yoseDocument?.streaming_date,
                    )} ～ ${formatDate(yoseDocument?.streaming_end_date)}`}
                  </FormSet>
                  <FormSet
                    label="新着期間"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {formatDate(yoseDocument?.new_period)}
                  </FormSet>
                </LayoutBox>
                <LayoutBox justify="end" flexShrink={0}>
                  <Switch
                    checked={yoseDocument?.is_suspend}
                    onChange={onStopDeliveryBtnClicked}
                    disabled={!hasEditRole}
                  >
                    配信停止
                  </Switch>
                  <Divider vertical height="32px" width="1px" />
                  <Button
                    icon="delete"
                    type="secondary"
                    color="danger"
                    width="70px"
                    onClick={() => toggleIsOpenDeleteConfirm()}
                    disabled={!hasEditRole}
                  >
                    削除
                  </Button>
                  <Button
                    icon="edit"
                    type="secondary"
                    color="main"
                    width="70px"
                    onClick={toggleIsYoseDialog}
                    disabled={!hasEditRole}
                  >
                    編集
                  </Button>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
            <LayoutBox align="center" fullWidth>
              <Toast onClose={() => setIsToastOpen(false)} open={isToastOpen}>
                URLをクリップボードにコピーしました
              </Toast>
            </LayoutBox>
          </LayoutBox>
        </div>
      </div>
      {isOpenDeleteConfirm && (
        <InformationDialog
          alertLevel="error"
          open={isOpenDeleteConfirm}
          closeDialog={() => toggleIsOpenDeleteConfirm()}
          title="削除してもよろしいですか？"
          footer={
            <LayoutBox>
              <Button color="danger" size="medium" onClick={onDeleteBtnClicked}>
                削除
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={() => toggleIsOpenDeleteConfirm()}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          この操作は取り消せません。
        </InformationDialog>
      )}
      {isOpenYoseDialog && (
        <NLE003
          yoseData={yoseDetailData}
          onCloseBtnClicked={toggleIsYoseDialog}
          onInsertBtnClicked={onInsertBtnClicked}
        />
      )}
    </>
  );
};

export default NLE002;
