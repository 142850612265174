import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  campaignCode: ValidationResultType<string>;
  campaignName: ValidationResultType<string>;
  discountRate: ValidationResultType<string>;
  discountPrice: ValidationResultType<string>;
  term: ValidationResultType<string>;
  limit: ValidationResultType<string>;
};

// バリデーションの不要な型定義
type DefaultType = {
  paymentType: string;
  paymentSpan: string;
  selectedDate: Date | null;
  campaignCodeError: string;
  afterCampaignType: string;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  campaignCode: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  campaignName: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  paymentType: "",
  paymentSpan: "",
  discountRate: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  discountPrice: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  term: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  selectedDate: null,
  limit: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  campaignCodeError: "",
  afterCampaignType: "",
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useNli034Input = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setCampaignDetailValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setCampaignCode = (
  value: DefaultValidationType["campaignCode"]["value"],
) => {
  _setCampaignDetailValidation(
    "campaignCode",
    value,
    VALIDATION_TYPE.CAMPAIGN_CODE_REQUIRED,
  );
};
export const setCampaignName = (
  value: DefaultValidationType["campaignName"]["value"],
) => {
  _setCampaignDetailValidation(
    "campaignName",
    value,
    VALIDATION_TYPE.CAMPAIGN_NAME_REQUIRED,
  );
};
export const setAfterCampaignType = (
  value: DefaultType["afterCampaignType"],
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    afterCampaignType: value,
  });
};
export const setDiscountRate = (
  value: DefaultValidationType["discountRate"]["value"],
) => {
  _setCampaignDetailValidation(
    "discountRate",
    value,
    VALIDATION_TYPE.DISCOUNT_RATE,
  );
};
export const setDiscountPrice = (
  value: DefaultValidationType["discountPrice"]["value"],
) => {
  _setCampaignDetailValidation(
    "discountPrice",
    value,
    VALIDATION_TYPE.DISCOUNT_AMOUNT,
  );
};
export const setTerm = (value: DefaultValidationType["term"]["value"]) => {
  _setCampaignDetailValidation(
    "term",
    value,
    VALIDATION_TYPE.CAMPAIGN_TERM_REQUIRED,
  );
};
export const setSelectedDate = (value: DefaultType["selectedDate"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    selectedDate: value,
  });
};
export const setLimit = (value: DefaultValidationType["limit"]["value"]) => {
  _setCampaignDetailValidation(
    "limit",
    value,
    VALIDATION_TYPE.CAMPAIGN_LIMIT_REQUIRED,
  );
};
export const setPaymentType = (value: DefaultType["paymentType"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    paymentType: value,
  });
};
export const setPaymentSpan = (value: DefaultType["paymentSpan"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    paymentSpan: value,
  });
};

export const setCampaignCodeError = (
  value: DefaultType["campaignCodeError"],
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    campaignCodeError: value,
  });
};

// 値をすべて更新
export const setCampaignDetailInput = ({
  campaignCode,
  campaignName,
  paymentType,
  discountRate,
  discountPrice,
  term,
  selectedDate,
  limit,
}: {
  campaignCode: DefaultValidationType["campaignCode"]["value"];
  campaignName: DefaultValidationType["campaignName"]["value"];
  paymentType: DefaultType["paymentType"];
  discountRate: DefaultValidationType["discountRate"]["value"];
  discountPrice: DefaultValidationType["discountPrice"]["value"];
  term: DefaultValidationType["term"]["value"];
  selectedDate: DefaultType["selectedDate"];
  limit: DefaultValidationType["limit"]["value"];
}) => {
  setCampaignCode(campaignCode);
  setCampaignName(campaignName);
  setPaymentType(paymentType);
  setDiscountRate(discountRate);
  setDiscountPrice(discountPrice);
  setTerm(term);
  setSelectedDate(selectedDate);
  setLimit(limit);
};

// 初期化
export const setInitCampaignDetailInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkCampaignInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    campaignCode,
    campaignName,
    term,
    selectedDate,
    limit,
    afterCampaignType,
    paymentSpan,
    discountPrice,
  } = currentInfos;

  // 必須チェック
  const isEmpty =
    campaignCode.value === "" ||
    campaignName.value === "" ||
    paymentSpan === "" ||
    term.value === "" ||
    selectedDate === null ||
    limit.value === "" ||
    afterCampaignType === "" ||
    discountPrice.value === "";

  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError =
    campaignCode.isError ||
    campaignName.isError ||
    term.isError ||
    limit.isError ||
    discountPrice.isError;

  if (isValidationError) return true;

  return false;
};

export default useNli034Input;
