import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useMie011Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openMie011Dialog = () => {
  stateReactiveVar(true);
};

export const closeMie011Dialog = () => {
  stateReactiveVar(false);
};

export default useMie011Dialog;
