import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNlc031CancelDialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc031CancelDialog = () => {
  stateReactiveVar(true);
};

export const closeNlc031CancelDialog = () => {
  stateReactiveVar(false);
};

export default useNlc031CancelDialog;
