import React, { useEffect } from "react";

import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Divider from "../components/Divider/Divider";
import DialogCustom from "../components/Dialog/DialogCustom";
import DatePicker from "../components/DatePicker/DatePicker";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import TimePicker from "../components/TimePicker/TimePicker";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { convertNlg004 } from "../utils/convertDisplay";
import useNlg004DbActions from "../hooks/pages/NLG004/useNlg004DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { combineDateAndTime } from "../utils/utils";
import useNotificationInput, {
  checkNotificationInputError,
  setNotificationDetail,
  setNotificationInput,
  setNotificationTitle,
  setSelectedDate,
  setTime,
} from "../hooks/input/useNotificationInput";

type NLG004Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const initialNotification: Nlg004TableType = {
  id: "",
  title: "",
  detail: "",
  imageUrl: "",
  status: 0,
  deliveriedDate: new Date(),
  deliveriedTime: new Date(),
  lastUpdatedAt: "",
  changer: "",
};

const NLG004: React.FC<NLG004Props> = ({ isOpen, setIsOpen }) => {
  const [uploadFiles, setUploadFiles] = React.useState<FileType[]>([]);

  const { fetchResult, fetchError, actionError, handleUpdateNotification } =
    useNlg004DbActions();
  const [notification, setNotification] =
    useStateCustomObj<Nlg004TableType>(initialNotification);
  const [{ notificationTitle, notificationDetail, selectedDate, time }] =
    useNotificationInput();

  useCheckErrorThrowError([actionError, fetchError]);

  useEffect(() => {
    const convertResult = convertNlg004(fetchResult);
    setNotification(convertResult);
    setNotificationInput({
      notificationTitle: convertResult.title,
      notificationDetail: convertResult.detail,
      selectedDate: convertResult.deliveriedDate,
      time: convertResult.deliveriedTime,
    });
    if (convertResult.imageUrl) {
      setUploadFiles([
        {
          url: convertResult.imageUrl,
          name: convertResult.id,
          percentage: 100,
        },
      ]);
    }
  }, [fetchResult, setNotification]);

  // タイトル変更
  const handleChangeTitle = (value: string) => {
    setNotificationTitle(value);
  };

  // 詳細変更
  const handleChangeDetail = (value: string) => {
    setNotificationDetail(value);
  };

  // 閉じる、キャンセルボタン
  const handleCloseButton = React.useCallback(
    () => setIsOpen(false),
    [setIsOpen],
  );

  // 日付変更
  const handleChangeDate = React.useCallback((date: Date) => {
    setSelectedDate(date);
  }, []);

  // 時間変更
  const handleChangeTime = React.useCallback((date: Date) => {
    setTime(date);
  }, []);

  // 登録ボタン押下
  const handleRegister = () => {
    const date = combineDateAndTime(selectedDate, time);
    handleUpdateNotification(
      notification.id,
      notificationTitle.value,
      notificationDetail.value,
      date,
      notification.status,
      uploadFiles,
    );
    setIsOpen(false);
  };

  return (
    <DialogCustom
      title="編集"
      subTitle={`最終更新日時:${notification.lastUpdatedAt}｜更新者：${notification.changer}`}
      open={isOpen}
      size="large"
      height="860px"
      closeDialog={handleCloseButton}
      footerRight={
        <LayoutBox>
          <Button
            type="sub"
            color="neutral"
            size="large"
            onClick={handleCloseButton}
          >
            キャンセル
          </Button>
          <Button
            size="large"
            onClick={handleRegister}
            disabled={checkNotificationInputError()}
          >
            登録
          </Button>
        </LayoutBox>
      }
    >
      <LayoutBox gap="2x" direction="column" fullWidth>
        <FormSet
          label="お知らせタイトル"
          labelWidth="90px"
          minWidth="259px"
          vertical
          errorTextList={[notificationTitle.validationMessage]}
          required="icon"
        >
          <Input
            placeholder="お知らせタイトル"
            width="100%"
            value={notificationTitle.value}
            onChange={handleChangeTitle}
          />
        </FormSet>
        <FormSet label="画像" labelWidth="90px" minWidth="259px" vertical>
          <FileUpload
            type="single"
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
          />
        </FormSet>
        <FormSet
          label="お知らせ詳細"
          labelWidth="90px"
          minWidth="259px"
          vertical
          errorTextList={[notificationDetail.validationMessage]}
          required="icon"
        >
          <Input
            placeholder="お知らせ詳細"
            width="100%"
            multiLineHeight="216px"
            multiLine
            value={notificationDetail.value}
            onChange={handleChangeDetail}
          />
        </FormSet>
        <Divider />
        <FormSet label="配信日時" labelWidth="90px" minWidth="259px" vertical>
          <LayoutBox gap="2x">
            <div>
              <DatePicker
                selectedDate={selectedDate}
                onChangeDate={handleChangeDate}
              />
            </div>
            <LayoutBox align="center">
              <TimePicker
                value={time}
                onChange={handleChangeTime}
                showButton={false}
              />
            </LayoutBox>
          </LayoutBox>
        </FormSet>
      </LayoutBox>
    </DialogCustom>
  );
};
export default NLG004;
