import { useEffect, useState } from "react";
import { useRealmApp } from "../contexts/RealmApp";
import { getAggregateNlc023Orders, getMongoDb } from "../utils/query";
import { Collection, OrderStatusID, ROLE_KEY } from "../constants/common";
import { addLoadCount, decrementLoadCount } from "./base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../contexts/CustomErrorBoundary";
import useId from "./common/useId";
import { useCheckHasRole } from "./common/useMasterRoleDbActions";

// 特定IDのEC注文履歴を取得
const useOrderFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [ordersInfo, setOrdersInfo] = useState<ordersNlf001StateType[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const [id] = useId();

  const hasRegisterRole = useCheckHasRole(ROLE_KEY.USER_ORDER_HISTORY_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        // 特定IDのorders情報を取得
        const collection = getMongoDb(currentUser, Collection.ORDERS);
        const conditions = {
          patient_id: id,
          status: {
            $nin: [
              OrderStatusID.CART_KEPT,
              OrderStatusID.ORDER_PENDING_PAYMENT_OR_AUTH,
              OrderStatusID.CANCELLED,
            ],
          },
        };
        const aggregate = getAggregateNlc023Orders(conditions);
        const result = (await collection.aggregate(
          aggregate,
        )) as ordersNlf001StateType[];

        setOrdersInfo(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    // 権限があるときのみデータ取得
    if (hasRegisterRole) void fetchData();
  }, [currentUser, hasRegisterRole, id]);

  return { error, ordersInfo };
};

export default useOrderFetch;
