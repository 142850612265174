/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactNode, useEffect } from "react";
import * as Realm from "realm-web";

import { redirectToInvalidFunctionPage } from "./CustomErrorBoundary";

const RealmAppContext = React.createContext<RealmAppContextInterface>(
  {} as RealmAppContextInterface,
);

export const useRealmApp = () => {
  const appContext = React.useContext(RealmAppContext);
  if (!appContext) {
    return redirectToInvalidFunctionPage();
  }

  return appContext;
};

export const RealmAppProvider = ({
  appId,
  children,
}: {
  appId: string;
  children: ReactNode;
}) => {
  const [app, setApp] = React.useState<Realm.App>(new Realm.App(appId));

  const firstRun = React.useRef<boolean>(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;

      return;
    }
    setApp(new Realm.App(appId));
  }, [appId]);
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);

  const logIn = React.useCallback(
    async (credentials: Realm.Credentials) => {
      await app.logIn(credentials);
      setCurrentUser(app.currentUser);
    },
    [app],
  );
  const logOut = React.useCallback(async () => {
    await app.currentUser?.logOut();
    setCurrentUser(app.currentUser);
  }, [app, setCurrentUser]);

  const memoizedRealmAppContext = React.useMemo(() => {
    const realmAppContext = {
      app,
      currentUser,
      logIn,
      logOut,
    };

    return realmAppContext;
  }, [app, currentUser, logIn, logOut]);

  return (
    <RealmAppContext.Provider value={memoizedRealmAppContext}>
      {children}
    </RealmAppContext.Provider>
  );
};
