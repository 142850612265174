import React from "react";

export type DividerProps = {
  width?: string;
  height?: string;
  margin?: string;
  vertical?: boolean;
  darkColor?: boolean;
};

const Divider = ({
  width = "",
  height = "",
  margin = "8px",
  vertical = false,
  darkColor = true,
}: DividerProps) => {
  const componentClass = React.useMemo(() => {
    const verticalClass = vertical ? ["divider--vertical"] : [];
    const colorClass = darkColor ? ["divider--darkColor"] : [];

    return ["divider", ...verticalClass, ...colorClass].join(" ");
  }, [darkColor, vertical]);

  const dividerStyle = React.useMemo(
    () => ({
      width: !vertical && width ? width : undefined,
      height: vertical && height ? height : undefined,
      margin: vertical ? `0 ${margin}` : `${margin} 0`,
    }),
    [height, margin, vertical, width],
  );

  return <hr className={componentClass} style={dividerStyle} />;
};

Divider.defaultProps = {
  width: "",
  height: "",
  margin: "8px",
  vertical: false,
  darkColor: true,
};

export default Divider;
