// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleException = (e: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (["InvalidPassword"].includes(e.errorCode as string)) {
    return "IDまたはパスワードが正しくありません";
  }

  return "認証プロセス内で予期せぬエラーが発生しました。";
};

export default handleException;
