/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/require-default-props */
import React from "react";
import Button from "../components/Button/Button";
import Calender from "../components/Calendar/Calendar";
import ContentFooter from "../components/ContentFooter/ContentFooter";
import Dropdown from "../components/Dropdown/Dropdown";
import FormSet from "../components/FormSet/FormSet";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import MenuList from "../components/MenuList/MenuList";
import Popover from "../components/Popover/Popover";
import Sheet from "../components/Sheet/Sheet";
import Tabs from "../components/Tab/Tabs";
import TimeTable from "../components/Table/TimeTable";
import Text from "../components/Text/Text";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import TableCell from "../components/Table/TableCell";
import useMie001Conditions, {
  setBulkChecked,
  setItemChecked,
  setSelectDailyItem,
} from "../hooks/pages/MIE001/useMie001Conditions";
import { setDailyScheduleItem } from "../hooks/pages/MIE011/useMie011Conditions";

const TAB_ITEMS = [
  { title: "予約受付", counter: 9 },
  { title: "スケジュール" },
  { title: "患者一覧" },
];

const TABLE_TAB_ITEMS = [
  { title: "枠管理-MRI" },
  { title: "枠管理-FDG PET" },
  { title: "スタッフ" },
];

const MASTER_MANAGEMENT_OPTIONS = [
  {
    id: "1",
    label: "権限管理",
    value: "1",
  },
  {
    id: "2",
    label: "ユーザー管理",
    value: "2",
  },
  {
    id: "3",
    label: "商品管理",
    value: "3",
  },
  {
    id: "4",
    label: "医療機関",
    value: "4",
  },
];

const TOGGLE_ITEMS = [
  {
    id: "1",
    label: "デフォルト",
  },
  {
    id: "2",
    label: "日別",
  },
];

const OPTION_DROPDOWN = [
  { label: "-", value: "-" },
  { label: "◯ ", value: "◯ " },
  { label: "×", value: "×" },
];

const DEFAULT_ITEM_CHECKED = {
  0: {
    "09:00": true,
    "09:15": true,
    "09:30": true,
    "09:45": true,
    "10:00": true,
    "10:15": true,
    "10:30": true,
    "10:45": true,
    "11:00": true,
    "11:15": true,
    "11:30": true,
    "11:45": true,
    "12:00": true,
    "12:15": true,
    "12:30": true,
    "12:45": true,
    "13:00": true,
    "13:15": true,
    "13:30": true,
    "13:45": true,
    "14:00": true,
    "14:15": true,
    "14:30": true,
    "14:45": true,
    "15:00": true,
    "15:15": true,
    "15:30": true,
    "15:45": true,
    "16:00": true,
    "16:15": true,
    "16:30": true,
    "16:45": true,
    "17:00": true,
    "17:15": true,
    "17:30": true,
    "17:45": true,
  },
};

export type TableRowMIE010Props = {
  title?: string;
  defaultCellText?: string;
  keyActive?: number[];
};

const MIE010 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("1");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [cancelCount] = React.useState(0);
  const [frameToggleType] = React.useState("1");

  const [{ itemChecked, selectDailyItem, bulkChecked }] = useMie001Conditions();

  const getWeekStartAndEnd = (inputDate: Date) => {
    let date = new Date(inputDate.getTime());

    const dayOfWeek = date.getDay();
    const differenceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

    const startOfWeek = new Date(
      date.setDate(date.getDate() + differenceToMonday),
    );

    date = new Date(inputDate.getTime());
    const differenceToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
    const endOfWeek = new Date(
      date.setDate(date.getDate() + differenceToSunday),
    );

    return { startOfWeek, endOfWeek };
  };

  const { startOfWeek, endOfWeek } = getWeekStartAndEnd(selectedDate);

  const getListDaysRender = () => {
    const listDays: Record<number, string> = {
      0: "月",
      1: "火",
      2: "水",
      3: "木",
      4: "金",
      5: "土",
      6: "日",
    };
    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(startOfWeek);
      nextDay.setDate(nextDay.getDate() + i);
      const day = nextDay.getDate();
      listDays[i] = `${day} ${listDays[i]}`;
    }

    return listDays;
  };

  const handleSelectMasterInfo = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs selectedTab="none" tabs={TAB_ITEMS} />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner--with-content-footer">
          <LayoutBox direction="column" gap="2x">
            <div className="util-mt-16 util-mb-16">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <Sheet type="border-blue" padding="16px">
                <LayoutBox gap="2x" direction="column" fullWidth>
                  <LayoutBox justify="between" fullWidth>
                    <Text size="2xl" bold>
                      〇〇〇〇医療機関
                    </Text>
                    <Button
                      type="secondary"
                      icon="edit"
                      size="medium"
                      width="180px"
                    >
                      医療機関情報を編集
                    </Button>
                  </LayoutBox>
                  <LayoutBox direction="column" fullWidth>
                    <LayoutBox fullWidth justify="between" gap="3x">
                      <FormSet
                        label="診療時間"
                        base
                        labelWidth="120px"
                        labelSize="medium"
                        formVertical={false}
                      >
                        9:00-13:00/15:00-18:00（月-金）
                      </FormSet>
                      <FormSet
                        label="定休日"
                        base
                        labelWidth="120px"
                        labelSize="medium"
                        formVertical={false}
                      >
                        土、日
                      </FormSet>
                    </LayoutBox>
                    <FormSet
                      label="電話番号"
                      base
                      labelWidth="120px"
                      labelSize="medium"
                      formVertical={false}
                    >
                      00000000000
                    </FormSet>
                    <FormSet
                      label="住所"
                      base
                      labelWidth="120px"
                      labelSize="medium"
                      formVertical={false}
                    >
                      〒238-0008 神奈川県横須賀市大滝町2-6 ザ・タワー横須賀中央
                      リドレ3階
                    </FormSet>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
              <div className="util-mt-24 util-mb-24">
                <Tabs
                  selectedTab={0}
                  tabs={TABLE_TAB_ITEMS}
                  size="medium"
                  bottomBorder
                />
              </div>
              <LayoutBox direction="column" gap="2x">
                <ToggleButton
                  size="medium"
                  items={TOGGLE_ITEMS}
                  selectedButton="1"
                />
                <LayoutBox fullWidth gap="2x">
                  <Calender
                    bordered={false}
                    calendarStartDay={1}
                    startDate={startOfWeek}
                    endDate={endOfWeek}
                    onChangeDate={(date) => setSelectedDate(date)}
                  />

                  <LayoutBox direction="column" fullWidth>
                    <LayoutBox align="center" justify="end" fullWidth>
                      <FormSet
                        label="一括変更"
                        base
                        labelWidth="120px"
                        labelSize="medium"
                        formVertical={false}
                        fullWidth={false}
                        toolTip={{
                          text: (
                            <div className="util-flex util-flex--column">
                              <Text size="xs" color="white">
                                -：デフォルトの予約枠から変更なし
                              </Text>
                              <Text size="xs" color="white">
                                ◯：枠ありに変更
                              </Text>
                              <Text size="xs" color="white">
                                ×：枠なしに変更
                              </Text>
                            </div>
                          ),
                          width: 200,
                        }}
                      >
                        <Dropdown
                          onChange={setSelectedValue}
                          value={selectedValue}
                          items={[
                            {
                              label: "-（変更なし）",
                              value: "1",
                            },
                            {
                              label: "◯",
                              value: "2",
                            },
                            {
                              label: "×",
                              value: "3",
                            },
                          ]}
                          width="128px"
                        />
                      </FormSet>
                      <Button type="secondary" color="main">
                        適用
                      </Button>
                    </LayoutBox>
                    <LayoutBox gap="2x" fullWidth direction="column">
                      <TimeTable
                        checkbox
                        timeFrame={15}
                        amStart="09:00"
                        amEnd="13:00"
                        pmStart="13:00"
                        pmEnd="18:00"
                        direction="column"
                        defaultItemChecked={DEFAULT_ITEM_CHECKED}
                        listDays={getListDaysRender()}
                        itemChecked={itemChecked}
                        setItemChecked={setItemChecked}
                        selectDailyItem={selectDailyItem}
                        setSelectDailyItem={setSelectDailyItem}
                        frameToggleType={frameToggleType}
                        cancelCount={cancelCount}
                        startOfWeek={new Date()}
                        selectedDate={selectedDate}
                        dailyScheduleItem={[]}
                        setDailyScheduleItem={setDailyScheduleItem}
                        bulkChecked={bulkChecked}
                        setBulkChecked={setBulkChecked}
                        renderTimeSlot={(item) => (
                          <TableCell
                            textAlign="center"
                            padding="8px"
                            background={item.selected ? "#CDE3FF" : undefined}
                            bottomBorder={item?.bottomBorder}
                            topBorder={item?.topBorder}
                          >
                            <LayoutBox align="center" gap="3x">
                              <Text width="20px" textAlign="center">
                                ◯
                              </Text>
                              <div className="util-flex--grow-one">
                                <Dropdown
                                  value="-"
                                  items={OPTION_DROPDOWN}
                                  width="100%"
                                />
                              </div>
                            </LayoutBox>
                          </TableCell>
                        )}
                      />
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
          <div className="util-mb-24" />
        </div>
      </div>
      <ContentFooter
        footerRight={
          <>
            <Button type="sub" color="neutral" size="large" width="108px">
              キャンセル
            </Button>
            <Button color="main" type="primary" size="large" width="64px">
              保存
            </Button>
          </>
        }
      />
    </>
  );
};

export default MIE010;
