import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useMie001Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openMie001Dialog = () => {
  stateReactiveVar(true);
};

export const closeMie001Dialog = () => {
  stateReactiveVar(false);
};

export default useMie001Dialog;
