import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";
import { setInitCampaignDetailInput } from "../../input/useNli034Input";

const stateReactiveVar = createReactiveVar<boolean>(false);

const useNli034Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNli034Dialog = () => {
  stateReactiveVar(true);
};

export const closeNli034Dialog = () => {
  setInitCampaignDetailInput();
  stateReactiveVar(false);
};

export default useNli034Dialog;
