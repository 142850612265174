import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  hospitalName: ValidationResultType<string>;
  tel: ValidationResultType<string>;
  address: ValidationResultType<string>;
  email: ValidationResultType<string>;
  nouknowPrice: ValidationResultType<string>;
  mriPrice: ValidationResultType<string>;
  brainHealthCheckPrice: ValidationResultType<string>;
  petPrice: ValidationResultType<string>;
};
// バリデーションの不要な型定義
type DefaultType = {
  pref: string;
  hasMri: boolean;
  hasPet: boolean;
  hasBrainHealthCheck: boolean;
  hasNouknow: boolean;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  hospitalName: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  tel: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  address: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  email: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nouknowPrice: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  mriPrice: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  brainHealthCheckPrice: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  petPrice: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  pref: "",
  hasMri: false,
  hasPet: false,
  hasBrainHealthCheck: false,
  hasNouknow: false,
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useHospitalInput = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setHospitalName = (
  value: DefaultValidationType["hospitalName"]["value"],
) => {
  _setValidation("hospitalName", value, VALIDATION_TYPE.HOSPITAL_NAME_REQUIRED);
};
export const setTel = (value: DefaultValidationType["tel"]["value"]) => {
  _setValidation("tel", value, VALIDATION_TYPE.TEL_REQUIRED);
};
export const setAddress = (
  value: DefaultValidationType["address"]["value"],
) => {
  _setValidation("address", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};
export const setEmail = (value: DefaultValidationType["email"]["value"]) => {
  _setValidation("email", value, VALIDATION_TYPE.EMAIL_REQUIRED);
};
export const setNouknowPrice = (
  value: DefaultValidationType["nouknowPrice"]["value"],
) => {
  _setValidation("nouknowPrice", value, VALIDATION_TYPE.PRICE_OPTIONAL);
};
export const setMriPrice = (
  value: DefaultValidationType["mriPrice"]["value"],
) => {
  _setValidation("mriPrice", value, VALIDATION_TYPE.PRICE_OPTIONAL);
};
export const setBrainHealthCheckPrice = (
  value: DefaultValidationType["brainHealthCheckPrice"]["value"],
) => {
  _setValidation(
    "brainHealthCheckPrice",
    value,
    VALIDATION_TYPE.PRICE_OPTIONAL,
  );
};
export const setPetPrice = (
  value: DefaultValidationType["petPrice"]["value"],
) => {
  _setValidation("petPrice", value, VALIDATION_TYPE.PRICE_OPTIONAL);
};

export const setPref = (value: DefaultType["pref"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    pref: value,
  });
};
export const setHasMri = (value: DefaultType["hasMri"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    hasMri: value,
  });
};
export const setHasPet = (value: DefaultType["hasPet"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    hasPet: value,
  });
};
export const setHasBrainHealthCheck = (
  value: DefaultType["hasBrainHealthCheck"],
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    hasBrainHealthCheck: value,
  });
};
export const setHasNouknow = (value: DefaultType["hasNouknow"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    hasNouknow: value,
  });
};

// 値をすべて更新
export const setHospitalInput = ({
  hospitalName,
  tel,
  address,
  email,
  pref,
  hasMri,
  hasPet,
  hasBrainHealthCheck,
  hasNouknow,
  nouknowPrice,
  mriPrice,
  brainHealthCheckPrice,
  petPrice,
}: {
  hospitalName: DefaultValidationType["hospitalName"]["value"];
  tel: DefaultValidationType["tel"]["value"];
  address: DefaultValidationType["address"]["value"];
  email: DefaultValidationType["email"]["value"];
  pref: DefaultType["pref"];
  hasMri: DefaultType["hasMri"];
  hasPet: DefaultType["hasPet"];
  hasBrainHealthCheck: DefaultType["hasBrainHealthCheck"];
  hasNouknow: DefaultType["hasNouknow"];
  nouknowPrice: DefaultValidationType["nouknowPrice"]["value"];
  mriPrice: DefaultValidationType["mriPrice"]["value"];
  brainHealthCheckPrice: DefaultValidationType["brainHealthCheckPrice"]["value"];
  petPrice: DefaultValidationType["petPrice"]["value"];
}) => {
  setHospitalName(hospitalName);
  setTel(tel);
  setAddress(address);
  setEmail(email);
  setPref(pref);
  setHasMri(hasMri);
  setHasPet(hasPet);
  setHasBrainHealthCheck(hasBrainHealthCheck);
  setHasNouknow(hasNouknow);
  setNouknowPrice(nouknowPrice);
  setMriPrice(mriPrice);
  setBrainHealthCheckPrice(brainHealthCheckPrice);
  setPetPrice(petPrice);
};

// 初期化
export const setInitHospitalInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkHospitalInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    pref: _pref,
    hasMri: _hasMri,
    hasPet: _hasPet,
    hasBrainHealthCheck: _hasBrainHealthCheck,
    hasNouknow: _hasNouknow,
    mriPrice: _mriPrice,
    petPrice: _petPrice,
    nouknowPrice: _nouknowPrice,
    brainHealthCheckPrice: _brainHealthCheckPrice,
    ...validationInfos
  } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  // 都道府県必須チェック
  if (!_pref) return true;

  return false;
};

export default useHospitalInput;
