import { useEffect } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Dialog from "../components/Dialog/Dialog";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Switch from "../components/Switch/Switch";
import ImageUpload from "../components/ImageUpload/ImageUpload";
import useNli010Dialog, {
  closeNli010Dialog,
} from "../hooks/pages/NLI010/useNli010Dialog";
import useImageUpload from "../hooks/component/ImageUpload/useImageUpload";
import useSkuInput, {
  checkSkuInputError,
  setSkuAmount,
  setSkuCode,
  setSkuInput,
  setSkuIsShow,
  setSkuName,
  setSkuStock,
  setSkuTax,
} from "../hooks/input/useSkuInput";
import useNli010DbActions from "../hooks/pages/NLI010/useNli010DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import useProductMgmtId from "../hooks/common/useProductMgmtId";
import { ItemsSkusStatusID, TAX_RATE } from "../constants/common";
import MoneyInput from "../components/MoneyInput/MoneyInput";
import RadioButton from "../components/RadioButton/RadioButton";

const NLI010 = () => {
  const [isOpen] = useNli010Dialog();
  const [, setUploadImages] = useImageUpload();
  const [{ code, name, amount, stock, tax, isShow }] = useSkuInput();
  const { fetchResult, fetchError, actionError, insertSku, updateSku } =
    useNli010DbActions();

  const [{ skuId }] = useProductMgmtId();

  // エラー処理
  useCheckErrorThrowError([fetchError, actionError]);

  useEffect(() => {
    // 編集ボタンを押下した場合の初期表示
    if (fetchResult) {
      setUploadImages(fetchResult.images);
      setSkuInput({
        code: fetchResult.sku_code,
        name: fetchResult.sku_name,
        amount: fetchResult.amount.toString(),
        stock: fetchResult.stock.toString(),
        options: [],
        tax: fetchResult.consumption_tax_rate,
        isShow: fetchResult.status === ItemsSkusStatusID.VISIBLE,
      });
    }
  }, [fetchResult, setUploadImages]);

  const onSwitch = setSkuIsShow;

  const closeDialog = () => {
    closeNli010Dialog();
  };

  const clickRegister = skuId ? updateSku : insertSku;

  return (
    <Dialog
      open={isOpen}
      closeDialog={closeDialog}
      size="large"
      title={skuId ? "SKUを編集" : "SKU新規追加"}
      height="704px"
      footerRight={
        <>
          <LayoutBox justify="end" fullWidth>
            <Button
              type="sub"
              color="neutral"
              size="large"
              width="108px"
              onClick={closeDialog}
            >
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              width="64px"
              onClick={clickRegister}
              disabled={checkSkuInputError()}
            >
              {skuId ? "保存" : "登録"}
            </Button>
          </LayoutBox>
        </>
      }
    >
      <LayoutBox direction="column" fullWidth gap="3x">
        <FormSet
          label="SKUコード"
          vertical
          errorTextList={[code.validationMessage]}
        >
          <Input
            placeholder="SKUコード"
            value={code.value}
            onChange={setSkuCode}
            width="100%"
            error={code.isError}
          />
        </FormSet>
        <FormSet
          label="SKU名"
          vertical
          errorTextList={[name.validationMessage]}
        >
          <Input
            placeholder="SKU名"
            value={name.value}
            onChange={setSkuName}
            width="100%"
            error={name.isError}
          />
        </FormSet>
        <FormSet label="商品画像" vertical>
          <LayoutBox direction="column" fullWidth>
            <LayoutBox wrap minWidth="168px" fullWidth>
              <ImageUpload />
            </LayoutBox>
          </LayoutBox>
        </FormSet>
        <FormSet
          label="金額"
          vertical
          errorTextList={[amount.validationMessage]}
        >
          <MoneyInput
            width="100%"
            value={amount.value}
            onChange={setSkuAmount}
          />
        </FormSet>
        <FormSet label="消費税率" vertical>
          <LayoutBox gap="2x" fullWidth>
            {TAX_RATE.map(({ rate, label }) => (
              <RadioButton
                checked={tax === rate}
                value={rate}
                onSelect={setSkuTax}
                key={label}
              >
                {label}
              </RadioButton>
            ))}
          </LayoutBox>
        </FormSet>
        <FormSet
          label="在庫"
          vertical
          errorTextList={[stock.validationMessage]}
        >
          <Input
            width="160px"
            value={stock.value}
            onChange={setSkuStock}
            error={stock.isError}
          />
        </FormSet>
        <FormSet label="商品表示" vertical>
          <Switch checked={isShow} onChange={onSwitch}>
            表示
          </Switch>
        </FormSet>
      </LayoutBox>
    </Dialog>
  );
};

export default NLI010;
