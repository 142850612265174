import React from "react";

export type SpinnerProps = {
  size?: "small" | "medium" | "large";
  color?: "main" | "danger" | "neutral" | "white";
  // changeEmp: 全体のロード
  isLoad?: boolean;
  isActionLoad?: boolean;
  actionText?: string;
};

const Spinner = ({
  size = "medium",
  color = "main",
  // changeEmp: 全体のロード
  isLoad = false,
  isActionLoad = false,
  actionText = "",
}: SpinnerProps) => {
  const spinnerClass = React.useMemo(() => {
    const colorClass = [`spinner--${color}`];

    return ["spinner", ...colorClass].join(" ");
  }, [color]);

  // changeEmp: 全体のロード
  const spinnerLoadingClass = React.useMemo(() => {
    if (isLoad || isActionLoad) {
      const loadClass = isLoad || isActionLoad ? "spinner--loading" : "";
      const actionLoadClass = isActionLoad ? "spinner--action-loading" : "";

      return [loadClass, actionLoadClass].join(" ");
    }

    return "";
  }, [isActionLoad, isLoad]);

  const spinnerSize = React.useMemo(() => {
    switch (size) {
      case "small":
        return 16;
      case "medium":
        return 24;
      case "large":
        return 40;
      default:
        return 40;
    }
  }, [size]);

  return (
    <>
      <div className={spinnerLoadingClass}>
        {/* changeEmp: 全体のロード中は背景をマスク */}
        <div className={isActionLoad ? "spinner--container" : ""}>
          <div className={spinnerClass}>
            <svg
              width={spinnerSize}
              height={spinnerSize}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 3C29.3888 3 37 10.6112 37 20C37 29.3888 29.3888 37 20 37C10.6112 37 3 29.3888 3 20C3 17.8879 3.38517 15.8658 4.08911 14"
                stroke="#003D6C"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {isActionLoad && (
            <div className="spinner--action" style={{ fontSize: spinnerSize }}>
              {actionText}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Spinner.defaultProps = {
  color: "main",
  size: "medium",
  // changeEmp: 全体のロード
  isLoad: false,
  isActionLoad: false,
  actionText: "",
};

export default Spinner;
