import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import {
  HospitalReservationStatusID,
  ModalityID,
  MriStatusID,
  PetStatusID,
} from "../../../constants/common";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import { parseDateString } from "../../../utils/utils";

const useMib002DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // 全候補日NG
  const handleUpdateNg = (id: string, note: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 予約取消API
        await currentUser?.functions["booking/cancelBooking"](id, note);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約確定
  const handleUpdateConfirm = (
    id: string,
    note: string,
    fixDateStartStr: string,
    modalityNum: number,
    hospitalInfo: HospitalTimeIntervalType | null,
    setIsOpen: (isOpen: boolean) => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 開始時間
        const fixStartDate = parseDateString(fixDateStartStr);

        // 時間枠
        const interval = hospitalInfo
          ? modalityNum === ModalityID.MRI
            ? hospitalInfo.mri_interval
            : modalityNum === ModalityID.PET
            ? hospitalInfo.pet_interval
            : 0
          : 0;

        // 終了時間
        const fixEndDate = fixStartDate
          ? new Date(
              fixStartDate.getFullYear(),
              fixStartDate.getMonth(),
              fixStartDate.getDate(),
              fixStartDate.getHours() + Math.floor(interval / 60),
              fixStartDate.getMinutes() + (interval % 60),
              fixStartDate.getSeconds(),
            )
          : null;

        // MRI、PET予約確定API
        await currentUser?.functions["booking/saveBooking"](
          id,
          fixStartDate,
          fixEndDate,
          note,
        );

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        setIsOpen(false);
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 予約取り下げ
  const handleUpdateCancel = (
    id: string,
    note: string,
    patientId: string,
    modalityNum: number,
    callbackOnSuccess: () => void,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // PET or MRI ステータス
        const petOrMriStatus =
          modalityNum === ModalityID.MRI
            ? { mri_status: MriStatusID.WITHDRAWN_AFTER_CONFIRMATION }
            : { pet_status: PetStatusID.WITHDRAWN_AFTER_CONFIRMATION };

        // 更新データ
        const updateData = {
          _id: id,
          note,
          status: HospitalReservationStatusID.WITHDRAWAL,
          patient_id: patientId,
          modality: modalityNum,
          ...petOrMriStatus,
        };
        // MRI、PET予約API
        await currentUser?.functions.upsertModalityBookApi(updateData);

        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        callbackOnSuccess();
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateNg,
    handleUpdateConfirm,
    handleUpdateCancel,
  };
};

export default useMib002DbActions;
