import { useEffect, useMemo, useState } from "react";
import { useRealmApp } from "../contexts/RealmApp";

import { Collection, HOSPITAL_MAIN_TAB_ITEMS } from "../constants/common";
import { HOSPITAL } from "../constants/pagePaths";
import useStateCustomObj from "./base/useStateCustomObj";
import { addLoadCount, decrementLoadCount } from "./base/useLoadingPage";
import { checkFetchErr } from "../contexts/CustomErrorBoundary";
import {
  getMongoDb,
  getSearchConditionsHospitalMainTabReservation,
} from "../utils/query";

// 病院メインタブの通知取得処理
const useHospitalMainTabFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  // 病院ID
  const hospitalId: string = currentUser
    ? (currentUser.customData.hospital_id as string)
    : "";

  const mainTabInfoArr = useMemo(
    () => [
      {
        collection: Collection.PATIENT_MODALITY_BOOKS,
        getSearchConditions: getSearchConditionsHospitalMainTabReservation,
        withHospitalId: true,
      },
      {
        collection: "",
        getSearchConditions: null,
        withHospitalId: false,
      },
      {
        collection: "",
        getSearchConditions: null,
        withHospitalId: false,
      },
    ],
    [],
  );

  const [tabItems, setTabItems] = useStateCustomObj<
    { counter: number; title: string; url: HOSPITAL }[]
  >(HOSPITAL_MAIN_TAB_ITEMS);

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mainTabItems = await Promise.all(
          mainTabInfoArr.map(
            async (
              { collection, getSearchConditions, withHospitalId },
              index,
            ) => {
              if (collection && getSearchConditions) {
                // 取得処理が設定されている場合
                const mongoDb = getMongoDb(currentUser, collection);
                const searchConditions = {
                  ...getSearchConditions(),
                  ...(withHospitalId && { hospital_id: hospitalId }),
                };
                const count = await mongoDb.count(searchConditions);

                return {
                  ...HOSPITAL_MAIN_TAB_ITEMS[index],
                  counter: count,
                };
              }

              // 取得処理が設定されていない場合
              return {
                ...HOSPITAL_MAIN_TAB_ITEMS[index],
                counter: 0,
              };
            },
          ),
        );

        setTabItems(mainTabItems);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, mainTabInfoArr, setTabItems, hospitalId]);

  return { error, tabItems };
};

export default useHospitalMainTabFetch;
