import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import Checkbox from "../components/Checkbox/Checkbox";
import FormSet from "../components/FormSet/FormSet";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Icon from "../components/Icon/Icon";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import MenuList from "../components/MenuList/MenuList";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import Sheet from "../components/Sheet/Sheet";
import Tabs from "../components/Tab/Tabs";
import Table from "../components/Table/Table";
import TableCell from "../components/Table/TableCell";
import TableColumn from "../components/Table/TableColumn";
import TableRow from "../components/Table/TableRow";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Switch from "../components/Switch/Switch";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import {
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  SEARCH_INFO,
} from "../constants/common";
import useNlc033Conditions from "../hooks/pages/NLC033/useNlc033Conditions";
import useNlc033Fetch from "../hooks/pages/NLC033/useNlc033Fetch";
import { ADMIN } from "../constants/pagePaths";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { setId } from "../hooks/common/useId";
import { setPage } from "../hooks/component/pagination/usePagination";
import { convertNlc033Table } from "../utils/convertDisplay";
import useNlc033DBActions from "../hooks/pages/NLC033/useNlc033DBActions";
import { setUserDetailTab } from "../hooks/commonCondition/useUserDetailTabConditions";

const NLC033 = () => {
  // 検索条件保持
  const [conditions, setConditions] = useNlc033Conditions();
  // 一覧データ
  const [users, setUsers] = React.useState<Nlc033TableType[]>([]);
  // ソート
  const [sortExamples, setSortExample] = React.useState(conditions.sort);
  // 検索条件入力
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  // 検索ボタンを押下したときの検索条件
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  const { result, error: fetchError } = useNlc033Fetch({
    sortExamples,
    submittedSearchCriteria,
  });
  const { handleUpdateCheckList, error } = useNlc033DBActions();

  // エラー処理
  useCheckErrorThrowError([mainTabError, fetchError, error]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNlc033Table(result);
    setUsers(convertResult);
  }, [result]);

  const handleChangeUserId = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, userId: value }));
  };

  const handleChangeName = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, name: value }));
  };

  const handleChangeCheckItem = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      checkList: {
        ...searchCriteria.checkList,
        [checkedItem]: checked,
      },
    }));
  };

  // タイプチェックボックス変更
  const handleChangeCheckType = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      userType: {
        ...searchCriteria.userType,
        [checkedItem]: checked,
      },
    }));
  };

  // ステータスチェックボックス変更
  const handleChangeCheckStatus = (checked: boolean, checkedItem: string) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      userStatus: {
        ...searchCriteria.userStatus,
        [checkedItem]: checked,
      },
    }));
  };

  // 検索ボタン押下
  const handleSearchUsers = () => {
    // 検索条件を確定
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリアボタン押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLC033);

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        handleUpdateCheckList(userId, checked);

        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);
  };

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToUserDetails = (id: string) => {
    setConditions({
      sort: sortExamples,
      search: submittedSearchCriteria,
    });
    setId(id);
    setUserDetailTab(1);
    navigate(ADMIN.NLC008);
  };

  const onClickSort = (sortDirection: "asc" | "desc" | "", id: string) => {
    const newSortExamples = sortExamples.map((sortExample) => {
      if (sortExample.id === id) {
        return { ...sortExample, id, sortDirection };
      }

      return { ...sortExample, sortDirection: "" };
    });

    setSortExample(newSortExamples);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={8}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <div className="util-full-width">
                  <LayoutBox align="center" gap="3x">
                    <FormSet
                      label="ID"
                      labelWidth="96px"
                      base
                      fullWidth={false}
                      flex="0.4"
                    >
                      <Input
                        value={searchCriteria.userId}
                        placeholder="ID"
                        width="100%"
                        onChange={handleChangeUserId}
                      />
                    </FormSet>
                    <FormSet
                      label="お客様名"
                      labelWidth="96px"
                      base
                      fullWidth={false}
                      flex="0.4"
                    >
                      <Input
                        value={searchCriteria.name}
                        placeholder="お客様名"
                        width="100%"
                        onChange={handleChangeName}
                      />
                    </FormSet>
                    <FormSet
                      label="ステータス"
                      labelWidth="96px"
                      base
                      formVertical={false}
                      fullWidth={false}
                    >
                      <LayoutBox
                        align="center"
                        justify="start"
                        gap="2x"
                        width="128px"
                      >
                        {SEARCH_INFO.NLC033_status.map((type) => (
                          <Checkbox
                            key={type.id}
                            label={type.label}
                            checked={searchCriteria.userStatus[type.flgKey]}
                            onChecked={(checked) =>
                              handleChangeCheckStatus(checked, type.flgKey)
                            }
                          />
                        ))}
                      </LayoutBox>
                    </FormSet>
                    <FormSet
                      label="要注意"
                      labelWidth="96px"
                      base
                      formVertical={false}
                      fullWidth={false}
                    >
                      <LayoutBox
                        align="center"
                        justify="start"
                        gap="2x"
                        width="70px"
                      >
                        <Checkbox
                          label="要注意"
                          checked={searchCriteria.checkList.isAttend}
                          onChecked={(checked) =>
                            handleChangeCheckItem(checked, "isAttend")
                          }
                        />
                      </LayoutBox>
                    </FormSet>
                  </LayoutBox>
                </div>
                <LayoutBox align="center" justify="start" gap="2x">
                  <FormSet
                    label="投稿者"
                    labelWidth="96px"
                    base
                    formVertical={false}
                    fullWidth={false}
                    flex="1"
                  >
                    <LayoutBox align="center" justify="start" gap="2x">
                      {SEARCH_INFO.NLC033_type.map((type) => (
                        <Checkbox
                          key={type.id}
                          label={type.label}
                          checked={searchCriteria.userType[type.flgKey]}
                          onChecked={(checked) =>
                            handleChangeCheckType(checked, type.flgKey)
                          }
                        />
                      ))}
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
              <div className="util-mt-16">
                <LayoutBox align="center" justify="end">
                  <Button
                    type="sub"
                    color="neutral"
                    size="large"
                    onClick={handleResetSearchCriteria}
                  >
                    クリア
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleSearchUsers}
                  >
                    検索
                  </Button>
                </LayoutBox>
              </div>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <Table
                  type="condensed"
                  width="100%"
                  fixedLayout
                  head={
                    <TableRow>
                      <TableColumn
                        width="123px"
                        canSort
                        sortedDirection={sortExamples[0].sortDirection}
                        id="col-1"
                        onClickSort={onClickSort}
                      >
                        ID
                      </TableColumn>
                      <TableColumn
                        width="247px"
                        minWidth="230px"
                        canSort
                        sortedDirection={sortExamples[1].sortDirection}
                        id="col-2"
                        onClickSort={onClickSort}
                      >
                        お客様名
                      </TableColumn>
                      <TableColumn width="77px" id="col-3" />
                      <TableColumn
                        width="128px"
                        minWidth="100px"
                        canSort
                        id="col-4"
                        sortedDirection={sortExamples[2].sortDirection}
                        onClickSort={onClickSort}
                      >
                        ステータス
                      </TableColumn>
                      <TableColumn
                        width="122px"
                        minWidth="122px"
                        id="col-5"
                        canSort
                        sortedDirection={sortExamples[3].sortDirection}
                        onClickSort={onClickSort}
                      >
                        投稿者
                      </TableColumn>
                      <TableColumn
                        width="168px"
                        minWidth="168px"
                        canSort
                        id="col-6"
                        sortedDirection={sortExamples[4].sortDirection}
                        onClickSort={onClickSort}
                      >
                        登録日
                      </TableColumn>
                      <TableColumn
                        canSort
                        id="col-7"
                        sortedDirection={sortExamples[5].sortDirection}
                        onClickSort={onClickSort}
                      >
                        内容
                      </TableColumn>
                      <TableColumn
                        width="103px"
                        canSort
                        sortedDirection={sortExamples[6].sortDirection}
                        id="col-8"
                        onClickSort={onClickSort}
                      >
                        要注意
                      </TableColumn>
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Text size="xs">{user.userId}</Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">{user.name}</Text>
                      </TableCell>
                      <TableCell>
                        <Button
                          type="sub"
                          size="small"
                          onClick={() => navigateToUserDetails(user.patientId)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Tag
                          label={user.isRead ? "既読" : "未読"}
                          showIcon={false}
                          state={user.isRead ? "success" : "error"}
                        />
                      </TableCell>
                      <TableCell>
                        <Text size="xs">
                          {user.commentedUser === "patient"
                            ? "会員"
                            : "サービス管理者"}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs">
                          {user.registerDate.toLocaleString()}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text size="xs" ellipsis width="100%">
                          {user.comment}
                        </Text>
                      </TableCell>

                      <TableCell textAlign="center">
                        <Switch
                          checked={user.checkList}
                          onChange={(checked) =>
                            handleCheckUserId(checked, user.id)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <div className="util-full-width">
                  <LayoutBox justify="end">
                    <Pagination input={false} />
                  </LayoutBox>
                </div>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
    </>
  );
};

export default NLC033;
