import Button from "../components/Button/Button";
import Text from "../components/Text/Text";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNlj002Dialog, {
  closeNlj002Dialog,
} from "../hooks/pages/NLJ002/useNlj002Dialog";

/* 汎用ポップアップ */
const NLJ002 = () => {
  const [{ isOpen, title, message, isError }] = useNlj002Dialog();

  return (
    <InformationDialog
      open={isOpen}
      closeDialog={closeNlj002Dialog}
      title={title}
      footer={
        <Button size="medium" onClick={closeNlj002Dialog}>
          OK
        </Button>
      }
      alertLevel={isError ? "error" : "normal"}
    >
      <Text size="small">{message}</Text>
    </InformationDialog>
  );
};

export default NLJ002;
