import { useEffect } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import Dialog from "../components/Dialog/Dialog";
import useNli015Dialog, {
  closeNli015Dialog,
} from "../hooks/pages/NLI015/useNli015Dialog";
import useNli015Input, {
  addNli015Value,
  checkNli015InputError,
  deleteNli015Value,
  setNli015Value,
  setNli015Values,
} from "../hooks/input/useNli015Input";
import useNli015DbActions from "../hooks/pages/NLI015/useNli015DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { OptionConfigsStatusID } from "../constants/common";

const NLI015 = () => {
  const [isOpen] = useNli015Dialog();
  const [optionArr] = useNli015Input();
  const { fetchResult, fetchError, actionError, saveOptions } =
    useNli015DbActions();

  useEffect(() => {
    if (fetchResult.length > 0) {
      setNli015Values(fetchResult);
    }
  }, [fetchResult]);

  // エラー処理
  useCheckErrorThrowError([fetchError, actionError]);

  return (
    <Dialog
      title="オプション管理"
      open={isOpen}
      size="small"
      closeDialog={closeNli015Dialog}
      footerRight={
        <LayoutBox>
          <Button
            type="sub"
            color="neutral"
            size="large"
            onClick={closeNli015Dialog}
          >
            キャンセル
          </Button>
          <Button
            size="large"
            disabled={checkNli015InputError()}
            onClick={saveOptions}
          >
            保存
          </Button>
        </LayoutBox>
      }
    >
      <LayoutBox gap="2x" direction="column" fullWidth>
        {optionArr
          .filter(({ status }) => status === OptionConfigsStatusID.VISIBLE)
          .map(({ key, value, validationMessage, isError }) => (
            <FormSet
              label=""
              vertical
              key={key}
              errorTextList={[validationMessage]}
            >
              <LayoutBox fullWidth align="center">
                <Input
                  placeholder="オプション"
                  width="100%"
                  value={value}
                  onChange={(value) => setNli015Value(value, key)}
                  error={isError}
                />
                <Button
                  icon="delete"
                  type="secondary"
                  color="danger"
                  size="large"
                  onClick={() => deleteNli015Value(key)}
                />
              </LayoutBox>
            </FormSet>
          ))}
        <Button icon="add" type="secondary" onClick={addNli015Value}>
          オプションを追加
        </Button>
      </LayoutBox>
    </Dialog>
  );
};
export default NLI015;
