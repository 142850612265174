import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";
import { getMongoDb } from "../../utils/query";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

type FilterType = {
  field: string;
  value: string;
};

type UpdateType = {
  field: string; //
  incrementValue: number;
};

type FindOneAndUpdateResult = {
  [key: string]: number;
};

// sequenceテーブルから指定のidを返し、インクリメントする
const useGenerateSequenceId = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const mongoDb = getMongoDb(currentUser, Collection.SEQUENCE);

  const generateId = async (
    filterCondition: FilterType,
    updateCondition: UpdateType,
  ) => {
    try {
      const filter = { [filterCondition.field]: filterCondition.value };
      const update = {
        $inc: { [updateCondition.field]: updateCondition.incrementValue },
      };
      const result = (await mongoDb.findOneAndUpdate(
        filter,
        update,
      )) as FindOneAndUpdateResult;
      const generatedId = result[updateCondition.field];

      return generatedId;
    } catch (err) {
      throw checkFetchErr(err);
    }
  };

  return { generateId };
};

export default useGenerateSequenceId;
