import React from "react";
import Divider from "../Divider/Divider";
import Icon from "../Icon/Icon";
import LayoutBox from "../LayoutBox/LayoutBox";
import Tag from "../Tag/Tag";
import Popover from "../Popover/Popover";
import Avatar from "../Avatar/Avatar";

export type AccountMenuProps = {
  user: {
    id: string;
    position: string;
    name: string;
  };
  onClickProfile: () => void;
  onClickChangePass: () => void;
  onClickLogout: () => void;
  onClickChangeEmail: () => void;
};

const AccountMenu = ({
  user,
  onClickProfile,
  onClickChangePass,
  onClickLogout,
  onClickChangeEmail,
}: AccountMenuProps) => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <>
      <div ref={activatorRef} onClick={() => toggleOpen(!isOpen)}>
        <Avatar />
      </div>
      <Popover
        open={isOpen}
        activatorRef={activatorRef}
        width={221}
        direction="underRight"
        forceDirection
        withShadow
        toggleOpen={toggleOpen}
      >
        <div className="menulist">
          <div className="util-px-16 menulist-item--profile">
            <LayoutBox direction="column">
              <LayoutBox align="center">
                {/* changeEmp */}
                {user.position && (
                  <Tag showIcon={false} label={user.position} />
                )}
                <span className="menulist-item--profile-id">ID: {user.id}</span>
              </LayoutBox>
              <span className="text--bold text--large">{user.name}</span>
            </LayoutBox>
          </div>
          <Divider margin="4px" />
          <div
            className="menulist-item--settings"
            onClick={() => {
              setIsOpen(false);
              onClickProfile();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon icon="person" size="2xs" className="icon--person" />
              プロフィール設定
            </LayoutBox>
          </div>
          <Divider margin="4px" />
          <div
            className="menulist-item--change-email"
            onClick={() => {
              setIsOpen(false);
              onClickChangeEmail();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon icon="mail" size="2xs" className="icon--mail" />
              メールアドレス変更
            </LayoutBox>
          </div>
          <Divider margin="4px" />
          <div
            className="menulist-item--change-pass"
            onClick={() => {
              setIsOpen(false);
              onClickChangePass();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon icon="lock" size="2xs" className="icon--lock" />
              パスワード変更
            </LayoutBox>
          </div>
          <Divider margin="4px" />
          <div
            className="menulist-item--logout"
            onClick={() => {
              setIsOpen(false);
              onClickLogout();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon icon="logout" size="xs" className="icon--logout" />
              ログアウト
            </LayoutBox>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AccountMenu;
