import React, { useEffect, useState } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import Tag from "../components/Tag/Tag";
import {
  downloadCsvNli003,
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectMasterMgmtSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useNli003DbActions from "../hooks/pages/NLI003/useNli003DbActions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import {
  AdminsRoleID,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  ROLE_KEY,
  UserStatusID,
} from "../constants/common";
import usePagination, {
  setPage,
} from "../hooks/component/pagination/usePagination";
import { convertNli003Table } from "../utils/convertDisplay";
import InformationDialog from "../components/Dialog/InformationDialog";
import NLI004 from "./NLI004";
import { setInitUserDetailInput } from "../hooks/input/useUserDetailInput";
import NLI006 from "./NLI006";
import { setInitPasswordInput } from "../hooks/input/usePasswordInput";
import { useRealmApp } from "../contexts/RealmApp";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLI003 = () => {
  const realmAppContext = useRealmApp();
  const [users, setUsers] = useStateCustomObj<Nli003TableType[]>([]);
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLI003,
  );
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLI003,
  );
  const {
    fetchResult,
    fetchError,
    actionError,
    handleUseUserBulkDelete,
    handleUseUserDelete,
  } = useNli003DbActions({
    submittedSearchCriteria,
  });
  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [checkedRows, setCheckedRows] = React.useState<Nli003TableType[]>([]);
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const [isDeleteBulkConfirmOpen, setIsDeleteBulkConfirmOpen] =
    React.useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);
  const [deleteUserId, setDeleteUserId] = React.useState("");
  const [isUserDialogOpen, setIsUserDialogOpen] = React.useState(false);
  const [isBulkEditOpen, setIsBulkEditOpen] = React.useState(false);
  const [userDialogTitle, setUserDialogTitle] = useState("");
  const [selectUser, setSelectUser] = useState<Nli003TableType>();
  const [{ page, perPage }] = usePagination();
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;

  // 権限チェック
  const hasCsvDownloadRole = useCheckHasRole(ROLE_KEY.MASTER_USER_CSV_DOWNLOAD);
  const hasAddRole = useCheckHasRole(ROLE_KEY.MASTER_USER_ADD);
  const hasEditRole = useCheckHasRole(ROLE_KEY.MASTER_USER_EDIT);

  useCheckErrorThrowError([mainTabError, fetchError, actionError]);

  useEffect(() => {
    const convertResult = convertNli003Table(fetchResult);
    setUsers(convertResult);
    // 選択クリア
    setCheckedRows([]);
  }, [fetchResult, setUsers]);

  // 検索ボックス入力
  const handleChangeSearch = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, search: value }));
  };

  // 検索ボタン押下
  const handleSearch = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // 一括削除ダイアログclose
  const handleCloseBulkDeleteConfirm = () => {
    setIsDeleteBulkConfirmOpen(false);
  };

  // 一括削除ダイアログopen
  const handleOpenBulkDeleteConfirm = () => {
    setIsDeleteBulkConfirmOpen(true);
  };

  // 削除ダイアログclose
  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  // 削除ダイアログopen
  const handleDeleteConfirm = (userId: string) => {
    setDeleteUserId(userId);
    setIsDeleteConfirmOpen(true);
  };

  // 一括削除処理
  const handleBulkDelete = () => {
    handleUseUserBulkDelete(checkedRows);
    setIsDeleteBulkConfirmOpen(false);
  };

  // 削除処理
  const handleDelete = () => {
    handleUseUserDelete(deleteUserId);
    setIsDeleteConfirmOpen(false);
  };

  // 一括編集ボタン押下
  const handleBulkEdit = () => {
    setIsBulkEditOpen(true);
  };

  // 編集ボタン押下
  const handleUpdateUser = (user: Nli003TableType) => {
    setUserDialogTitle("更新");
    setSelectUser(user);
    setIsUserDialogOpen(true);
  };

  // 新規登録ボタン押下
  const handleNewRegistration = () => {
    setUserDialogTitle("登録");
    setSelectUser(undefined);
    setInitUserDetailInput();
    setInitPasswordInput();
    setIsUserDialogOpen(true);
  };

  // CSVダウンロードボタン押下
  const handleCSVDownload = () => {
    downloadCsvNli003(users, window.location.pathname);
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, checked };
      }

      return user;
    });

    setUsers(newUsers);

    const visibleUsers = newUsers.slice(startIndex, endIndex);
    const isAllChecked = visibleUsers.every((user) => user.checked);
    setIsCheckAllUserIds(isAllChecked);

    const userToManage = newUsers.find((user) => user.id === userId);

    if (checked && userToManage) {
      setCheckedRows((prevCheckedRows) => [...prevCheckedRows, userToManage]);
    } else {
      setCheckedRows((prevCheckedRows) =>
        prevCheckedRows.filter((row) => row.id !== userId),
      );
    }
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = users.map((user) => ({ ...user, checked }));
    setUsers(newUsers);

    if (checked) {
      const checkedIds = newUsers.slice(startIndex, endIndex);
      setCheckedRows(checkedIds);
    } else {
      setCheckedRows([]);
    }
  };

  const isCheckEachId = React.useMemo(
    () => users.some((user) => user.checked === true),
    [users],
  );

  const isCheckAllId = React.useMemo(
    () => users.every((user) => user.checked === true),
    [users],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  // 一括ボタンの非活性条件
  // 権限がない場合、チェックしていない場合、検証中ユーザをチェックした場合非活性
  const isNoRole = !hasEditRole;
  const isNoCheck = checkedRows.length === 0;
  const hasVerifyingUser = checkedRows.some(
    (row) => row.statusNum === UserStatusID.UNREGISTERED,
  );
  // 削除ボタンはログインユーザを選択したとき非活性
  const hasCurrentUser = checkedRows.some(
    (row) => row.id === realmAppContext.currentUser?.id,
  );
  const isAllEditDisabled = isNoRole || isNoCheck || hasVerifyingUser;
  const isAllDeleteDisabled =
    isNoRole || isNoCheck || hasVerifyingUser || hasCurrentUser;

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <div className="util-py-12">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox align="center" justify="start" fullWidth>
                  <div className="util-mr-16">
                    <ToggleButton
                      size="large"
                      items={MASTER_MANAGEMENT_OPTIONS}
                      selectedButton="2"
                      onClick={handleSelectMasterMgmtSubTab}
                    />
                  </div>
                  <Input
                    value={searchCriteria.search}
                    placeholder="検索"
                    width="360px"
                    onChange={handleChangeSearch}
                  />
                  <div className="util-ml-8">
                    <Button
                      type="primary"
                      size="large"
                      icon="search"
                      width="88px"
                      onClick={handleSearch}
                    >
                      検索
                    </Button>
                  </div>
                </LayoutBox>
                <LayoutBox align="center" justify="between" fullWidth>
                  <LayoutBox align="center" justify="start">
                    <Button
                      type="secondary"
                      size="large"
                      icon="edit"
                      width="120px"
                      onClick={handleBulkEdit}
                      disabled={isAllEditDisabled}
                    >
                      一括編集
                    </Button>
                    <Button
                      color="danger"
                      type="secondary"
                      size="large"
                      icon="delete"
                      width="120px"
                      onClick={handleOpenBulkDeleteConfirm}
                      disabled={isAllDeleteDisabled}
                    >
                      一括削除
                    </Button>
                  </LayoutBox>
                  <LayoutBox align="center" justify="end">
                    {/* CSVアップロード不要のため、コメントアウト
                    <Button
                      type="secondary"
                      size="large"
                      icon="upload"
                      width="182px"
                      onClick={handleUploadCSV}
                    >
                      CSVアップロード
                    </Button> */}
                    <Button
                      type="secondary"
                      size="large"
                      icon="download"
                      width="184px"
                      onClick={handleCSVDownload}
                      disabled={!hasCsvDownloadRole}
                    >
                      CSVダウンロード
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      icon="person_add_alt_1"
                      width="120px"
                      onClick={handleNewRegistration}
                      disabled={!hasAddRole}
                    >
                      新規登録
                    </Button>
                  </LayoutBox>
                </LayoutBox>
                <Table
                  type="condensed"
                  width="100%"
                  roleKey={ROLE_KEY.MASTER_USER_BROWSING}
                  head={
                    <TableRow>
                      <TableColumn width="408px" id="col-1">
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="ID"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn width="408px" id="col-2">
                        ユーザー名
                      </TableColumn>
                      <TableColumn width="408px" id="col-3">
                        権限
                      </TableColumn>
                      <TableColumn width="408px" id="col-4">
                        メールアドレス
                      </TableColumn>
                      <TableColumn width="90px" id="col-5">
                        ステータス
                      </TableColumn>
                      <TableColumn width="57px" id="col-6">
                        編集
                      </TableColumn>
                      <TableColumn width="57px" id="col-7">
                        削除
                      </TableColumn>
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id} isSelected={user.checked}>
                      <TableCell>
                        <Checkbox
                          label={user.userId}
                          checked={user.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, user.id)
                          }
                        />
                      </TableCell>
                      <TableCell>{user.fullName}</TableCell>
                      <TableCell>
                        <Tag
                          label={user.roleStr}
                          showIcon={false}
                          state={
                            user.role === AdminsRoleID.ADMIN
                              ? "information"
                              : user.role === AdminsRoleID.CMC
                              ? "success"
                              : "neutral"
                          }
                        />
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell textAlign="center">
                        <Icon
                          icon={
                            user.statusNum === UserStatusID.ACTIVE
                              ? "check"
                              : "block"
                          }
                          color={
                            user.statusNum === UserStatusID.ACTIVE
                              ? "#81C784"
                              : "#A21B11"
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          type="sub"
                          icon="edit"
                          size="small"
                          disabled={
                            !hasEditRole ||
                            user.statusNum === UserStatusID.UNREGISTERED
                          }
                          onClick={() => handleUpdateUser(user)}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          type="sub"
                          icon="delete"
                          color="danger"
                          size="small"
                          disabled={
                            !hasEditRole ||
                            user.statusNum === UserStatusID.UNREGISTERED ||
                            realmAppContext.app.currentUser?.id === user.id
                          }
                          onClick={() => handleDeleteConfirm(user.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
                {isDeleteBulkConfirmOpen && (
                  <InformationDialog
                    alertLevel="error"
                    open={isDeleteBulkConfirmOpen}
                    closeDialog={handleCloseBulkDeleteConfirm}
                    title="削除してもよろしいですか？"
                    footer={
                      <LayoutBox>
                        <Button
                          color="danger"
                          size="medium"
                          onClick={handleBulkDelete}
                        >
                          削除
                        </Button>
                        <Button
                          color="neutral"
                          type="sub"
                          size="medium"
                          onClick={handleCloseBulkDeleteConfirm}
                        >
                          キャンセル
                        </Button>
                      </LayoutBox>
                    }
                  >
                    この操作は取り消せません。
                  </InformationDialog>
                )}
                {isDeleteConfirmOpen && (
                  <InformationDialog
                    alertLevel="error"
                    open={isDeleteConfirmOpen}
                    closeDialog={handleCloseDeleteConfirm}
                    title="削除してもよろしいですか？"
                    footer={
                      <LayoutBox>
                        <Button
                          color="danger"
                          size="medium"
                          onClick={handleDelete}
                        >
                          削除
                        </Button>
                        <Button
                          color="neutral"
                          type="sub"
                          size="medium"
                          onClick={handleCloseDeleteConfirm}
                        >
                          キャンセル
                        </Button>
                      </LayoutBox>
                    }
                  >
                    この操作は取り消せません。
                  </InformationDialog>
                )}
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isUserDialogOpen && (
        <NLI004
          isOpen={isUserDialogOpen}
          setIsOpen={setIsUserDialogOpen}
          title={userDialogTitle}
          user={selectUser}
          setUser={setSelectUser}
        />
      )}
      {isBulkEditOpen && (
        <NLI006
          isOpen={isBulkEditOpen}
          setIsOpen={setIsBulkEditOpen}
          selectUsers={checkedRows}
        />
      )}
    </>
  );
};

export default NLI003;
