import { useEffect, useState } from "react";
import { Collection } from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc031, getMongoDb } from "../../../utils/query";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import useNlc031Dialog from "./useNlc031Dialog";
import useNlc031Conditions, { setNlc031Data } from "./useNlc031Conditions";
import useForceUpdate from "../../common/useForceUpdate";

const useNlc031DbFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [isOpen] = useNlc031Dialog();
  const [{ brainCheckId }] = useNlc031Conditions();
  const [id] = useId();
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_BRAIN_CHECKS,
        );
        const aggregate = getAggregateNlc031(brainCheckId);
        const result = (await collection.aggregate(
          aggregate,
        )) as PatientNlc031StateType[];

        if (result[0]) setNlc031Data(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (isOpen) void fetchData();
  }, [currentUser, id, brainCheckId, isOpen, forceUpdate]);

  return { fetchError };
};

export default useNlc031DbFetch;
