import { INIT_SEARCH_CRITERIA } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  search: typeof INIT_SEARCH_CRITERIA.NLI033;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  search: INIT_SEARCH_CRITERIA.NLI033,
});

const useNli033Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNli033Conditions;
