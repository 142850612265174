import React, { useEffect } from "react";
import Button from "../components/Button/Button";
import Divider from "../components/Divider/Divider";
import Dropdown from "../components/Dropdown/Dropdown";
import Label from "../components/FormSet/Label";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Icon from "../components/Icon/Icon";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import List from "../components/List/List";
import ListItem from "../components/List/ListItem";
import MenuList from "../components/MenuList/MenuList";
import Popover from "../components/Popover/Popover";
import Sheet from "../components/Sheet/Sheet";
import Tabs from "../components/Tab/Tabs";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import {
  getBrainCheckTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
  useHandleTestResultSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  INSPECT_BRAIN_CHECK_STATUS_OPTIONS,
  MASTER_MANAGEMENT_OPTIONS,
  PatientBrainCheckStatusID,
  PatientBrainCheckStatusLabels,
  PatientTypeID,
  TEST_RESULT_ITEMS,
  USER_EVALUATION,
  UserStatusID,
} from "../constants/common";
import { ADMIN } from "../constants/pagePaths";
import UserDetailHeader from "../features/UserDetailHeader";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import Chat from "../features/Chat";
import EventHistories from "../features/EventHistories";
import MedicalRecord from "../features/MedicalRecord";
import useUserDetailTabConditions from "../hooks/commonCondition/useUserDetailTabConditions";
import useNlc030Conditions, {
  setSelectBrainCheckId,
  setStatus,
} from "../hooks/pages/NLC030/useNlc030Conditions";
import useNlc030DbFetch from "../hooks/pages/NLC030/useNlc030DbFetch";
import Pagination from "../components/Pagination/Pagination";
import useNlc030Dialog, {
  closeNlc030SendDialog,
  openNlc030SendDialog,
} from "../hooks/pages/NLC030/useNlc030Dialog";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNlc031Dialog, {
  openNlc031Dialog,
} from "../hooks/pages/NLC031/useNlc031Dialog";
import NLC031 from "./NLC031";
import useNlc030DbActions from "../hooks/pages/NLC030/useNlc030DbActions";
import RadioButton from "../components/RadioButton/RadioButton";

const NLC030 = () => {
  const [isNlc031Open] = useNlc031Dialog();
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const [{ status }] = useNlc030Conditions();
  const { brainCheckResult, error: brainCheckFetchError } = useNlc030DbFetch();
  const [{ userDetailTab }] = useUserDetailTabConditions();
  const [{ isSendConfirm }] = useNlc030Dialog();
  const { sendResult, actionError } = useNlc030DbActions();

  // ユーザ評価更新
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  // 患者情報取得
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();

  // エラー処理
  useCheckErrorThrowError([
    mainTabError,
    patientFetchError,
    updateEvaluationError,
    userDetailTabError,
    brainCheckFetchError,
    actionError,
  ]);

  const {
    id,
    userId,
    name,
    birth,
    genderStr,
    tel,
    email,
    evaluation,
    address,
    patientTypeNum,
    patientTypeStr,
    statusNum,
    statusStr,
    orgName,
  } = convertPatientInfo(patientInfo);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  // ユーザ評価選択
  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      // ユーザ評価更新
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  // 結果送信
  const clickSend = (id: string) => {
    setSelectBrainCheckId(id);
    openNlc030SendDialog();
  };

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();
  const handleTestResultSubTab = useHandleTestResultSubTab();

  // ボタン表示制御
  const renderRightButton = (status: number, id: string) => {
    switch (status) {
      case PatientBrainCheckStatusID.NOT_TESTED:
        return (
          <Button onClick={() => openNlc031Dialog(id)}>
            レポートアップロード
          </Button>
        );
      case PatientBrainCheckStatusID.COMPLETED:
        return (
          <LayoutBox>
            <Button onClick={() => clickSend(id)}>結果送信</Button>
            <Button type="secondary" onClick={() => openNlc031Dialog(id)}>
              レポート
            </Button>
          </LayoutBox>
        );
      default:
        return (
          <Button type="secondary" onClick={() => openNlc031Dialog(id)}>
            レポート
          </Button>
        );
    }
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={2}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC029}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <LayoutBox align="center">
                          <span className="util-font-neutral--light font-component-chip">
                            {userId}
                          </span>
                          <Tag
                            showIcon={false}
                            label={patientTypeStr}
                            state={
                              patientTypeNum === PatientTypeID.MEMBER
                                ? "information"
                                : patientTypeNum === PatientTypeID.ORG
                                ? "success"
                                : patientTypeNum === PatientTypeID.INVITATION
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <Tag
                            showIcon={false}
                            label={statusStr}
                            state={
                              statusNum === UserStatusID.ACTIVE ||
                              statusNum === UserStatusID.INVITATION
                                ? "success"
                                : statusNum === UserStatusID.CANCELLATION
                                ? "error"
                                : statusNum === UserStatusID.SUSPENDED
                                ? "warning"
                                : "neutral"
                            }
                          />
                        </LayoutBox>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          {patientTypeNum !== PatientTypeID.INVITATION && (
                            <div className="util-half-width">
                              <LayoutBox align="stretch">
                                <Label base size="medium" width="120px">
                                  認知症リスク
                                  <br />
                                  評価
                                </Label>
                                <div className="util-flex util-flex--wrap util-flex--col-gap16">
                                  {USER_EVALUATION.map((item) => (
                                    <RadioButton
                                      key={item.value}
                                      name="userRatingRadioButton"
                                      value={item.value}
                                      checked={selectedValue === item.value}
                                      onSelect={handleChangeEvaluation}
                                    >
                                      {item.label}
                                    </RadioButton>
                                  ))}
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    <Text>{email}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    住所
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {address}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    団体
                                  </Label>
                                  {orgName}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <LayoutBox direction="column" gap="2x" fullWidth>
                    <Tabs
                      selectedTab={2}
                      tabs={userDetailTabItems}
                      size="medium"
                      bottomBorder
                      onClick={handleSelectAdminUserDetailTab}
                    />
                    <LayoutBox gap="2x" direction="column" fullWidth>
                      <ToggleButton
                        size="medium"
                        items={TEST_RESULT_ITEMS}
                        selectedButton="4"
                        onClick={handleTestResultSubTab}
                      />

                      <LayoutBox direction="column" gap="none">
                        <div className="util-mt-3 util-mb-3">
                          <Text>ステータス</Text>
                        </div>
                        <Dropdown
                          value={status.toString()}
                          width="148px"
                          items={INSPECT_BRAIN_CHECK_STATUS_OPTIONS}
                          onChange={setStatus}
                        />
                      </LayoutBox>
                      <div className="admin-user__inspection-results">
                        <List>
                          {brainCheckResult.map((resultInfo) => (
                            <ListItem
                              key={resultInfo._id}
                              id={resultInfo._id}
                              alignCenter
                              center={
                                <>
                                  <LayoutBox gap="1x">
                                    <Text size="small" color="neutralLight">
                                      受検案内日: {resultInfo.examStartDate}
                                    </Text>
                                    <Tag
                                      showIcon={false}
                                      label={
                                        PatientBrainCheckStatusLabels[
                                          resultInfo.status as keyof typeof PatientBrainCheckStatusLabels
                                        ]
                                      }
                                      state={getBrainCheckTagState(
                                        PatientBrainCheckStatusLabels[
                                          resultInfo.status as keyof typeof PatientBrainCheckStatusLabels
                                        ],
                                      )}
                                    />
                                  </LayoutBox>
                                  <Text size="large" bold>
                                    あたまの健康チェック
                                  </Text>
                                </>
                              }
                              right={renderRightButton(
                                resultInfo.status,
                                resultInfo._id,
                              )}
                            />
                          ))}
                        </List>
                        <div className="util-mb-16" />
                        {brainCheckResult.length === 0 && (
                          <div className="util-py-40">
                            <LayoutBox
                              direction="column"
                              align="center"
                              justify="center"
                            >
                              <Icon icon="inbox" />
                              <div className="util-font-neutral--light">
                                表示する結果がありません
                              </div>
                            </LayoutBox>
                          </div>
                        )}
                        <LayoutBox justify="end" fullWidth>
                          <Pagination input={false} />
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          {userDetailTab === 0 && <EventHistories />}
          {userDetailTab === 1 && <Chat />}
          {userDetailTab === 2 && <MedicalRecord />}
        </div>
        {isSendConfirm && (
          <InformationDialog
            alertLevel="normal"
            open={isSendConfirm}
            closeDialog={() => closeNlc030SendDialog()}
            title="結果を送信しますか？"
            footer={
              <LayoutBox>
                <Button color="main" size="medium" onClick={() => sendResult()}>
                  OK
                </Button>
                <Button
                  color="neutral"
                  type="sub"
                  size="medium"
                  onClick={() => closeNlc030SendDialog()}
                >
                  キャンセル
                </Button>
              </LayoutBox>
            }
          >
            <></>
          </InformationDialog>
        )}
      </div>
      {isNlc031Open && <NLC031 />}
    </>
  );
};

export default NLC030;
