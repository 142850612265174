import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

// 各種配送設定ダイアログの開閉状態を管理
const useNli029Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNli029Dialog = () => {
  stateReactiveVar(true);
};

export const closeNli029Dialog = () => {
  stateReactiveVar(false);
};

export default useNli029Dialog;
