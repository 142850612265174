import { useState, useEffect } from "react";

import { getMongoDb, getAggregateNli019 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection, INIT_SEARCH_CRITERIA } from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nli019FetchParams = {
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLI019;
};

const useNli019DbActions = ({ submittedSearchCriteria }: Nli019FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;
  const [fetchResults, setFetchResults] = useStateCustomObj<OrganizationType[]>(
    [],
  );
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();
  const { search } = submittedSearchCriteria;

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const collection = getMongoDb(currentUser, Collection.ORGANIZATIONS);
        const aggregate = getAggregateNli019(search, skip, perPage);
        const aggregateResult = (await collection.aggregate(
          aggregate,
        )) as AggregateResultType<OrganizationType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResults(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    search,
    setFetchResults,
    forceUpdate.forceUpdateCount,
  ]);

  return {
    fetchResults,
    fetchError,
  };
};

export default useNli019DbActions;
