import { useCallback, useState } from "react";
import { getAggregateNLE001, getMongoDb } from "../../../utils/query";
import { Collection, ROLE_KEY, SEARCH_INFO } from "../../../constants/common";
import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import { useCheckHasRole } from "../../common/useMasterRoleDbActions";

export const useNLE001DbActions = () => {
  const realmAppContext = useRealmApp();
  const [actionError, setActionError] = useState<Error | null>(null);
  const { currentUser } = realmAppContext;
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.YOSE_LIST_BROWSING);

  /**
   *  寄席テーブルのデータを取得します。
   * @param {yoseDataTermsType} データの検索条件。
   */
  const getYoseData = useCallback(
    async (terms: yoseDataTermsType) => {
      const data: NLE001YoseList[] = [];

      if (!hasBrowsingRole) return data;

      try {
        openTableLoading();
        const mongoDb = getMongoDb(currentUser, Collection.YOSES);
        const aggregate = getAggregateNLE001({ ...terms, skip, perPage });

        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<NLE001YoseList>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        data.push(...result);
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeTableLoading();
      }

      return data;
    },
    [currentUser, hasBrowsingRole, perPage, skip],
  );

  return {
    getYoseData,
    actionError,
  };
};
