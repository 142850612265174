import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";
import { setPetResultId } from "./useNlc028Conditions";

const stateReactiveVar = createReactiveVar<boolean>(false);

// PET結果ダイアログの開閉状態を管理
const useNlc028Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlc028Dialog = (id: string) => {
  setPetResultId(id);
  stateReactiveVar(true);
};

export const closeNlc028Dialog = () => {
  stateReactiveVar(false);
};

export default useNlc028Dialog;
