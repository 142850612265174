import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type DayTimeSlots = {
  [day: number]: {
    [time: string]: boolean;
  };
};

type ConditionsType = {
  timeItems: DayTimeSlots;
  dailyScheduleItem: ScheduleDailyInfo;
  timeInterval: string;
  timeFrame: 0 | 15 | 30 | 60 | 90 | 120;
  originData: {
    timeItems: DayTimeSlots;
    dailyScheduleItem: ScheduleDailyInfo;
    timeInterval: string;
    timeFrame: 0 | 15 | 30 | 60 | 90 | 120;
    amStartTimeStr: string;
    amEndTimeStr: string;
    pmStartTimeStr: string;
    pmEndTimeStr: string;
  };
  amStartTime: Date;
  amEndTime: Date;
  pmStartTime: Date;
  pmEndTime: Date;
  initAmStartTime: Date;
  initAmEndTime: Date;
  initPmStartTime: Date;
  initPmEndTime: Date;
  itemChecked: Record<number, Record<string, boolean>>;
  selectDailyItem: Record<number, Record<string, string>>;
  changeDailyItem: DailySchedule[];
  bulkChecked: Record<number, Record<string, boolean>>;
  isChangedTemplate: boolean;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  timeItems: [],
  dailyScheduleItem: [],
  timeInterval: "60",
  timeFrame: 60,
  isChangedTemplate: false,
  originData: {
    timeItems: [],
    dailyScheduleItem: [],
    timeInterval: "60",
    timeFrame: 60,
    amStartTimeStr: "09:00",
    amEndTimeStr: "12:00",
    pmStartTimeStr: "13:00",
    pmEndTimeStr: "18:00",
  },
  amStartTime: new Date(0, 0, 0, 9, 0),
  amEndTime: new Date(0, 0, 0, 12, 0),
  pmStartTime: new Date(0, 0, 0, 13, 0),
  pmEndTime: new Date(0, 0, 0, 18, 0),
  initAmStartTime: new Date(0, 0, 0, 9, 0),
  initAmEndTime: new Date(0, 0, 0, 12, 0),
  initPmStartTime: new Date(0, 0, 0, 13, 0),
  initPmEndTime: new Date(0, 0, 0, 18, 0),
  changeDailyItem: [],
  selectDailyItem: {
    0: {
      "09:00": "-",
      "09: 15": "-",
    },
    1: {
      "09:00": "-",
      "09: 15": "-",
    },
    2: {
      "09:00": "-",
      "09: 15": "-",
    },
    3: {
      "09:00": "-",
      "09: 15": "-",
    },
    4: {
      "09:00": "-",
      "09: 15": "-",
    },
    5: {
      "09:00": "-",
      "09: 15": "-",
    },
    6: {
      "09:00": "-",
      "09: 15": "-",
    },
  },
  itemChecked: {
    0: {
      "09:00": false,
      "09: 15": false,
    },
    1: {
      "09:00": false,
      "09: 15": false,
    },
    2: {
      "09:00": false,
      "09: 15": false,
    },
    3: {
      "09:00": false,
      "09: 15": false,
    },
    4: {
      "09:00": false,
      "09: 15": false,
    },
    5: {
      "09:00": false,
      "09: 15": false,
    },
    6: {
      "09:00": false,
      "09: 15": false,
    },
  },
  bulkChecked: {
    0: {
      AM: false,
      PM: false,
    },
    1: {
      AM: false,
      PM: false,
    },
    2: {
      AM: false,
      PM: false,
    },
    3: {
      AM: false,
      PM: false,
    },
    4: {
      AM: false,
      PM: false,
    },
    5: {
      AM: false,
      PM: false,
    },
    6: {
      AM: false,
      PM: false,
    },
  },
});

const useMie011Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

// 枠更新用関数
export const setTimeItems = (value: DayTimeSlots) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, timeItems: value });
};

// 時間単位更新用関数
export const setTimeInterval = (value: string) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, timeInterval: value });
};

// timeFrame更新用関数
export const setTimeFrame = (value: 0 | 15 | 30 | 60 | 90 | 120) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, timeFrame: value });
};

// AM開始時間更新用関数
export const setAmStartTime = (date: Date) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, amStartTime: date });
};

// AM終了時間更新用関数
export const setAmEndTime = (date: Date) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, amEndTime: date });
};

// PM開始時間更新用関数
export const setPmStartTime = (date: Date) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, pmStartTime: date });
};

// PM終了時間更新用関数
export const setPmEndTime = (date: Date) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, pmEndTime: date });
};

// デフォルト チェック状態管理
export const setItemChecked = (
  value: Record<number, Record<string, boolean>>,
) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, itemChecked: value });
};

// 日別 選択状態管理
export const setSelectDailyItem = (
  value: Record<number, Record<string, string>>,
) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, selectDailyItem: value });
};

// 枠テンプレート変更状態
export const setIsChangedTemplate = (value: boolean) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, isChangedTemplate: value });
};

// 変更データクリア
export const clearChangeDailyItem = () => {
  stateReactiveVar({ ...stateReactiveVar(), changeDailyItem: [] });
};

// 変更したセレクトボックスクリア
export const clearSelectItem = (
  selectDailyItem: Record<number, Record<string, string>>,
) => {
  const clearedSelectDailyItem = Object.fromEntries(
    Object.entries(selectDailyItem).map(([key, value]) => [
      key,
      Object.fromEntries(Object.keys(value).map((subKey) => [subKey, "-"])),
    ]),
  );
  setSelectDailyItem(clearedSelectDailyItem);
};

// 一括 チェック状態管理
export const setBulkChecked = (
  value: Record<number, Record<string, boolean>>,
) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({ ...currentInfos, bulkChecked: value });
};

// 一括チェッククリア
export const clearBulkChecked = (
  bulkChecked: Record<number, Record<string, boolean>>,
) => {
  const clearBulkChecked = Object.fromEntries(
    Object.entries(bulkChecked).map(([key, value]) => [
      key,
      Object.fromEntries(Object.keys(value).map((subKey) => [subKey, false])),
    ]),
  );
  setBulkChecked(clearBulkChecked);
};

// 日別スケジュール
export const setDailyScheduleItem = (value: ScheduleDailyInfo) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({
    ...currentInfos,
    dailyScheduleItem: value,
  });
};

// 一括変更 状態反映
export const bulkUpdateSelectDailyItem = (
  selectedValue: string,
  startOfWeek: Date,
) => {
  const currentInfos = stateReactiveVar();

  // AMがtrueの曜日のインデックスを抽出
  const selectedAmDays = Object.keys(currentInfos.bulkChecked)
    .filter((day) => currentInfos.bulkChecked[parseInt(day, 10)].AM)
    .map((day) => parseInt(day, 10));

  // PMがtrueの曜日のインデックスを抽出
  const selectedPmDays = Object.keys(currentInfos.bulkChecked)
    .filter((day) => currentInfos.bulkChecked[parseInt(day, 10)].PM)
    .map((day) => parseInt(day, 10));

  let newValue: string;
  switch (selectedValue) {
    case "1":
      newValue = "-";
      break;
    case "2":
      newValue = "◯";
      break;
    case "3":
      newValue = "×";
      break;
    default:
      newValue = "-";
  }

  // 更新を集めるための配列
  const collectedChanges: { dateTime: Date; value: string }[] = [];

  // 時間をセットして値を更新
  const updateAndSetChangeDailyItem = (
    hour: number,
    minute: number,
    newValue: string,
    index: number,
  ) => {
    const dateTime = new Date(startOfWeek);
    dateTime.setDate(dateTime.getDate() + index);
    dateTime.setHours(hour);
    dateTime.setMinutes(minute);
    collectedChanges.push({ dateTime, value: newValue });
  };

  // AMにチェックがあり、PMにチェックがない場合、amStartTimeとamEndTimeの範囲内のみを更新
  selectedAmDays.forEach((index) => {
    if (!selectedPmDays.includes(index)) {
      // AMにチェックがあり、PMにチェックがない場合の処理
      const { amStartTime, amEndTime } = currentInfos;
      const startTimeHour = amStartTime.getHours();
      const endTimeHour = amEndTime.getHours();

      // 範囲内の時間を更新
      for (let hour = startTimeHour; hour <= endTimeHour; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const time = `${hour < 10 ? "0" : ""}${hour}:${
            minute < 10 ? "0" : ""
          }${minute}`;
          if (currentInfos.selectDailyItem[index][time] !== undefined) {
            currentInfos.selectDailyItem[index][time] = newValue;
            updateAndSetChangeDailyItem(hour, minute, newValue, index);
          }
        }
      }
    } else {
      // AMにチェックがあり、PMにチェックがある場合の処理
      const { amStartTime, pmEndTime } = currentInfos;
      const startTimeHour = amStartTime.getHours();
      const endTimeHour = pmEndTime.getHours();

      // 範囲内の時間を更新
      for (let hour = startTimeHour; hour <= endTimeHour; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const time = `${hour < 10 ? "0" : ""}${hour}:${
            minute < 10 ? "0" : ""
          }${minute}`;
          if (currentInfos.selectDailyItem[index][time] !== undefined) {
            currentInfos.selectDailyItem[index][time] = newValue;
            updateAndSetChangeDailyItem(hour, minute, newValue, index);
          }
        }
      }
    }
  });

  // PMにチェックがあり、AMにチェックがない場合、pmStartTimeとpmEndTimeの範囲内のみを更新
  selectedPmDays.forEach((index) => {
    if (!selectedAmDays.includes(index)) {
      // PMにチェックがあり、AMにチェックがない場合の処理
      const { pmStartTime, pmEndTime } = currentInfos;
      const startTimeHour = pmStartTime.getHours();
      const endTimeHour = pmEndTime.getHours();

      // 範囲内の時間を更新
      for (let hour = startTimeHour; hour <= endTimeHour; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const time = `${hour < 10 ? "0" : ""}${hour}:${
            minute < 10 ? "0" : ""
          }${minute}`;
          if (currentInfos.selectDailyItem[index][time] !== undefined) {
            currentInfos.selectDailyItem[index][time] = newValue;
            updateAndSetChangeDailyItem(hour, minute, newValue, index);
          }
        }
      }
    }
  });

  // 状態を更新
  stateReactiveVar({
    ...currentInfos,
    selectDailyItem: currentInfos.selectDailyItem,
  });

  // 更新されたデータをまとめてセット
  const updatedDailyScheduleItems = [...currentInfos.dailyScheduleItem];
  collectedChanges.forEach(({ dateTime, value }) => {
    // dateTime から Date オブジェクトを作成
    const changeDateTime = new Date(dateTime);
    // 秒とミリ秒を無視
    changeDateTime.setSeconds(0, 0);

    // 同じ dateTime（秒を除く）を持つ要素を探す
    const existingItemIndex = updatedDailyScheduleItems.findIndex((item) => {
      const itemDateTime = new Date(item.datetime);
      // 秒とミリ秒を無視
      itemDateTime.setSeconds(0, 0);

      return itemDateTime.getTime() === changeDateTime.getTime();
    });
    if (existingItemIndex !== -1) {
      if (value === "-") {
        updatedDailyScheduleItems.splice(existingItemIndex, 1);
      } else {
        // 同じ dateTime を持つ要素が存在する場合は、is_open のみを更新
        updatedDailyScheduleItems[existingItemIndex].is_open = value === "◯";
      }
    } else if (value !== "-") {
      // 該当する要素がない場合は、新しい要素を追加
      updatedDailyScheduleItems.push({
        datetime: dateTime,
        is_open: value === "◯",
      });
    }
  });
  setDailyScheduleItem(updatedDailyScheduleItems);
};

export const setResetOriginData = (timeInterval: string) => {
  const currentInfos = stateReactiveVar();
  stateReactiveVar({
    ...currentInfos,
    originData: {
      ...currentInfos.originData,
      timeItems: [],
      dailyScheduleItem: [],
      timeInterval,
      timeFrame: Number(timeInterval) as 0 | 15 | 30 | 60 | 90 | 120,
    },
  });
};

export default useMie011Conditions;
