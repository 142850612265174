import React from "react";
import Icon from "../Icon/Icon";
import LayoutBox from "../LayoutBox/LayoutBox";

export type DetailsMenuItemProps = {
  label: string;
  icon?: string;
  danger?: boolean;
  disabled?: boolean;
  onClick: (value: string) => void | (() => void);
};

const DetailsMenuItem = ({
  label = "",
  icon = "",
  danger = false,
  disabled = false,
  onClick,
}: DetailsMenuItemProps) => {
  const detailsMenuItemClass = React.useMemo(() => {
    const styleClass = ["details-menu__item"];
    const dangerClass = danger ? ["details-menu__item--danger"] : [];
    const disabledClass = disabled ? ["details-menu__item--disabled"] : [];

    return [...styleClass, ...dangerClass, ...disabledClass].join(" ");
  }, [danger, disabled]);

  const iconColorClass = React.useMemo(() => {
    const dangerClass = danger ? ["icon--danger"] : ["icon--normal"];
    const disabledClass = disabled ? ["icon--disabled"] : [];

    return [...dangerClass, ...disabledClass].join(" ");
  }, [danger, disabled]);

  const onClickItem = React.useCallback(() => {
    if (!disabled) onClick(label);
  }, [onClick, label, disabled]);

  return (
    <>
      <div className={detailsMenuItemClass} onClick={onClickItem}>
        <LayoutBox gap="1/2x" align="center">
          <Icon icon={icon} size="xs" className={iconColorClass} />
          {label}
        </LayoutBox>
      </div>
    </>
  );
};

DetailsMenuItem.defaultProps = {
  icon: "",
  danger: false,
  disabled: false,
};

export default DetailsMenuItem;
