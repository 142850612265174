import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateAdminDoctor, getMongoDb } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useNlc021NewDialog from "./useNlc021NewDialog";
import useStateCustomObj from "../../base/useStateCustomObj";
import { formatDate } from "../../../utils/utils";

// ドクターを取得
const useNlc021DoctorFetch = (
  selectedValue: string,
  selectedDate: Date | undefined,
  otherTimeStr: string,
) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [isOpen] = useNlc021NewDialog();
  const [doctors, setDoctors] = useStateCustomObj<AdminDoctorReservation[]>([]);
  const [doctorFetchError, setDoctorFetchError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersCollection = getMongoDb(currentUser, Collection.USERS);
        const usersAggregate = getAggregateAdminDoctor();
        const userResult = (await usersCollection.aggregate(
          usersAggregate,
        )) as AdminDoctor[];

        // ドクターの予約のOKかNGを判定
        const doctorInfo = userResult.map((user) => {
          // 空の配列の場合はすぐに false をセット
          if (user.interview_fix_date.length === 0) {
            return {
              title: user.full_name,
              value: user._id,
              email: user.email,
              disabled: false,
            };
          }

          let isDisabled;
          if (selectedValue === "other") {
            isDisabled = user.interview_fix_date.some((dateString) => {
              if (dateString === null) return false;

              if (!selectedDate || !otherTimeStr) {
                return false;
              }

              const otherDateStr = `${formatDate(
                selectedDate,
              )} ${otherTimeStr}`;

              // 時刻の分を "00" に設定して selectedValue と比較
              return `${dateString.split(":")[0]}:00`.includes(
                `${otherDateStr.split(":")[0]}:00`,
              );
            });
          } else {
            isDisabled = user.interview_fix_date.some((dateString) => {
              if (dateString === null) return false;

              // 時刻の分を "00" に設定して selectedValue と比較
              return `${dateString.split(":")[0]}:00`.includes(selectedValue);
            });
          }

          return {
            title: user.full_name,
            value: user._id,
            email: user.email,
            disabled: isDisabled,
          };
        });

        setDoctors(doctorInfo);
      } catch (err) {
        setDoctorFetchError(checkFetchErr(err));
      }
    };

    if (isOpen) void fetchData();
  }, [
    currentUser,
    isOpen,
    setDoctors,
    selectedValue,
    selectedDate,
    otherTimeStr,
  ]);

  return { doctors, doctorFetchError };
};

export default useNlc021DoctorFetch;
