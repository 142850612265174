import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<boolean>(false);

// キャンペーン登録ダイアログの開閉状態を管理
const useNli010Dialog = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNli010Dialog = () => {
  stateReactiveVar(true);
};

export const closeNli010Dialog = () => {
  stateReactiveVar(false);
};

export default useNli010Dialog;
