import { useState, useEffect } from "react";

import { getMongoDb, getAggregateMib001 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
  SEARCH_INFO,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import { useMemoStatusArr } from "../../../utils/utils";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";

type Mib001FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.MIB001;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.MIB001;
};

const useMib001DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Mib001FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // 病院ID
  const hospitalId: string = currentUser
    ? (currentUser.customData.hospital_id as string)
    : "";

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchResult, setFetchResult] = useStateCustomObj<Mib001StateType[]>(
    [],
  );
  const [allData, setAllData] = useStateCustomObj<Mib001StateType[]>([]);

  const [fetchError, setFetchError] = useState<Error | null>(null);
  const { userId, patientName, tel, applicationDate } = submittedSearchCriteria;
  const [forceUpdate] = useForceUpdate();

  // 検査内容のチェック状態
  const modalityArray = useMemoStatusArr(
    SEARCH_INFO.MIB001_MODALITY,
    submittedSearchCriteria.modality,
  );

  // ステータスのチェック状態
  const statusArray = useMemoStatusArr(
    SEARCH_INFO.MIB001_STATUS,
    submittedSearchCriteria.status,
  );

  // sortを取得 未選択の場合は最終更新日の降順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "created_at_db", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateMib001(
          hospitalId,
          userId,
          patientName,
          tel,
          applicationDate,
          modalityArray,
          statusArray,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultAllDataType<Mib001StateType>;

        const { result, count, allData } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setAllData(allData);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    setFetchResult,
    setAllData,
    applicationDate,
    modalityArray,
    statusArray,
    patientName,
    tel,
    forceUpdate.forceUpdateCount,
    hospitalId,
    userId,
  ]);

  return {
    fetchResult,
    allData,
    fetchError,
  };
};

export default useMib001DbActions;
