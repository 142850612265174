import React, { useEffect, useReducer } from "react";

import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import FileUpload, { FileType } from "../components/FileUpload/FileUpload";
import Divider from "../components/Divider/Divider";
import DatePicker from "../components/DatePicker/DatePicker";
import { yoseInsertData } from "../hooks/pages/NLE003/useNLE003DbActions";

const NLE003 = (props: {
  yoseData?: yoseDetailData | undefined;
  onCloseBtnClicked: () => void;
  onInsertBtnClicked: (data: yoseInsertData) => void;
}) => {
  const [uploadFiles, setUploadFiles] = React.useState<FileType[]>([]);

  const { yoseData, onCloseBtnClicked, onInsertBtnClicked } = props;
  const yoseDocument = yoseData?.document;
  const initialState: NLE003FormType = {
    yoseTitle: yoseDocument?.yose_title || "",
    vimeoId: yoseDocument?.vimeo_id || "",
    yoseDescription: yoseDocument?.yose_description || "",
    cast: yoseDocument?.cast || "",
    castNote: yoseDocument?.cast_note || "",
    castProfileImage: uploadFiles[0]?.url,
    newStreamingDate: yoseDocument?.streaming_date,
    newStreamingEndDate: yoseDocument?.streaming_end_date,
    newPeriod: yoseDocument?.new_period || new Date(),
    isSuspend: yoseDocument?.is_suspend || false,
  };

  const [formState, setFormState] = useReducer(
    (currentState: NLE003FormType, update: Partial<NLE003FormType>) => ({
      ...currentState,
      ...update,
    }),
    initialState,
  );

  // trueの場合、登録可能
  const enableRegister =
    formState.yoseTitle &&
    formState.vimeoId &&
    formState.cast &&
    formState.newStreamingDate &&
    formState.newStreamingEndDate;

  /**
   *  配信期間の範囲を変更します。
   */
  const changeDeliveryPeriod = (
    streamingDate?: Date,
    streamingEndDate?: Date,
  ) => {
    const newStreamingDate = streamingDate
      ? new Date(streamingDate)
      : undefined;
    const newStreamingEndDate = streamingEndDate
      ? new Date(streamingEndDate)
      : undefined;
    // ステータス管理のために、その日の0時で保存
    if (newStreamingDate) {
      newStreamingDate.setHours(0, 0, 0, 0);
    }

    if (newStreamingEndDate) {
      newStreamingEndDate.setHours(0, 0, 0, 0);
    }

    setFormState({
      newStreamingDate,
      newStreamingEndDate,
    });
  };

  /**
   *  新着期間を変更します。
   */
  const changeNewPeriod = (newPeriod: Date) => {
    // ステータス管理のために、その日の0時で保存
    newPeriod.setHours(0, 0, 0, 0);

    setFormState({ newPeriod });
  };

  useEffect(() => {
    if (!yoseData?.sign_url) return;

    setUploadFiles([
      {
        name: "castImage",
        percentage: 100,
        url: yoseData?.sign_url,
      },
    ]);
  }, [yoseData?.sign_url]);

  return (
    <DialogCustom
      title="新規動画登録"
      open
      size="large"
      height="1254px"
      closeDialog={onCloseBtnClicked}
      footerRight={
        <LayoutBox>
          <Button
            type="sub"
            color="neutral"
            size="large"
            onClick={onCloseBtnClicked}
          >
            キャンセル
          </Button>
          <Button
            size="large"
            onClick={() => {
              onInsertBtnClicked({ formState, uploadFiles });
            }}
            disabled={!enableRegister}
          >
            登録
          </Button>
        </LayoutBox>
      }
    >
      <LayoutBox gap="2x" direction="column" fullWidth>
        <FormSet
          label="動画タイトル"
          labelWidth="90px"
          minWidth="259px"
          required="icon"
          vertical
        >
          <Input
            placeholder="動画タイトル"
            width="100%"
            value={formState.yoseTitle}
            onChange={(yoseTitle) => setFormState({ yoseTitle })}
          />
        </FormSet>
        <FormSet
          label="動画ID（Vimeo）"
          labelWidth="90px"
          minWidth="259px"
          required="icon"
          vertical
        >
          <Input
            placeholder="動画URL"
            width="100%"
            value={formState.vimeoId}
            onChange={(vimeoId) => setFormState({ vimeoId })}
          />
        </FormSet>
        {/* TODO vimeoで保存することになった。デザイン改修予定 */}
        {/* <FormSet
        label="サムネイル画像"
        labelWidth="90px"
        minWidth="259px"
        vertical
      >
        <Input
          placeholder="動画URL"
          width="100%"
          value={formState.vimeoId}
          onChange={(vimeoId) => setFormState({ vimeoId })}
        />
      </FormSet> */}

        <FormSet
          label="動画詳細説明"
          labelWidth="90px"
          minWidth="259px"
          vertical
        >
          <Input
            placeholder="動画詳細説明"
            width="100%"
            multiLine
            value={formState.yoseDescription}
            onChange={(yoseDescription) => setFormState({ yoseDescription })}
          />
        </FormSet>
        <Divider />
        <FormSet label="出演者名" labelWidth="90px" required="icon" vertical>
          <Input
            placeholder="出演者名"
            width="189px"
            value={formState.cast}
            onChange={(cast) => setFormState({ cast })}
          />
        </FormSet>

        <FormSet
          label="出演者プロフィール画像"
          labelWidth="90px"
          minWidth="259px"
          vertical
        >
          <FileUpload
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
            type="single"
          />
        </FormSet>
        <FormSet label="出演者備考" labelWidth="90px" minWidth="259px" vertical>
          <Input
            placeholder="出演者備考"
            width="100%"
            multiLine
            value={formState.castNote}
            onChange={(castNote) => setFormState({ castNote })}
          />
        </FormSet>
        <Divider />
        <FormSet label="配信日" labelWidth="90px" required="icon" vertical>
          <DatePicker
            placeholder="開始日"
            placeholderEnd="終了日"
            selectedDate={formState.newStreamingDate}
            onChangeRangeDate={changeDeliveryPeriod}
            selectedDateRange={{
              startDate: formState.newStreamingDate,
              endDate: formState.newStreamingEndDate,
            }}
            width="285px"
            type="range"
          />
        </FormSet>
        <FormSet label="新着期間" labelWidth="90px" minWidth="259px" vertical>
          <DatePicker
            placeholder="新着期間"
            selectedDate={formState.newPeriod}
            onChangeDate={changeNewPeriod}
            width="285px"
          />
        </FormSet>
      </LayoutBox>
    </DialogCustom>
  );
};
export default NLE003;

NLE003.defaultProps = { yoseData: undefined };
