import React from "react";
import TableCell from "./TableCell";
import TableRow from "./TableRow";

export interface TimeDataItem {
  hour: number;
  minute: number;
  dayNumber: number;
  onClickItem: (item: TimeDataItem) => void;
  selected: boolean;
  topBorder?: boolean;
  bottomBorder?: boolean;
  onSelectItem: (item: TimeDataItem, value: string) => void;
  selectDailyItem: string;
  bulkChecked: Record<string, Record<string, boolean>>;
  timePeriod: "AM" | "PM";
}

export type TimeTableRowProps = {
  rowspan: number;
  // eslint-disable-next-line react/require-default-props
  showHourCell?: string;
  formattedHour: string;
  formattedMinutes: string;
  hour: number;
  minute: number;
  itemChecked: Record<string, Record<string, boolean>>;
  selectDailyItem: Record<string, Record<string, string>>;
  onClickItem: (item: Omit<TimeDataItem, "onClickItem">) => void;
  renderTimeSlot: (item: TimeDataItem) => React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  topBorder?: boolean;
  onSelectItem: (
    item: Omit<TimeDataItem, "onSelectItem">,
    value: string,
  ) => void;
  bulkChecked: Record<string, Record<string, boolean>>;
  timePeriod: "AM" | "PM";
};

const TimeTableRow = ({
  rowspan,
  showHourCell,
  formattedMinutes,
  formattedHour,
  hour,
  minute,
  itemChecked,
  renderTimeSlot,
  onClickItem,
  topBorder,
  onSelectItem,
  selectDailyItem,
  bulkChecked,
  timePeriod,
}: TimeTableRowProps) => {
  const time = `${formattedHour}:${formattedMinutes}`;

  return (
    <TableRow>
      {showHourCell && (
        <TableCell
          key={`${formattedHour}-${formattedMinutes}`}
          padding="8px"
          verticalAlign="top"
          bold
          bottomBorder
          rowSpan={rowspan}
          background="#eff4fe"
        >
          {showHourCell}〜
        </TableCell>
      )}
      <TableCell
        bottomBorder
        topBorder={topBorder}
        background="#eff4fe"
        key={time}
      >
        {time}
      </TableCell>

      {Array.from({ length: 7 }).map((_, index) => (
        <React.Fragment key={`timeSlot-${index}`}>
          {renderTimeSlot({
            hour,
            minute,
            dayNumber: index,
            onClickItem,
            selected: itemChecked[index][time],
            onSelectItem,
            selectDailyItem: selectDailyItem[index][time],
            bulkChecked,
            timePeriod,
          })}
        </React.Fragment>
      ))}
    </TableRow>
  );
};

export default TimeTableRow;
