import React from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Tag from "../components/Tag/Tag";
import Image from "../components/Image/Image";
import Switch from "../components/Switch/Switch";
import Divider from "../components/Divider/Divider";
import {
  MASTER_MANAGEMENT_OPTIONS,
  NotificationStatusID,
  ROLE_KEY,
} from "../constants/common";
import { handleSelectMainTab, handleSelectMasterInfo } from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { convertNlg003 } from "../utils/convertDisplay";
import { ADMIN } from "../constants/pagePaths";
import InformationDialog from "../components/Dialog/InformationDialog";
import useNlg003DbActions from "../hooks/pages/NLG003/useNlg003DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithBack } from "../hooks/base/usePageTransitionCustom";
import NLG004 from "./NLG004";
import { setId } from "../hooks/common/useId";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLG003 = () => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const {
    actionError,
    fetchResult: notification,
    handleDeliveryToggle,
    handleNotificationDelete,
  } = useNlg003DbActions();
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { id, title, detail, statusStr, statusNum, deliveriedAt, imageUrl } =
    convertNlg003(notification);

  // 権限チェック
  const hasEditRole = useCheckHasRole(ROLE_KEY.NOTICE_DETAIL_EDIT);

  useCheckErrorThrowError([actionError, mainTabError]);

  // 削除ダイアログopen
  const openDialog = () => {
    setDialogOpen(true);
  };

  // 削除ダイアログclose
  const closeDialog = () => {
    setDialogOpen(false);
  };

  // 編集押下
  const handleEdit = () => {
    setIsEditDialogOpen(true);
    setId(id);
  };

  // 一覧へ戻る
  const handleGoBack = useNavigateWithBack(ADMIN.NLG001);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={6}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Button
              type="sub"
              size="large"
              icon="arrow_back"
              onClick={handleGoBack}
            >
              一覧へ戻る
            </Button>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox justify="between" direction="row" gap="2x" fullWidth>
                <LayoutBox direction="column" gap="2x" fullWidth>
                  <FormSet
                    label="お知らせタイトル"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {title}
                  </FormSet>

                  <FormSet
                    label="お知らせ詳細"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {detail}
                  </FormSet>

                  <FormSet
                    label="画像"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    <Image type="picture" url={imageUrl || ""} />
                  </FormSet>
                  <FormSet
                    label="ステータス"
                    base
                    labelSize="large"
                    labelWidth="126px"
                  >
                    <Tag
                      label={statusStr}
                      showIcon={false}
                      state={statusStr === "未公開" ? "error" : "success"}
                    />
                  </FormSet>
                  <Divider margin="0" />
                  <FormSet
                    label="配信日"
                    base
                    labelSize="large"
                    labelWidth="126px"
                    formVertical={false}
                  >
                    {deliveriedAt}
                  </FormSet>
                </LayoutBox>
                <LayoutBox justify="end" flexShrink={0}>
                  <Switch
                    checked={statusNum === NotificationStatusID.UNPUBLISHED}
                    onChange={handleDeliveryToggle}
                    disabled={!hasEditRole}
                  >
                    配信停止
                  </Switch>
                  <Divider vertical height="32px" width="1px" />
                  <Button
                    icon="delete"
                    type="secondary"
                    color="danger"
                    width="70px"
                    onClick={openDialog}
                    disabled={!hasEditRole}
                  >
                    削除
                  </Button>
                  <InformationDialog
                    alertLevel="error"
                    open={dialogOpen}
                    closeDialog={closeDialog}
                    title="削除してもよろしいですか？"
                    footer={
                      <LayoutBox>
                        <Button
                          color="danger"
                          size="medium"
                          onClick={handleNotificationDelete}
                        >
                          削除
                        </Button>
                        <Button
                          color="neutral"
                          type="sub"
                          size="medium"
                          onClick={closeDialog}
                        >
                          キャンセル
                        </Button>
                      </LayoutBox>
                    }
                  >
                    この操作は取り消せません。
                  </InformationDialog>
                  <Button
                    icon="edit"
                    type="secondary"
                    color="main"
                    width="70px"
                    onClick={handleEdit}
                    disabled={!hasEditRole}
                  >
                    編集
                  </Button>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isEditDialogOpen && (
        <NLG004 isOpen={isEditDialogOpen} setIsOpen={setIsEditDialogOpen} />
      )}
    </>
  );
};

export default NLG003;
