import { useState, useEffect } from "react";

import {
  getAggregateNlg001,
  getMongoDb,
  getAggregateAdmins,
} from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  SORT_EXAMPLES,
  SEARCH_INFO,
  NotificationStatusID,
  UserTypeID,
  UserStatusID,
  ROLE_KEY,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import { useMemoStatusArr } from "../../../utils/utils";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeActionLoading,
  openDeleteActionLoading,
} from "../../base/useLoadingAction";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import useForceUpdate from "../../common/useForceUpdate";
import {
  checkMasterRole,
  useCheckHasRole,
} from "../../common/useMasterRoleDbActions";

type Nlg001FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLG001;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLG001;
};

const useNlg001DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Nlg001FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchResult, setFetchResult] = useStateCustomObj<
    NotificationsNlg001StateType[]
  >([]);
  const [changerList, setChangerList] = useStateCustomObj<SelectBoxType[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const {
    lastUpdatedAdminId,
    title,
    deliveryStartDate,
    deliveryEndDate,
    lastUpdatedStartDate,
    lastUpdatedEndDate,
  } = submittedSearchCriteria;

  // 検索のチェック状態をquery用に整形
  const memoStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLG001,
    submittedSearchCriteria.status,
  );

  // sortを取得 未選択の場合は最終更新日の降順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "last_updated_at", sortDirection = "desc" } =
    currentSort || {};

  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.NOTICE_LIST_BROWSING);

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDbNotifications = getMongoDb(
          currentUser,
          Collection.NOTIFICATIONS,
        );
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNlg001(
          title,
          lastUpdatedAdminId,
          memoStatusArr,
          deliveryStartDate,
          deliveryEndDate,
          lastUpdatedStartDate,
          lastUpdatedEndDate,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDbNotifications.aggregate(
          aggregate,
        )) as AggregateResultType<NotificationsNlg001StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        // 更新者リスト
        const mongoDbAdmins = getMongoDb(currentUser, Collection.USERS);
        // 検索条件
        const adminSearchConditions = {
          user_type: UserTypeID.ADMIN,
          status: UserStatusID.ACTIVE,
        };
        const adminAggregate = getAggregateAdmins(adminSearchConditions);
        const adminResult = (await mongoDbAdmins.aggregate(
          adminAggregate,
        )) as AdminsType[];

        // 画面表示用に変換
        const changerList = adminResult.map((admin: AdminsType) => ({
          value: admin._id,
          label: admin.full_name,
        }));

        setTotal(total);
        setFetchResult(result);
        setChangerList(changerList);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    // 権限があるときのみデータ取得
    if (hasBrowsingRole) void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    title,
    colName,
    sortDirection,
    lastUpdatedAdminId,
    memoStatusArr,
    deliveryStartDate,
    deliveryEndDate,
    lastUpdatedStartDate,
    lastUpdatedEndDate,
    forceUpdate.forceUpdateCount,
    setFetchResult,
    setChangerList,
    hasBrowsingRole,
  ]);

  // 削除処理
  const handleNotificationDelete = (deleteNotificationId: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.NOTICE_DETAIL_EDIT, currentUser);

        const notificationsCollection = getMongoDb(
          currentUser,
          Collection.NOTIFICATIONS,
        );
        await notificationsCollection.updateOne(
          { _id: deleteNotificationId },
          {
            $set: {
              status: NotificationStatusID.DELETED,
              last_updated_at: new Date(),
            },
          },
        );
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    changerList,
    fetchError,
    handleNotificationDelete,
    actionError,
  };
};

export default useNlg001DbActions;
