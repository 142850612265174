import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.NLD005;
  search: typeof INIT_SEARCH_CRITERIA.NLD005;
  authorizationStartDate: Date | undefined;
  authorizationEndDate: Date | undefined;
  paymentStartDate: Date | undefined;
  paymentEndDate: Date | undefined;
  receiptIssueStartDate: Date | undefined;
  receiptIssueEndDate: Date | undefined;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.NLD005,
  search: INIT_SEARCH_CRITERIA.NLD005,
  authorizationStartDate: undefined,
  authorizationEndDate: undefined,
  paymentStartDate: undefined,
  paymentEndDate: undefined,
  receiptIssueStartDate: undefined,
  receiptIssueEndDate: undefined,
});

const useNld005Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNld005Conditions;
