import React, { useState } from "react";
import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import useMie006DbActions from "../hooks/pages/MIE006/useMie006DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import InformationDialog from "../components/Dialog/InformationDialog";
import useUserDetailInput, {
  checkUserDetailInputError,
  setEmail,
  setInitUserDetailInput,
  setUserName,
} from "../hooks/input/useUserDetailInput";
import usePasswordInput, {
  checkPasswordInputError,
  setInitPasswordInput,
  setPassword,
  setPasswordError,
  setRePassword,
} from "../hooks/input/usePasswordInput";
import { useRealmApp } from "../contexts/RealmApp";
import { getUserType } from "../utils/utils";
import { ROLE_KEY, UserStatusID, UserTypeID } from "../constants/common";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

type MIE006Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  hospitalId: string | undefined;
  // eslint-disable-next-line react/require-default-props
  hospitalUser?: Nli017TableType | null;
  // eslint-disable-next-line react/require-default-props
  setHospitalUser?: React.Dispatch<
    React.SetStateAction<Nli017TableType | undefined>
  >;
};

const MIE006: React.FC<MIE006Props> = ({
  isOpen,
  setIsOpen,
  title,
  hospitalId,
  hospitalUser = null,
  setHospitalUser = null,
}) => {
  const realmAppContext = useRealmApp();
  const [id, setId] = useState("");
  const [{ userName, email }] = useUserDetailInput();
  const [{ password, rePassword }] = usePasswordInput();
  const {
    actionError,
    handleRegisterHospitalUser,
    handleUpdateHospitalUser,
    handleDeleteUser,
    existingEmailError,
  } = useMie006DbActions();
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);

  // エラー処理
  useCheckErrorThrowError([actionError, existingEmailError]);

  // 登録ボタン押下
  const handleRegisterUserButton = () => {
    if (hospitalUser) {
      // 更新
      handleUpdateHospitalUser(id, userName.value, hospitalId, () =>
        setIsOpen(false),
      );
    } else {
      // パスワードバリデーション
      if (password.value !== rePassword.value) {
        setPasswordError();

        return;
      }
      // 登録
      handleRegisterHospitalUser(
        hospitalId,
        userName.value,
        email.value,
        password.value,
        () => setIsOpen(false),
      );
    }
  };

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = () => {
    if (setHospitalUser) {
      setHospitalUser(undefined);
    }
    setIsOpen(false);
  };

  // 削除押下
  const handleDelete = (id?: string) => {
    if (id) {
      handleDeleteUser(id);
    }
    setIsDeleteDialog(false);
    setIsOpen(false);
  };

  React.useEffect(() => {
    setInitUserDetailInput();
    setInitPasswordInput();
    if (hospitalUser) {
      setId(hospitalUser.id);
      setUserName(hospitalUser.fullName);
      setEmail(hospitalUser.email);
    }
  }, [hospitalUser]);

  // サービス管理者の場合の権限チェック
  const userType = getUserType(realmAppContext.currentUser);
  const hasAddRole = useCheckHasRole(ROLE_KEY.MASTER_HOSPITAL_EDIT);

  return (
    <>
      <DialogCustom
        title={title}
        size="medium"
        height="610px"
        open={isOpen}
        closeDialog={handleCloseButton}
        footerRight={
          <LayoutBox>
            <Button
              onClick={handleCloseButton}
              color="neutral"
              type="sub"
              size="large"
            >
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleRegisterUserButton}
              disabled={
                checkUserDetailInputError() ||
                (!hospitalUser && checkPasswordInputError()) ||
                (userType === UserTypeID.ADMIN && !hasAddRole)
              }
            >
              登録
            </Button>
          </LayoutBox>
        }
        footerLeft={
          hospitalUser &&
          realmAppContext.currentUser?.id !== hospitalUser.id ? (
            <Button
              icon="delete"
              type="secondary"
              color="danger"
              size="large"
              width="88px"
              onClick={() => setIsDeleteDialog(true)}
              disabled={
                (userType === UserTypeID.ADMIN && !hasAddRole) ||
                hospitalUser.status !== UserStatusID.ACTIVE
              }
            >
              削除
            </Button>
          ) : undefined
        }
      >
        <LayoutBox direction="column" fullWidth>
          <FormSet
            label="ユーザー名"
            vertical
            required="icon"
            errorTextList={[userName.validationMessage]}
          >
            <Input
              size="default"
              value={userName.value}
              placeholder="ユーザー名"
              width="100%"
              onChange={(value) => setUserName(value)}
              error={userName.isError}
            />
          </FormSet>

          <FormSet
            label="メールアドレス"
            vertical
            required="icon"
            errorTextList={[email.validationMessage]}
          >
            <Input
              disabled={!!hospitalUser}
              size="default"
              value={email.value}
              placeholder="メールアドレス"
              width="100%"
              onChange={(value) => setEmail(value)}
              error={email.isError}
            />
          </FormSet>
          {!hospitalUser && (
            <>
              <FormSet
                label="新しいパスワード"
                vertical
                required="icon"
                errorTextList={[password.validationMessage]}
              >
                <Input
                  size="default"
                  width="259px"
                  password
                  rightButton="password"
                  autoComplete="new-password"
                  value={password.value}
                  onChange={(value) => setPassword(value)}
                  error={password.isError}
                />
              </FormSet>
              <FormSet
                label="新しいパスワード（確認用）"
                vertical
                required="icon"
                errorTextList={[rePassword.validationMessage]}
              >
                <Input
                  size="default"
                  width="259px"
                  password
                  rightButton="password"
                  autoComplete="new-password"
                  value={rePassword.value}
                  onChange={(value) => setRePassword(value)}
                  error={rePassword.isError}
                />
              </FormSet>
            </>
          )}
        </LayoutBox>
        {isDeleteDialog && (
          <InformationDialog
            alertLevel="error"
            open={isDeleteDialog}
            closeDialog={() => setIsDeleteDialog(false)}
            title="削除してもよろしいですか？"
            footer={
              <LayoutBox>
                <Button
                  color="danger"
                  size="medium"
                  onClick={() => handleDelete(hospitalUser?.id)}
                >
                  削除
                </Button>
                <Button
                  color="neutral"
                  type="sub"
                  size="medium"
                  onClick={() => setIsDeleteDialog(false)}
                >
                  キャンセル
                </Button>
              </LayoutBox>
            }
          >
            この操作は取り消せません。
          </InformationDialog>
        )}
      </DialogCustom>
    </>
  );
};

export default MIE006;
