import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  email: ValidationResultType<string>;
};

const INIT_STATE: DefaultValidationType = {
  email: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType>(INIT_STATE);
const useEmailInput = (): ReactiveVarHooks<DefaultValidationType> =>
  useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setEmailValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setEmail = (value: DefaultValidationType["email"]["value"]) => {
  _setEmailValidation("email", value, VALIDATION_TYPE.EMAIL_REQUIRED);
};

// 入力エラーチェック エラーの場合true
export const checkEmailInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { ...validationInfos } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

// 同一Emailチェック
export const setExistingEmailError = () => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    email: {
      ...currentInput.email,
      validationMessage:
        "すでに登録されているメールアドレスです。別のメールアドレスを入力してください。",
    },
  });
};

export default useEmailInput;
