import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Sheet from "../components/Sheet/Sheet";
import FormSet from "../components/FormSet/FormSet";
import Checkbox from "../components/Checkbox/Checkbox";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Dropdown from "../components/Dropdown/Dropdown";
import {
  downloadCsvNlf006,
  handleSelectECMgmtSubTab,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import {
  ADMIN_ORDERS_TOP_TAB_ITEMS,
  INIT_PAGE,
  INIT_SEARCH_CRITERIA,
  MASTER_MANAGEMENT_OPTIONS,
  MONTH_LIST,
  ROLE_KEY,
} from "../constants/common";
import useNlf006DbActions from "../hooks/pages/NLF006/useNlf006DbActions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { convertNlf006Table } from "../utils/convertDisplay";
import { setPage } from "../hooks/component/pagination/usePagination";
import NLF007 from "./NLF007";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLF006 = () => {
  const [sales, setSales] = useStateCustomObj<Nlf006TableType[]>([]);
  const [searchCriteria, setSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLF006,
  );
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    INIT_SEARCH_CRITERIA.NLF006,
  );
  const { fetchResult, fetchError, selectSalesYear } = useNlf006DbActions({
    submittedSearchCriteria,
  });
  const [isSalesDialogOpen, setIsSalesDialogOpen] = React.useState(false);
  const [selectSalesData, setSelectSalesData] = React.useState({
    selectSalesId: "",
    selectSalesOrderTime: "",
  });
  const [salesIdArray, setSalesIdArray] = React.useState<string[]>([]);

  const [isCheckAllUserIds, setIsCheckAllUserIds] = React.useState(false);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  useCheckErrorThrowError([mainTabError, fetchError]);

  useEffect(() => {
    const convertResult = convertNlf006Table(fetchResult);
    setSales(convertResult);
    // 詳細ポップアップ用にid配列をセット
    setSalesIdArray(convertResult.map((result) => result.id));
  }, [fetchResult, setSales]);

  // 注文日 年
  const handleChangeYear = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, salesYear: value }));
  };

  // 注文日 月
  const handleChangeMonth = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, salesMonth: value }));
  };

  // 検索押下
  const handleSearchSales = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // クリア押下
  const handleResetSearchCriteria = () =>
    setSearchCriteria(INIT_SEARCH_CRITERIA.NLF006);

  // チェックした明細出力
  const handleDetailedOutput = () => {
    downloadCsvNlf006(sales, window.location.pathname);
  };

  // 一つの明細出力
  const handleOneDetailedOutput = (salesId: string) => {
    const copiedSales = sales.map((sale) => ({
      ...sale,
      checked: sale.id === salesId,
    }));
    downloadCsvNlf006(copiedSales, window.location.pathname);
  };

  // 詳細ボタン押下
  const handleOpenSalesDetail = (id: string, orderTime: string) => {
    setSelectSalesData({
      selectSalesId: id,
      selectSalesOrderTime: orderTime,
    });
    setIsSalesDialogOpen(true);
  };

  const handleCheckUserId = (checked: boolean, userId: string) => {
    const newUsers = sales.map((sale) => {
      if (sale.id === userId) {
        return { ...sale, checked };
      }

      return sale;
    });

    setSales(newUsers);
    setIsCheckAllUserIds(newUsers.every((user) => user.checked));

    setIsChecked(newUsers.some((user) => user.checked));
  };

  const handleCheckIsAllUserIds = (checked: boolean) => {
    setIsCheckAllUserIds(checked);
    const newUsers = sales.map((sale) => ({ ...sale, checked }));
    setSales(newUsers);

    setIsChecked(checked);
  };

  const isCheckEachId = React.useMemo(
    () => sales.some((sale) => sale.checked === true),
    [sales],
  );

  const isCheckAllId = React.useMemo(
    () => sales.every((sale) => sale.checked === true),
    [sales],
  );

  const isIndeterminate = React.useMemo(
    () =>
      (!isCheckAllUserIds && isCheckEachId) ||
      (isCheckAllUserIds && !isCheckAllId),
    [isCheckAllUserIds, isCheckEachId, isCheckAllId],
  );

  // 権限チェック
  const hasDetailRole = useCheckHasRole(ROLE_KEY.EC_SALES_DETAIL);

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={5}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <ToggleButton
              size="large"
              items={ADMIN_ORDERS_TOP_TAB_ITEMS}
              onClick={handleSelectECMgmtSubTab}
              selectedButton="2"
            />
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column">
                <LayoutBox align="center" justify="around" gap="3x" fullWidth>
                  <FormSet label="売り上月" labelWidth="96px" base>
                    <LayoutBox gap="2x">
                      <LayoutBox>
                        <Dropdown
                          items={selectSalesYear}
                          width="122px"
                          placeholder="年"
                          value={searchCriteria.salesYear}
                          onChange={handleChangeYear}
                        />
                        <Dropdown
                          items={MONTH_LIST}
                          width="74px"
                          placeholder="月"
                          value={searchCriteria.salesMonth}
                          onChange={handleChangeMonth}
                        />
                      </LayoutBox>
                      <LayoutBox>
                        <Button
                          type="sub"
                          color="neutral"
                          size="large"
                          width="75px"
                          onClick={handleResetSearchCriteria}
                        >
                          クリア
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          width="75px"
                          onClick={handleSearchSales}
                        >
                          検索
                        </Button>
                      </LayoutBox>
                    </LayoutBox>
                  </FormSet>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <LayoutBox align="center" justify="start" fullWidth>
                  <Button
                    type="secondary"
                    size="medium"
                    icon="download"
                    width="98px"
                    onClick={handleDetailedOutput}
                    disabled={!isChecked || !hasDetailRole}
                  >
                    明細出力
                  </Button>
                </LayoutBox>
                <Table
                  type="condensed"
                  width="100%"
                  roleKey={ROLE_KEY.EC_SALES_BROWSING}
                  head={
                    <TableRow>
                      <TableColumn width="550.33px" id="col-1">
                        <LayoutBox align="center" justify="start">
                          <Checkbox
                            checked={isCheckAllUserIds}
                            label="売り上月"
                            indeterminate={isIndeterminate}
                            onChecked={(checked) =>
                              handleCheckIsAllUserIds(checked)
                            }
                          />
                        </LayoutBox>
                      </TableColumn>
                      <TableColumn
                        width="550.33px"
                        id="col-2"
                        textAlign="right"
                      >
                        件数
                      </TableColumn>
                      <TableColumn
                        width="550.33px"
                        id="col-3"
                        textAlign="right"
                      >
                        売り上げ
                      </TableColumn>
                      <TableColumn width="96px" id="col-4" />
                      <TableColumn width="92px" id="col-5" />
                    </TableRow>
                  }
                  body={sales.map((sale) => (
                    <TableRow key={sale.id} isSelected={sale.checked}>
                      <TableCell>
                        <Checkbox
                          label={sale.paymentDate}
                          checked={sale.checked}
                          onChecked={(checked) =>
                            handleCheckUserId(checked, sale.id)
                          }
                        />
                      </TableCell>
                      <TableCell textAlign="right">{sale.count}</TableCell>
                      <TableCell textAlign="right">
                        {sale.totalAmount}
                      </TableCell>
                      <TableCell>
                        <Button
                          type="secondary"
                          size="small"
                          width="78px"
                          icon="download"
                          onClick={() => {
                            handleOneDetailedOutput(sale.id);
                            setIsOpen(false);
                          }}
                          disabled={!hasDetailRole}
                        >
                          明細出力
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          type="sub"
                          size="small"
                          width="38px"
                          onClick={() =>
                            handleOpenSalesDetail(sale.id, sale.paymentDate)
                          }
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isSalesDialogOpen && (
        <NLF007
          isOpen={isSalesDialogOpen}
          setIsOpen={setIsSalesDialogOpen}
          selectSalesData={selectSalesData}
          salesIdArray={salesIdArray}
        />
      )}
    </>
  );
};

export default NLF006;
