import { useState, useEffect } from "react";

import {
  getMongoDb,
  getAggregateNlf001,
  getAggregateAllOrderYear,
} from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import {
  Collection,
  INIT_SEARCH_CRITERIA,
  ROLE_KEY,
  SEARCH_INFO,
  SORT_EXAMPLES,
} from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import useForceUpdate from "../../common/useForceUpdate";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import {
  closeActionLoading,
  openDeleteActionLoading,
} from "../../base/useLoadingAction";
import {
  extractUniqueYearsFromData,
  useMemoStatusArr,
} from "../../../utils/utils";
import {
  checkActionErr,
  checkFetchErr,
} from "../../../contexts/CustomErrorBoundary";
import {
  checkMasterRole,
  useCheckHasRole,
} from "../../common/useMasterRoleDbActions";

type Nlf001FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLG001;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLF001;
};

type SettlementTimeType = {
  settlement_time: Date;
};

type YearType = {
  value: string;
  label: string;
};

const useNlf001DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Nlf001FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchResult, setFetchResult] = useStateCustomObj<
    ordersNlf001StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [actionError, setActionError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const [selectOrderYear, setSelectOrderYear] = useState<YearType[]>([]);

  const { id, patientName, orderYear, orderMonth, userId } =
    submittedSearchCriteria;

  // ステータスのチェック状態
  const statusArray = useMemoStatusArr(
    SEARCH_INFO.NLF001_STATUS,
    submittedSearchCriteria.status,
  );

  // 配達のチェック状態
  const deliveryMethodArray = useMemoStatusArr(
    SEARCH_INFO.NLF001_DELIVERY,
    submittedSearchCriteria.deliveryMethod,
  );

  // sortを取得 未選択の場合は更新日の降順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "updated_at", sortDirection = "desc" } = currentSort || {};

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.EC_ORDER_BROWSING);

  // 一覧取得
  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.ORDERS);
        const aggregate = getAggregateNlf001(
          id,
          patientName,
          orderYear,
          orderMonth,
          statusArray,
          deliveryMethodArray,
          userId,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<ordersNlf001StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    // 権限があるときのみデータ取得
    if (hasBrowsingRole) void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    setFetchResult,
    deliveryMethodArray,
    id,
    orderMonth,
    orderYear,
    patientName,
    statusArray,
    forceUpdate.forceUpdateCount,
    hasBrowsingRole,
    userId,
  ]);

  // 注文日のセレクトボックス
  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.ORDERS);
        const aggregate = getAggregateAllOrderYear();
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as SettlementTimeType[];

        setSelectOrderYear(
          extractUniqueYearsFromData({
            key: "settlement_time",
            data: aggregateResult,
          }),
        );
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount]);

  // 注文削除処理
  const handleOrderDelete = (orderId: string) => {
    void (async () => {
      openDeleteActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.EC_ORDER_CANCEL, currentUser);

        await currentUser?.functions["stripe/ec/cancelPayment"](orderId);
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    fetchResult,
    fetchError,
    handleOrderDelete,
    actionError,
    selectOrderYear,
  };
};

export default useNlf001DbActions;
