import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import LayoutBox from "../LayoutBox/LayoutBox";
import Text from "../Text/Text";
import Spinner from "../Loading/Spinner";

export type ImageProps = {
  url?: string;
  alt?: string;
  width?: string;
  height?: string;
  type?:
    | "simple"
    | "avatar"
    | "picture"
    | "imageUpload"
    | "imageUploadFullPreview";
  placholderText?: string;
  onClick?: () => void | ((index: number) => void);
  className?: string;
  loading?: boolean;
};

const Image = ({
  url = "",
  alt = "",
  width = "85px",
  height = "48px",
  type = "simple",
  placholderText = "",
  onClick,
  className,
  loading = false,
}: ImageProps) => {
  const imageClass = className ? `image ${className}` : "image";
  if (url === "" && type === "simple") {
    return (
      <div className="image--placeholder" style={{ width, height }}>
        {placholderText && (
          <LayoutBox justify="center" align="center" fullWidth>
            <Text size="xl" color="neutralLight">
              {placholderText}
            </Text>
          </LayoutBox>
        )}
      </div>
    );
  }
  if (url === "" && type === "avatar") {
    return (
      <div className="image--avatar">
        <Icon
          icon="person"
          className="icon--avatar"
          size="xl"
          color="#B4BBC6"
        />
        {placholderText && (
          <LayoutBox justify="center" align="center" fullWidth>
            <Text size="xl">{placholderText}</Text>
          </LayoutBox>
        )}
      </div>
    );
  }
  if (url === "" && type === "picture") {
    return (
      <div className="image--picture">
        <Icon
          icon="image"
          className="icon--picture"
          size="xl"
          color="#B4BBC6"
        />
        {placholderText && (
          <LayoutBox justify="center" align="center" fullWidth>
            <Text size="xl">{placholderText}</Text>
          </LayoutBox>
        )}
      </div>
    );
  }

  if (url && type === "imageUpload" && !loading) {
    return (
      <div
        className={url === "" ? "image--picture" : "image-upload--image"}
        style={{
          backgroundImage: `url(${url})`,
          width,
          height,
        }}
      >
        <LayoutBox fullWidth align="end" justify="end">
          <Button
            size="small"
            color="neutral"
            type="sub"
            onClick={onClick}
            icon="close"
          />
        </LayoutBox>
        {placholderText && (
          <LayoutBox justify="center" align="center" fullWidth>
            <Text size="xl">{placholderText}</Text>
          </LayoutBox>
        )}
      </div>
    );
  }

  if (url && type === "imageUploadFullPreview" && loading) {
    return (
      <div
        className="image-preview--loading"
        style={{
          width,
          height,
        }}
      >
        <Spinner size="large" />
      </div>
    );
  }

  if (url && type === "imageUploadFullPreview" && !loading) {
    return (
      <div className="image-preview">
        <img
          className={imageClass}
          src={url}
          alt={alt}
          height={height}
          width={width}
        />
        <Button
          className="image-preview--button"
          size="small"
          color="neutral"
          type="sub"
          onClick={onClick}
          icon="close"
        />
      </div>
    );
  }

  if (url && type === "avatar") {
    return (
      <div className="image--avatar_icon">
        <img className="image--avatar_icon" src={url} alt={alt} />
      </div>
    );
  }

  return (
    <button type="button" onClick={onClick} className="image--button">
      <img
        className={imageClass}
        src={url}
        alt={alt}
        height={height}
        width={width}
      />
    </button>
  );
};

Image.defaultProps = {
  url: "",
  alt: "",
  height: "48px",
  width: "85px",
  type: "simple",
  placholderText: "",
  onClick: () => {},
  className: "",
  loading: false,
};

export default Image;
