import React from "react";

export type CheckboxProps = {
  checked?: boolean;
  label?: string | JSX.Element;
  disabled?: boolean;
  indeterminate?: boolean;
  onChecked?: (checked: boolean) => void;
  className?: string;
};

const Checkbox = ({
  checked = false,
  label = "",
  indeterminate = false,
  disabled = false,
  onChecked = () => {},
  className = "",
}: CheckboxProps) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null!);
  const [isChecked, setIsChecked] = React.useState(checked);

  React.useEffect(() => {
    if (indeterminate) {
      checkboxRef.current.indeterminate = true;
    } else {
      checkboxRef.current.indeterminate = false;
    }

    setIsChecked(checked);
  }, [indeterminate, checked]);

  const componentClass = React.useMemo(() => {
    const disabledClass = disabled ? ["checkbox--disabled"] : [];

    return ["checkbox", ...disabledClass].join(" ");
  }, [disabled]);

  const _onChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
      onChecked(e.currentTarget.checked);
    },
    [onChecked],
  );

  return (
    <label className={`${componentClass} ${className}`}>
      <input
        ref={checkboxRef}
        tabIndex={-1}
        checked={isChecked}
        type="checkbox"
        className="checkbox__input"
        disabled={disabled}
        onChange={_onChecked}
      />
      {label && <span>{label}</span>}
    </label>
  );
};

Checkbox.defaultProps = {
  checked: false,
  label: "",
  disabled: false,
  indeterminate: false,
  onChecked: () => {},
  className: "",
};

export default Checkbox;
