import { useState, useEffect } from "react";

import { getMongoDb, getAggregateOneHospital } from "../../utils/query";
import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";
import useForceUpdate from "./useForceUpdate";
import useId from "./useId";
import {
  checkFetchErr,
  redirectToNoDataPage,
} from "../../contexts/CustomErrorBoundary";

// 特定IDの医療機関情報を取得
const useOneHospitalFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [id] = useId();

  const [fetchResult, setFetchResult] =
    useState<hospitalNli016StateType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 特定IDのhospitalを取得
        const mongoDb = getMongoDb(currentUser, Collection.HOSPITALS);
        const conditions = { _id: id };
        const aggregate = getAggregateOneHospital(conditions);
        const result = (await mongoDb.aggregate(
          aggregate,
        )) as hospitalNli016StateType[];

        if (result.length < 1) redirectToNoDataPage();
        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser, forceUpdate.forceUpdateCount, id]);

  return { fetchError, fetchResult };
};

export default useOneHospitalFetch;
