import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

// paginationコンポーネントで管理する値
const stateReactiveVar = createReactiveVar<boolean>(false);
const useSwitch = (): ReactiveVarHooks<boolean> =>
  useReactiveVarHooks(stateReactiveVar);

export default useSwitch;
