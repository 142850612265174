import { useState, useEffect } from "react";

import { getMongoDb } from "../../utils/query";
import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";

import { checkFetchErr } from "../../contexts/CustomErrorBoundary";

// settings取得
const useSettingsFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [fetchSettingsResult, setFetchSettingsResult] =
    useState<SettingsType>();
  const [fetchSettingsError, setFetchSettingsError] = useState<Error | null>(
    null,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collection = getMongoDb(currentUser, Collection.SETTINGS);
        const settingsResult = await collection.find();
        setFetchSettingsResult(settingsResult[0] as SettingsType);
      } catch (err) {
        setFetchSettingsError(checkFetchErr(err));
      }
    };

    void fetchData();
  }, [currentUser]);

  return { fetchSettingsError, fetchSettingsResult };
};

export default useSettingsFetch;
