import React from "react";
import Icon from "../Icon/Icon";

export type InputProps = {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  leftIcon?: string;
  rightButton?: "" | "clear" | "password" | "copy";
  password?: boolean;
  width?: string;
  multiLine?: boolean;
  error?: boolean;
  size?: "default" | "small";
  multiLineHeight?: string;
  textAlign?: "start" | "center" | "end";
  autoComplete?: string;
  onChange?: (value: string) => void;
  copied?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const Input = ({
  value,
  disabled = false,
  placeholder,
  leftIcon,
  rightButton,
  password = false,
  width = "224px",
  multiLine = false,
  error = false,
  size = "default",
  multiLineHeight = "120px",
  textAlign,
  autoComplete,
  onChange,
  copied,
  onFocus,
  onBlur,
}: InputProps) => {
  const [innerValue, setInnerValue] = React.useState(value);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const _rightButton = React.useMemo(() => {
    switch (rightButton) {
      case "clear":
        return "cancel";
      case "password":
        return !password ? "" : showPassword ? "visibility" : "visibility_off";
      case "copy":
        return "content_copy";
      default:
        return "";
    }
  }, [password, rightButton, showPassword]);

  const componentClass = React.useMemo(() => {
    const withLeftIconClass = leftIcon ? ["input--with-left-icon"] : [];
    const withRightButtonClass = rightButton ? ["input--with-right-icon"] : [];
    const errorClass = error ? ["input--error"] : [];
    const sizeClass =
      size === "default" ? ["input--default"] : ["input--small"];
    const disabledClass = disabled ? ["input--disabled"] : [];
    const multiLineClass = multiLine ? ["input--multiLine"] : [];
    const textAlignClass = textAlign ? [`layout-box--align-${textAlign}`] : [];

    return [
      "input",
      ...withLeftIconClass,
      ...withRightButtonClass,
      ...errorClass,
      ...sizeClass,
      ...disabledClass,
      ...multiLineClass,
      ...textAlignClass,
    ].join(" ");
  }, [disabled, error, leftIcon, multiLine, rightButton, size, textAlign]);

  const onClickRightButton = React.useCallback(() => {
    switch (rightButton) {
      case "clear":
        // eslint-disable-next-line no-unused-expressions
        onChange && onChange("");
        setInnerValue("");
        break;
      case "password":
        setShowPassword((prevState) => !prevState);
        break;
      case "copy":
        if (navigator.clipboard && innerValue) {
          navigator.clipboard.writeText(innerValue).catch((err) => {
            throw err;
          });
        }
        // eslint-disable-next-line no-unused-expressions
        copied && copied();
        break;
      default:
        break;
    }

    return "";
  }, [copied, setInnerValue, rightButton, innerValue, onChange]);

  const inputType = React.useMemo(
    () => (password && !showPassword ? "password" : "text"),
    [password, showPassword],
  );

  const showLeftIcon = React.useMemo(
    () => leftIcon && !multiLine,
    [leftIcon, multiLine],
  );

  const showRightButton = React.useMemo(
    () => rightButton && !multiLine,
    [multiLine, rightButton],
  );

  const _onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // eslint-disable-next-line no-unused-expressions
      onChange && onChange(e.currentTarget.value);
      setInnerValue(e.currentTarget.value);
    },
    [onChange],
  );

  return (
    <div className={componentClass} style={{ width }}>
      {showLeftIcon && (
        <div className="input__icon input__icon--left">
          <Icon icon={leftIcon} size="xs" />
        </div>
      )}
      {error && (
        <div className="input__icon input__icon--error">
          <Icon icon="error" size="xs" />
        </div>
      )}
      {multiLine ? (
        <textarea
          value={innerValue}
          className="input__textarea"
          placeholder={placeholder}
          disabled={disabled}
          style={{ height: multiLineHeight }}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={_onChange}
        />
      ) : (
        <input
          value={innerValue}
          className="input__input-area"
          type={inputType}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={_onChange}
          style={{ textAlign }}
          autoComplete={autoComplete}
        />
      )}
      {showRightButton && (
        <button
          type="button"
          className="input__right-button"
          onClick={onClickRightButton}
        >
          <Icon icon={_rightButton} size="xs" />
        </button>
      )}
    </div>
  );
};

Input.defaultProps = {
  value: "",
  disabled: false,
  placeholder: "",
  leftIcon: "",
  rightButton: "",
  password: false,
  width: "224px",
  multiLine: false,
  error: false,
  size: "default",
  multiLineHeight: "120px",
  textAlign: "",
  autoComplete: undefined,
  onChange: () => {},
  copied: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export default Input;
