import React from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Dialog from "../components/Dialog/Dialog";
import FormSet from "../components/FormSet/FormSet";
import Input from "../components/Input/Input";
import useNli034Input, {
  checkCampaignInputError,
  setAfterCampaignType,
  setCampaignCode,
  setCampaignName,
  setDiscountPrice,
  setLimit,
  setPaymentSpan,
  setSelectedDate,
  setTerm,
} from "../hooks/input/useNli034Input";
import RadioButton from "../components/RadioButton/RadioButton";
import Text from "../components/Text/Text";
import DatePicker from "../components/DatePicker/DatePicker";
import useNli034Dialog, {
  closeNli034Dialog,
} from "../hooks/pages/NLI034/useNli034Dialog";
import useNli034DbActions from "../hooks/pages/NLI034/useNli034DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";

const radioButtonOptions = [
  {
    label: "退会",
    value: "0",
  },
  {
    label: "通常料金で継続",
    value: "1",
  },
];

const NLI034 = () => {
  const [isNli034Open] = useNli034Dialog();
  const { handleRegisterCampaign, actionError } = useNli034DbActions();
  const [
    {
      campaignCode,
      campaignName,
      paymentSpan,
      discountPrice,
      term,
      selectedDate,
      limit,
      afterCampaignType,
      campaignCodeError,
    },
  ] = useNli034Input();

  // エラー処理
  useCheckErrorThrowError([actionError]);

  // 登録ボタン
  const handleRegister = React.useCallback(() => {
    // 登録
    handleRegisterCampaign();
  }, [handleRegisterCampaign]);

  return (
    <>
      <Dialog
        open={isNli034Open}
        closeDialog={closeNli034Dialog}
        title="新規キャンペーン登録"
        height="785px"
        footerRight={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                type="sub"
                color="neutral"
                size="large"
                width="108px"
                onClick={closeNli034Dialog}
              >
                キャンセル
              </Button>
              <Button
                type="primary"
                size="large"
                width="64px"
                onClick={handleRegister}
                disabled={checkCampaignInputError()}
              >
                登録
              </Button>
            </LayoutBox>
          </>
        }
      >
        <LayoutBox direction="column" fullWidth gap="2x">
          <FormSet
            label="キャンペーン名"
            vertical
            errorTextList={[campaignName.validationMessage]}
          >
            <Input width="100%" onChange={(value) => setCampaignName(value)} />
          </FormSet>
          <FormSet
            label="キャンペーンコード"
            vertical
            errorTextList={[
              campaignCodeError || campaignCode.validationMessage,
            ]}
          >
            <Input width="190px" onChange={(value) => setCampaignCode(value)} />
          </FormSet>
          <FormSet label="月額/年額" vertical>
            <LayoutBox gap="2x" fullWidth>
              <RadioButton
                checked={paymentSpan === "1"}
                onSelect={() => setPaymentSpan("1")}
              >
                月額
              </RadioButton>
              <RadioButton
                checked={paymentSpan === "2"}
                onSelect={() => setPaymentSpan("2")}
              >
                年額
              </RadioButton>
            </LayoutBox>
          </FormSet>
          <FormSet
            label="値引き額"
            vertical
            errorTextList={[discountPrice.validationMessage]}
          >
            <LayoutBox align="center">
              <Input
                width="86px"
                onChange={(value) => setDiscountPrice(value)}
              />
              <Text>円</Text>
            </LayoutBox>
          </FormSet>

          <FormSet
            label="値引き適用期間"
            vertical
            errorTextList={[term.validationMessage]}
          >
            <LayoutBox align="center">
              <Input width="86px" onChange={(value) => setTerm(value)} />
              <Text>{paymentSpan === "2" ? "年" : "ヶ月"}</Text>
            </LayoutBox>
          </FormSet>
          <FormSet label="キャンペーン終了日" vertical>
            <LayoutBox align="center">
              <DatePicker
                width="178px"
                selectedDate={selectedDate || undefined}
                onChangeDate={setSelectedDate}
              />
            </LayoutBox>
          </FormSet>
          <FormSet label="キャンペーン終了後" vertical>
            <RadioButtonGroup
              name="radio1"
              items={radioButtonOptions}
              onChange={setAfterCampaignType}
              selectedValue={afterCampaignType}
              radioGap="16px"
            />
          </FormSet>
          <FormSet
            label="利用上限"
            vertical
            errorTextList={[limit.validationMessage]}
          >
            <LayoutBox align="center">
              <Input width="86px" onChange={(value) => setLimit(value)} />
              <Text>人</Text>
            </LayoutBox>
          </FormSet>
        </LayoutBox>
        <div className="util-mb-8" />
      </Dialog>
    </>
  );
};

export default NLI034;
