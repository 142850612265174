import React from "react";
import { Navigate } from "react-router-dom";

import { ADMIN, COMMON, HOSPITAL } from "../constants/pagePaths";
import { useRealmApp } from "../contexts/RealmApp";
import { UserStatusID, UserTypeID } from "../constants/common";

// ログイン必須画面
const RequiredLoginElement: React.FC<{
  element: JSX.Element;
  path: string;
}> = ({ element, path }) => {
  const realmAppContext = useRealmApp();
  const UserAccessPaths = {
    [UserTypeID.ADMIN.toString()]: Object.values(ADMIN),
    [UserTypeID.HOSPITAL.toString()]: Object.values(HOSPITAL),
  };
  const isLogin = Boolean(realmAppContext.currentUser);

  // ステータスチェック
  const statusObject = realmAppContext.currentUser?.customData
    .status as usersNumberIntType;
  const statusString = statusObject ? statusObject.$numberInt : null;
  const status = statusString ? Number(statusString) : null;

  if (status === UserStatusID.SUSPENDED) {
    // ステータスエラー
    return <Navigate to={COMMON.NLJ007} />;
  }

  // ユーザーチェック
  const userTypeObject = realmAppContext.currentUser?.customData
    .user_type as usersNumberIntType;
  const userTypeString = userTypeObject ? userTypeObject.$numberInt : "";

  // ユーザタイプが存在しない場合、ログイン画面
  if (userTypeString === "") {
    return <Navigate to={ADMIN.NLA001} />;
  }

  // 管理者、病院それぞれの画面に遷移しようとしてない場合、エラーページ
  const isInvalid = !(UserAccessPaths[userTypeString] as string[])?.includes(
    path,
  );
  if (isInvalid) {
    return <Navigate to={COMMON.NOT_FOUND} />;
  }

  return isLogin ? element : <Navigate to={COMMON.NLJ007} />;
};

export default RequiredLoginElement;
