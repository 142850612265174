import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

const initialState = localStorage.getItem("organizationId") || "";
const stateReactiveVar = createReactiveVar<string>(initialState);

const useOrganizationId = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setOrganizationId = (id: string) => {
  stateReactiveVar(id);
  localStorage.setItem("organizationId", id);
};

export default useOrganizationId;
