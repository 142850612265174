import { ADMIN, HOSPITAL } from "./pagePaths";

export enum ResponseStatusCode {
  OK = 200,
  NG = 400,
  ACCEPT = 202,
  CREATED = 201,
}

export enum ErrorCode {
  NORMAL = 0,
  BAD_REQUEST = 1,
  NOT_UPDATED = 2,
}

export enum Collection {
  ADMINS = "admins",
  CAMPAIGNS = "campaigns",
  HOSPITAL_USERS = "hospital_users",
  HOSPITALS = "hospitals",
  ITEM_TAGS = "item_tags",
  ITEMS = "items",
  NOTIFICATIONS = "notifications",
  ORDERS = "orders",
  PATIENT_BRAINSUITES = "patient_brainsuites",
  PATIENT_INTERVIEWS = "patient_interviews",
  PATIENT_NOUKNOWS = "patient_nouknows",
  PATIENTS = "patients",
  SALES = "sales",
  SKUS = "skus",
  SEQUENCE = "sequence",
  PATIENT_MODALITY_BOOKS = "patient_modality_books",
  PATIENT_ONLINE_TESTS = "patient_online_tests",
  OPTION_CONFIGS = "option_configs",
  OPTION_CONTENTS = "option_contents",
  USERS = "users",
  EVENT_HISTORIES = "event_histories",
  MASTER_ROLES = "master_roles",
  SETTINGS = "settings",
  YOSES = "yoses",
  CHATS = "chats",
  KARTES = "kartes",
  HOSPITAL_PAYMENTS = "hospital_payments",
  INTERVIEW_DEFAULT_SCHEDULES = "interview_default_schedules",
  INTERVIEW_DAILY_SCHEDULES = "interview_daily_schedules",
  ORGANIZATIONS = "organizations",
  CAMPAIGN_COUPONS = "campaign_coupons",
  PATIENT_BRAIN_CHECKS = "patient_brain_checks",
  PATIENT_INVITATIONS = "patient_invitations",
  SHIPPINGS = "shippings",
}

export const INIT_PAGE = 1;
export const INIT_PER_PAGE = 10;

// ソート可能なカラム情報初期値
export const SORT_EXAMPLES = {
  NLC002: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    { id: "col-7", sortDirection: "", colName: "created_at" },
  ],
  NLC003: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    { id: "col-7", sortDirection: "", colName: "insuranse_entry_date" },
  ],
  NLC004: [
    { id: "col-1", sortDirection: "", colName: "user_id" },
    { id: "col-2", sortDirection: "", colName: "name_sei_kana" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "patient_nouknow_info.finished_at",
    },
    {
      id: "col-5",
      sortDirection: "",
      colName: "patient_nouknow_info.bpi1_rank",
    },
    { id: "col-6", sortDirection: "", colName: "patient_nouknow_info.status" },
  ],
  NLC005: [
    { id: "col-1", sortDirection: "", colName: "user_info.user_id" },
    { id: "col-2", sortDirection: "", colName: "user_info.name_sei_kana" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "patient_modality_book_info.mri_status",
    },
    {
      id: "col-6",
      sortDirection: "",
      colName: "patient_modality_book_info.fix_book_date_start",
    },
  ],
  NLC006: [
    { id: "col-1", sortDirection: "", colName: "user_id" },
    { id: "col-2", sortDirection: "", colName: "name_sei_kana" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "patient_modality_book_info.pet_status",
    },
    {
      id: "col-6",
      sortDirection: "",
      colName: "patient_modality_book_info.fix_book_date_start",
    },
  ],
  NLC007: [
    { id: "col-1", sortDirection: "", colName: "user_id" },
    { id: "col-2", sortDirection: "", colName: "name_sei_kana" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "interviews_info.status",
    },
  ],
  NLC029: [
    { id: "col-2", sortDirection: "", colName: "user_info.name_sei_kana" },
    { id: "col-4", sortDirection: "", colName: "status" },
  ],
  NLC033: [
    { id: "col-1", sortDirection: "", colName: "user_info.user_id" },
    { id: "col-2", sortDirection: "", colName: "user_info.name_sei_kana" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "is_read",
    },
    {
      id: "col-5",
      sortDirection: "",
      colName: "commented_user",
    },
    {
      id: "col-6",
      sortDirection: "",
      colName: "register_date",
    },
    {
      id: "col-7",
      sortDirection: "",
      colName: "comment",
    },
    {
      id: "col-8",
      sortDirection: "",
      colName: "check_list",
    },
  ],
  NLC035: [
    { id: "col-1", sortDirection: "", colName: "user_id" },
    { id: "col-2", sortDirection: "", colName: "name_sei_kana" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "patient_info.patient_type",
    },
    {
      id: "col-5",
      sortDirection: "",
      colName: "status",
    },
  ],
  NLD001: [
    { id: "col-1", sortDirection: "", colName: "patient_id" },
    { id: "col-2", sortDirection: "", colName: "full_name" },
    // 2次以降フェーズ対応: No162 ステータスはまだ連携されていないので連携され次第対応
    { id: "col-3", sortDirection: "", colName: "status" },
    { id: "col-4", sortDirection: "", colName: "status" },
    { id: "col-7", sortDirection: "", colName: "status" },
  ],
  NLD002: [
    { id: "col-1", sortDirection: "", colName: "hospital_info.hospital_name" },
    { id: "col-2", sortDirection: "", colName: "prefecture" },
    { id: "col-3", sortDirection: "", colName: "user_info.name" },
    { id: "col-5", sortDirection: "", colName: "category" },
  ],
  NLD003: [
    { id: "col-3", sortDirection: "", colName: "category" },
    {
      id: "col-4",
      sortDirection: "",
      colName: "patient_modality_book_info.fix_book_date_start",
    },
  ],
  NLD004: [
    { id: "col-1", sortDirection: "", colName: "user_info.user_id" },
    { id: "col-2", sortDirection: "", colName: "user_info.name_sei_kana" },
    { id: "col-4", sortDirection: "", colName: "category" },
  ],
  NLD005: [
    { id: "col-1", sortDirection: "", colName: "user_info.user_id" },
    { id: "col-2", sortDirection: "", colName: "user_info.name_sei_kana" },
    { id: "col-4", sortDirection: "", colName: "category" },
    { id: "col-5", sortDirection: "", colName: "amount" },
    { id: "col-6", sortDirection: "", colName: "activation_date" },
    { id: "col-7", sortDirection: "", colName: "payment_date" },
    { id: "col-8", sortDirection: "", colName: "receipt_issue_date" },
  ],
  NLF001: [
    { id: "col-3", sortDirection: "", colName: "user_info.user_id" },
    { id: "col-4", sortDirection: "", colName: "user_info.full_name" },
    { id: "col-5", sortDirection: "", colName: "status" },
    { id: "col-8", sortDirection: "", colName: "settlement_time" },
    { id: "col-9", sortDirection: "", colName: "delivery_method" },
  ],
  NLG001: [
    { id: "col-2", sortDirection: "", colName: "status" },
    { id: "col-3", sortDirection: "", colName: "deliveried_at" },
    { id: "col-4", sortDirection: "", colName: "last_updated_at" },
    { id: "col-5", sortDirection: "", colName: "last_updated_admin_id" },
  ],
  NLI017: [
    { id: "col-1", sortDirection: "", colName: "_id" },
    { id: "col-2", sortDirection: "", colName: "doctor_name" },
  ],
  NLI021: [
    { id: "col-1", sortDirection: "", colName: "user_id" },
    { id: "col-2", sortDirection: "", colName: "user_info.full_name" },
    { id: "col-3", sortDirection: "", colName: "status" },
    { id: "col-4", sortDirection: "", colName: "user_info.activation_date" },
  ],
  MIB001: [
    { id: "col-1", sortDirection: "", colName: "user_id" },
    { id: "col-2", sortDirection: "", colName: "patient_name" },
    { id: "col-3", sortDirection: "", colName: "modality" },
    { id: "col-5", sortDirection: "", colName: "created_at" },
    { id: "col-6", sortDirection: "", colName: "status" },
  ],
  MIC001: [
    { id: "col-1", sortDirection: "", colName: "user_info.user_id" },
    { id: "col-2", sortDirection: "", colName: "user_info.patient_name" },
    { id: "col-3", sortDirection: "", colName: "patient_info.tel" },
    { id: "col-4", sortDirection: "", colName: "modality" },
    { id: "col-5", sortDirection: "", colName: "status" },
    { id: "col-6", sortDirection: "", colName: "fix_book_date_start" },
  ],
  MID001: [
    { id: "col-1", sortDirection: "", colName: "user_id" },
    { id: "col-2", sortDirection: "", colName: "name_sei_kana" },
  ],
};
type Status = {
  [key: string]: boolean;
};
export const INIT_SEARCH_CRITERIA = {
  NLB001: {
    number: {
      selectedToggle: "1",
      searchDay: new Date(),
    },
    user: {
      selectedMembershipToggle: "3",
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      day: new Date(),
    },
    // 再生回数無し
    // yose: {
    //   selectedYoseToggle: "3",
    //   year: new Date().getFullYear().toString(),
    //   month: (new Date().getMonth() + 1).toString(),
    //   day: new Date(),
    // },
    appointment: {
      selectedIndex: 0,
      selectedAppointmentToggle: "3",
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      day: new Date(),
    },
    task: {
      category: "1",
    },
  },
  // 検索欄入力情報初期値 NLC003でも使用可
  NLC002: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      isUnsupported: true,
      isCompleted: true,
      isNG: true,
    },
  },
  NLC004: {
    id: "",
    name: "",
    phoneNum: "",
    reportStatus: {
      isNotTested: false,
      isCompleted: true,
      isUploaded: true,
      isConfirmed: true,
      isReported: true,
    } as Status,
  },
  NLC005: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      hasProblem: true,
      hasNoProblem: true,
      isReserved: true,
      hasReservationConfirmed: true,
      hasExamined: true,
      isCompletedImageNotLinked: true,
      isCompletedUnderAIDiagnosis: true,
      isCompletedAIDiagnose: true,
      isCompletedDocDiagnosed: true,
      isUserSupported: true,
      withdrawal: false,
      isOnlineMedicalComplete: true,
      isNoExamination: true,
    } as Status,
  },
  NLC006: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      isReserved: true,
      hasReservationConfirmed: true,
      inspected: true,
      diagnosed: true,
      isUserReported: true,
      cancellation: false,
      isNoExamination: true,
    } as Status,
  },
  NLC007: {
    id: "",
    name: "",
    phoneNum: "",
    manager: "すべて",
    status: {
      isNewReservation: true,
      isConfirmedReservation: true,
      isSettled: true,
      isReservationUnsettled: true,
      isCanceled: false,
    } as Status,
  },
  NLC029: {
    name: "",
    userId: "",
    phoneNum: "",
    startExamDate: undefined as Date | undefined,
    endExamDate: undefined as Date | undefined,
    brainCheckStatus: {
      isNotTested: true,
      isCompleted: true,
      isReported: true,
    } as Status,
  },
  NLC033: {
    name: "",
    userId: "",
    userType: {
      isPatient: true,
      isAdmin: true,
    } as Status,
    userStatus: {
      isRead: true,
      isUnread: true,
    } as Status,
    checkList: {
      isAttend: false,
    } as Status,
  },
  NLC035: {
    name: "",
    userId: "",
    email: "",
    phoneNum: "",
    patientType: {
      isMember: true,
      isInvitee: true,
      isPresent: true,
      isOrg: true,
    } as Status,
    userStatus: {
      isActive: true,
      isCancel: true,
      isSuspended: true,
    } as Status,
  },
  NLD001: {
    id: "",
    name: "",
    phoneNum: "",
    status: {
      isActive: true,
      isNoCoveredInsurance: true,
      hasMRIExam: true,
      hasFDGPETScan: true,
    },
  },
  NLD002: {
    hospitalName: "",
    name: "",
    phoneNum: "",
    status: {
      isKnow: true,
      isMRIExam: true,
      isFDGPETScan: true,
      isHealthCheck: true,
      isHealthConsultation: true,
    },
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
  },
  NLD003: {
    name: "",
    status: {
      isKnow: true,
      isMRIExam: true,
      isFDGPETScan: true,
      isHealthCheck: true,
      isHealthConsultation: true,
    },
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
  },
  NLD004: {
    id: "",
    name: "",
    userId: "",
    email: "",
    phoneNum: "",
    authorizationStartDate: undefined as Date | undefined,
    authorizationEndDate: undefined as Date | undefined,
    paymentStartDate: undefined as Date | undefined,
    paymentEndDate: undefined as Date | undefined,
    status: {
      fee: true,
      interview: true,
      isFDGPETScan: true,
      ec: true,
    },
    statusPayment: {
      creditAcquisition: true,
      creditCancellation: true,
      sold: true,
      saleCancellation: true,
      saleFailure: true,
    },
  },
  NLD005: {
    id: "",
    name: "",
    authorizationStartDate: undefined as Date | undefined,
    authorizationEndDate: undefined as Date | undefined,
    paymentStartDate: undefined as Date | undefined,
    paymentEndDate: undefined as Date | undefined,
    receiptIssueStartDate: undefined as Date | undefined,
    receiptIssueEndDate: undefined as Date | undefined,
    minTotal: undefined as string | undefined,
    maxTotal: undefined as string | undefined,
    status: {
      fee: true,
      interview: true,
      isFDGPETScan: true,
      ec: true,
    },
  },
  NLE001: {
    yoseTitle: "",
    deliveryStartDate: undefined as Date | undefined,
    deliveryEndDate: undefined as Date | undefined,
    unDeliveryStartDate: undefined as Date | undefined,
    unDeliveryEndDate: undefined as Date | undefined,
    status: {
      isBeforeDelivery: true,
      isNew: true,
      isPublic: true,
      isClosePublic: true,
      isStopDelivery: true,
    },
  },
  NLF001: {
    id: "",
    userId: "",
    patientName: "",
    orderYear: "",
    orderMonth: "",
    status: {
      isPaymentCompleted: true,
      isShipmentCompleted: true,
      isDeliveryCompleted: true,
    },
    deliveryMethod: {
      isNormalDelivery: true,
      isDateSpecifiedDelivery: true,
    },
  },
  NLF006: {
    salesYear: "",
    salesMonth: "",
  },
  NLG001: {
    title: "",
    deliveryStartDate: undefined as Date | undefined,
    deliveryEndDate: undefined as Date | undefined,
    lastUpdatedStartDate: undefined as Date | undefined,
    lastUpdatedEndDate: undefined as Date | undefined,
    lastUpdatedAdminId: "",
    status: {
      isUnpublished: true,
      isNowOpen: true,
    },
    forceUpdateCount: 0,
  },
  NLI003: {
    search: "",
  },
  NLI007: {
    itemCode: "",
    itemName: "",
  },
  NLI009: {
    id: "",
    name: "",
    skuName: "",
  },
  NLI016: {
    search: "",
  },
  NLI017: {
    id: "",
    hospitalUserId: "",
    doctorName: "",
    email: "",
    role: {
      isAdmin: true,
      isGeneral: true,
    },
  },
  NLI019: {
    search: "",
  },
  NLI021: {
    userId: "",
    name: "",
    phoneNum: "",
    email: "",
    status: {
      isActive: true,
      isCancel: true,
      isVerifying: true,
    },
  },
  NLI033: {
    code: "",
    name: "",
    paymentSpan: {
      month: true,
      year: true,
    },
    campaignEnd: {
      cancel: true,
      regularPrice: true,
    },
    campaignStartDate: undefined as Date | undefined,
    campaignEndDate: undefined as Date | undefined,
  },
  MIB001: {
    userId: "",
    patientName: "",
    tel: "",
    applicationDate: undefined as Date | undefined,
    modality: {
      isMRI: true,
      isPET: true,
    },
    status: {
      isNew: true,
      isConfirm: false,
      isInspected: false,
      isNg: false,
      isApplicationForWithdrawal: false,
      isWithdrawal: false,
      isAdminCancel: false,
      isNoExamination: false,
    },
  },
  MIC001: {
    userId: "",
    patientName: "",
    tel: "",
    fixBookDate: undefined as Date | undefined,
    status: {
      isNew: false,
      isConfirm: true,
      isInspected: false,
      isNg: false,
      isApplicationForWithdrawal: false,
      isWithdrawal: false,
      isAdminCancel: false,
      isNoExamination: false,
    },
    content: {
      MRI: true,
      PET: true,
    },
  },
  MID001: {
    id: "",
    patientName: "",
    tel: "",
    status: {
      isNotInspected: true,
      isInspected: true,
    },
  },
};

type LabelObjectType = {
  [key: string]: string;
};
type IdType = number | string;
// 各種IDと表示文言を作成 チェックボックス セレクトボックスで使用可能
const _createIdAndLabel = <T extends { [key in K]: IdType }, K extends keyof T>(
  key: K,
  idObject: T,
  labelObject: LabelObjectType,
  flgKey = "",
) => {
  const id = idObject[key];
  const value = id.toString();
  const label = labelObject[id.toString()];

  return { id, label, value, flgKey };
};

// 2次以降フェーズ対応: No150 お客さんに正式なstatusIdを聞く
export enum PatientStatusID {
  // 仮登録
  UNSUPPORTED,
  // 会員登録
  REGISTERED,
  // 保険審査中
  // 保険審査通過
  // 保険審査謝絶
  NG,
}

export enum PatientNouKnowStatusID {
  PRETEST = 0,
  COMPLETED = 1,
  UPLOADED = 2,
  CONFIRMED = 4,
  USER_REPORTED = 5,
  ALL = 999,
}

export const PatientNouKnowStatusLabels = {
  [PatientNouKnowStatusID.PRETEST]: "テスト未実施",
  [PatientNouKnowStatusID.COMPLETED]: "テスト終了",
  [PatientNouKnowStatusID.UPLOADED]: "結果取得済",
  [PatientNouKnowStatusID.CONFIRMED]: "医師確認済",
  [PatientNouKnowStatusID.USER_REPORTED]: "会員報告済",
  [PatientNouKnowStatusID.ALL]: "すべて",
};

const Nlc004NouKnowStatus: {
  key: keyof typeof PatientNouKnowStatusID;
  flag: string;
}[] = [
  { key: "PRETEST", flag: "isNotTested" },
  { key: "COMPLETED", flag: "isCompleted" },
  { key: "UPLOADED", flag: "isUploaded" },
  { key: "CONFIRMED", flag: "isConfirmed" },
  { key: "USER_REPORTED", flag: "isReported" },
];

export enum CommentedUserTypeID {
  PATIENT = "patient",
  ADMIN = "admin",
}

export const CommentedUserTypeLabels = {
  [CommentedUserTypeID.PATIENT]: "会員",
  [CommentedUserTypeID.ADMIN]: "サービス管理者",
};

const Nlc033CommentedUserType: {
  key: keyof typeof CommentedUserTypeID;
  flag: string;
}[] = [
  { key: "PATIENT", flag: "isPatient" },
  { key: "ADMIN", flag: "isAdmin" },
];

export enum PatientBrainCheckStatusID {
  NOT_TESTED,
  COMPLETED,
  REPORTED,
  ALL = 999,
}

const Nlc029BrainCheckStatus: {
  key: keyof typeof PatientBrainCheckStatusID;
  flag: string;
}[] = [
  { key: "NOT_TESTED", flag: "isNotTested" },
  { key: "COMPLETED", flag: "isCompleted" },
  { key: "REPORTED", flag: "isReported" },
];

export const PatientBrainCheckStatusLabels = {
  [PatientBrainCheckStatusID.NOT_TESTED]: "未受検",
  [PatientBrainCheckStatusID.COMPLETED]: "受検済",
  [PatientBrainCheckStatusID.REPORTED]: "レポート送信済み",
  [PatientNouKnowStatusID.ALL]: "すべて",
};

const InspecBrainCheckStatusArr: (keyof typeof PatientBrainCheckStatusID)[] = [
  "ALL",
  "NOT_TESTED",
  "COMPLETED",
  "REPORTED",
];

export enum PatientTypeID {
  MEMBER = 1,
  INVITATION = 2,
  PRESENT = 3,
  ORG = 4,
}

export const PatientTypeLabels = {
  [PatientTypeID.MEMBER]: "会員",
  [PatientTypeID.INVITATION]: "招待者",
  [PatientTypeID.PRESENT]: "被招待者",
  [PatientTypeID.ORG]: "団体",
};

const Nlc035PatientType: {
  key: keyof typeof PatientTypeID;
  flag: string;
}[] = [
  { key: "MEMBER", flag: "isMember" },
  { key: "INVITATION", flag: "isInvitee" },
  { key: "PRESENT", flag: "isPresent" },
  { key: "ORG", flag: "isOrg" },
];

export enum ReadStatusID {
  READ = "isRead",
  UNREAD = "isUnread",
}

export const readStatusLabels = {
  [ReadStatusID.READ]: "既読",
  [ReadStatusID.UNREAD]: "未読",
};

const Nlc033ReadStatus: {
  key: keyof typeof ReadStatusID;
  flag: string;
}[] = [
  { key: "READ", flag: "isRead" },
  { key: "UNREAD", flag: "isUnread" },
];

export enum CheckListID {
  CHECK = "isAttend",
}

export const checkListLabels = {
  [CheckListID.CHECK]: "要注意",
};

const Nlc033CheckList: {
  key: keyof typeof CheckListID;
  flag: string;
}[] = [{ key: "CHECK", flag: "isAttend" }];

export enum paymentTypeID {
  AMOUNT,
  RATE,
}

export enum paymentSpanID {
  MONTH = 1,
  YEAR = 2,
}

export const paymentSpanLabels = {
  [paymentSpanID.MONTH]: "月額",
  [paymentSpanID.YEAR]: "年額",
};

const Nli033PaymentSpan: {
  key: keyof typeof paymentSpanID;
  flag: string;
}[] = [
  { key: "MONTH", flag: "month" },
  { key: "YEAR", flag: "year" },
];

export enum AfterCampaignID {
  CANCELLATION,
  CONTINUATION,
}

export const afterCampaignTypeLabels = {
  [AfterCampaignID.CANCELLATION]: "退会",
  [AfterCampaignID.CONTINUATION]: "通常料金で継続",
};

const Nli033CampaignType: {
  key: keyof typeof AfterCampaignID;
  flag: string;
}[] = [
  { key: "CANCELLATION", flag: "cancel" },
  { key: "CONTINUATION", flag: "regularPrice" },
];

export enum UserStatusID {
  UNREGISTERED,
  ACTIVE,
  INVITATION,
  CANCELLATION,
  SUSPENDED,
}

export const userStatusLabels = {
  [UserStatusID.ACTIVE]: "アクティブ",
  [UserStatusID.CANCELLATION]: "退会",
  [UserStatusID.SUSPENDED]: "休会",
};

const Nlc035UserStatus: {
  key: keyof typeof UserStatusID;
  flag: string;
}[] = [
  { key: "ACTIVE", flag: "isActive" },
  { key: "CANCELLATION", flag: "isCancel" },
  { key: "SUSPENDED", flag: "isSuspended" },
];

const InspectNouKnowStatusArr: (keyof typeof PatientNouKnowStatusID)[] = [
  "ALL",
  "PRETEST",
  "COMPLETED",
  "UPLOADED",
  "CONFIRMED",
  "USER_REPORTED",
];

export enum PetStatusID {
  NON_TARGET = 0,
  RESERVED = 2,
  RESERVATION_CONFIRMED = 3,
  EXAMINED = 4,
  DIAGNOSED = 5,
  USER_REPORTED = 6,
  WITHDRAWN_AFTER_CONFIRMATION = 7,
  NG_BEFORE_CONFIRMATION = 8,
  NO_EXAMINATION = 9,
  ALL = 999,
}

export const petStatusLabels = {
  [PetStatusID.RESERVED]: "仮予約",
  [PetStatusID.RESERVATION_CONFIRMED]: "予約確定",
  [PetStatusID.EXAMINED]: "検査済",
  [PetStatusID.DIAGNOSED]: "診断済",
  [PetStatusID.USER_REPORTED]: "会員報告済",
  [PetStatusID.WITHDRAWN_AFTER_CONFIRMATION]: "予約取消",
  [PetStatusID.NO_EXAMINATION]: "受検なし",
};

const Nlc006PatientPetStatus: {
  key: keyof typeof PetStatusID;
  flag: string;
}[] = [
  { key: "RESERVED", flag: "isReserved" },
  { key: "RESERVATION_CONFIRMED", flag: "hasReservationConfirmed" },
  { key: "EXAMINED", flag: "inspected" },
  { key: "DIAGNOSED", flag: "diagnosed" },
  { key: "USER_REPORTED", flag: "isUserReported" },
  { key: "WITHDRAWN_AFTER_CONFIRMATION", flag: "cancellation" },
  { key: "NO_EXAMINATION", flag: "isNoExamination" },
];

export enum PatientInterviewStatusID {
  NEW_RESERVATION,
  CONFIRMED_RESERVATION,
  SETTLED,
  RESERVATION_UNSETTLED,
  CANCELED = 9,
}

export const InterviewStatusLabels = {
  [PatientInterviewStatusID.NEW_RESERVATION]: "新規予約",
  [PatientInterviewStatusID.CONFIRMED_RESERVATION]: "予約確定",
  [PatientInterviewStatusID.SETTLED]: "面談済",
  [PatientInterviewStatusID.RESERVATION_UNSETTLED]: "予約確定",
  [PatientInterviewStatusID.CANCELED]: "面談取消",
};
const Nlc007PatientInterviewStatus: {
  key: keyof typeof PatientInterviewStatusID;
  flag: string;
}[] = [
  { key: "NEW_RESERVATION", flag: "isNewReservation" },
  { key: "CONFIRMED_RESERVATION", flag: "isConfirmedReservation" },
  { key: "SETTLED", flag: "isSettled" },
  { key: "RESERVATION_UNSETTLED", flag: "isConfirmedReservation" },
  { key: "CANCELED", flag: "isCanceled" },
];

export enum NotificationStatusID {
  UNPUBLISHED,
  NOW_OPEN,
  DELETED,
}

export enum CampaignsStatusID {
  UNPUBLISHED,
  NOW_OPEN,
}

export enum CampaignCouponsStatusID {
  APPLY = 1,
  DELETED = 2,
}

export enum EmbeddedPageID {
  MY_PAGE,
  YOSE_LIST,
  YOSE_DETAILS,
  EC_LIST,
  EC_DETAILS,
}

export enum EmbeddedLocationID {
  TOP,
  FOOTER,
}

export enum HospitalRoleID {
  GENERAL,
  ADMIN,
}
export enum OrderStatusID {
  CART_KEPT,
  ORDER_PENDING_PAYMENT_OR_AUTH,
  PAYMENT_COMPLETED,
  SHIPPED,
  DELIVERY_COMPLETED,
  CANCELLED = 9,
}
export enum DeliveryMethodID {
  NORMAL = 1,
  SPECIFIED = 2,
}
export enum ItemsSkusStatusID {
  HIDDEN,
  VISIBLE,
  DELETED,
}
export enum OptionConfigsStatusID {
  HIDDEN,
  VISIBLE,
}

export enum AdminsRoleID {
  ADMIN = 1,
  CMC = 2,
  PUBLIC_USER = 3,
}
export enum ModalityID {
  MRI,
  PET,
}
export enum HospitalReservationStatusID {
  NEW,
  CONFIRM,
  NG,
  APPLICATION_FOR_WITHDRAWAL,
  WITHDRAWAL,
  INSPECTED,
  ADMIN_CANCEL,
  NO_EXAMINATION,
}
export enum HospitalInspectionStatusID {
  NOT_INSPECTED,
  INSPECTED,
}
export enum EventHistoryStatusID {
  DELETED,
  VALID,
}

export enum MedicalRecordsStatusID {
  DELETED,
  VALID,
}

export enum ModalityStatusID {
  NEW_RESERVATION,
  CONFIRMED_RESERVATION,
  NG,
  WITHDRAWAL_REQUEST,
  WITHDRAWAL,
  INSPECTED,
}

export enum YOSE_STATUS {
  preDelivery,
  newPeriod,
  publicOpen,
  publicClose,
  suspend,
}

/**
 * 寄席の配信ステータス
 */
export const YOSE_STATUS_TEXT = {
  preDelivery: "配信前",
  newPeriod: "新着期間",
  publicOpen: "公開中",
  publicClose: "公開終了",
  suspend: "配信停止",
} as const;

export const ModalityStatusLabels = {
  [ModalityStatusID.NEW_RESERVATION]: "新規予約",
  [ModalityStatusID.CONFIRMED_RESERVATION]: "予約確定",
  [ModalityStatusID.NG]: "NG",
  [ModalityStatusID.WITHDRAWAL_REQUEST]: "予約取り下げ申請",
  [ModalityStatusID.WITHDRAWAL]: "予約取り下げ",
};

export const PetStatusLabels = {
  [PetStatusID.RESERVED]: "仮予約",
  [PetStatusID.RESERVATION_CONFIRMED]: "予約確定",
  [PetStatusID.EXAMINED]: "検査済",
  [PetStatusID.DIAGNOSED]: "診断済",
  [PetStatusID.USER_REPORTED]: "会員報告済",
  [PetStatusID.WITHDRAWN_AFTER_CONFIRMATION]: "予約取消",
  [PetStatusID.NG_BEFORE_CONFIRMATION]: "NG",
  [PetStatusID.NO_EXAMINATION]: "受検なし",
  [PetStatusID.ALL]: "すべて",
};
const InspectPetStatusArr: (keyof typeof PetStatusID)[] = [
  "ALL",
  "RESERVED",
  "RESERVATION_CONFIRMED",
  "EXAMINED",
  "DIAGNOSED",
  "USER_REPORTED",
  "WITHDRAWN_AFTER_CONFIRMATION",
  "NG_BEFORE_CONFIRMATION",
];

export enum MriStatusID {
  NON_TARGET = 0,
  GUIDING = 1,
  RESERVED = 2,
  RESERVATION_CONFIRMED = 3,
  EXAMINED = 4,
  DONE_AI_DIAGNOSING = 5,
  DONE_AI_DIAGNOSED = 6,
  DONE_DOCTOR_DIAGNOSED = 7,
  USER_REPORTED = 8,
  WITHDRAWN_AFTER_CONFIRMATION = 9,
  NG_BEFORE_CONFIRMATION = 10,
  NO_EXAMINATION = 11,
  ONLINE_MEDICAL_COMPLETED = 99,
  ALL = 999,
}
export const MriStatusLabels = {
  [MriStatusID.RESERVED]: "仮予約",
  [MriStatusID.RESERVATION_CONFIRMED]: "予約確定",
  [MriStatusID.EXAMINED]: "検査済",
  [MriStatusID.DONE_AI_DIAGNOSING]: "AI診断中",
  [MriStatusID.DONE_AI_DIAGNOSED]: "AI診断済",
  [MriStatusID.DONE_DOCTOR_DIAGNOSED]: "ドクター診断済",
  [MriStatusID.USER_REPORTED]: "会員報告済",
  [MriStatusID.WITHDRAWN_AFTER_CONFIRMATION]: "予約取消",
  [MriStatusID.NG_BEFORE_CONFIRMATION]: "NG",
  [MriStatusID.ONLINE_MEDICAL_COMPLETED]: "オンライン問診済",
  [MriStatusID.NO_EXAMINATION]: "受検なし",
  [MriStatusID.ALL]: "すべて",
};

export enum onlineTestStatus {
  notDone = 0, // 未実施
  done = 1, // 実施済み
  resultObtained = 2, // 結果取得済み
}

const Nlc005MriStatus: {
  key: keyof typeof MriStatusID;
  flag: string;
}[] = [
  { key: "ONLINE_MEDICAL_COMPLETED", flag: "isOnlineMedicalComplete" },
  { key: "RESERVED", flag: "isReserved" },
  { key: "RESERVATION_CONFIRMED", flag: "hasReservationConfirmed" },
  { key: "EXAMINED", flag: "hasExamined" },
  { key: "DONE_AI_DIAGNOSING", flag: "isCompletedUnderAIDiagnosis" },
  { key: "DONE_AI_DIAGNOSED", flag: "isCompletedAIDiagnose" },
  { key: "DONE_DOCTOR_DIAGNOSED", flag: "isCompletedDocDiagnosed" },
  { key: "USER_REPORTED", flag: "isUserSupported" },
  { key: "WITHDRAWN_AFTER_CONFIRMATION", flag: "withdrawal" },
  { key: "NO_EXAMINATION", flag: "isNoExamination" },
];
const InspectMriStatusArr: (keyof typeof MriStatusID)[] = [
  "ALL",
  "RESERVED",
  "RESERVATION_CONFIRMED",
  "DONE_AI_DIAGNOSING",
  "DONE_AI_DIAGNOSED",
  "DONE_DOCTOR_DIAGNOSED",
  "USER_REPORTED",
  "WITHDRAWN_AFTER_CONFIRMATION",
  "NG_BEFORE_CONFIRMATION",
  "ONLINE_MEDICAL_COMPLETED",
];

export enum MriProblemID {
  EXCLUDE,
  PROBLEM,
  NO_PROBLEM,
  ALL = 999,
}
const MriProblemLabels = {
  [MriProblemID.EXCLUDE]: "対象外",
  [MriProblemID.PROBLEM]: "問題あり",
  [MriProblemID.NO_PROBLEM]: "問題なし",
  [MriProblemID.ALL]: "すべて",
};

const InspectMriResultArr: (keyof typeof MriProblemID)[] = [
  "ALL",
  "NO_PROBLEM",
  "PROBLEM",
];

export enum PetProblemID {
  EXCLUDE,
  PROBLEM,
  NO_PROBLEM,
  ALL = 999,
}
const PetProblemLabels = {
  [PetProblemID.EXCLUDE]: "対象外",
  [PetProblemID.PROBLEM]: "問題あり",
  [PetProblemID.NO_PROBLEM]: "問題なし",
  [PetProblemID.ALL]: "すべて",
};
// 2次以降フェーズ対応: NLC006作成後対応
// const Nlc006PatientModalityBookMriProblem: {
//   key: keyof typeof PetProblemID;
//   flag: string;
// }[] = [
//   { key: "PROBLEM", flag: "hasProblem" },
//   { key: "NO_PROBLEM", flag: "hasNoProblem" },
// ];
const InspectPetResultArr: (keyof typeof PetProblemID)[] = [
  "ALL",
  "NO_PROBLEM",
  "PROBLEM",
];

export enum UserTypeID {
  ADMIN = 1,
  PATIENT = 2,
  HOSPITAL = 3,
}

export enum InspectionFeesStatusID {
  UNPAID,
  PAID,
  NOT_NEEDED,
}

export enum PlanSpanID {
  MONTH = 1,
  YEAR = 2,
}

export enum EventHistoriesPriorityID {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export enum SalesCategoryID {
  SUBSCRIPTION,
  INTERVIEW,
  PRODUCT_PURCHASE,
  PET,
  MRI,
  NOUKNOW,
  BRAIN_HEALTH_CHECK,
}

export enum HospitalPaymentsExaminationID {
  NOUKNOW = 1,
  MRI = 2,
  PET = 3,
  BRAIN_HEALTH_CHECK = 4,
  HEALTH = 5,
  INTERVIEW = 6,
}

export enum SalesStatusID {
  CREDIT_ACQUISITION,
  SOLD,
  CREDIT_CANCELLATION,
  CREDIT_FAILURE,
  SALE_CANCELLATION,
  SALE_FAILURE,
}

export enum BookTypeID {
  INTERVIEW = "interview",
  MODALITY = "modality",
}
export enum InspectionTabID {
  MRI = "1",
  PET = "2",
}

export enum Nlb001CategoryID {
  ALL = "1",
  CHAT = "2",
  INSPECTION = "3",
  INTERVIEW = "4",
  EC = "5",
}

export const EmbeddedStr = {
  PAGE: {
    [EmbeddedPageID.MY_PAGE]: "マイページ",
    [EmbeddedPageID.YOSE_LIST]: "寄席一覧",
    [EmbeddedPageID.YOSE_DETAILS]: "寄席詳細",
    [EmbeddedPageID.EC_LIST]: "EC一覧",
    [EmbeddedPageID.EC_DETAILS]: "EC詳細",
  },
  LOCATION: {
    [EmbeddedLocationID.TOP]: "トップ",
    [EmbeddedLocationID.FOOTER]: "フッター",
  },
};

export const SEARCH_INFO = {
  // 2次以降フェーズ対応
  NLC002: [
    {
      id: PatientStatusID.UNSUPPORTED,
      label: "未対応",
      flgKey: "isUnsupported",
    },
    {
      id: PatientStatusID.REGISTERED,
      label: "完了",
      flgKey: "isCompleted",
    },
    {
      id: PatientStatusID.NG,
      label: "NG",
      flgKey: "isNG",
    },
  ],
  // 2次以降フェーズ対応
  NLC003: [
    {
      id: PatientStatusID.UNSUPPORTED,
      label: "未対応",
      flgKey: "isUnsupported",
    },
    {
      id: PatientStatusID.REGISTERED,
      label: "完了",
      flgKey: "isCompleted",
    },
    {
      id: PatientStatusID.NG,
      label: "NG",
      flgKey: "isNG",
    },
  ],
  NLC004: Nlc004NouKnowStatus.map((item) =>
    _createIdAndLabel(
      item.key,
      PatientNouKnowStatusID,
      PatientNouKnowStatusLabels,
      item.flag,
    ),
  ),
  NLC005: Nlc005MriStatus.map((item) =>
    _createIdAndLabel(item.key, MriStatusID, MriStatusLabels, item.flag),
  ),
  NLC006: Nlc006PatientPetStatus.map((item) =>
    _createIdAndLabel(item.key, PetStatusID, petStatusLabels, item.flag),
  ),
  NLC007: Nlc007PatientInterviewStatus.map((item) =>
    _createIdAndLabel(
      item.key,
      PatientInterviewStatusID,
      InterviewStatusLabels,
      item.flag,
    ),
  ),
  NLC029: Nlc029BrainCheckStatus.map((item) =>
    _createIdAndLabel(
      item.key,
      PatientBrainCheckStatusID,
      PatientBrainCheckStatusLabels,
      item.flag,
    ),
  ),
  NLC033_type: Nlc033CommentedUserType.map((item) =>
    _createIdAndLabel(
      item.key,
      CommentedUserTypeID,
      CommentedUserTypeLabels,
      item.flag,
    ),
  ),
  NLC033_status: Nlc033ReadStatus.map((item) =>
    _createIdAndLabel(item.key, ReadStatusID, readStatusLabels, item.flag),
  ),
  NLC033_check: Nlc033CheckList.map((item) =>
    _createIdAndLabel(item.key, CheckListID, checkListLabels, item.flag),
  ),
  NLC035_type: Nlc035PatientType.map((item) =>
    _createIdAndLabel(item.key, PatientTypeID, PatientTypeLabels, item.flag),
  ),
  NLC035_status: Nlc035UserStatus.map((item) =>
    _createIdAndLabel(item.key, UserStatusID, userStatusLabels, item.flag),
  ),
  NLD001: [],
  NLD002: [
    {
      id: HospitalPaymentsExaminationID.NOUKNOW,
      label: "のうKNOW",
      flgKey: "isKnow",
    },
    {
      id: HospitalPaymentsExaminationID.MRI,
      label: "MRI検査",
      flgKey: "isMRIExam",
    },
    {
      id: HospitalPaymentsExaminationID.PET,
      label: "PET検査",
      flgKey: "isFDGPETScan",
    },
    {
      id: HospitalPaymentsExaminationID.BRAIN_HEALTH_CHECK,
      label: "あたまの健康チェック",
      flgKey: "isHealthCheck",
    },
    {
      id: HospitalPaymentsExaminationID.INTERVIEW,
      label: "健康相談",
      flgKey: "isHealthConsultation",
    },
  ],
  NLD003: [
    {
      id: HospitalPaymentsExaminationID.NOUKNOW,
      label: "のうKNOW",
      flgKey: "isKnow",
    },
    {
      id: HospitalPaymentsExaminationID.MRI,
      label: "MRI検査",
      flgKey: "isMRIExam",
    },
    {
      id: HospitalPaymentsExaminationID.PET,
      label: "PET検査",
      flgKey: "isFDGPETScan",
    },
    {
      id: HospitalPaymentsExaminationID.BRAIN_HEALTH_CHECK,
      label: "あたまの健康チェック",
      flgKey: "isHealthCheck",
    },
    {
      id: HospitalPaymentsExaminationID.INTERVIEW,
      label: "健康相談",
      flgKey: "isHealthConsultation",
    },
  ],
  NLD004_ITEM: [
    {
      id: SalesCategoryID.SUBSCRIPTION,
      label: "会費",
      flgKey: "fee",
    },
    {
      id: SalesCategoryID.PET,
      label: "PET検査",
      flgKey: "isFDGPETScan",
    },
    {
      id: SalesCategoryID.INTERVIEW,
      label: "面談",
      flgKey: "interview",
    },
    {
      id: SalesCategoryID.PRODUCT_PURCHASE,
      label: "EC",
      flgKey: "ec",
    },
  ],
  NLD004_PAYMENT: [
    {
      id: SalesStatusID.CREDIT_ACQUISITION,
      label: "与信取得",
      flgKey: "creditAcquisition",
    },
    {
      id: SalesStatusID.CREDIT_CANCELLATION,
      label: "与信取消",
      flgKey: "creditCancellation",
    },
    {
      id: SalesStatusID.SOLD,
      label: "売上済",
      flgKey: "sold",
    },
    {
      id: SalesStatusID.SALE_CANCELLATION,
      label: "売上取消",
      flgKey: "saleCancellation",
    },
    {
      id: SalesStatusID.SALE_FAILURE,
      label: "売上失敗",
      flgKey: "saleFailure",
    },
  ],
  NLD005_ITEM: [
    {
      id: SalesCategoryID.SUBSCRIPTION,
      label: "会費",
      flgKey: "fee",
    },
    {
      id: SalesCategoryID.PET,
      label: "PET検査",
      flgKey: "isFDGPETScan",
    },
    {
      id: SalesCategoryID.INTERVIEW,
      label: "面談",
      flgKey: "interview",
    },
    {
      id: SalesCategoryID.PRODUCT_PURCHASE,
      label: "EC",
      flgKey: "ec",
    },
  ],
  NLF001_STATUS: [
    {
      id: OrderStatusID.PAYMENT_COMPLETED,
      label: "支払完了",
      flgKey: "isPaymentCompleted",
    },
    {
      id: OrderStatusID.SHIPPED,
      label: "出荷済",
      flgKey: "isShipmentCompleted",
    },
    {
      id: OrderStatusID.DELIVERY_COMPLETED,
      label: "配送完了",
      flgKey: "isDeliveryCompleted",
    },
  ],
  NLF001_DELIVERY: [
    {
      id: DeliveryMethodID.NORMAL,
      label: "通常配送",
      flgKey: "isNormalDelivery",
    },
    {
      id: DeliveryMethodID.SPECIFIED,
      label: "お届け日指定",
      flgKey: "isDateSpecifiedDelivery",
    },
  ],
  NLG001: [
    {
      id: NotificationStatusID.UNPUBLISHED,
      label: "未公開",
      flgKey: "isUnpublished",
    },
    {
      id: NotificationStatusID.NOW_OPEN,
      label: "公開中",
      flgKey: "isNowOpen",
    },
  ],
  NLE001: [
    {
      id: YOSE_STATUS.preDelivery,
      label: "配信前",
      flgKey: "isBeforeDelivery",
    },
    {
      id: YOSE_STATUS.newPeriod,
      label: "新着",
      flgKey: "isNew",
    },
    {
      id: YOSE_STATUS.publicOpen,
      label: "公開中",
      flgKey: "isPublic",
    },
    {
      id: YOSE_STATUS.publicClose,
      label: "公開終了",
      flgKey: "isClosePublic",
    },
    {
      id: YOSE_STATUS.suspend,
      label: "配信停止",
      flgKey: "isStopDelivery",
    },
  ],
  NLH001: [
    {
      id: CampaignsStatusID.UNPUBLISHED,
      label: "未公開",
      flgKey: "",
    },
    {
      id: CampaignsStatusID.NOW_OPEN,
      label: "公開中",
      flgKey: "",
    },
  ],
  NLI017: [
    {
      id: HospitalRoleID.ADMIN,
      label: "Admin",
      flgKey: "isAdmin",
    },
    {
      id: HospitalRoleID.GENERAL,
      label: "一般ユーザー",
      flgKey: "isGeneral",
    },
  ],
  NLI021: Nlc035UserStatus.map((item) =>
    _createIdAndLabel(item.key, UserStatusID, userStatusLabels, item.flag),
  ),
  NLI033_PAYMENT: Nli033PaymentSpan.map((item) =>
    _createIdAndLabel(item.key, paymentSpanID, paymentSpanLabels, item.flag),
  ),
  NLI033_CAMPAIGN: Nli033CampaignType.map((item) =>
    _createIdAndLabel(
      item.key,
      AfterCampaignID,
      afterCampaignTypeLabels,
      item.flag,
    ),
  ),
  MIB001_MODALITY: [
    {
      id: ModalityID.MRI,
      label: "MRI検査",
      flgKey: "isMRI",
    },
    {
      id: ModalityID.PET,
      label: "FDG PET検査",
      flgKey: "isPET",
    },
  ],
  MIB001_STATUS: [
    {
      id: HospitalReservationStatusID.NEW,
      label: "新規予約",
      flgKey: "isNew",
    },
    {
      id: HospitalReservationStatusID.CONFIRM,
      label: "確定",
      flgKey: "isConfirm",
    },
    {
      id: HospitalReservationStatusID.INSPECTED,
      label: "検査済",
      flgKey: "isInspected",
    },
    {
      id: HospitalReservationStatusID.NG,
      label: "NG",
      flgKey: "isNg",
    },
    {
      id: HospitalReservationStatusID.APPLICATION_FOR_WITHDRAWAL,
      label: "取り下げ申請",
      flgKey: "isApplicationForWithdrawal",
    },
    {
      id: HospitalReservationStatusID.WITHDRAWAL,
      label: "取り下げ",
      flgKey: "isWithdrawal",
    },
    {
      id: HospitalReservationStatusID.ADMIN_CANCEL,
      label: "管理者取消",
      flgKey: "isAdminCancel",
    },
    {
      id: HospitalReservationStatusID.NO_EXAMINATION,
      label: "受検なし",
      flgKey: "isNoExamination",
    },
  ],
  MIC001_CONTENT: [
    {
      id: ModalityID.MRI,
      label: "MRI検査",
      flgKey: "MRI",
    },
    {
      id: ModalityID.PET,
      label: "FDG PET検査",
      flgKey: "PET",
    },
  ],
};

// ユーザ管理、ユーザ詳細のテスト結果セレクトボックス選択肢
export const INSPECT_MRI_RESULTS_OPTIONS = InspectMriResultArr.map((key) =>
  _createIdAndLabel(key, MriProblemID, MriProblemLabels),
);
export const INSPECT_PET_RESULTS_OPTIONS = InspectPetResultArr.map((key) =>
  _createIdAndLabel(key, PetProblemID, PetProblemLabels),
);
export const INSPECT_NOUKNOW_STATUS_OPTIONS = InspectNouKnowStatusArr.map(
  (key) =>
    _createIdAndLabel(key, PatientNouKnowStatusID, PatientNouKnowStatusLabels),
);
export const INSPECT_MRI_STATUS_OPTIONS = InspectMriStatusArr.map((key) =>
  _createIdAndLabel(key, MriStatusID, MriStatusLabels),
);
export const INSPECT_PET_STATUS_OPTIONS = InspectPetStatusArr.map((key) =>
  _createIdAndLabel(key, PetStatusID, PetStatusLabels),
);
export const INSPECT_BRAIN_CHECK_STATUS_OPTIONS = InspecBrainCheckStatusArr.map(
  (key) =>
    _createIdAndLabel(
      key,
      PatientBrainCheckStatusID,
      PatientBrainCheckStatusLabels,
    ),
);

export const MASTER_MANAGEMENT_OPTIONS = [
  {
    id: "1",
    label: "権限管理",
    value: "1",
    url: ADMIN.NLI001,
  },
  {
    id: "2",
    label: "ユーザー管理",
    value: "2",
    url: ADMIN.NLI003,
  },
  {
    id: "3",
    label: "商品管理",
    value: "3",
    url: ADMIN.NLI007,
  },
  {
    id: "4",
    label: "医療機関",
    value: "4",
    url: ADMIN.NLI016,
  },
  {
    id: "5",
    label: "面談枠",
    value: "5",
    url: ADMIN.NLI031,
  },
  {
    id: "6",
    label: "団体管理",
    value: "6",
    url: ADMIN.NLI019,
  },
  {
    id: "7",
    label: "キャンペーン管理",
    value: "7",
    url: ADMIN.NLI033,
  },
];

export const HOSPITAL_MASTER_MANAGEMENT_OPTIONS = [
  {
    id: "1",
    label: "枠管理-MRI",
    value: "1",
    url: HOSPITAL.MIE001,
  },
  {
    id: "2",
    label: "枠管理-PET",
    value: "2",
    url: HOSPITAL.MIE011,
  },
  {
    id: "3",
    label: "スタッフ",
    value: "3",
    url: HOSPITAL.MIE004,
  },
];

export const ADMIN_MAIN_TAB_ITEMS = [
  { title: "ダッシュボード", url: ADMIN.NLB001, counter: 0 },
  { title: "会員管理", url: ADMIN.NLC035, counter: 0 },
  { title: "検査管理", url: ADMIN.NLC004, counter: 0 },
  { title: "支払・請求", url: ADMIN.NLD002, counter: 0 },
  { title: "オリジナル落語動画", url: ADMIN.NLE001, counter: 0 },
  { title: "EC管理", url: ADMIN.NLF001, counter: 0 },
  { title: "お知らせ", url: ADMIN.NLG001, counter: 0 },
  { title: "バナー", url: ADMIN.NLH001, counter: 0 },
  { title: "チャット", url: ADMIN.NLC033, counter: 0 },
];

export const HOSPITAL_MAIN_TAB_ITEMS = [
  { title: "予約受付", url: HOSPITAL.MIB001, counter: 0 },
  { title: "スケジュール", url: HOSPITAL.MIC001, counter: 0 },
  { title: "患者一覧", url: HOSPITAL.MID001, counter: 0 },
];

export const ADMIN_USER_MGMT_SUB_TAB_ITEMS = [
  {
    id: "1",
    label: "のうKNOW",
    url: ADMIN.NLC004,
  },
  {
    id: "2",
    label: "MRI検査",
    url: ADMIN.NLC005,
  },
  {
    id: "3",
    label: "FDG PET検査",
    url: ADMIN.NLC006,
  },
  {
    id: "4",
    label: "あたまの健康チェック",
    url: ADMIN.NLC029,
  },
  {
    id: "5",
    label: "面談",
    url: ADMIN.NLC007,
  },
];

export const ADMIN_PAYMENT_SUB_TAB_ITEMS = [
  {
    id: "1",
    label: "検査費用支払い",
    url: ADMIN.NLD002,
  },
  {
    id: "2",
    label: "会員請求",
    url: ADMIN.NLD004,
  },
  {
    id: "3",
    label: "領収書",
    url: ADMIN.NLD005,
  },
];

export const MASTER_ITEMS_SUB_TAB_ITEMS = [
  { id: "1", title: "商品種別", url: ADMIN.NLI007 },
  { id: "2", title: "SKU", url: ADMIN.NLI009 },
];

export const ADMIN_ORDERS_TOP_TAB_ITEMS = [
  {
    id: "1",
    label: "注文管理",
    url: ADMIN.NLF001,
  },
  {
    id: "2",
    label: "売上管理",
    url: ADMIN.NLF006,
  },
];

export const ADMIN_ORDERS_BOTTOM_TAB_ITEMS = [
  {
    id: "1",
    label: "すべて",
  },
  {
    id: "2",
    label: "支払い完了",
  },
  {
    id: "3",
    label: "出荷済",
  },
  {
    id: "4",
    label: "配送完了",
  },
];

export const ADMIN_USER_DETAIL_TAB_ITEMS = [
  { title: "登録情報", url: ADMIN.NLC008, counter: 0 },
  { title: "予約状況", url: ADMIN.NLC020, counter: 0 },
  { title: "テスト結果", url: ADMIN.NLC011, counter: 0 },
  { title: "支払い状況", url: ADMIN.NLC019, counter: 0 },
  { title: "注文履歴", url: ADMIN.NLC023, counter: 0 },
];

export const TEST_RESULT_ITEMS = [
  {
    id: "1",
    label: "のうKNOW",
    url: ADMIN.NLC011,
  },
  {
    id: "2",
    label: "MRI検査",
    url: ADMIN.NLC014,
  },
  {
    id: "3",
    label: "FDG PET検査",
    url: ADMIN.NLC017,
  },
  {
    id: "4",
    label: "あたまの健康チェック",
    url: ADMIN.NLC030,
  },
];

export const ADMIN_USER_DETAIL_CONTACT_ITEMS = [
  { title: "応対履歴", icon: "phone" },
  { title: "チャット", icon: "chat" },
  { title: "カルテ", icon: "description" },
];

export const MONTH_LIST = [
  { value: "01", label: "1" },
  { value: "02", label: "2" },
  { value: "03", label: "3" },
  { value: "04", label: "4" },
  { value: "05", label: "5" },
  { value: "06", label: "6" },
  { value: "07", label: "7" },
  { value: "08", label: "8" },
  { value: "09", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

// 日本時間
export const JST = "+09:00";
export const JST_OFFSET_HOURS = 9;

// 消費税10%
export const TAX_10_PER = {
  label: "消費税10%対象",
  rate: "10%",
  calcTaxNum: 1.1,
};

// 消費税8%
export const TAX_8_PER = {
  label: "消費税8%対象",
  rate: "8%",
  calcTaxNum: 1.08,
};

// 画像アップロード最大個数
export const IMG_DEFAULT_MAX_COUNT = 4;

// 注文状況アイコン
export const ORDER_STEPS = [
  {
    id: "content-1",
    icon: "credit_card",
    label: "",
  },
  {
    id: "content-2",
    icon: "shopping_cart",
    label: "",
  },
];

export const ADMIN_SELECT_ROLE = [
  {
    label: "一般ユーザー",
    value: "3",
  },
  {
    label: "ドクター",
    value: "2",
  },
  {
    label: "Admin",
    value: "1",
  },
];

export const ADMIN_SELECT_STATUS = [
  {
    label: "有効",
    value: "1",
  },

  {
    label: "停止",
    value: "0",
  },
];

export const RECOMMEND_TERMS = [
  {
    value: "99",
    label: "すべて",
  },
  {
    value: "1",
    label: "A",
  },
  {
    value: "2",
    label: "B",
  },
  {
    value: "3",
    label: "C",
  },
  {
    value: "4",
    label: "D",
  },
  {
    value: "5",
    label: "E",
  },
  {
    value: "0",
    label: "設定しない",
  },
];
// すべてと設定しないを除いたRECOMMEND_TERMS
export const RECOMMEND_TERMS_CONDITIONS = RECOMMEND_TERMS.map(({ value }) =>
  Number(value),
).filter((value) => value !== 99 && value !== 0);
export const RECOMMEND_SCOPES = [
  {
    value: "1",
    label: "以上",
  },
  {
    value: "2",
    label: "以下",
  },
  {
    value: "3",
    label: "-",
  },
];

export const TAX_RATE = [
  {
    rate: 8,
    label: "8%",
  },
  {
    rate: 10,
    label: "10%",
  },
];

export const CONSUMPTION_RATE = [
  {
    label: "切り捨て",
    value: "ceil",
  },
  {
    label: "切り上げ",
    value: "floor",
  },
  {
    label: "四捨五入",
    value: "round",
  },
];

// ユーザ評価初期値
export const INIT_EVALUATION = "3";

// ユーザ評価選択肢
export const USER_EVALUATION = [
  {
    label: "A",
    value: "1",
  },
  {
    label: "B",
    value: "2",
  },
  {
    label: "C",
    value: "3",
  },
  {
    label: "D",
    value: "4",
  },
  {
    label: "E",
    value: "5",
  },
];

// 都道府県の選択肢
export const PREFECTURE_OPTIONS = [
  {
    label: "北海道",
    value: "北海道",
  },
  {
    label: "青森県",
    value: "青森県",
  },
  {
    label: "岩手県",
    value: "岩手県",
  },
  {
    label: "宮城県",
    value: "宮城県",
  },
  {
    label: "秋田県",
    value: "秋田県",
  },
  {
    label: "山形県",
    value: "山形県",
  },
  {
    label: "福島県",
    value: "福島県",
  },
  {
    label: "茨城県",
    value: "茨城県",
  },
  {
    label: "栃木県",
    value: "栃木県",
  },
  {
    label: "群馬県",
    value: "群馬県",
  },
  {
    label: "埼玉県",
    value: "埼玉県",
  },
  {
    label: "千葉県",
    value: "千葉県",
  },
  {
    label: "東京都",
    value: "東京都",
  },
  {
    label: "神奈川県",
    value: "神奈川県",
  },
  {
    label: "新潟県",
    value: "新潟県",
  },
  {
    label: "富山県",
    value: "富山県",
  },
  {
    label: "石川県",
    value: "石川県",
  },
  {
    label: "福井県",
    value: "福井県",
  },
  {
    label: "山梨県",
    value: "山梨県",
  },
  {
    label: "長野県",
    value: "長野県",
  },
  {
    label: "岐阜県",
    value: "岐阜県",
  },
  {
    label: "静岡県",
    value: "静岡県",
  },
  {
    label: "愛知県",
    value: "愛知県",
  },
  {
    label: "三重県",
    value: "三重県",
  },
  {
    label: "滋賀県",
    value: "滋賀県",
  },
  {
    label: "京都府",
    value: "京都府",
  },
  {
    label: "大阪府",
    value: "大阪府",
  },
  {
    label: "兵庫県",
    value: "兵庫県",
  },
  {
    label: "奈良県",
    value: "奈良県",
  },
  {
    label: "和歌山県",
    value: "和歌山県",
  },
  {
    label: "鳥取県",
    value: "鳥取県",
  },
  {
    label: "島根県",
    value: "島根県",
  },
  {
    label: "岡山県",
    value: "岡山県",
  },
  {
    label: "広島県",
    value: "広島県",
  },
  {
    label: "山口県",
    value: "山口県",
  },
  {
    label: "徳島県",
    value: "徳島県",
  },
  {
    label: "香川県",
    value: "香川県",
  },
  {
    label: "愛媛県",
    value: "愛媛県",
  },
  {
    label: "高知県",
    value: "高知県",
  },
  {
    label: "福岡県",
    value: "福岡県",
  },
  {
    label: "佐賀県",
    value: "佐賀県",
  },
  {
    label: "長崎県",
    value: "長崎県",
  },
  {
    label: "熊本県",
    value: "熊本県",
  },
  {
    label: "大分県",
    value: "大分県",
  },
  {
    label: "宮崎県",
    value: "宮崎県",
  },
  {
    label: "鹿児島県",
    value: "鹿児島県",
  },
  {
    label: "沖縄県",
    value: "沖縄県",
  },
];

// 誕生日の年セレクトボックス値作成
const currentYear = new Date().getFullYear();
const startYear = 1900;
const endYear = currentYear;
export const BIRTH_YEAR_OPTION = Array.from(
  { length: endYear - startYear + 1 },
  (_, index) => ({
    label: (startYear + index).toString(),
    value: (startYear + index).toString(),
  }),
);

// 月セレクトボックス値作成
export const MONTH_OPTIONS = Array.from({ length: 12 }, (_, index) => {
  const monthNumber = index + 1;
  const label = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;

  return {
    label,
    value: monthNumber.toString(),
  };
});

// 性別ラジオボタン選択肢
export const GENDER_ITEMS = [
  {
    column: 2,
    items: [
      {
        label: "男性",
        value: "male",
      },
      {
        label: "女性",
        value: "female",
      },
    ],
  },
];

// 応対履歴 カテゴリ
export const EVENT_CATEGORY_OPTIONS = [
  {
    label: "支払い",
    value: "支払い",
  },
  {
    label: "テスト・検査",
    value: "テスト・検査",
  },
  {
    label: "登録",
    value: "登録",
  },
  {
    label: "EC",
    value: "EC",
  },
  {
    label: "寄席",
    value: "寄席",
  },
  {
    label: "その他",
    value: "その他",
  },
];

// 応対履歴 カテゴリ
export const EVENT_CATEGORY_FIND_OPTIONS = [
  {
    label: "すべて",
    value: "ALL",
  },
  {
    label: "支払い",
    value: "支払い",
  },
  {
    label: "テスト・検査",
    value: "テスト・検査",
  },
  {
    label: "登録",
    value: "登録",
  },
  {
    label: "EC",
    value: "EC",
  },
  {
    label: "寄席",
    value: "寄席",
  },
  {
    label: "その他",
    value: "その他",
  },
];

// 応対履歴 重要度
export const EVENT_IMPORTANCE_OPTIONS = [
  {
    label: "低",
    value: "1",
  },
  {
    label: "中",
    value: "2",
  },
  {
    label: "高",
    value: "3",
  },
];

// 応対履歴 ソート
export const EVENT_SORT_OPTIONS = [
  {
    id: "1",
    label: "登録順",
    value: "registered_date",
  },
  {
    id: "2",
    label: "重要度順",
    value: "priority",
  },
];

// ユーザー詳細 アクション
export const USER_DETAIL_TAB_OPTIONS = [
  {
    label: "編集",
    value: "0",
    icon: "edit",
  },
  {
    label: "削除",
    value: "1",
    icon: "delete",
    danger: true,
  },
];

export const NOUKNOW_SCORE_TEXT = {
  A: "正常な状態です",
  B: "ボーダーライン",
  C: "維持向上のための活動を取り⼊れましょう",
};

export const INSPECTION_TOGGLE_ITEMS = [
  {
    id: "1",
    label: "デフォルト",
  },
  {
    id: "2",
    label: "日別",
  },
];

// 権限検索キー(master_rolesのrole_sort_no)
export enum ROLE_KEY {
  NONE,
  // ユーザ管理
  USER_LIST_CSV,
  USER_EVENT_HISTORY_REGISTER,
  USER_DOC_CHAT_REGISTER,
  USER_DOC_CHAT_REPORT,
  USER_SCREENING_CONFIRM,
  USER_MRI_INTERPRETATION,
  USER_INTERVIEW_RESERVE,
  USER_PET_SEND_RESULT,
  USER_PAY_BROWSING,
  USER_ORDER_HISTORY_BROWSING,
  // 支払・請求
  PAYMENT_LIST_BROWSING,
  PAYMENT_LIST_CSV,
  // 寄席
  YOSE_LIST_BROWSING,
  YOSE_LIST_ADD,
  YOSE_DETAIL_EDIT,
  // EC管理
  EC_ORDER_BROWSING,
  EC_ORDER_DETAIL,
  EC_ORDER_DELIVERY,
  EC_ORDER_DELIVERY_NUM,
  EC_ORDER_CANCEL,
  EC_SALES_BROWSING,
  EC_SALES_DETAIL,
  // お知らせ
  NOTICE_LIST_BROWSING,
  NOTICE_LIST_ADD,
  NOTICE_DETAIL_EDIT,
  // キャンペーン
  CAMPAIGN_LIST_BROWSING,
  CAMPAIGN_LIST_ADD,
  CAMPAIGN_DETAIL_EDIT,
  // マスタ管理 権限管理
  MASTER_AUTHORITY_BROWSING,
  MASTER_AUTHORITY_EDIT,
  // マスタ管理 ユーザ管理
  MASTER_USER_BROWSING,
  MASTER_USER_CSV_DOWNLOAD,
  MASTER_USER_CSV_UPLOAD,
  MASTER_USER_ADD,
  MASTER_USER_EDIT,
  // マスタ管理 商品管理
  MASTER_PRODUCT_BROWSING,
  MASTER_PRODUCT_ADD,
  MASTER_PRODUCT_EDIT,
  // マスタ管理 医療機関
  MASTER_HOSPITAL_BROWSING,
  MASTER_HOSPITAL_CSV_DOWNLOAD,
  MASTER_HOSPITAL_CSV_UPLOAD,
  MASTER_HOSPITAL_ADD,
  MASTER_HOSPITAL_EDIT,
}

export const FORMAT_STYLE = {
  "YYYY/MM/DD": 0,
  YYYYMMDD: 1,
  "YYYY/MM/DD HH:MM": 3,
  "YYYY-MM-DD": 4,
} as const;

// 枠テンプレート 選択肢
export const SCHEDULE_FRAME_DROPDOWN_ITEMS = [
  {
    value: "15",
    label: "15",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "60",
    label: "60",
  },
  {
    value: "90",
    label: "90",
  },
  {
    value: "120",
    label: "120",
  },
];

// スケジュール 時間 選択肢
export const SCHEDULE_HOUR_OPTIONS = new Array(24).fill(1).map((_, i) => {
  const label = i.toString().padStart(2, "0");
  const value = i.toString();

  return { label, value };
});

// スケジュール 分 選択肢
export const SCHEDULE_MINUTE_OPTIONS = [0, 15, 30, 45].map((item) => ({
  label: item.toString().length === 1 ? `0${item}` : `${item}`,
  value: `${item}`,
}));

// 時間編集
export const createTimeFromDateStr = (timeStr: string): Date => {
  // 現在の日付を取得
  const today = new Date();
  // 時間文字列を"HH:MM"の形式で分割
  const [hoursStr, minutesStr] = timeStr.split(":");
  // 時間と分を数値に変換
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  // 現在の日付の年月日を使用して、新しいDateオブジェクトを作成
  const dateWithTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    hours,
    minutes,
  );

  return dateWithTime;
};

// 病院の曜日を数値に変換する関数
export const convertHospitalDayToNumber = (day: string): number => {
  const dayMap: { [key: string]: number } = {
    sun: 6,
    mon: 0,
    tue: 1,
    wed: 2,
    thu: 3,
    fri: 4,
    sat: 5,
  };

  return dayMap[day.toLowerCase()];
};

// チェックボックス表示用に編集
export const generateHospitalDefaultItemChecked = (
  scheduleInfo: ScheduleInfo,
): { [day: number]: TimeSlotObject } => {
  const defaultItemChecked: { [day: number]: TimeSlotObject } = {};

  scheduleInfo.forEach((schedule) => {
    const timeSlots = schedule.default_time
      .split(",")
      .map((slot) => slot.trim());
    const day = convertHospitalDayToNumber(schedule.default_week);

    if (!(day in defaultItemChecked)) {
      defaultItemChecked[day] = {};
    }

    timeSlots.forEach((timeSlot) => {
      const isOpen = schedule.is_open;
      defaultItemChecked[day][timeSlot] = isOpen;
    });
  });

  return defaultItemChecked;
};

// チェックボックス表示用に編集
export const generateInterviewDefaultItemChecked = (
  scheduleInfo: InterviewDefaultSchedule[],
): { [day: number]: TimeSlotObject } => {
  const defaultItemChecked: { [day: number]: TimeSlotObject } = {};

  scheduleInfo.forEach((schedule) => {
    const timeSlots = schedule.default_time
      .split(",")
      .map((slot) => slot.trim());
    const day = convertHospitalDayToNumber(schedule.default_week);

    if (!(day in defaultItemChecked)) {
      defaultItemChecked[day] = {};
    }

    timeSlots.forEach((timeSlot) => {
      const isOpen = schedule.is_open;
      defaultItemChecked[day][timeSlot] = isOpen;
    });
  });

  return defaultItemChecked;
};

// デフォルトスケジュールAPI送信用にデータ編集
export const convertItemCheckedToDefaultSchedule = (
  itemChecked: Record<number, Record<string, boolean>>,
) => {
  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  return Object.entries(itemChecked).flatMap(([dayIndex, daySchedule]) =>
    Object.entries(daySchedule).map(([time, isOpen]) => ({
      default_week: daysOfWeek[parseInt(dayIndex)],
      default_time: time,
      is_open: isOpen,
    })),
  );
};

// 日別スケジュール 選択肢
export const DAILY_SCHEDULE_OPTION_DROPDOWN = [
  { label: "-", value: "-" },
  { label: "◯", value: "◯" },
  { label: "×", value: "×" },
];

// 日別スケジュールAPI送信用にデータ編集
export const convertToConvertedDailySchedule = (dailyItem: DailySchedule[]) =>
  dailyItem
    .filter((item) => item.value !== "-")
    .map((item) => ({
      datetime: new Date(item.dateTime),
      is_open: item.value === "◯",
    }));

// 病院のMRI,PET予約時間のTOの時間
export const HOSPITAL_RESERVATION_TO_MINUTE = 60;

// campaign_coupons.payment_type
export enum CampaignCouponsPaymentType {
  FIXED_AMOUNT,
  FIXED_RATE,
}

export const BULLET_LIST_ITEMS = [
  {
    id: "1",
    value: "心臓ペースメーカー",
  },
  {
    id: "2",
    value: "植込み型除細動器（ICD）",
  },
  {
    id: "3",
    value: "体内刺激電極",
  },
  {
    id: "4",
    value: "人工内耳",
  },
  {
    id: "5",
    value: "心臓ペーシングワイヤー",
  },
  {
    id: "6",
    value: "磁性体脳動脈瘤クリップ",
  },
  {
    id: "7",
    value: "マグネット式インプラント",
  },
  {
    id: "8",
    value: "機械式人工弁",
  },
  {
    id: "9",
    value: "磁性体義眼",
  },
  {
    id: "10",
    value: "乳房再建用の皮膚拡張器",
    helperText: "※MRI検査可能なものは除く",
  },
  {
    id: "11",
    value: "金の糸（金糸）",
  },
  {
    id: "12",
    value: "持続血糖測定器（CGM、FGM）",
    helperText: "※MRI検査可能なものは除く",
  },
];

export const MRI_PRECAUTIONS_ITEMS = [
  "冠動脈ステントが入っている方",
  "動脈瘤クリップが入っている方 手術の時期や場所によって、検査を受けられないことがあります。",
  "人工関節が入っている方",
  "上記以外の体内金属異物が入っている方 熱感、退色等の可能性があります。",
  "刺青・タトゥが入っている方",
  "現在妊娠中、または妊娠の可能性のある方",
];

export const PET_PRECAUTIONS_ITEMS = [
  "現在妊娠中、または妊娠の可能性のある方",
  "授乳中の方 検査当日は母乳を与えることを避けていただく場合があります",
  "乳幼児がいる方 検査当日はだっこ・おんぶをするのは避けてください",
  "これまでに薬を使用してアレルギー反応を起こしたことのある方",
  "30分程度同じ姿勢を保つことが難しい方",
  "糖尿病の方",
  "ペースメーカーを使用中の方 検査当日、手帳をお持ちください",
];
