import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  code: ValidationResultType<string>;
  name: ValidationResultType<string>;
  amount: ValidationResultType<string>;
  stock: ValidationResultType<string>;
};
type DefaultOptionValidationType = {
  options: {
    [key: string]: ValidationResultType<string>;
  };
};
// バリデーションの不要な型定義
type DefaultNoValidationType = {
  tax: number;
  isShow: boolean;
};

type DefaultType = DefaultValidationType &
  DefaultOptionValidationType &
  DefaultNoValidationType;

const INIT_STATE: DefaultType = {
  code: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  name: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  amount: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  stock: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  options: {},
  tax: 8,
  isShow: true,
};

// SKUの新規登録、編集ダイアログの入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultType>(INIT_STATE);
const useSkuInput = (): ReactiveVarHooks<DefaultType> =>
  useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setSkuValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setSkuCode = (value: DefaultValidationType["code"]["value"]) => {
  _setSkuValidation("code", value, VALIDATION_TYPE.SKU_CODE_REQUIRED);
};
export const setSkuName = (value: DefaultValidationType["name"]["value"]) => {
  _setSkuValidation("name", value, VALIDATION_TYPE.SKU_NAME_REQUIRED);
};
export const setSkuAmount = (
  value: DefaultValidationType["amount"]["value"],
) => {
  _setSkuValidation("amount", value, VALIDATION_TYPE.PRICE_REQUIRED);
};
export const setSkuStock = (value: DefaultValidationType["stock"]["value"]) => {
  _setSkuValidation("stock", value, VALIDATION_TYPE.STOCK_REQUIRED);
};

export const setSkuTax = (value: number) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    tax: value,
  });
};

export const setSkuOption = (targetKey: string, value: string) => {
  const currentInput = stateReactiveVar();
  const { options } = currentInput;

  if (options[targetKey]) {
    const updatedOptions = {
      ...options,
      [targetKey]: {
        value,
        ...commonValidation(value, VALIDATION_TYPE.OPTION_OPTIONAL),
      },
    };

    stateReactiveVar({
      ...currentInput,
      options: updatedOptions,
    });
  }
};

export const setSkuOptions = (
  options: {
    description: string;
    optionConfigId: string;
    optionContentsId: string;
  }[],
) => {
  const currentInput = stateReactiveVar();

  const optionInputs = options.reduce(
    (acc, { optionConfigId, description }) => {
      acc[optionConfigId] = {
        value: description,
        ...commonValidation(description, VALIDATION_TYPE.OPTION_OPTIONAL),
      };

      return acc;
    },
    {} as DefaultOptionValidationType["options"],
  );

  stateReactiveVar({
    ...currentInput,
    options: optionInputs,
  });
};

export const setSkuIsShow = (value: DefaultNoValidationType["isShow"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    isShow: value,
  });
};

// 値をすべて更新
export const setSkuInput = ({
  code,
  name,
  amount,
  stock,
  options,
  tax,
  isShow,
}: {
  code: DefaultValidationType["code"]["value"];
  name: DefaultValidationType["name"]["value"];
  amount: DefaultValidationType["amount"]["value"];
  stock: DefaultValidationType["stock"]["value"];
  options: {
    description: string;
    optionConfigId: string;
    optionContentsId: string;
  }[];
  tax: DefaultNoValidationType["tax"];
  isShow: DefaultNoValidationType["isShow"];
}) => {
  setSkuCode(code);
  setSkuName(name);
  setSkuAmount(amount);
  setSkuStock(stock);
  setSkuOptions(options);
  setSkuTax(tax);
  setSkuIsShow(isShow);
};

// 初期化
export const setInitSkuInput = () => {
  const currentInfos = stateReactiveVar();
  // optionsのみ現在の値を参照して初期化
  const resetOptions = Object.keys(currentInfos.options).reduce((acc, key) => {
    acc[key] = {
      value: "",
      isError: false,
      validationMessage: "",
    };

    return acc;
  }, {} as DefaultOptionValidationType["options"]);

  stateReactiveVar({
    ...INIT_STATE,
    options: resetOptions,
  });
};

// 入力エラーチェック エラーの場合true
export const checkSkuInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { tax: _tax, isShow: _, options, ...validationInfos } = currentInfos;
  const requiredValues = Object.values(validationInfos);
  const validationValues = Object.values({
    ...validationInfos,
    ...options,
  });

  // 必須チェック
  const isEmpty = requiredValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useSkuInput;
