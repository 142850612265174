import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Input from "../components/Input/Input";
import List from "../components/List/List";
import Dropdown from "../components/Dropdown/Dropdown";
import ListItem from "../components/List/ListItem";
import DetailsMenu from "../components/Popover/DetailsMenu";
import {
  ADMIN_USER_DETAIL_CONTACT_ITEMS,
  EVENT_IMPORTANCE_OPTIONS,
  EVENT_SORT_OPTIONS,
  EventHistoriesPriorityID,
  ROLE_KEY,
  USER_DETAIL_TAB_OPTIONS,
} from "../constants/common";
import { useCheckErrorThrowError } from "../utils/checkError";
import { convertEventHistories } from "../utils/convertDisplay";
import useEventHistoriesDbActions from "../hooks/common/useEventHistoriesDbActions";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import InformationDialog from "../components/Dialog/InformationDialog";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";
import useUserDetailTabConditions, {
  setUserDetailTab,
} from "../hooks/commonCondition/useUserDetailTabConditions";
import FormSet from "../components/FormSet/FormSet";

const EventHistories = () => {
  const sortButtonRef = React.useRef<HTMLDivElement>(null);
  const [isSortOpen, setIsSortOpen] = React.useState(false);
  const [searchCriteria, setSearchCriteria] = React.useState("");
  const [eventSort, setEventSort] = React.useState("registered_date");

  const [events, setEvents] = useStateCustomObj<EventHistoriesDisplayType[]>(
    [],
  );
  const [inputObj, setInputObj] = useStateCustomObj({
    eventId: "",
    priority: "",
    description: "",
  });
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = React.useState(false);

  const [{ userDetailTab }] = useUserDetailTabConditions();

  const {
    error: eventHistoriesError,
    eventHistories,
    handleUpsert,
    handleDelete,
  } = useEventHistoriesDbActions({ eventSort, searchCriteria });

  // 登録権限
  const hasRegisterRole = useCheckHasRole(ROLE_KEY.USER_EVENT_HISTORY_REGISTER);
  const isDisabled = !hasRegisterRole;

  useCheckErrorThrowError([eventHistoriesError]);

  useEffect(() => {
    const convertResults = convertEventHistories(eventHistories);
    setEvents(convertResults);
  }, [eventHistories, setEvents]);

  // 検索
  const handleChangeSearch = (value: string) => {
    setSearchCriteria(value);
  };

  // ソート選択
  const handleSelectSort = (value: string) => {
    setEventSort(value);
    setIsSortOpen(false);
  };

  // 選択・入力
  const handleSelectInput = (value: string, key: string) => {
    setInputObj((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // キャンセル
  const handleClear = () => {
    setInputObj({
      eventId: "",
      description: "",
      priority: "",
    });
  };

  // 登録ボタン
  const handleUpsertButton = () => {
    handleUpsert(inputObj, handleClear);
  };

  // 3点リーダ押下
  const handleEventDetail = (
    label: string,
    event: EventHistoriesDisplayType,
  ) => {
    const selectedItem = USER_DETAIL_TAB_OPTIONS.find(
      (item) => item.label === label,
    );
    if (selectedItem) {
      if (selectedItem.value === "0") {
        // 編集
        setInputObj({
          eventId: event.id,
          description: event.description,
          priority: event.priorityNum.toString(),
        });
      } else if (selectedItem.value === "1") {
        // 削除
        setInputObj((prevState) => ({
          ...prevState,
          eventId: event.id,
        }));
        setIsOpenDeleteConfirm(true);
      }
    }
  };

  // 削除ボタン
  const handleDeleteButton = () => {
    handleDelete(inputObj, handleClear, () => setIsOpenDeleteConfirm(false));
  };

  const getPriorityState = (priority: number) => {
    if (priority === EventHistoriesPriorityID.LOW) {
      return "success";
    }
    if (priority === EventHistoriesPriorityID.MEDIUM) {
      return "warning";
    }
    if (priority === EventHistoriesPriorityID.HIGH) {
      return "error";
    }

    return "neutral";
  };

  return (
    <>
      <div className="admin-user__info-detail">
        <div className="util-flex util-px-16 util-flex--grow-one util-overflow-y">
          <LayoutBox direction="column" gap="2x" fullWidth>
            <LayoutBox fullWidth>
              <Tabs
                selectedTab={userDetailTab}
                size="medium"
                tabs={ADMIN_USER_DETAIL_CONTACT_ITEMS}
                bottomBorder
                onClick={setUserDetailTab}
              />
            </LayoutBox>
            <>
              <div className="util-full-width">
                <LayoutBox gap="2x">
                  <Input
                    leftIcon="search"
                    placeholder="検索"
                    width="100%"
                    onChange={handleChangeSearch}
                  />
                  <LayoutBox>
                    <div
                      ref={sortButtonRef}
                      onClick={() => setIsSortOpen(true)}
                    >
                      <Button
                        type="sub"
                        color="main"
                        size="large"
                        icon="tune"
                      />
                    </div>
                    <Popover
                      open={isSortOpen}
                      activatorRef={sortButtonRef}
                      width={133}
                      direction="underRight"
                      forceDirection
                      withShadow
                      toggleOpen={() => setIsSortOpen(!isSortOpen)}
                    >
                      <MenuList
                        items={EVENT_SORT_OPTIONS}
                        onClick={handleSelectSort}
                      />
                    </Popover>
                  </LayoutBox>
                </LayoutBox>
              </div>

              <div className="admin-user__info-detail-list">
                <List>
                  {events.map((event) => (
                    <ListItem
                      key={event.id}
                      id={event.id}
                      center={
                        <>
                          <LayoutBox>
                            <Text size="small" color="neutralLight">
                              {event.registeredDateDisplay}
                            </Text>
                            <Tag
                              showIcon={false}
                              state={getPriorityState(event.priorityNum)}
                              label={`重要度：${event.priorityStr}`}
                              emphasis={
                                event.priorityNum ===
                                EventHistoriesPriorityID.HIGH
                              }
                            />
                          </LayoutBox>
                          <Text whiteSpace="pre-line">{event.description}</Text>
                          <Text size="small" color="neutralLight">
                            応対者: {event.registeredUserName}
                          </Text>
                        </>
                      }
                      right={
                        isDisabled ? (
                          <></>
                        ) : (
                          <DetailsMenu
                            items={USER_DETAIL_TAB_OPTIONS}
                            buttonSize="medium"
                            width={101}
                            onClick={(label) => handleEventDetail(label, event)}
                          />
                        )
                      }
                    />
                  ))}
                </List>
              </div>
            </>
          </LayoutBox>
        </div>
        <div className="admin-user__info-detail--footer">
          <div className="admin-user__info-detail--footer-inner">
            <>
              <LayoutBox gap="2x">
                <div className="admin-user__info-detail--footer-select">
                  <span className="font-component-button-small">重要度</span>
                  <Dropdown
                    value={inputObj.priority}
                    width="100px"
                    items={EVENT_IMPORTANCE_OPTIONS}
                    onChange={(value) => handleSelectInput(value, "priority")}
                    disabled={isDisabled}
                  />
                </div>
              </LayoutBox>
              <div className="admin-user__info-detail--footer-text">
                <FormSet label="内容" vertical required="icon">
                  <Input
                    multiLine
                    width="100%"
                    value={inputObj.description}
                    onChange={(value) =>
                      handleSelectInput(value, "description")
                    }
                    disabled={isDisabled}
                  />
                </FormSet>
              </div>
            </>
          </div>
          <div className="admin-user__info-detail--footer-button">
            <LayoutBox align="center" justify="center">
              <Button
                size="large"
                color="neutral"
                type="sub"
                onClick={handleClear}
                disabled={isDisabled}
              >
                キャンセル
              </Button>
              <Button
                size="large"
                onClick={handleUpsertButton}
                disabled={isDisabled || !inputObj.description}
              >
                登録
              </Button>
            </LayoutBox>
          </div>
        </div>
      </div>
      {isOpenDeleteConfirm && (
        <InformationDialog
          alertLevel="error"
          open={isOpenDeleteConfirm}
          closeDialog={() => setIsOpenDeleteConfirm(false)}
          title="削除してもよろしいですか？"
          footer={
            <LayoutBox>
              <Button color="danger" size="medium" onClick={handleDeleteButton}>
                削除
              </Button>
              <Button
                color="neutral"
                type="sub"
                size="medium"
                onClick={() => setIsOpenDeleteConfirm(false)}
              >
                キャンセル
              </Button>
            </LayoutBox>
          }
        >
          この操作は取り消せません。
        </InformationDialog>
      )}
    </>
  );
};

export default EventHistories;
