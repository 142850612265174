import createReactiveVar from "./createReactiveVar";
import { ReactiveVarHooks, useReactiveVarHooks } from "./useReactiveVarHooks";

type ProductMgmtIdType = {
  itemId: string;
  skuId: string;
};

// 商品管理の商品とSKUのIDを管理
const initialItemId = localStorage.getItem("itemId") || "";
const initialSkuId = localStorage.getItem("skuId") || "";
const stateReactiveVar = createReactiveVar<ProductMgmtIdType>({
  itemId: initialItemId,
  skuId: initialSkuId,
});
const useProductMgmtId = (): ReactiveVarHooks<ProductMgmtIdType> =>
  useReactiveVarHooks(stateReactiveVar);

// 商品IDをセット
export const setItemId = (itemId: string) => {
  const currentState = stateReactiveVar();
  if (currentState.itemId !== itemId)
    stateReactiveVar({
      ...currentState,
      itemId,
    });
  localStorage.setItem("itemId", itemId);
};

// skuIDをセット
export const setSkuId = (skuId: string) => {
  const currentState = stateReactiveVar();
  if (currentState.skuId !== skuId)
    stateReactiveVar({
      ...currentState,
      skuId,
    });
  localStorage.setItem("skuId", skuId);
};

// 初期化
export const initProductMgmtId = () => {
  localStorage.setItem("itemId", "");
  localStorage.setItem("skuId", "");
  stateReactiveVar({
    itemId: "",
    skuId: "",
  });
};

export default useProductMgmtId;
