import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc014, getMongoDb } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useId from "../../common/useId";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useNlc014Conditions from "./useNlc014Conditions";
import useForceUpdate from "../../common/useForceUpdate";

// MRIのテスト結果を取得
const useNlc014DbFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [{ status }] = useNlc014Conditions();
  // 患者ID
  const [id] = useId();
  const [forceUpdate] = useForceUpdate();

  const [mriTestResult, setMriTestResult] = useState<PatientNlc014StateType[]>(
    [],
  );
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!id) redirectToInvalidAccessPage();

        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_ONLINE_TESTS,
        );
        const aggregate = getAggregateNlc014(id, status, skip, perPage);
        const aggregateResult = (await collection.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNlc014StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setMriTestResult(result);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, perPage, skip, status, forceUpdate]);

  return { error, mriTestResult };
};

export default useNlc014DbFetch;
