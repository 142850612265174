import { useState, useEffect } from "react";

import { getMongoDb, getAggregateOneOrganization } from "../../utils/query";
import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import useForceUpdate from "./useForceUpdate";
import useOrganizationId from "./useOrganizationId";
import { ADMIN } from "../../constants/pagePaths";
import { useNavigateCustom } from "../base/usePageTransitionCustom";

// 団体情報を1件取得
const useOneOrganizationFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [id] = useOrganizationId();

  const [fetchResult, setFetchResult] = useState<OrganizationType | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();
  const navigateCustom = useNavigateCustom();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        // 団体情報を取得
        const collection = getMongoDb(currentUser, Collection.ORGANIZATIONS);
        const conditions = { _id: id };
        const aggregate = getAggregateOneOrganization(conditions);
        const result = (await collection.aggregate(
          aggregate,
        )) as OrganizationType[];

        // 団体情報がない場合、団体一覧へ
        if (result.length < 1) {
          navigateCustom(ADMIN.NLI019);
        }

        setFetchResult(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id, navigateCustom, forceUpdate.forceUpdateCount]);

  return { fetchError, fetchResult };
};

export default useOneOrganizationFetch;
