import { INIT_SEARCH_CRITERIA } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type SearchCriteriaStatus = {
  [key: string]: boolean;
};

type ConditionsType = {
  search: {
    itemCode: string;
    itemName: string;
    status?: SearchCriteriaStatus;
  };
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  search: INIT_SEARCH_CRITERIA.NLI007,
});

const useNli007Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNli007Conditions;
