import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getMongoDb } from "../../../utils/query";
import { Collection, PetStatusID } from "../../../constants/common";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import useNlc028Conditions from "./useNlc028Conditions";
import { closeNlc028Dialog } from "./useNlc028Dialog";
import { closeNlc028CancelDialog } from "./useNlc028CancelDialog";

const useNlc028DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);

  const [{ modalityId, interpretationNote }] = useNlc028Conditions();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // PET 読影登録、ステータスを診断済
  const confirmSelection = () => {
    void (async () => {
      try {
        openUpdateActionLoading();
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        await collection.updateOne(
          { _id: modalityId },
          {
            $set: {
              pet_status: PetStatusID.DIAGNOSED,
              interpretation_note: interpretationNote,
              updated_at: new Date(),
            },
          },
        );
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNlc028Dialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // 結果送信取消
  const sendCancel = () => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const updateData = {
          pet_status: PetStatusID.DIAGNOSED,
          updated_at: new Date(),
        };

        await collection.updateOne({ _id: modalityId }, { $set: updateData });
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc028Dialog();
        closeNlc028CancelDialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, confirmSelection, sendCancel };
};

export default useNlc028DbActions;
