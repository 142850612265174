import React from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import InformationDialog from "../components/Dialog/InformationDialog";
import Text from "../components/Text/Text";
import Icon from "../components/Icon/Icon";

type MIB002Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsParentOpen: (isOpen: boolean) => void;
  name: string;
  tel: string;
};

const MIB004: React.FC<MIB002Props> = ({
  isOpen,
  setIsOpen,
  setIsParentOpen,
  name,
  tel,
}) => {
  const handleCloseButton = () => {
    setIsOpen(false);
    setIsParentOpen(false);
  };

  return (
    <>
      <InformationDialog
        title="予約を取り下げました"
        height="283px"
        alertLevel="none"
        open={isOpen}
        closeDialog={handleCloseButton}
        footer={
          <div className="util-mb-4">
            <LayoutBox>
              {/* <Button color="neutral" type="sub" size="medium">
                キャンセル
              </Button> */}
              <Button type="primary" size="medium" onClick={handleCloseButton}>
                完了
              </Button>
            </LayoutBox>
          </div>
        }
      >
        <LayoutBox direction="column" fullWidth>
          <div className="util-mb-12">
            <Text>
              予約を取り下げた旨は、必ず患者さまに電話連絡をしてください。
            </Text>
          </div>
          <Sheet padding="16px" type="gray-custom">
            <LayoutBox
              direction="column"
              gap="2x"
              justify="center"
              align="center"
            >
              <LayoutBox>
                <Text size="large">{name}</Text>
              </LayoutBox>
              <LayoutBox gap="2x">
                <Icon size="small" icon="phone" color="#096AE2" />
                <Text bold>{tel}</Text>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </LayoutBox>
      </InformationDialog>
    </>
  );
};

export default MIB004;
