import { useState } from "react";
import Spinner from "../components/Loading/Spinner";
import { ADMIN, COMMON, HOSPITAL } from "../constants/pagePaths";
import { useLoadingAction } from "../hooks/base/useLoadingAction";
import { useLoadingPage } from "../hooks/base/useLoadingPage";
import NLJ002 from "../pages/NLJ002";
import useMasterRoleDbActions from "../hooks/common/useMasterRoleDbActions";
import { useCheckErrorThrowError } from "../utils/checkError";

const PageCommon = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [{ isLoadingPage }] = useLoadingPage();
  const [{ text, isLoadingAction }] = useLoadingAction();
  const { roleFetchError } = useMasterRoleDbActions();
  useCheckErrorThrowError([roleFetchError]);

  const [pageId, setPageId] = useState("");
  if (process.env.NODE_ENV === "development") {
    const getPathKey = (
      enumObj: { [key: string]: string },
      path: string,
    ): string | undefined =>
      Object.keys(enumObj).find((key) => enumObj[key] === path);
    const commonKey = getPathKey(COMMON, window.location.pathname);
    const hospitalKey = getPathKey(ADMIN, window.location.pathname);
    const adminKey = getPathKey(HOSPITAL, window.location.pathname);
    const currentPageId = commonKey || hospitalKey || adminKey;
    if (currentPageId !== pageId) {
      // eslint-disable-next-line no-console
      console.log(currentPageId);
      if (currentPageId) setPageId(currentPageId);
    }
  }

  return (
    <>
      {/* ページ全体のスピナー */}
      {isLoadingPage && <Spinner color="white" size="large" isLoad />}
      {/* DBアクション中のスピナー */}
      {isLoadingAction && (
        <Spinner color="white" isActionLoad actionText={text} />
      )}
      {children}
      <NLJ002 />
    </>
  );
};

export default PageCommon;
