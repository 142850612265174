import React from "react";

export type ContentFooterProps = {
  footerLeft?: JSX.Element;
  footerCenter?: JSX.Element;
  footerRight?: JSX.Element;
};

const ContentFooter = ({
  footerLeft,
  footerCenter,
  footerRight,
}: ContentFooterProps) => (
  <div className="content-footer">
    <div className="content-footer__left">{footerLeft}</div>
    <div className="content-footer__center">{footerCenter}</div>
    <div className="content-footer__right">{footerRight}</div>
  </div>
);

ContentFooter.defaultProps = {
  footerLeft: undefined,
  footerCenter: undefined,
  footerRight: undefined,
};

export default ContentFooter;
