import React, { useEffect } from "react";
import ReactDatePicker, {
  registerLocale,
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker";
import {
  getMonth,
  getYear,
  getDate,
  getHours,
  getMinutes,
  format,
} from "date-fns";
import { ja, enUS } from "date-fns/locale";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import MonthSelect from "./MonthSelect";
import Dropdown from "../Dropdown/Dropdown";

registerLocale("ja", ja);
registerLocale("en", enUS);

const HOUR_OPTIONS = new Array(24).fill(1).map((_, i) => ({
  label: i.toString().length === 1 ? `0${i}` : `${i}`,
  value: `${i}`,
}));

const MINUTE_OPTIONS = new Array(60).fill(1).map((_, i) => ({
  label: i.toString().length === 1 ? `0${i}` : `${i}`,
  value: `${i}`,
}));

export type DatePickerProps = {
  size?: "default" | "small";
  type?: "single" | "range";
  status?: "default" | "error";
  selectedDate?: Date;
  selectedDateRange?: { startDate?: Date; endDate?: Date };
  disabled?: boolean;
  placeholder?: string;
  placeholderEnd?: string;
  width?: string;
  timePicker?: boolean;
  locale?: string;
  maxWidth?: string;
  masks?: {
    title: string;
    input: string;
  };
  onChangeDate?: (date: Date) => void;
  onChangeRangeDate?: (start?: Date, end?: Date) => void;
} & Omit<ReactDatePickerProps, "onChange">;

const DatePicker = ({
  size = "default",
  type = "single",
  status = "default",
  selectedDate,
  selectedDateRange,
  disabled = false,
  placeholder,
  placeholderEnd,
  width,
  timePicker = false,
  locale = "ja",
  maxWidth,
  onChangeDate,
  onChangeRangeDate,
  masks = { title: "yyyy/MM", input: "yyyy/MM/dd" },
  ...rest
}: DatePickerProps) => {
  const datePickerClass = React.useMemo(() => {
    const sizeClass = [`date-picker__input--${size}`];
    const typeClass = [`date-picker__input--${type}`];
    const statusClass = [`date-picker__input--status-${status}`];
    const disabledClass = disabled ? [`date-picker__input--disabled`] : [];

    return [
      "date-picker__input",
      ...sizeClass,
      ...typeClass,
      ...statusClass,
      ...disabledClass,
    ].join(" ");
  }, [disabled, size, status, type]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomTimePicker = React.forwardRef(
    (
      { date, onChange }: { date: Date; onChange: (value: string) => void },
      ref: React.Ref<HTMLDivElement>,
    ) => (
      <div className="time-picker__container" ref={ref}>
        <Icon icon="access_time" size="small" />
        <Dropdown
          items={HOUR_OPTIONS}
          width="96px"
          size="small"
          value={`${getHours(date)}`}
          onChange={(hour) => {
            const changeDate = !Number.isNaN(date.getTime())
              ? date
              : new Date();
            changeDate.setHours(Number.parseInt(hour));
            onChange(format(changeDate, "hh:mm"));
          }}
        />
        :
        <Dropdown
          items={MINUTE_OPTIONS}
          width="96px"
          size="small"
          value={`${getMinutes(date)}`}
          onChange={(hour) => {
            const changeDate = !Number.isNaN(date.getTime())
              ? date
              : new Date();
            changeDate.setMinutes(Number.parseInt(hour));
            onChange(format(changeDate, "hh:mm"));
          }}
        />
      </div>
    ),
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomDateInput = React.forwardRef(
    (
      {
        value,
        onClick,
      }: {
        value: string;
        onClick: React.MouseEventHandler<HTMLInputElement>;
      },
      ref: React.Ref<HTMLDivElement>,
    ) => (
      <div className={datePickerClass} style={{ width }} ref={ref}>
        <input
          defaultValue={value}
          disabled={disabled}
          placeholder={placeholder}
          onClick={onClick}
          readOnly
        />
        {status === "error" && (
          <span className="date-picker__input--error-icon">
            <Icon icon="error" size="xs" />
          </span>
        )}
        <span className="date-picker__input--calendar-icon">
          <Icon icon="calendar_today" size="small" />
        </span>
      </div>
    ),
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomDateRangeInput = React.forwardRef(
    (
      { onClick }: { onClick: React.MouseEventHandler<HTMLInputElement> },
      ref: React.Ref<HTMLDivElement>,
    ) => (
      <div className="range" ref={ref}>
        <div
          className={datePickerClass}
          style={{ width, maxWidth }}
          onClick={onClick}
        >
          <input
            defaultValue={
              selectedDateRange?.startDate
                ? format(selectedDateRange.startDate, masks.input)
                : ""
            }
            disabled={disabled}
            placeholder={placeholder}
          />
          <span className="tilde">～</span>
          <input
            defaultValue={
              selectedDateRange?.endDate
                ? format(selectedDateRange.endDate, masks.input)
                : ""
            }
            disabled={disabled}
            placeholder={placeholderEnd}
          />
          {status === "error" && (
            <span className="date-picker__input--error-icon">
              <Icon icon="error" size="xs" />
            </span>
          )}
          <span className="date-picker__input--calendar-icon">
            <Icon icon="calendar_today" size="small" />
          </span>
        </div>
      </div>
    ),
  );

  const customHeader = React.useCallback(
    ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
    }: ReactDatePickerCustomHeaderProps) => (
      <div className="date-picker__header">
        <Button
          onClick={decreaseMonth}
          type="sub"
          color="neutral"
          size="small"
          icon="chevron_left"
        />
        <MonthSelect
          value={date}
          locale="ja"
          onChange={(changedDate: Date) => {
            changeYear(getYear(changedDate));
            changeMonth(getMonth(changedDate));
          }}
          dateFormat={masks.title}
        />
        <Button
          onClick={increaseMonth}
          type="sub"
          color="neutral"
          size="small"
          icon="chevron_right"
        />
      </div>
    ),
    [masks.title],
  );

  const customRangeHeader = React.useCallback(
    ({
      monthDate,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      customHeaderCount,
    }: ReactDatePickerCustomHeaderProps) => (
      <div className="date-picker__header">
        <span
          style={{
            visibility: customHeaderCount === 0 ? "visible" : "hidden",
          }}
        >
          <Button
            onClick={decreaseMonth}
            type="sub"
            color="neutral"
            size="small"
            icon="chevron_left"
          />
        </span>

        <MonthSelect
          value={monthDate}
          locale="ja"
          onChange={(date: Date) => {
            changeYear(getYear(date));
            changeMonth(getMonth(date));
          }}
          dateFormat={masks.title}
        />
        <span
          style={{
            visibility: customHeaderCount === 1 ? "visible" : "hidden",
          }}
        >
          <Button
            onClick={increaseMonth}
            type="sub"
            color="neutral"
            size="small"
            icon="chevron_right"
          />
        </span>
      </div>
    ),
    [masks.title],
  );

  const renderDayContents = React.useCallback(
    (day: number, date?: Date) => (
      <div className="date-picker__date">{date ? getDate(date) : ""}</div>
    ),
    [],
  );

  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const _onChangeRangeDate = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onChangeRangeDate) {
      onChangeRangeDate(start, end);
    }
  };

  useEffect(() => {
    if (
      selectedDateRange?.endDate !== endDate &&
      selectedDateRange?.startDate !== startDate
    ) {
      setStartDate(selectedDateRange?.startDate || null);
      setEndDate(selectedDateRange?.endDate || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  if (type === "range") {
    return (
      <ReactDatePicker
        monthsShown={2}
        customInput={React.createElement(CustomDateRangeInput)}
        renderCustomHeader={customRangeHeader}
        locale={locale}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={_onChangeRangeDate}
        renderDayContents={renderDayContents}
        dateFormat={masks.input}
        calendarClassName="date-picker"
        showPopperArrow={false}
        selectsRange
        disabled={disabled}
        calendarStartDay={1}
        focusSelectedMonth
        {...rest}
      />
    );
  }

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={(date) => date && onChangeDate && onChangeDate(date)}
      customInput={React.createElement(CustomDateInput)}
      renderCustomHeader={customHeader}
      showTimeInput={timePicker}
      customTimeInput={React.createElement(CustomTimePicker)}
      renderDayContents={renderDayContents}
      showPopperArrow={false}
      calendarClassName="date-picker"
      locale={locale}
      dateFormat={masks.input}
      calendarStartDay={1}
      disabled={disabled}
      focusSelectedMonth
      {...rest}
    />
  );
};

DatePicker.defaultProps = {
  size: "default",
  type: "single",
  status: "default",
  selectedDate: null,
  selectedDateRange: { startDate: "", endDate: "" },
  disabled: false,
  placeholder: "",
  placeholderEnd: "",
  width: undefined,
  locale: "ja",
  maxWidth: "",
  masks: { title: "yyyy/MM", input: "yyyy/MM/dd" },
  timePicker: false,
  onChangeDate: undefined,
  onChangeRangeDate: undefined,
};

export default DatePicker;
