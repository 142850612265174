import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<string>("");

const useMid002Conditions = (): ReactiveVarHooks<string> =>
  useReactiveVarHooks(stateReactiveVar);

export const setModalityId = (id: string) => {
  stateReactiveVar(id);
};

export default useMid002Conditions;
