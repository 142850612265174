import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  notificationTitle: ValidationResultType<string>;
  notificationDetail: ValidationResultType<string>;
};
// バリデーションの不要な型定義
type DefaultType = {
  selectedDate: Date;
  time: Date;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  notificationTitle: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  notificationDetail: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  selectedDate: new Date(),
  time: new Date(),
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useNotificationInput = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setUserEditValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setNotificationTitle = (
  value: DefaultValidationType["notificationTitle"]["value"],
) => {
  _setUserEditValidation(
    "notificationTitle",
    value,
    VALIDATION_TYPE.ALLOW_ALL_REQUIRED,
  );
};
export const setNotificationDetail = (
  value: DefaultValidationType["notificationDetail"]["value"],
) => {
  _setUserEditValidation(
    "notificationDetail",
    value,
    VALIDATION_TYPE.ALLOW_ALL_REQUIRED,
  );
};

export const setSelectedDate = (value: DefaultType["selectedDate"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    selectedDate: value,
  });
};
export const setTime = (value: DefaultType["time"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    time: value,
  });
};

// 値をすべて更新
export const setNotificationInput = ({
  notificationTitle,
  notificationDetail,
  selectedDate,
  time,
}: {
  notificationTitle: DefaultValidationType["notificationTitle"]["value"];
  notificationDetail: DefaultValidationType["notificationDetail"]["value"];
  selectedDate: DefaultType["selectedDate"];
  time: DefaultType["time"];
}) => {
  setNotificationTitle(notificationTitle);
  setNotificationDetail(notificationDetail);
  setSelectedDate(selectedDate);
  setTime(time);
};

export const setInitNotificationInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkNotificationInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    selectedDate: _selectedDate,
    time: _time,
    ...validationInfos
  } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useNotificationInput;
