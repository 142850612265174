import React, { useEffect } from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Sheet from "../components/Sheet/Sheet";
import Tag from "../components/Tag/Tag";
import Text from "../components/Text/Text";
import Label from "../components/FormSet/Label";
import List from "../components/List/List";
import Dropdown from "../components/Dropdown/Dropdown";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import ListItem from "../components/List/ListItem";
import Divider from "../components/Divider/Divider";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useAdminUserDetailTabFetch from "../hooks/useAdminUserDetailTabFetch";
import {
  INSPECT_MRI_STATUS_OPTIONS,
  MASTER_MANAGEMENT_OPTIONS,
  MriStatusID,
  MriStatusLabels,
  PatientTypeID,
  TEST_RESULT_ITEMS,
  USER_EVALUATION,
  UserStatusID,
} from "../constants/common";
import {
  formatDate,
  getMriStatusTagState,
  handleSelectMainTab,
  handleSelectMasterInfo,
  useHandleSelectAdminUserDetailTab,
  useHandleTestResultSubTab,
} from "../utils/utils";
import { ADMIN } from "../constants/pagePaths";
import usePatientInfoFetch from "../hooks/usePatientInfoFetch";
import { convertPatientInfo } from "../utils/convertDisplay";
import useEvaluationDbActions from "../hooks/common/useEvaluationDbActions";
import Pagination from "../components/Pagination/Pagination";
import useNlc014DbActions from "../hooks/pages/NLC014/useNlc014DbActions";
import useNlc014Conditions, {
  setSelectMriId,
  setStatus,
} from "../hooks/pages/NLC014/useNlc014Conditions";
import NLC015 from "./NLC015";
import useNlc015Dialog, {
  openNlc015Dialog,
} from "../hooks/pages/NLC015/useNlc015Dialog";
import EventHistories from "../features/EventHistories";
import UserDetailHeader from "../features/UserDetailHeader";
import Chat from "../features/Chat";
import MedicalRecord from "../features/MedicalRecord";
import useUserDetailTabConditions from "../hooks/commonCondition/useUserDetailTabConditions";
import useNlc014Dialog, {
  closeNlc014CancelDialog,
  closeNlc014SendDialog,
  openNlc014CancelDialog,
  openNlc014SendDialog,
} from "../hooks/pages/NLC014/useNlc014Dialog";
import InformationDialog from "../components/Dialog/InformationDialog";
import useSignedMriUrl from "../hooks/common/useSignedMriUrl";
import useNlc014DbFetch from "../hooks/pages/NLC014/useNlc014DbFetch";
import RadioButton from "../components/RadioButton/RadioButton";

const NLC014 = () => {
  const masterManagementButtonRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("3");

  const [{ status }] = useNlc014Conditions();
  const [isNlc015Open] = useNlc015Dialog();
  const [{ isCancelConfirm, isSendConfirm }] = useNlc014Dialog();
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();
  const { userDetailTabError, userDetailTabItems } =
    useAdminUserDetailTabFetch();
  const { error: patientFetchError, patientInfo } = usePatientInfoFetch();
  const [{ userDetailTab }] = useUserDetailTabConditions();
  const { fetchSignedMriUrl, signedMriUrlError } = useSignedMriUrl();
  const { mriTestResult, error: fetchError } = useNlc014DbFetch();
  const { sendResult, cancelReservation, actionError } = useNlc014DbActions();
  const { error: updateEvaluationError, handleUpdateEvaluation } =
    useEvaluationDbActions();

  const {
    id,
    userId,
    name,
    birth,
    genderStr,
    tel,
    email,
    address,
    evaluation,
    patientTypeNum,
    patientTypeStr,
    statusNum,
    statusStr,
    orgName,
  } = convertPatientInfo(patientInfo);

  useCheckErrorThrowError([
    mainTabError,
    userDetailTabError,
    patientFetchError,
    updateEvaluationError,
    fetchError,
    actionError,
    signedMriUrlError,
  ]);

  useEffect(() => {
    setSelectedValue(evaluation);
  }, [evaluation]);

  const handleChangeEvaluation = React.useCallback(
    (value: string) => {
      handleUpdateEvaluation(id, value);
      setSelectedValue(value);
    },
    [handleUpdateEvaluation, id],
  );

  const handleSelectAdminUserDetailTab = useHandleSelectAdminUserDetailTab();
  const handleTestResultSubTab = useHandleTestResultSubTab();

  const handleSendMriResult = (id: string) => {
    setSelectMriId(id);
    openNlc014SendDialog();
  };

  const handleCancelReservation = (id: string) => {
    setSelectMriId(id);
    openNlc014CancelDialog();
  };

  const handleOpenReport = async (id: string) => {
    const signedUrl = await fetchSignedMriUrl(id);
    if (signedUrl) {
      window.open(signedUrl, "_blank");
    }
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={2}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div
                  ref={masterManagementButtonRef}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={masterManagementButtonRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-user__wrapper">
          <div className="admin-user__info">
            <LayoutBox direction="column" gap="2x">
              <UserDetailHeader
                patientInfo={patientInfo}
                backPath={ADMIN.NLC005}
              />
              <Sheet className="util-px-24 util-py-24">
                <LayoutBox direction="column" gap="4x">
                  <div className="admin-user__profile">
                    <LayoutBox direction="column" gap="2x">
                      <LayoutBox direction="column" fullWidth>
                        <LayoutBox align="center">
                          <span className="util-font-neutral--light font-component-chip">
                            {userId}
                          </span>
                          <Tag
                            showIcon={false}
                            label={patientTypeStr}
                            state={
                              patientTypeNum === PatientTypeID.MEMBER
                                ? "information"
                                : patientTypeNum === PatientTypeID.ORG
                                ? "success"
                                : patientTypeNum === PatientTypeID.INVITATION
                                ? "warning"
                                : "neutral"
                            }
                          />
                          <Tag
                            showIcon={false}
                            label={statusStr}
                            state={
                              statusNum === UserStatusID.ACTIVE ||
                              statusNum === UserStatusID.INVITATION
                                ? "success"
                                : statusNum === UserStatusID.CANCELLATION
                                ? "error"
                                : statusNum === UserStatusID.SUSPENDED
                                ? "warning"
                                : "neutral"
                            }
                          />
                        </LayoutBox>
                        <LayoutBox fullWidth align="center">
                          <div className="util-half-width">
                            <Text size="2xl" bold>
                              {name}
                            </Text>
                          </div>
                          {patientTypeNum !== PatientTypeID.INVITATION && (
                            <div className="util-half-width">
                              <LayoutBox align="center">
                                <Label base size="medium" width="120px">
                                  認知症リスク
                                  <br />
                                  評価
                                </Label>
                                <div className="util-flex util-flex--wrap util-flex--col-gap16">
                                  {USER_EVALUATION.map((item) => (
                                    <RadioButton
                                      key={item.value}
                                      name="userRatingRadioButton"
                                      value={item.value}
                                      checked={selectedValue === item.value}
                                      onSelect={handleChangeEvaluation}
                                    >
                                      {item.label}
                                    </RadioButton>
                                  ))}
                                </div>
                              </LayoutBox>
                            </div>
                          )}
                        </LayoutBox>
                      </LayoutBox>
                      <Divider margin="0px" />
                      <div className="util-full-width">
                        <LayoutBox direction="column">
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    生年月日
                                  </Label>
                                  {birth}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    性別
                                  </Label>
                                  {genderStr}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    電話番号
                                  </Label>
                                  {tel}
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    メールアドレス
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    <Text>{email}</Text>
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                          <div className="util-full-width">
                            <LayoutBox justify="between">
                              <div className="util-half-width">
                                <LayoutBox align="stretch">
                                  <Label base size="medium" width="120px">
                                    住所
                                  </Label>
                                  <LayoutBox align="center" justify="center">
                                    {address}
                                  </LayoutBox>
                                </LayoutBox>
                              </div>
                              <div className="util-half-width">
                                <LayoutBox align="center">
                                  <Label base size="medium" width="120px">
                                    団体
                                  </Label>
                                  {orgName}
                                </LayoutBox>
                              </div>
                            </LayoutBox>
                          </div>
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                  <div className="util-full-width">
                    <LayoutBox direction="column" gap="2x">
                      <Tabs
                        selectedTab={2}
                        tabs={userDetailTabItems}
                        size="medium"
                        bottomBorder
                        onClick={handleSelectAdminUserDetailTab}
                      />
                      <ToggleButton
                        selectedButton="2"
                        items={TEST_RESULT_ITEMS}
                        onClick={handleTestResultSubTab}
                      />
                      <LayoutBox gap="2x">
                        <div className="admin-user__info-detail--footer-select">
                          <span className="font-component-button-small">
                            ステータス
                          </span>
                          <Dropdown
                            value={status.toString()}
                            width="148px"
                            items={INSPECT_MRI_STATUS_OPTIONS}
                            onChange={setStatus}
                          />
                        </div>
                      </LayoutBox>
                      <div className="admin-user__inspection-results">
                        <List height="100%">
                          {mriTestResult.map((mriResultInfo, index) => (
                            <ListItem
                              key={`${mriResultInfo._id}${index}`}
                              id={mriResultInfo._id}
                              center={
                                <>
                                  <LayoutBox direction="column" fullWidth>
                                    <LayoutBox
                                      fullWidth
                                      direction="row"
                                      gap={
                                        mriResultInfo.isExitMriData
                                          ? undefined
                                          : "none"
                                      }
                                    >
                                      <Text size="small" color="neutralLight">
                                        {mriResultInfo.date &&
                                          `受検日：${formatDate(
                                            mriResultInfo.date,
                                          )} `}
                                        {mriResultInfo.hospital}
                                      </Text>
                                      <Tag
                                        label={
                                          MriStatusLabels[
                                            mriResultInfo.tagLabel as keyof typeof MriStatusLabels
                                          ]
                                        }
                                        showIcon={false}
                                        state={getMriStatusTagState(
                                          MriStatusLabels[
                                            mriResultInfo.tagLabel as keyof typeof MriStatusLabels
                                          ],
                                        )}
                                      />
                                    </LayoutBox>
                                    <Text size="large" bold>
                                      MRI検査
                                    </Text>
                                  </LayoutBox>
                                </>
                              }
                              right={
                                <LayoutBox
                                  align="end"
                                  fullWidth
                                  direction="row"
                                >
                                  {mriResultInfo.tagLabel ===
                                    MriStatusID.DONE_DOCTOR_DIAGNOSED && (
                                    <Button
                                      type="primary"
                                      size="medium"
                                      color="neutral"
                                      onClick={() =>
                                        handleSendMriResult(
                                          mriResultInfo.patient_modality_book_id,
                                        )
                                      }
                                    >
                                      結果送信
                                    </Button>
                                  )}
                                  {mriResultInfo.isExitMriData &&
                                    [
                                      MriStatusID.EXAMINED,
                                      MriStatusID.DONE_AI_DIAGNOSING,
                                      MriStatusID.DONE_AI_DIAGNOSED,
                                      MriStatusID.DONE_DOCTOR_DIAGNOSED,
                                      MriStatusID.USER_REPORTED,
                                    ].includes(mriResultInfo.tagLabel) && (
                                      <Button
                                        type="secondary"
                                        size="medium"
                                        color="neutral"
                                        onClick={() => {
                                          openNlc015Dialog(
                                            mriResultInfo.patient_modality_book_id,
                                          );
                                        }}
                                      >
                                        結果
                                      </Button>
                                    )}
                                  {mriResultInfo.isExitMriData &&
                                    [
                                      MriStatusID.RESERVED,
                                      MriStatusID.RESERVATION_CONFIRMED,
                                      MriStatusID.NO_EXAMINATION,
                                    ].includes(mriResultInfo.tagLabel) && (
                                      <Button
                                        type="secondary"
                                        size="medium"
                                        color="danger"
                                        onClick={() =>
                                          handleCancelReservation(
                                            mriResultInfo.patient_modality_book_id,
                                          )
                                        }
                                      >
                                        予約取消
                                      </Button>
                                    )}
                                  {mriResultInfo.brainsuiteReportPath && (
                                    <Button
                                      type="secondary"
                                      size="medium"
                                      color="neutral"
                                      onClick={() => {
                                        void handleOpenReport(
                                          mriResultInfo.patient_modality_book_id,
                                        );
                                      }}
                                    >
                                      レポート
                                    </Button>
                                  )}
                                </LayoutBox>
                              }
                            />
                          ))}
                        </List>
                        <div className="util-mb-16" />
                        {mriTestResult.length === 0 && (
                          <div className="util-py-40">
                            <LayoutBox
                              direction="column"
                              align="center"
                              justify="center"
                            >
                              <Icon icon="inbox" />
                              <div className="util-font-neutral--light">
                                表示する結果がありません
                              </div>
                            </LayoutBox>
                          </div>
                        )}
                        <LayoutBox justify="end" fullWidth>
                          <Pagination input={false} />
                        </LayoutBox>
                      </div>
                    </LayoutBox>
                  </div>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </div>
          {userDetailTab === 0 && <EventHistories />}
          {userDetailTab === 1 && <Chat />}
          {userDetailTab === 2 && <MedicalRecord />}
        </div>
        {isSendConfirm && (
          <InformationDialog
            alertLevel="normal"
            open={isSendConfirm}
            closeDialog={() => closeNlc014SendDialog()}
            title="結果を送信しますか？"
            footer={
              <LayoutBox>
                <Button color="main" size="medium" onClick={() => sendResult()}>
                  OK
                </Button>
                <Button
                  color="neutral"
                  type="sub"
                  size="medium"
                  onClick={() => closeNlc014SendDialog()}
                >
                  キャンセル
                </Button>
              </LayoutBox>
            }
          >
            <></>
          </InformationDialog>
        )}
        {isCancelConfirm && (
          <InformationDialog
            alertLevel="error"
            open={isCancelConfirm}
            closeDialog={closeNlc014CancelDialog}
            title="予約を取り消してもよろしいですか？"
            footer={
              <LayoutBox>
                <Button
                  color="danger"
                  size="medium"
                  onClick={cancelReservation}
                >
                  予約取消
                </Button>
                <Button
                  color="neutral"
                  type="sub"
                  size="medium"
                  onClick={closeNlc014CancelDialog}
                >
                  キャンセル
                </Button>
              </LayoutBox>
            }
          >
            <></>
          </InformationDialog>
        )}
      </div>
      {isNlc015Open && <NLC015 />}
    </>
  );
};

export default NLC014;
