import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

type ConditionsType = {
  userDetailTab: number;
};

// ユーザー詳細のタブ状態を管理
const stateReactiveVar = createReactiveVar<ConditionsType>({
  userDetailTab: 0,
});
const useUserDetailTabConditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setUserDetailTab = (userDetailTab: number) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    userDetailTab,
  });
};

export default useUserDetailTabConditions;
