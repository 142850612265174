import { useState, useEffect } from "react";

import {
  Collection,
  INIT_SEARCH_CRITERIA,
  SEARCH_INFO,
  SORT_EXAMPLES,
} from "../../../constants/common";
import { getMongoDb, getAggregateNli021 } from "../../../utils/query";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import useStateCustomObj from "../../base/useStateCustomObj";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import { useRealmApp } from "../../../contexts/RealmApp";
import useForceUpdate from "../../common/useForceUpdate";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useMemoStatusArr } from "../../../utils/utils";
import useOrganizationId from "../../common/useOrganizationId";

type Nli021FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLI021;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLI021;
};

const useNli021DbFetch = ({
  sortExamples,
  submittedSearchCriteria,
}: Nli021FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [fetchUserResult, setFetchUserResult] = useStateCustomObj<
    Nli021StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const { userId, name, email, phoneNum } = submittedSearchCriteria;

  // 団体ID取得
  const [organizationId] = useOrganizationId();

  // 検索のチェック状態をquery用に整形
  const memoStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLI021,
    submittedSearchCriteria.status,
  );

  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "user_info.activation_date", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.PATIENTS);
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNli021(
          userId,
          name,
          email,
          phoneNum,
          memoStatusArr,
          organizationId,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<Nli021StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchUserResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    email,
    setFetchUserResult,
    forceUpdate.forceUpdateCount,
    memoStatusArr,
    name,
    organizationId,
    phoneNum,
    userId,
  ]);

  return {
    fetchUserResult,
    fetchError,
  };
};

export default useNli021DbFetch;
