import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type Nlh002DialogType = {
  dialogStatus: "add" | "update" | "";
  location: string;
};

const stateReactiveVar = createReactiveVar<Nlh002DialogType>({
  dialogStatus: "",
  location: "",
});

// キャンペーン登録ダイアログの開閉状態を管理
const useNlh002Dialog = (): ReactiveVarHooks<Nlh002DialogType> =>
  useReactiveVarHooks(stateReactiveVar);

export const openNlh002AddDialog = (location: string) => {
  stateReactiveVar({ location, dialogStatus: "add" });
};
export const openNlh002UpdateDialog = (location: string) => {
  stateReactiveVar({ location, dialogStatus: "update" });
};
export const reOpenNlh002Dialog = (action: Nlh002DialogType) => {
  stateReactiveVar(action);
};

export const getIsOpenNlh002 = () => Boolean(stateReactiveVar().dialogStatus);

export const getLocationNlh002 = () => stateReactiveVar().location;

export const closeNlh002Dialog = () => {
  stateReactiveVar({ ...stateReactiveVar(), dialogStatus: "" });
};

export default useNlh002Dialog;
