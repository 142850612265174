import React from "react";
import Step from "./Step";

export type StepNavigationProps = {
  currentStepId: string;
  steps: {
    id: string;
    label: string;
    icon?: string;
    error?: boolean;
  }[];
  isVertical?: boolean;
};

const StepNavigation = ({
  currentStepId = "",
  steps,
  isVertical,
}: StepNavigationProps) => {
  const currentIndex = React.useMemo(
    () => steps.map((step) => step.id).indexOf(currentStepId),
    [currentStepId, steps],
  );

  return (
    <div className={`step-navigation${isVertical ? "--vertical" : ""}`}>
      {steps.map(({ label, ...step }, index) => {
        const isCurrent = currentStepId === step.id;
        const isToDo = currentIndex < index;
        const isDone = currentIndex > index;

        return (
          <Step
            {...step}
            isCurrent={isCurrent}
            isToDo={isToDo}
            isDone={isDone}
            stepCounter={index + 1}
            totalCount={steps.length}
            key={`step-navigation-${index}`}
            isVertical={isVertical}
          >
            {label}
          </Step>
        );
      })}
    </div>
  );
};

StepNavigation.defaultProps = {
  isVertical: false,
};

export default StepNavigation;
