import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection, ROLE_KEY } from "../../../constants/common";
import { getMongoDb } from "../../../utils/query";
import useForceUpdate from "../../common/useForceUpdate";
import {
  closeActionLoading,
  openUpdateActionLoading,
} from "../../base/useLoadingAction";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import { checkMasterRole } from "../../common/useMasterRoleDbActions";

const useNli006DbActions = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;
  const [error, setError] = useState<Error | null>(null);
  const [forceUpdate, setForceUpdate] = useForceUpdate();
  const mongoDb = getMongoDb(currentUser, Collection.USERS);

  // ユーザ一括更新
  const handleUpdateUser = (
    idArray: string[],
    role: string,
    status: string,
  ) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        // 更新可能か権限チェック
        await checkMasterRole(ROLE_KEY.MASTER_USER_EDIT, currentUser);

        // 更新データ
        const updateData = {
          admin_role: Number(role),
          status: Number(status),
        };

        await mongoDb.updateMany(
          { _id: { $in: idArray } },
          { $set: updateData },
        );
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
      } catch (err) {
        setError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return {
    actionError: error,
    handleUpdateUser,
  };
};

export default useNli006DbActions;
