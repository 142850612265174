import React, { useEffect } from "react";

import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Tag from "../components/Tag/Tag";
import Image from "../components/Image/Image";
import Link from "../components/Link/Link";
import Tooltip from "../components/Tooltip/Tooltip";
import { MASTER_MANAGEMENT_OPTIONS, ROLE_KEY } from "../constants/common";
import {
  copyClipboard,
  handleSelectMainTab,
  handleSelectMasterInfo,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import useNlh001Fetch from "../hooks/pages/NLH001/useNlh001Fetch";
import { convertNlh001Table } from "../utils/convertDisplay";
import { ADMIN } from "../constants/pagePaths";
import { useCheckErrorThrowError } from "../utils/checkError";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { openNlh002AddDialog } from "../hooks/pages/NLH002/useNlh002Dialog";
import NLH002 from "./NLH002";
import useStateCustomObj from "../hooks/base/useStateCustomObj";
import { setId } from "../hooks/common/useId";
import { useCheckHasRole } from "../hooks/common/useMasterRoleDbActions";

const NLH001 = () => {
  const [users, setUsers] = useStateCustomObj<Nlh001TableType[]>([]);
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { result, error: fetchError } = useNlh001Fetch();
  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // 権限チェック
  const hasAddRole = useCheckHasRole(ROLE_KEY.CAMPAIGN_LIST_ADD);

  // エラー処理
  useCheckErrorThrowError([fetchError, mainTabError]);

  useEffect(() => {
    // データ取得時に表示用に変換
    const convertResult = convertNlh001Table(result);
    setUsers(convertResult);
  }, [result, setUsers]);

  // 登録ボタン押下
  const clickRegister = (id: string, location: string) => {
    setId(id);
    openNlh002AddDialog(location);
  };

  // 詳細ボタン押下時イベント
  const navigate = useNavigateWithUrl();
  const navigateToUserDetails = (id: string) => {
    setId(id);
    navigate(ADMIN.NLH003);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab={7}
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="2x">
            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x" justify="end">
                <Table
                  type="relaxed"
                  width="100%"
                  roleKey={ROLE_KEY.CAMPAIGN_LIST_BROWSING}
                  head={
                    <TableRow>
                      <TableColumn width="175px" id="col-1">
                        設置箇所
                      </TableColumn>
                      <TableColumn width="384px" id="col-2">
                        キャンペーンタイトル
                      </TableColumn>
                      <TableColumn width="72px" id="col-3" />
                      <TableColumn width="384px" id="col-4">
                        URL
                      </TableColumn>
                      <TableColumn width="115px" id="col-5" />
                      <TableColumn width="97px" id="col-6">
                        ステータス
                      </TableColumn>
                      <TableColumn width="175px" id="col-7">
                        配信日時
                      </TableColumn>
                      <TableColumn width="175px" id="col-8">
                        最終更新日時
                      </TableColumn>
                      <TableColumn width="175px" id="col-9">
                        更新者
                      </TableColumn>
                      <TableColumn width="57px" id="col-10" />
                    </TableRow>
                  }
                  body={users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.location}</TableCell>
                      <TableCell>{user.campaignTitle}</TableCell>
                      <TableCell textAlign="center">
                        <Button
                          disabled={Boolean(user.isRegistered) || !hasAddRole}
                          size="small"
                          width="38px"
                          onClick={() => clickRegister(user.id, user.location)}
                        >
                          登録
                        </Button>
                      </TableCell>
                      <TableCell>
                        <LayoutBox direction="row" justify="between" fullWidth>
                          {user.url && (
                            <>
                              <LayoutBox>
                                <Link external url={user.url}>
                                  {user.url}
                                </Link>
                              </LayoutBox>
                              <LayoutBox>
                                <Tooltip content="URLをコピー" direction="top">
                                  <Button
                                    icon="content_copy"
                                    size="small"
                                    type="sub"
                                    onClick={() => copyClipboard(user.url)}
                                  />
                                </Tooltip>
                              </LayoutBox>
                            </>
                          )}
                        </LayoutBox>
                      </TableCell>
                      <TableCell>
                        <Image url={user.picture} height="48px" width="85px" />
                      </TableCell>
                      <TableCell textAlign="center">
                        <Tag
                          label={user.status}
                          type="default"
                          showIcon={false}
                          state={
                            user.status === "公開中" ? "success" : "neutral"
                          }
                        />
                      </TableCell>
                      <TableCell>{user.deliveryDate}</TableCell>
                      <TableCell>{user.deliveryDateUpdate}</TableCell>
                      <TableCell>{user.update}</TableCell>
                      <TableCell textAlign="center">
                        <Button
                          disabled={!user.isRegistered}
                          size="small"
                          width="38px"
                          type="sub"
                          onClick={() => navigateToUserDetails(user.id)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      <NLH002 />
    </>
  );
};

export default NLH001;
