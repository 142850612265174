import { useState, useEffect, useMemo } from "react";

import { getAggregateNli007, getMongoDb } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { Collection, ROLE_KEY } from "../../../constants/common";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import useStateCustomObj from "../../base/useStateCustomObj";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import useItemTagsFetch from "../../common/useItemTagsFetch";
import useForceUpdate from "../../common/useForceUpdate";
import useProductMgmtDbActions from "../../common/useProductMgmtDbActions";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useCheckHasRole } from "../../common/useMasterRoleDbActions";

type Nli007FetchParams = {
  submittedSearchCriteria: {
    itemCode: string;
    itemName: string;
    status?: { [key: string]: boolean };
  };
};

const useNli007DbActions = ({ submittedSearchCriteria }: Nli007FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  // 商品タグ取得
  const { fetchItemTagsResult: checkboxItems, fetchItemTagsError } =
    useItemTagsFetch();

  // 商品種別、SKUのDB操作
  const { actionError, deleteItem, dragAndDropItem } =
    useProductMgmtDbActions();

  const [fetchResult, setFetchResult] = useStateCustomObj<
    ItemsNli007StateType[]
  >([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const [forceUpdate] = useForceUpdate();

  const { itemCode, itemName } = submittedSearchCriteria;

  // タグチェック有のid配列
  const tagIdArray = useMemo(() => {
    const status = submittedSearchCriteria?.status || {};

    return Object.keys(status).filter((key) => status[key] === true);
  }, [submittedSearchCriteria?.status]);

  // 最終更新日の降順
  const colName = "sort";
  const sortDirection = "desc";

  // 権限チェック
  const hasBrowsingRole = useCheckHasRole(ROLE_KEY.MASTER_PRODUCT_BROWSING);

  // 商品種別一覧取得
  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.ITEMS);
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNli007(
          itemCode,
          itemName,
          tagIdArray,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<ItemsNli007StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setFetchResult(result);
      } catch (err) {
        setTotal(0);
        setFetchError(checkFetchErr(err));
      } finally {
        closeTableLoading();
      }
    };

    // 権限があるときのみデータ取得
    if (hasBrowsingRole) void fetchData();
  }, [
    currentUser,
    skip,
    perPage,
    colName,
    sortDirection,
    itemCode,
    itemName,
    setFetchResult,
    checkboxItems,
    tagIdArray,
    forceUpdate.forceUpdateCount,
    hasBrowsingRole,
  ]);

  return {
    fetchResult,
    fetchError,
    fetchItemTagsError,
    actionError,
    checkboxItems,
    deleteItem,
    dragAndDropItem,
  };
};

export default useNli007DbActions;
