import { useEffect } from "react";
import Button from "../components/Button/Button";
import LayoutBox from "../components/LayoutBox/LayoutBox";

import DialogCustom from "../components/Dialog/DialogCustom";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Dropdown from "../components/Dropdown/Dropdown";
import Input from "../components/Input/Input";
import Accordion from "../components/Accordion/Accordion";
import useNli029Dialog, {
  closeNli029Dialog,
} from "../hooks/pages/NLI029/useNli029Dialog";
import useNli029DbFetch from "../hooks/pages/NLI029/useNli029DbFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import useShippingInput, {
  checkShippingInputError,
  setCompanyName,
  setCompanyNameItems,
  setDeliveryCondition,
  setDeliveryFee,
  setDeliveryTimes,
  setShippingId,
} from "../hooks/input/useShippingInput";
import useNli029DbActions from "../hooks/pages/NLI029/useNli029DbActions";
import { ValidationResultType } from "../utils/commonValidation";

const convertDeliveryCondition = (condition: {
  minimum_order_amount: number;
  shipping_cost: number;
}) => ({
  minimum_order_amount: condition.minimum_order_amount.toString(),
  shipping_cost: condition.shipping_cost.toString(),
});

const NLI029 = () => {
  const [isShippingSettingDialog] = useNli029Dialog();
  const { fetchResult, fetchError } = useNli029DbFetch();
  const { updateShipping, actionError } = useNli029DbActions();
  const [
    {
      companyName,
      companyNameItems,
      deliveryTimes,
      deliveryFee,
      deliveryCondition,
    },
  ] = useShippingInput();

  // エラー処理
  useCheckErrorThrowError([fetchError, actionError]);

  const convertDeliveryTimesToString = (
    deliveryTimes: { start: number; end: number }[],
  ): {
    start: ValidationResultType<string>;
    end: ValidationResultType<string>;
  }[] =>
    deliveryTimes.map((time) => ({
      start: {
        value: time.start.toString(),
        validationMessage: "",
        isError: false,
      },
      end: {
        value: time.end.toString(),
        validationMessage: "",
        isError: false,
      },
    }));

  useEffect(() => {
    if (fetchResult) {
      setShippingId(fetchResult._id);
      setCompanyNameItems([
        {
          label: fetchResult.companyName,
          value: fetchResult.companyName,
        },
      ]);
      setCompanyName(fetchResult.companyName);
      setDeliveryTimes(convertDeliveryTimesToString(fetchResult.deliveryTimes));
      setDeliveryFee(fetchResult.deliveryFee.toString());
      setDeliveryCondition(
        convertDeliveryCondition(fetchResult.deliveryCondition),
      );
    }
  }, [fetchResult]);

  // 配送時間 開始 変更
  const handleStartChange = (index: number, value: string) => {
    const updatedTimes = deliveryTimes.map((time, i) =>
      i === index ? { ...time, start: { ...time.start, value } } : time,
    );
    setDeliveryTimes(updatedTimes);
  };

  // 配送時間 終了 変更
  const handleEndChange = (index: number, value: string) => {
    const updatedTimes = deliveryTimes.map((time, i) =>
      i === index ? { ...time, end: { ...time.end, value } } : time,
    );
    setDeliveryTimes(updatedTimes);
  };

  // 時間を追加
  const handleAddTime = () => {
    const newTime = {
      start: { value: "", validationMessage: "", isError: false },
      end: { value: "", validationMessage: "", isError: false },
    };
    setDeliveryTimes([...deliveryTimes, newTime]);
  };

  // 時間を削除
  const handleRemoveTime = (index: number) => {
    const updatedTimes = deliveryTimes.filter((_, i) => i !== index);
    setDeliveryTimes(updatedTimes);
  };

  // 条件の配送料 変更
  const handleShippingCostChange = (value: string) => {
    setDeliveryCondition({
      minimum_order_amount: deliveryCondition.minimum_order_amount.value,
      shipping_cost: value,
    });
  };

  // 条件 変更
  const handleMinimumOrderAmountChange = (value: string) => {
    setDeliveryCondition({
      minimum_order_amount: value,
      shipping_cost: deliveryCondition.shipping_cost.value,
    });
  };

  return (
    <>
      <DialogCustom
        open={isShippingSettingDialog}
        closeDialog={closeNli029Dialog}
        size="medium"
        title="各種配送設定"
        height="640px"
        footerRight={
          <>
            <LayoutBox justify="end" fullWidth>
              <Button
                type="sub"
                color="neutral"
                size="large"
                width="108px"
                onClick={closeNli029Dialog}
              >
                キャンセル
              </Button>
              <Button
                type="primary"
                size="large"
                width="96px"
                disabled={checkShippingInputError()}
                onClick={updateShipping}
              >
                保存
              </Button>
            </LayoutBox>
          </>
        }
      >
        <LayoutBox direction="column" fullWidth gap="3x">
          <LayoutBox fullWidth direction="column" gap="none">
            <Sheet type="header" className="util-mb-16" padding="8px">
              <Text bold size="xl">
                配送会社
              </Text>
            </Sheet>
            <LayoutBox fullWidth direction="column" gap="2x">
              <LayoutBox fullWidth direction="column" gap="none">
                <Text bold size="xs">
                  配送会社
                </Text>
                <Dropdown
                  width="100%"
                  items={companyNameItems}
                  value={companyName}
                  onChange={setCompanyName}
                />
              </LayoutBox>
              <Accordion ghost isOpen title="配送時間">
                <LayoutBox direction="column">
                  {deliveryTimes.map((time, index) => (
                    <LayoutBox align="center" gap="2x" key={index}>
                      <LayoutBox align="center">
                        <Input
                          value={time.start.value}
                          width="70px"
                          onChange={(value) => handleStartChange(index, value)}
                        />
                        <Text>時〜</Text>
                        <Input
                          value={time.end.value}
                          width="70px"
                          onChange={(value) => handleEndChange(index, value)}
                        />
                        <Text>時</Text>
                      </LayoutBox>
                      <Button
                        type="sub"
                        color="neutral"
                        size="small"
                        icon="close"
                        onClick={() => handleRemoveTime(index)}
                      />
                      {(deliveryTimes[index].start.validationMessage ||
                        deliveryTimes[index].end.validationMessage) && (
                        <div
                          key={`caption-massage-${index}`}
                          className="form-set__caption-massage--error"
                        >
                          {deliveryTimes[index].start.validationMessage ||
                            deliveryTimes[index].end.validationMessage}
                        </div>
                      )}
                    </LayoutBox>
                  ))}
                  {deliveryTimes.length === 0 && (
                    <div className="form-set__caption-massage--error">
                      配送時間を入力してください
                    </div>
                  )}
                  <Button
                    type="sub"
                    size="small"
                    icon="add"
                    iconPosition="left"
                    onClick={handleAddTime}
                    disabled={deliveryTimes.length > 4}
                  >
                    時間を追加
                  </Button>
                </LayoutBox>
              </Accordion>
              <Accordion ghost isOpen title="配送料設定">
                <LayoutBox gap="none" direction="column">
                  <LayoutBox align="center">
                    <Text width="150px" bold>
                      配送料
                    </Text>
                    <Text bold>条件</Text>
                  </LayoutBox>
                  <LayoutBox direction="column">
                    <LayoutBox align="center">
                      <LayoutBox align="center">
                        <Input
                          leftIcon="currency_yen"
                          width="150px"
                          value={deliveryFee.value}
                          onChange={setDeliveryFee}
                        />
                        <Input
                          leftIcon="currency_yen"
                          width="150px"
                          value="-"
                          disabled
                        />
                      </LayoutBox>
                    </LayoutBox>
                    {deliveryFee.validationMessage && (
                      <div
                        key="caption-massage"
                        className="form-set__caption-massage--error"
                      >
                        {deliveryFee.validationMessage}
                      </div>
                    )}
                    {deliveryCondition && (
                      <LayoutBox align="center">
                        <LayoutBox align="center">
                          <Input
                            leftIcon="currency_yen"
                            width="150px"
                            value={deliveryCondition.shipping_cost.value}
                            onChange={handleShippingCostChange}
                          />
                          <Input
                            leftIcon="currency_yen"
                            width="150px"
                            value={deliveryCondition.minimum_order_amount.value}
                            onChange={handleMinimumOrderAmountChange}
                          />
                        </LayoutBox>
                        {/* 1つの条件のため、一旦コメント */}
                        {/* <Button
                          type="sub"
                          color="neutral"
                          size="small"
                          icon="close"
                        /> */}
                      </LayoutBox>
                    )}
                    {deliveryCondition.shipping_cost.validationMessage && (
                      <div
                        key="caption-massage"
                        className="form-set__caption-massage--error"
                      >
                        {deliveryCondition.shipping_cost.validationMessage}
                      </div>
                    )}
                    {deliveryCondition.minimum_order_amount
                      .validationMessage && (
                      <div
                        key="caption-massage2"
                        className="form-set__caption-massage--error"
                      >
                        {
                          deliveryCondition.minimum_order_amount
                            .validationMessage
                        }
                      </div>
                    )}
                    {/* 1つの条件のため、一旦コメント */}
                    {/* <Button
                      type="sub"
                      size="small"
                      icon="add"
                      iconPosition="left"
                    >
                      条件を追加
                    </Button> */}
                  </LayoutBox>
                </LayoutBox>
              </Accordion>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      </DialogCustom>
    </>
  );
};

export default NLI029;
