import { useState, useEffect } from "react";
import {
  INIT_SEARCH_CRITERIA,
  Collection,
  SORT_EXAMPLES,
  SEARCH_INFO,
} from "../../../constants/common";
import { getMongoDb, getAggregateNld004 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  openTableLoading,
  closeTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nld004FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLD004;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLD004;
};

// NLD004画面のデータ取得処理
const useNld004DbActions = ({
  sortExamples,
  submittedSearchCriteria,
}: Nld004FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    result: PatientNld004StateType[];
    error: Error | null;
  }>({
    result: [],
    error: null,
  });

  // 検索条件をquery用に整形
  const {
    email,
    authorizationStartDate,
    authorizationEndDate,
    paymentStartDate,
    paymentEndDate,
    userId,
  } = submittedSearchCriteria;
  const name = convertSearchName(submittedSearchCriteria.name);
  const tel = submittedSearchCriteria.phoneNum;

  // 項目 チェック
  const itemSearchArray = useMemoStatusArr(
    SEARCH_INFO.NLD004_ITEM,
    submittedSearchCriteria.status,
  );

  // 支払状況 チェック
  const paymentSearchArray = useMemoStatusArr(
    SEARCH_INFO.NLD004_PAYMENT,
    submittedSearchCriteria.statusPayment,
  );

  // sortを取得 未選択の場合はIDの昇順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const { colName = "payment_date", sortDirection = "desc" } =
    currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.SALES);

        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNld004(
          name,
          email,
          tel,
          itemSearchArray,
          paymentSearchArray,
          authorizationStartDate,
          authorizationEndDate,
          paymentStartDate,
          paymentEndDate,
          userId,
          skip,
          perPage,
          colName,
          sortDirection,
        );

        // データ取得
        const aggregateResult = (await mongoDb.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNld004StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setErrorAndResult({ result, error: null });
      } catch (err) {
        setTotal(0);
        setErrorAndResult({
          result: [],
          error: checkFetchErr(err),
        });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    colName,
    currentUser,
    itemSearchArray,
    paymentSearchArray,
    name,
    perPage,
    skip,
    sortDirection,
    tel,
    email,
    authorizationStartDate,
    authorizationEndDate,
    paymentStartDate,
    paymentEndDate,
    userId,
  ]);

  return errorAndResult;
};

export default useNld004DbActions;
