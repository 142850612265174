import { useState, useEffect } from "react";

import { getMongoDb, getAggregateChildPatients } from "../../utils/query";
import { useRealmApp } from "../../contexts/RealmApp";
import { Collection } from "../../constants/common";
import { addLoadCount, decrementLoadCount } from "../base/useLoadingPage";
import { checkFetchErr } from "../../contexts/CustomErrorBoundary";
import useId from "./useId";
import { formatDate } from "../../utils/utils";

// 親に紐づく被招待者の情報取得
const useChildPatientFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchChildPatientResult, setFetchChildPatientResult] = useState<
    ChildPatientStateType[]
  >([]);
  const [fetchChildPatientError, setFetchChildPatientError] =
    useState<Error | null>(null);
  const [id] = useId();

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_INVITATIONS,
        );
        const aggregate = getAggregateChildPatients(id);
        const result = (await collection.aggregate(
          aggregate,
        )) as ChildPatientStateType[];

        // ストライプ情報を追加するための処理
        const updatedResult = await Promise.all(
          result.map(async (item) => {
            const stripeInfo = (await currentUser?.functions[
              "stripe/subscription/info"
            ](item.stripeSubscriptionId)) as SubscriptionInfo;

            const nextBillingDateStr = stripeInfo.nextBillingDate
              ? formatDate(stripeInfo.nextBillingDate)
              : "";

            return {
              ...item,
              nextBillingDateStr,
            };
          }),
        );

        setFetchChildPatientResult(updatedResult);
      } catch (err) {
        setFetchChildPatientError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, id]);

  return { fetchChildPatientError, fetchChildPatientResult };
};

export default useChildPatientFetch;
