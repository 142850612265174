import React from "react";

export type ListProps = {
  height?: string;
  children?: React.ReactNode;
};

const List = ({ height = "100%", children = "" }: ListProps) => (
  <div className="list" style={{ height }}>
    {children}
  </div>
);
List.defaultProps = {
  height: "100%",
  children: "",
};

export default List;
