import { INIT_SEARCH_CRITERIA } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type SearchCriteriaStatus = {
  [key: string]: boolean;
};

type ConditionsType = {
  search: {
    id: string;
    name: string;
    skuName: string;
    status?: SearchCriteriaStatus;
  };
};

// 商品管理SKUタブの検索の入力を管理
const stateReactiveVar = createReactiveVar<ConditionsType>({
  search: INIT_SEARCH_CRITERIA.NLI009,
});
const useNli009Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNli009Conditions;
