import { PetProblemID, PetStatusID } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  modalityId: string;
} & PatientNlc028StateType;

const INIT_TEST_DATA: PatientNlc028StateType = {
  status: PetStatusID.NON_TARGET,
  date: "",
  doctor: "",
  doctorReview: "",
  doctorNote: "",
  problem: PetProblemID.NO_PROBLEM,
  images: [],
  interpretationNote: "",
  studyUid: "",
};

// PET結果画面
const stateReactiveVar = createReactiveVar<ConditionsType>({
  modalityId: "",
  ...INIT_TEST_DATA,
});
const useNlc028Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export const setPetResultId = (modalityId: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...INIT_TEST_DATA,
    modalityId,
  });
};

export const setNlc028Data = (data: PatientNlc028StateType) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    ...data,
  });
};

export const setInterpretationNote = (interpretationNote: string) => {
  const currentState = stateReactiveVar();
  stateReactiveVar({
    ...currentState,
    interpretationNote,
  });
};

// 入力エラーチェック エラーの場合true
export const checkItemInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const { interpretationNote } = currentInfos;

  // 必須チェック（note が空ならエラー）
  const isEmpty = interpretationNote === "";

  return isEmpty;
};

export default useNlc028Conditions;
