import * as Realm from "realm-web";

import Sheet from "../components/Sheet/Sheet";
import Heading from "../components/Heading/Heading";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import FormSet from "../components/FormSet/FormSet";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Notification from "../components/Notification/Notification";
import { COMMON } from "../constants/pagePaths";
import { useRealmApp } from "../contexts/RealmApp";
import useLogInInputObj, {
  setId,
  setPassword,
  getIsEmpty,
  getIsError,
  handleLoginFailure,
  setInitLoginInput,
} from "../hooks/useLogInInputObj";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import Divider from "../components/Divider/Divider";
import Link from "../components/Link/Link";
import { addLoadCount, decrementLoadCount } from "../hooks/base/useLoadingPage";
import Logo from "../components/Logo/Logo";

// ログイン画面コンポーネント
const NLA001 = () => {
  const navigate = useNavigateCustom();

  const realmAppContext = useRealmApp();
  const { logIn } = realmAppContext;

  const [logInInputObj] = useLogInInputObj();
  const {
    id,
    password,
    isErrorId,
    isErrorPassword,
    idValidationMessage,
    passwordValidationMessage,
    isLoginFailed,
    loginFailedMessage,
  } = logInInputObj;

  const handleChangeId = (value: string) => {
    setId(value, logInInputObj);
  };

  const handleChangePassword = (value: string) => {
    setPassword(value, logInInputObj);
  };

  // ログインボタン押下
  const clickLogin = () => {
    void (async () => {
      addLoadCount();
      try {
        await logIn(Realm.Credentials.emailPassword(id, password));
        setInitLoginInput();
        navigate(COMMON.LOGIN_PROCESSING);
      } catch (e) {
        handleLoginFailure(logInInputObj, e as Error);
      } finally {
        decrementLoadCount();
      }
    })();
  };

  // 入力が空orエラーの場合ログインボタンを非活性
  const isDisableLogInButton =
    getIsEmpty(logInInputObj) || getIsError(logInInputObj);

  return (
    <div className="login-wrap">
      <div className="login-content-area">
        <LayoutBox align="center" direction="column" justify="center" gap="5x">
          <Logo size="large" type="blue-horizontal" />
          <div className="login-form-inner">
            <Sheet
              type="default"
              className="util-px-40 util-py-40 util-full-width"
            >
              <LayoutBox direction="column" gap="3x" justify="center">
                <div className="util-full-width">
                  <LayoutBox align="center" justify="center">
                    <Heading tag="h2" className="text--2xl text--bold">
                      ログイン
                    </Heading>
                  </LayoutBox>
                </div>
                {isLoginFailed && (
                  <Notification
                    text="ログインできません"
                    description={loginFailedMessage}
                  />
                )}
                <div className="util-full-width">
                  <LayoutBox direction="column" gap="2x">
                    <FormSet
                      label="ID"
                      required="icon"
                      labelSize="small"
                      vertical
                      errorTextList={[idValidationMessage]}
                    >
                      <Input
                        value={id}
                        placeholder="ID"
                        width="100%"
                        leftIcon="person"
                        onChange={handleChangeId}
                        error={isErrorId}
                      />
                    </FormSet>
                    <FormSet
                      label="パスワード"
                      required="icon"
                      labelSize="small"
                      option={{
                        text: "（パスワードを忘れた場合）",
                        href: COMMON.NLJ006,
                      }}
                      vertical
                      errorTextList={[passwordValidationMessage]}
                    >
                      <Input
                        value={password}
                        placeholder="パスワード"
                        width="100%"
                        leftIcon="lock"
                        rightButton="password"
                        password
                        onChange={handleChangePassword}
                        error={isErrorPassword}
                      />
                    </FormSet>
                  </LayoutBox>
                </div>
                <div className="util-full-width">
                  <LayoutBox direction="column" gap="1x">
                    <Button
                      type="primary"
                      color="main"
                      size="large"
                      width="100%"
                      disabled={isDisableLogInButton}
                      onClick={clickLogin}
                    >
                      ログイン
                    </Button>
                  </LayoutBox>
                </div>
                <LayoutBox
                  justify="center"
                  align="center"
                  direction="column"
                  gap="2x"
                  fullWidth
                >
                  <Divider margin="0" />
                  <Link url={COMMON.NLJ004} external={false}>
                    認証メールを再送
                  </Link>
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </div>
        </LayoutBox>
      </div>
    </div>
  );
};

export default NLA001;
