import React from "react";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Icon from "../components/Icon/Icon";
import HeaderAdmin from "../components/Header/HeaderAdmin";
import Tabs from "../components/Tab/Tabs";
import Button from "../components/Button/Button";
import Sheet from "../components/Sheet/Sheet";
import Table from "../components/Table/Table";
import TableRow from "../components/Table/TableRow";
import TableColumn from "../components/Table/TableColumn";
import TableCell from "../components/Table/TableCell";
import Pagination from "../components/Pagination/Pagination";
import Popover from "../components/Popover/Popover";
import MenuList from "../components/MenuList/MenuList";
import Text from "../components/Text/Text";
import ToggleButton from "../components/ToggleButton/ToggleButton";
import Input from "../components/Input/Input";
import {
  handleSelectMainTab,
  handleSelectMasterInfo,
  handleSelectMasterMgmtSubTab,
} from "../utils/utils";
import useAdminMainTabFetch from "../hooks/useAdminMainTabFetch";
import { useCheckErrorThrowError } from "../utils/checkError";
import { INIT_PAGE, MASTER_MANAGEMENT_OPTIONS } from "../constants/common";
import { setPage } from "../hooks/component/pagination/usePagination";
import useNli019DbActions from "../hooks/pages/NLI019/useNli019DbActions";
import useNli019Conditions from "../hooks/pages/NLI019/useNli019Conditions";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { ADMIN } from "../constants/pagePaths";
import useNli020Dialog, {
  openNli020Dialog,
} from "../hooks/pages/NLI020/useNli020Dialog";
import NLI020 from "./NLI020";
import { setOrganizationId } from "../hooks/common/useOrganizationId";

const NLI019 = () => {
  const [isNli020Open] = useNli020Dialog();
  const [conditions, setConditions] = useNli019Conditions();
  const [searchCriteria, setSearchCriteria] = React.useState(conditions.search);
  const [submittedSearchCriteria, setSubmittedSearchCriteria] = React.useState(
    conditions.search,
  );
  const { fetchResults, fetchError } = useNli019DbActions({
    submittedSearchCriteria,
  });

  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { error: mainTabError, tabItems: mainTabItems } =
    useAdminMainTabFetch();

  // エラー処理
  useCheckErrorThrowError([mainTabError, fetchError]);

  // 検索入力値変更
  const handleChangeSearch = (value: string) => {
    setSearchCriteria((prevState) => ({ ...prevState, search: value }));
  };

  // 検索ボタン押下
  const handleSearch = () => {
    setSubmittedSearchCriteria(searchCriteria);
    setPage(INIT_PAGE);
  };

  // 団体を追加ボタン押下
  const handleAdd = () => {
    openNli020Dialog();
  };

  // 詳細ボタン押下
  const navigateWithUrl = useNavigateWithUrl();
  const navigateToDetail = (id: string) => {
    setConditions({
      search: submittedSearchCriteria,
    });
    setOrganizationId(id);
    navigateWithUrl(ADMIN.NLI021);
  };

  return (
    <>
      <div className="admin-area">
        <div className="admin-header">
          <HeaderAdmin showInfo={false} />
          <div className="util-border-bottom-gray util-bg-neutral--white">
            <LayoutBox align="center" justify="between">
              <Tabs
                selectedTab="none"
                tabs={mainTabItems}
                onClick={handleSelectMainTab}
              />
              <div className="util-mr-16 util-mt-8 util-mb-8">
                <div ref={activatorRef} onClick={() => setIsOpen(!isOpen)}>
                  <Button type="secondary" size="large" icon="settings">
                    マスタ管理
                    <Icon icon="keyboard_arrow_down" className="util-ml-8" />
                  </Button>
                </div>
                <Popover
                  open={isOpen}
                  activatorRef={activatorRef}
                  width={160}
                  direction="under"
                  withShadow
                  toggleOpen={() => setIsOpen(!isOpen)}
                >
                  <MenuList
                    items={MASTER_MANAGEMENT_OPTIONS}
                    onClick={handleSelectMasterInfo}
                  />
                </Popover>
              </div>
            </LayoutBox>
          </div>
        </div>
        <div className="admin-inner">
          <LayoutBox direction="column" gap="3x">
            <div className="util-mt-8 util-mb-8">
              <Text size="2xl" bold>
                マスタ管理
              </Text>
            </div>

            <ToggleButton
              size="large"
              items={MASTER_MANAGEMENT_OPTIONS}
              selectedButton="6"
              onClick={handleSelectMasterMgmtSubTab}
            />

            <Sheet className="util-px-24 util-py-24">
              <LayoutBox direction="column" gap="2x">
                <LayoutBox justify="between" fullWidth>
                  <LayoutBox align="center" justify="start" fullWidth>
                    <Input
                      value={searchCriteria.search}
                      placeholder="検索"
                      width="360px"
                      onChange={handleChangeSearch}
                    />
                    <div className="util-ml-8">
                      <Button
                        type="secondary"
                        size="large"
                        width="88px"
                        onClick={handleSearch}
                      >
                        <LayoutBox align="center">
                          <Icon icon="search" />
                          検索
                        </LayoutBox>
                      </Button>
                    </div>
                  </LayoutBox>
                  <Button size="large" icon="add" onClick={handleAdd}>
                    団体を追加
                  </Button>
                </LayoutBox>
                <Table
                  type="condensed"
                  width="100%"
                  head={
                    <TableRow>
                      <TableColumn width="782px" id="col-1">
                        団体名
                      </TableColumn>
                      <TableColumn width="184px" id="col-3">
                        電話番号
                      </TableColumn>
                      <TableColumn width="782px " id="col-4">
                        住所
                      </TableColumn>

                      <TableColumn width="77px" id="col-5" />
                    </TableRow>
                  }
                  body={fetchResults.map((fetchResult) => (
                    <TableRow key={fetchResult._id}>
                      <TableCell>{fetchResult.organization_name}</TableCell>
                      <TableCell>{fetchResult.tel}</TableCell>
                      <TableCell>{fetchResult.address}</TableCell>
                      <TableCell textAlign="center">
                        <Button
                          type="sub"
                          size="small"
                          onClick={() => navigateToDetail(fetchResult._id)}
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <LayoutBox justify="end" fullWidth>
                  <Pagination input={false} />
                </LayoutBox>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </div>
      </div>
      {isNli020Open && <NLI020 />}
    </>
  );
};

export default NLI019;
