import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.NLG001;
  search: typeof INIT_SEARCH_CRITERIA.NLG001;
  startDate: Date | undefined;
  endDate: Date | undefined;
  startUpdateDate: Date | undefined;
  endUpdateDate: Date | undefined;
};

const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.NLG001,
  search: INIT_SEARCH_CRITERIA.NLG001,
  startDate: undefined,
  endDate: undefined,
  startUpdateDate: undefined,
  endUpdateDate: undefined,
});

const useNlg001Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNlg001Conditions;
