import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.NLC035;
  search: typeof INIT_SEARCH_CRITERIA.NLC035;
};

// 検索条件をページ間で保持
const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.NLC035,
  search: INIT_SEARCH_CRITERIA.NLC035,
});
const useNlc035Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNlc035Conditions;
