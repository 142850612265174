import { useEffect, useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNlc021, getMongoDb } from "../../../utils/query";
import { Collection } from "../../../constants/common";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import {
  checkFetchErr,
  redirectToInvalidAccessPage,
} from "../../../contexts/CustomErrorBoundary";
import useNlc021NewDialog from "./useNlc021NewDialog";
import useNlc021Conditions, { setNlc021Data } from "./useNlc021Conditions";
import useForceUpdate from "../../common/useForceUpdate";

// 面談の予約情報を取得
const useNlc021InterviewFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [isOpen] = useNlc021NewDialog();
  const [{ interviewId }] = useNlc021Conditions();
  const [forceUpdate] = useForceUpdate();

  const [fetchError, setFetchError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        if (!interviewId) redirectToInvalidAccessPage();

        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_INTERVIEWS,
        );
        const aggregate = getAggregateNlc021(interviewId);
        const result = (await collection.aggregate(
          aggregate,
        )) as Nlc021StateType[];

        if (result[0]) setNlc021Data(result[0]);
      } catch (err) {
        setFetchError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (isOpen) void fetchData();
  }, [currentUser, interviewId, isOpen, forceUpdate.forceUpdateCount]);

  return { fetchError };
};

export default useNlc021InterviewFetch;
