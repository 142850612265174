import React from "react";

export type LogoProps = {
  size?: "small" | "medium" | "large";
  type?:
    | "horizontal"
    | "vertical"
    | "mark"
    | "blue-horizontal"
    | "white-horizontal";
};

const Logo = ({ size = "medium", type = "horizontal" }: LogoProps) => {
  const logoClass = React.useMemo(() => {
    const sizeClass = `logo--${size}`;
    const logoTypeClass = `logo--${type}`;

    return ["logo", sizeClass, logoTypeClass].join(" ");
  }, [size, type]);

  return <div className={logoClass} />;
};

Logo.defaultProps = {
  size: "medium",
  type: "horizontal",
};

export default Logo;
