import { INIT_SEARCH_CRITERIA, SORT_EXAMPLES } from "../../../constants/common";
import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

type ConditionsType = {
  sort: typeof SORT_EXAMPLES.NLC033;
  search: typeof INIT_SEARCH_CRITERIA.NLC033;
};

// 検索条件をページ間で保持
const stateReactiveVar = createReactiveVar<ConditionsType>({
  sort: SORT_EXAMPLES.NLC033,
  search: INIT_SEARCH_CRITERIA.NLC033,
});
const useNlc033Conditions = (): ReactiveVarHooks<ConditionsType> =>
  useReactiveVarHooks(stateReactiveVar);

export default useNlc033Conditions;
