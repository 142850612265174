import React from "react";
import Button from "../Button/Button";

export type DialogCustomProps = {
  open?: boolean;
  size?: "small" | "medium" | "large" | "fullscreen";
  title?: string;
  subTitle?: string;
  height?: string;
  isShowCloseButton?: boolean;
  closeDialog: () => void;
  headerRight?: JSX.Element;
  footerLeft?: JSX.Element;
  footerRight?: JSX.Element;
  children: React.ReactNode;
};

const DialogCustom = ({
  open = false,
  size = "medium",
  title,
  subTitle,
  height = "",
  isShowCloseButton = true,
  closeDialog,
  headerRight,
  footerLeft,
  footerRight,
  children,
}: DialogCustomProps) => {
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const dialogContentRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const dialogCustomClass = React.useMemo(() => {
    const sizeClass = [`dialog-custom--${size}`];

    return ["dialog-custom", ...sizeClass].join(" ");
  }, [size]);

  const _height = React.useMemo(() => {
    const defaultHeightBySize =
      size === "fullscreen" ? "calc(100% - 64px)" : "542px";

    return height || defaultHeightBySize;
  }, [height, size]);

  const toggleDialog = React.useCallback(() => {
    if (!dialogRef?.current) {
      return;
    }
    if (!open) {
      dialogRef.current.classList.add("open");
      dialogRef.current.addEventListener(
        "animationend",
        () => {
          if (dialogRef.current) {
            dialogRef.current.classList.remove("open");
          }
        },
        { once: true },
      );
    }
  }, [open]);

  const onClose = React.useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  React.useEffect(() => {
    if (open !== isOpen && dialogContentRef?.current) {
      toggleDialog();
      setIsOpen(open);
      dialogContentRef.current.scrollTo(0, 0);
    }
  }, [isOpen, open, toggleDialog]);

  const dialogCustomAnimationClass = React.useMemo(() => {
    const DialogCustomAnimation = open
      ? ["open-animation open"]
      : ["close-animation"];

    return ["dialog-custom-wrapper", ...DialogCustomAnimation].join(" ");
  }, [open]);

  const isShowHeader = React.useMemo(
    () =>
      title !== undefined ||
      subTitle !== undefined ||
      headerRight !== undefined,
    [subTitle, title, headerRight],
  );

  const isShowFooter = React.useMemo(
    () => footerLeft !== undefined || footerRight !== undefined,
    [footerLeft, footerRight],
  );

  return (
    <div className={dialogCustomAnimationClass} ref={dialogRef}>
      <div className={dialogCustomClass} style={{ height: _height }}>
        <div className="dialog-custom-content-container">
          {isShowHeader && (
            <div className="dialog-custom__header">
              <div className="dialog-custom__header-inner">
                <span className="dialog-custom__title">{title}</span>
                <span className="dialog__sub-title">{subTitle}</span>
              </div>
              <div className="dialog-custom__header-right">
                {headerRight}
                {isShowCloseButton && (
                  <Button
                    className="dialog-custom__close-button"
                    icon="close"
                    shape="square"
                    size="medium"
                    color="neutral"
                    type="sub"
                    onClick={onClose}
                  />
                )}
              </div>
            </div>
          )}

          <div className="dialog-custom__content" ref={dialogContentRef}>
            {children}
          </div>
          {isShowFooter && (
            <div className="dialog-custom__footer">
              <div>{footerLeft}</div>
              <div>{footerRight}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DialogCustom.defaultProps = {
  title: "",
  subTitle: "",
  open: false,
  size: "medium",
  height: "",
  headerRight: undefined,
  footerLeft: undefined,
  footerRight: undefined,
  isShowCloseButton: true,
};

export default DialogCustom;
