import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<{
  isSendConfirm: boolean;
  isCancelConfirm: boolean;
}>({
  isSendConfirm: false,
  isCancelConfirm: false,
});

const useNlc017Dialog = (): ReactiveVarHooks<{
  isSendConfirm: boolean;
  isCancelConfirm: boolean;
}> => useReactiveVarHooks(stateReactiveVar);

export const openNlc017SendDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isSendConfirm: true,
  });
};

export const closeNlc017SendDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isSendConfirm: false,
  });
};

export const openNlc017CancelDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isCancelConfirm: true,
  });
};

export const closeNlc017CancelDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isCancelConfirm: false,
  });
};

export default useNlc017Dialog;
