import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

type LoadingType = {
  count: number;
  isLoadingPage: boolean;
};

// ページ全体のロード状態を管理
const stateReactiveVar = createReactiveVar<LoadingType>({
  count: 0,
  isLoadingPage: false,
});
export const useLoadingPage = (): ReactiveVarHooks<LoadingType> =>
  useReactiveVarHooks(stateReactiveVar);

export const addLoadCount = () => {
  const currentState = stateReactiveVar();
  const { count } = currentState;

  stateReactiveVar({
    ...currentState,
    isLoadingPage: true,
    count: count + 1,
  });
};

export const decrementLoadCount = () => {
  const currentState = stateReactiveVar();
  const { count } = currentState;
  // ロード処理がすべて終わってカウントが0になったとき
  const newCount = count - 1;
  const isLoadingPage = newCount > 0;

  stateReactiveVar({
    ...currentState,
    isLoadingPage,
    count: newCount,
  });
};
