import MenuListItem from "./MenuListItem";

export type MenuListProps = {
  items: {
    id?: string;
    label: string;
    value: string;
    checked?: boolean;
  }[];
  selectedValues?: string[];
  checkbox?: boolean;
  onClick?: (value: string) => void | (() => void);
  onCheck?: (value: string) => void;
  padding?: React.CSSProperties["padding"];
};

const MenuList = ({
  items = [],
  selectedValues = [],
  checkbox = false,
  onClick = () => {},
  onCheck,
  padding,
}: MenuListProps) => (
  <div className="menulist" style={{ padding }}>
    {items.map((item) => {
      const isSelected = checkbox
        ? selectedValues.some((selectedValue) => selectedValue === item.value)
        : undefined;

      return (
        <MenuListItem
          {...item}
          checkbox={checkbox}
          isSelected={isSelected}
          onClick={onClick}
          onCheck={onCheck}
          key={`menu-list-item-${item.value}`}
        />
      );
    })}
  </div>
);

MenuList.defaultProps = {
  checkbox: false,
  padding: undefined,
  onClick: () => {},
  onCheck: () => {},
  selectedValues: [],
};

export default MenuList;
