import createReactiveVar from "../../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../../common/useReactiveVarHooks";

const stateReactiveVar = createReactiveVar<{
  isSendConfirm: boolean;
}>({
  isSendConfirm: false,
});

const useNlc011Dialog = (): ReactiveVarHooks<{
  isSendConfirm: boolean;
}> => useReactiveVarHooks(stateReactiveVar);

export const openNlc011SendDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isSendConfirm: true,
  });
};

export const closeNlc011SendDialog = () => {
  stateReactiveVar({
    ...stateReactiveVar(),
    isSendConfirm: false,
  });
};

export default useNlc011Dialog;
