import React from "react";
import Icon from "../Icon/Icon";

export type MoneyInputProps = {
  size?: "default" | "small";
  status?: "error" | "default";
  value?: string;
  width?: string;
  disabled?: boolean;
  placeholder?: string;
  currency?: string;
  onChange: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const MoneyInput = ({
  value,
  onChange,
  size = "default",
  status = "default",
  width = "144px",
  disabled = false,
  placeholder,
  currency = "¥",
  onFocus,
  onBlur,
}: MoneyInputProps) => {
  const moneyInputClass = React.useMemo(() => {
    const sizeClass = [`money__input--${size}`];
    const statusClass = status !== "default" ? [`money__input--${status}`] : [];

    return ["money__input", ...sizeClass, ...statusClass].join(" ");
  }, [size, status]);

  const onChangeInnerInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value;
      const initialPosition = e.currentTarget.selectionStart ?? 0;

      const numericValue = inputValue.replace(/[^0-9]/g, "");

      const options = { maximumFractionDigits: 2 };
      const parsedNumericValue = parseInt(numericValue);
      const formattedValue = Number.isNaN(parsedNumericValue)
        ? "0"
        : Intl.NumberFormat("en-US", options).format(parsedNumericValue);

      e.currentTarget.value = formattedValue;

      onChange(formattedValue);

      const element = e.currentTarget;
      let cursorPosition = initialPosition;
      const diff = formattedValue.length - inputValue.length;
      cursorPosition += diff;

      window.requestAnimationFrame(() => {
        element.selectionStart = cursorPosition;
        element.selectionEnd = cursorPosition;
      });
    },
    [onChange],
  );

  return (
    <div className="money" style={{ width }}>
      <span
        className={`money__currency ${
          disabled ? "money__currency--disabled" : ""
        }`}
      >
        {currency ? currency.charAt(0) : ""}
      </span>
      <input
        value={value ?? ""}
        className={moneyInputClass}
        disabled={disabled}
        placeholder={placeholder}
        type="text"
        onChange={onChangeInnerInput}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {status === "error" && (
        <span className="money__icon-error">
          <Icon icon="error" size="xs" />
        </span>
      )}
    </div>
  );
};

MoneyInput.defaultProps = {
  size: "default",
  status: "default",
  value: "",
  width: "144px",
  disabled: false,
  placeholder: "",
  currency: "￥",
  onFocus: () => {},
  onBlur: () => {},
};

export default MoneyInput;
