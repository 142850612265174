import React, { useEffect } from "react";
import Dialog from "../components/Dialog/Dialog";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import useNli005DbActions from "../hooks/pages/NLI005/useNli005DbActions";
import useNli005Input, {
  checkUserDetailInputError,
  setUserName,
} from "../hooks/input/useNli005Input";
import { useCheckErrorThrowError } from "../utils/checkError";

type NLI005Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const NLI005: React.FC<NLI005Props> = ({ isOpen, setIsOpen }) => {
  const [id, setId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [{ userName }] = useNli005Input();
  const { fetchResult, fetchError, actionError, handleUpdateUser } =
    useNli005DbActions();

  useCheckErrorThrowError([fetchError, actionError]);

  useEffect(() => {
    if (fetchResult.full_name) setUserName(fetchResult.full_name);
    setUserId(fetchResult.user_id);
    setId(fetchResult._id);
  }, [fetchResult]);

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = React.useCallback(
    () => setIsOpen(false),
    [setIsOpen],
  );

  // 登録ボタン押下
  const handleUpdateUserButton = () => {
    handleUpdateUser(id, userName.value);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        title="編集"
        size="medium"
        height="310px"
        open={isOpen}
        closeDialog={handleCloseButton}
        footerRight={
          <LayoutBox>
            <Button
              color="neutral"
              type="sub"
              width="108px"
              size="large"
              onClick={handleCloseButton}
            >
              キャンセル
            </Button>
            <Button
              type="primary"
              width="64px"
              size="large"
              onClick={handleUpdateUserButton}
              disabled={checkUserDetailInputError()}
            >
              登録
            </Button>
          </LayoutBox>
        }
      >
        <LayoutBox direction="column" fullWidth>
          <FormSet label="ID" vertical>
            <div className="util-mt-8 util-mb-8">
              <Text>{userId}</Text>
            </div>
          </FormSet>

          <FormSet
            label="ユーザー名"
            vertical
            errorTextList={[userName.validationMessage]}
          >
            <Input
              size="default"
              value={userName.value}
              placeholder="ユーザー名"
              width="100%"
              onChange={(value) => setUserName(value)}
              error={userName.isError}
            />
          </FormSet>
        </LayoutBox>
      </Dialog>
    </>
  );
};

export default NLI005;
