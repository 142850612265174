import commonValidation, {
  INIT_VALIDATION_RESULT,
  VALIDATION_TYPE,
  ValidationResultType,
} from "../../utils/commonValidation";
import createReactiveVar from "../common/createReactiveVar";
import {
  ReactiveVarHooks,
  useReactiveVarHooks,
} from "../common/useReactiveVarHooks";

// バリデーションの必要な型定義
type DefaultValidationType = {
  nameSei: ValidationResultType<string>;
  nameMei: ValidationResultType<string>;
  nameSeiKana: ValidationResultType<string>;
  nameMeiKana: ValidationResultType<string>;
  tel: ValidationResultType<string>;
  email: ValidationResultType<string>;
  zipcode: ValidationResultType<string>;
  city: ValidationResultType<string>;
  town1: ValidationResultType<string>;
  birthYear: ValidationResultType<string>;
  birthMonth: ValidationResultType<string>;
  birthDay: ValidationResultType<string>;
};
// バリデーションの不要な型定義
type DefaultType = {
  town2: string;
  pref: string;
  gender: string;
};

const INIT_STATE: DefaultValidationType & DefaultType = {
  nameSei: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameMei: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameSeiKana: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  nameMeiKana: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  tel: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  email: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  zipcode: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  city: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  town1: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  birthYear: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  birthMonth: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  birthDay: {
    value: "",
    ...INIT_VALIDATION_RESULT,
  },
  pref: "",
  town2: "",
  gender: "",
};

// 入力状態を管理
const stateReactiveVar = createReactiveVar<DefaultValidationType & DefaultType>(
  INIT_STATE,
);
const useUserEditInput = (): ReactiveVarHooks<
  DefaultValidationType & DefaultType
> => useReactiveVarHooks(stateReactiveVar);

// バリデーションチェックを行いながら値をセット
const _setUserEditValidation = (
  key: keyof DefaultValidationType,
  value: DefaultValidationType[keyof DefaultValidationType]["value"],
  validationType: VALIDATION_TYPE,
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    [key]: {
      value,
      ...commonValidation(value, validationType),
    },
  });
};

// 各種バリデーションチェック付きの値セット
export const setNameSei = (
  value: DefaultValidationType["nameSei"]["value"],
) => {
  _setUserEditValidation("nameSei", value, VALIDATION_TYPE.USER_NAME_REQUIRED);
};
export const setNameMei = (
  value: DefaultValidationType["nameMei"]["value"],
) => {
  _setUserEditValidation("nameMei", value, VALIDATION_TYPE.USER_NAME_REQUIRED);
};
export const setNameSeiKana = (
  value: DefaultValidationType["nameSeiKana"]["value"],
) => {
  _setUserEditValidation(
    "nameSeiKana",
    value,
    VALIDATION_TYPE.USER_NAME_REQUIRED,
  );
};
export const setNameMeiKana = (
  value: DefaultValidationType["nameMeiKana"]["value"],
) => {
  _setUserEditValidation(
    "nameMeiKana",
    value,
    VALIDATION_TYPE.USER_NAME_REQUIRED,
  );
};
export const setTel = (value: DefaultValidationType["tel"]["value"]) => {
  _setUserEditValidation("tel", value, VALIDATION_TYPE.TEL_REQUIRED);
};
export const setEmail = (value: DefaultValidationType["email"]["value"]) => {
  _setUserEditValidation("email", value, VALIDATION_TYPE.EMAIL_REQUIRED);
};
export const setZipcode = (
  value: DefaultValidationType["zipcode"]["value"],
) => {
  _setUserEditValidation("zipcode", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};
export const setCity = (value: DefaultValidationType["city"]["value"]) => {
  _setUserEditValidation("city", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};
export const setTown1 = (value: DefaultValidationType["town1"]["value"]) => {
  _setUserEditValidation("town1", value, VALIDATION_TYPE.ADDRESS_REQUIRED);
};
export const setPref = (value: DefaultType["pref"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    pref: value,
  });
};
export const setTown2 = (value: DefaultType["town2"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    town2: value,
  });
};
export const setBirthYear = (
  value: DefaultValidationType["birthYear"]["value"],
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    birthYear: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthMonth = (
  value: DefaultValidationType["birthMonth"]["value"],
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    birthMonth: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setBirthDay = (
  value: DefaultValidationType["birthDay"]["value"],
) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    birthDay: {
      value,
      isError: false,
      validationMessage: "",
    },
  });
};
export const setGender = (value: DefaultType["gender"]) => {
  const currentInput = stateReactiveVar();
  stateReactiveVar({
    ...currentInput,
    gender: value === "男" ? "male" : "female",
  });
};

// 値をすべて更新
export const setUserEditInput = ({
  nameSei,
  nameMei,
  nameSeiKana,
  nameMeiKana,
  tel,
  email,
  zipcode,
  pref,
  city,
  town1,
  town2,
  birthYear,
  birthMonth,
  birthDay,
  gender,
}: {
  nameSei: DefaultValidationType["nameSei"]["value"];
  nameMei: DefaultValidationType["nameMei"]["value"];
  nameSeiKana: DefaultValidationType["nameSeiKana"]["value"];
  nameMeiKana: DefaultValidationType["nameMeiKana"]["value"];
  tel: DefaultValidationType["tel"]["value"];
  email: DefaultValidationType["email"]["value"];
  zipcode: DefaultValidationType["zipcode"]["value"];
  city: DefaultValidationType["city"]["value"];
  town1: DefaultValidationType["town1"]["value"];
  pref: DefaultType["pref"];
  town2: DefaultType["town2"];
  birthYear: DefaultValidationType["birthYear"]["value"];
  birthMonth: DefaultValidationType["birthMonth"]["value"];
  birthDay: DefaultValidationType["birthDay"]["value"];
  gender: DefaultType["gender"];
}) => {
  setNameSei(nameSei);
  setNameMei(nameMei);
  setNameSeiKana(nameSeiKana);
  setNameMeiKana(nameMeiKana);
  setTel(tel);
  setEmail(email);
  setZipcode(zipcode);
  setCity(city);
  setTown1(town1);
  setPref(pref);
  setTown2(town2);
  setBirthYear(birthYear);
  setBirthMonth(birthMonth);
  setBirthDay(birthDay);
  setGender(gender);
};

// 初期化
export const setInitUserEditInput = () => {
  stateReactiveVar(INIT_STATE);
};

// 入力エラーチェック エラーの場合true
export const checkUserEditInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    pref: _pref,
    town2: _town2,
    gender: _gender,
    ...validationInfos
  } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

// 招待者・被招待者のユーザチェック
export const checkInvitersAndInviteesInputError = (): boolean => {
  const currentInfos = stateReactiveVar();
  const {
    pref: _pref,
    town2: _town2,
    gender: _gender,
    zipcode: _zipcode,
    town1: _town1,
    city: _city,
    ...validationInfos
  } = currentInfos;
  const validationValues = Object.values(validationInfos);

  // 必須チェック
  const isEmpty = validationValues.some(({ value }) => value === "");
  if (isEmpty) return true;

  // バリデーションチェック
  const isValidationError = validationValues.some(({ isError }) => isError);
  if (isValidationError) return true;

  return false;
};

export default useUserEditInput;
