import { useEffect, useState } from "react";
import { useRealmApp } from "../contexts/RealmApp";
import { getAggregateHospitalTimeInterval, getMongoDb } from "../utils/query";
import { Collection } from "../constants/common";
import { addLoadCount, decrementLoadCount } from "./base/useLoadingPage";
import {
  checkFetchErr,
  redirectToNoDataPage,
} from "../contexts/CustomErrorBoundary";

// 病院の枠時間を取得
const useHospitalTimeIntervalFetch = () => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // 病院ID
  const hospitalId: string = currentUser
    ? (currentUser.customData.hospital_id as string)
    : "";

  const [hospitalInfo, setHospitalInfo] =
    useState<HospitalTimeIntervalType | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const mongoDb = getMongoDb(currentUser, Collection.HOSPITALS);
        const conditions = { _id: hospitalId };
        const aggregate = getAggregateHospitalTimeInterval(conditions);
        const results = (await mongoDb.aggregate(
          aggregate,
        )) as HospitalTimeIntervalType[];

        if (results.length < 1) redirectToNoDataPage();
        setHospitalInfo(results[0]);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    void fetchData();
  }, [currentUser, hospitalId]);

  return { error, hospitalInfo };
};

export default useHospitalTimeIntervalFetch;
